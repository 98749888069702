import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { GraficosService } from 'src/app/service/graficos.service';

@Component({
  selector: 'app-consumo-motobomba',
  templateUrl: './consumo-motobomba.component.html',
  styleUrls: ['./consumo-motobomba.component.css']
})
export class ConsumoMotobombaComponent implements OnInit {

  idCliente = localStorage.getItem('cliente_filtro');
  dataInicio = localStorage.getItem('DateStartGraficos');
  dataFinal = localStorage.getItem('DateEndGraficos');
  validaResp = false;
  loading = true;
  barChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
  };
  barChartLabels: any;
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartData: ChartDataSets[] = [];
  erro = false;

  constructor( 
    private graficosService: GraficosService
    ) { }

  ngOnInit(): void {
    this.consumo()
  }

  consumo() {
    this.graficosService.graficoConsumoMotobomba(this.dataInicio, this.dataFinal, this.idCliente).subscribe((resposta: any) => {
        this.loading = false;
        if(resposta.frotas.length == 0){
            this.validaResp = true
        }else{
            this.barChartLabels = resposta.frotas
            this.barChartData = [{ data: resposta.gastos, label: 'Litros/Hora' }]
        }
    }, err =>{
      this.loading = false;
      this.erro = true;
    })
}
}
