import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';
import { HttpOptionsService } from './header-options.service';

@Injectable({
  providedIn: 'root'
})
export class ProdutividadeHectaresService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  AcharHectares(cliente: any, dataInicial: any, dataFinal: any) {
    let url = `${this.BASE_URL_HIDROMETER}produtividade/cliente/${cliente}/data/${dataInicial}/${dataFinal}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }
}
