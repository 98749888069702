<div class="pagina" (click)="fecharDialog()">
    <video id="animacao-top" class="animacao" autoplay>
        <source src="/assets/animacao-whatsapp.webm" type="video/webm">
    </video>
    <div class="d-flex flex-column justify-content-start align-items-end content" (click)="$event.stopPropagation()">
        <button class="botao-fechar" (click)="fecharDialog()">
            <img class="icones" src="/assets/icones-gerais/fechar-azul.svg" alt="Icone de fechar">
        </button>
        <div class="d-flex flex-column texto">
            <img id="riana-whatsapp" src="/assets/rianaWhatsapp.png">
            <h2>Obrigada pelo seu interresse!</h2>
            <p class="lh-sm">Agradecemos pelo seu interesse em explorar esta funcionalidade.</p>
            <p class="lh-sm">Nossa equipe está dedicada a oferecer a melhor experiência possível, e estamos empolgados
                em
                trazeressa novidade para você.</p>
            <p class="lh-sm">Deixe o WhatsApp para receber as notificações e em breve entraremos em contato.</p>

            <form [formGroup]="formWhatsapp" (ngSubmit)="inputWhatsapp()" class="d-flex envio-numero"
                [class]="{'success': validacao, 'fail': validacao === false}">
                <input formControlName="vc_telefone" class="input-whatsapp" placeholder="Digite seu Whatsapp"
                    type="text" (ngModelChange)="resetaVerificacao()" name="vc_telefone" id="vc_telefone"
                    mask="+00(00) 00000-0000" required>
                <button #botaoEnviar class="btn btn-sm botao-enviar" type="submit" [disabled]="!formWhatsapp.valid">
                    <span *ngIf="validacao === null">Enviar</span>
                    <img *ngIf="validacao" class="icones joia" src="/assets/icones-gerais/joia.svg"
                        alt="Icone de sucesso">
                    <img *ngIf="validacao === false" class="icones" src="/assets/icones-gerais/fechar.svg"
                        alt="Icone de erro">
                </button>
            </form>
            <span *ngIf="validacao === false" class="error">Ocorreu um erro, por favor tente novamente</span>
        </div>
    </div>
    <video id="animacao-bottom" class="animacao" autoplay>
        <source src="/assets/animacao-whatsapp.webm" type="video/webm">
    </video>
</div>