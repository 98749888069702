import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';
import { HttpOptionsService } from './header-options.service';

@Injectable({
  providedIn: 'root'
})
export class ArquivosService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  importarLinha(arquivo: any) {
    let url = `${this.BASE_URL_HIDROMETER}linhas`
    return this.http.post(url, arquivo, this.httpOptionsService.getHttpOptionsAuth())
  }
  EncontrarLinha(id: any, dataInicial: any, dataFinal: any) {
    let url = `${this.BASE_URL_HIDROMETER}linhas/url/cliente/${id}/${dataInicial}/${dataFinal}`
    return this.http.get(url, this.httpOptionsService.getHttpOptionsAuth())
  }

  importarShape(arquivo: any) {
    let url = `${this.BASE_URL_HIDROMETER}shapes`
    return this.http.post(url, arquivo, this.httpOptionsService.getHttpOptionsAuth())
  }

  EncontrarShape(id: any, dataInicial: any, dataFinal: any) {
    let url = `${this.BASE_URL_HIDROMETER}shapes/url/cliente/${id}/${dataInicial}/${dataFinal}`
    return this.http.get(url, this.httpOptionsService.getHttpOptionsAuth())
  }

  listarPoligonos(cliente: any, shape: any) {
    let url = `${this.BASE_URL_HIDROMETER}poligonos/cliente/${cliente}/shape/${shape}`
    return this.http.get(url, this.httpOptionsService.getHttpOptionsAuth())
  }
}
