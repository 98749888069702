import { BASE_URL_HIDROMETER } from './../app.constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpOptionsService } from './header-options.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  setarBlMensagens(atualizacaoUsuario: object): Observable<any> {
    const url = `${this.BASE_URL_HIDROMETER}usuarios/alterarBlMensagensEmTempoReal`;
    return this.http.put(url, atualizacaoUsuario, this.httpOptionsService.getHttpOptions());
  }
}
