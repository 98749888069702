import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { RedirectService } from 'src/app/service/redirect.service';


@Component({
  selector: 'app-corpo-notificacoes',
  templateUrl: './corpo-notificacoes.component.html',
  styleUrls: ['./corpo-notificacoes.component.css']
})
export class CorpoNotificacoesComponent implements OnInit {
  @ViewChild('selectEquip') selectEquip!: MatSelect;

  constructor(private redirectService: RedirectService) { }

  mensagensExibidas: Array<any> = [];
  mensagensFiltradasPorEquipamento: Array<any> = [];
  mensagensFiltradasPorTipo: Array<any> = [];
  mensagensLocalStorageString: any;
  imagemClicada: string = '';
  qtdFiltro: number = 0;
  loader = true;

  filtro: any = {
    equipamento: '',
    mensagem: []
  }

  mensagensWebSocket: Array<any> = [];

  optionsFiltroAlertas: Array<any> = [
    {
      titulo: "Não justificadas",
      imagem: "assets/icones-botoes-acao-mapa/alertaNaoJustificado.svg",
      valor: "PARADA NÃO JUSTIFICADA"
    },
    {
      titulo: "Paradas",
      imagem: "assets/icones-botoes-acao-mapa/alertaParado.svg",
      valor: "PARADA"
    },
    {
      titulo: "Alertas",
      imagem: "assets/icones-botoes-acao-mapa/alertas.svg",
      valor: "ALERTA"
    },
    {
      titulo: "Comandos remotos",
      imagem: "assets/icones-botoes-acao-mapa/alertaComandoRemoto.svg",
      valor: "COMANDO"
    },
    {
      titulo: "Erros operacionais",
      imagem: "assets/icones-botoes-acao-mapa/alertaErro.svg",
      valor: "ERRO"
    }
  ];

  ngOnInit(): void {
    this.redirectService.mensagemWebSocket.subscribe(() => [
      this.recuperarMensagensLocalStorage()
    ]);
    this.redirectService.loaderMensagemWebSocket.subscribe((exibirLoader: boolean) => {
      this.loader = exibirLoader
    });
    this.recuperarMensagensLocalStorage();
  }

  recuperarMensagensLocalStorage(): void {
    this.mensagensLocalStorageString = localStorage.getItem('mensagensWebsocket');
    let mensagens = JSON.parse(this.mensagensLocalStorageString);
    this.mensagensWebSocket = mensagens;
    if (this.mensagensWebSocket != null) {
      this.loader = false;
      this.filtrarMensagens();
    }
  }

  abrirSelectMensagens(): void {
    this.selectEquip.open();
  }

  enviarFiltroEquipamento(itemFiltro: string | Array<string>): void {
    if (typeof itemFiltro == 'string') {
      let equipAnterior = this.filtro.equipamento
      this.filtro.equipamento = itemFiltro
      if (this.filtro.equipamento == equipAnterior) {
        this.filtro.equipamento = ''
        this.imagemClicada = ''
      } else {
        this.imagemClicada = itemFiltro
      }
    } else {
      this.filtro.mensagem = itemFiltro
    }
    this.filtrarMensagens();
  }

  filtrarMensagens(): void {
    this.mensagensExibidas = this.mensagensWebSocket;

    if (this.filtro.equipamento != '') {
      this.mensagensExibidas = this.mensagensWebSocket.filter(equip => equip.tipoEquipamento == this.filtro.equipamento);
    }

    if (this.filtro.mensagem.length != 0) {
      this.mensagensExibidas = this.mensagensExibidas.filter(valor => this.filtro.mensagem.includes(valor.tipo));
    }
  }

  iconeTipoEquipamento(tipoEquipamento: string): string {
    switch (tipoEquipamento) {
      case 'Motobomba':
      case 'Eletrobomba':
        return 'assets/icones-botoes-acao-mapa/motobombaAzul.svg';
      case 'Carretel':
        return 'assets/icones-botoes-acao-mapa/carretelAzul.svg';
      case 'Trator':
        return 'assets/icones-botoes-acao-mapa/tratorAzul.svg';
      default:
        return '';
    }
  }

  atividadeFiltroMensagem(): void {
    this.qtdFiltro = this.filtro.mensagem.length;
  }
}
