import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAPBOX_TOKEN } from 'src/app/app.constants';
import { MensagensService } from 'src/app/service/mensagens.service';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-mensagem-parada-mapa',
  templateUrl: './mensagem-parada-mapa.component.html',
  styleUrls: ['./mensagem-parada-mapa.component.css']
})
export class MensagemParadaMapaComponent implements OnInit {

  map!: mapboxgl.Map;
  loadMapa = true;
  motivo: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mensagensService: MensagensService) { }

  ngOnInit(): void {
    this.mostrarParadaNoMapa();
  }
  mostrarParadaNoMapa() {
    (mapboxgl as any).accessToken = MAPBOX_TOKEN;

    this.map = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/mapbox/satellite-streets-v11?optimize=true', // style URL
      center: [this.data.lng, this.data.lat], // starting position
      zoom: 12 // starting zoom
    });

    new mapboxgl.Marker().setLngLat([this.data.lng, this.data.lat]).addTo(this.map);
    this.map.flyTo({
      center: [this.data.lng, this.data.lat]
    });
  }

}
