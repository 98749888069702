<div *ngIf="!load">
    <div class="row mb-3">
        <div class="col border-right">
            <div class="d-flex flex-column justify-content-start align-items-start mb-2">
                <div>
                    <img src="assets/icone-config.png" style="width: 1.2rem;">
                    <span class="texto-ultima-mensagem"> Status do motor</span>
                </div>
                <span class="texto-informacoes ml-4">{{statusOperacao}}</span>
            </div>
            <div class="d-flex flex-column justify-content-start align-items-start mb-2">
                <div>
                    <img src="assets/icone-pressao.png" style="width: 1rem;">
                    <span class="texto-ultima-mensagem"> Pressão de entrada</span>
                </div>
                <span class="texto-informacoes ml-4">{{pressaoEntrada}}</span>
            </div>
            <div class="d-flex flex-column justify-content-start align-items-start mb-2">
                <div>
                    <img src="assets/icone-pressao.png" style="width: 1rem;">
                    <span class="texto-ultima-mensagem"> Pressão de saída</span>
                </div>
                <span class="texto-informacoes ml-4">{{pressaoSaida}}</span>
            </div>
            <div class="d-flex flex-column justify-content-start align-items-start mb-2">
                <div>
                    <img src="assets/icone-motobomba.png" style="width: 1.3rem;">
                    <span class="texto-ultima-mensagem"> RPM do motor</span>
                </div>
                <span class="texto-informacoes ml-4">{{rpmMotor}}</span>
            </div>
        </div>
        <div class="col">
            <div class="d-flex flex-column justify-content-start align-items-start mb-2">
                <div>
                    <img src="assets/icone-succao.png" style="width: 1.1rem;">
                    <span class="texto-ultima-mensagem"> Nível de sucção</span>
                </div>
                <span class="texto-informacoes ml-4">{{nivelSuccao}}</span>
            </div>
            <div class="d-flex flex-column justify-content-start align-items-start mb-2">
                <div>
                    <img src="assets/icone-combustivel.png" style="width: .7rem;">
                    <span class="texto-ultima-mensagem"> Nível de combustível</span>
                </div>
                <span class="texto-informacoes ml-4">{{nivelDeCombustivel}}</span>
            </div>

            <div class="d-flex flex-column justify-content-start align-items-start mb-2">
                <div>
                    <img src="assets/icone-alerta.svg" style="width: 1.5vw;">
                    <span class="texto-ultima-mensagem" [ngClass]="{'text-danger': alertas.includes('1')}">
                        Alertas</span>
                </div>
                <div class="row mt-2">
                    <ng-container *ngFor="let alert of alertasConfig.slice(0, finalIndex); let i = index">
                        <div class="col-2 mt-2" *ngIf="alert.icone==true">
                            <img [src]="getAlertasIcones(i)" class="icone" [matTooltip]="alert.descricao">
                        </div>
                    </ng-container>
                    <div class="col-2 mt-2" *ngIf="alertas.length > 16">
                        <img [src]="getAlertasSemIcones()" class="icone" [matTooltip]="descricoesAlertasSemIcone">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <span class="texto-ultima-mensagem"><img src="assets/icone-chat.png" style="width: 1.2rem;">
            Última mensagem
        </span>
        <hr />
        <div class="d-flex justify-content-between">
            <span class="texto">{{tipoMensagem}} </span>
            <span class="texto-data">
                {{ultimaMensagem == 'Sem dados' ? ' ' : ultimaMensagem | date: "dd/MM/yyyy HH:mm:ss"}}
            </span>
        </div>
        <hr />
    </div>
</div>
<div *ngIf="load" style="width: auto;" fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="load" class="spinner"></div>
</div>