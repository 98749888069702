<main class="container mt-4" fxLayout="column" fxLayoutAlign="center center">
    <div class="d-flex">
        <h1>Editar característica adicional</h1>
    </div>
    <div class="row">
        <form [formGroup]="addDataForm" fxLayout="column" fxLayoutAlign="space-evenly center" fxShow.gt-xs fxHide.xs>
            <div class="form-row" fxLayout="column" fxLayoutAlign="space-evenly center">
                <div class="form-row">
                    <mat-form-field appearance="outline" fxFlex style="width: 30vw">
                        <mat-label>Característica</mat-label>
                        <label>
                            <input matInput formControlName="name" autocomplete="off" required />
                        </label>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex style="width: 30vw;" fxFlexOffset="1.5rem">
                        <mat-label>Tipo</mat-label>
                        <mat-select formControlName="tipo" required [disabled]="disabled">
                            <mat-option value="string">Texto</mat-option>
                            <mat-option value="int">Número</mat-option>
                            <mat-option value="timestamp">Hora</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-row">
                    <mat-form-field appearance="outline" fxFlex style="width: 30vw">
                        <mat-label>Atribuição</mat-label>
                        <mat-select formControlName="atribuicao" required [disabled]="disabled">
                            <mat-option value="operacao">Operação</mat-option>
                            <mat-option value="equipamento">equipamento</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <pre></pre>
            </div>
            <div class="form-row" fxLayout="row" fxLayoutAlign="center">
                <button mat-raised-button class="botao-criar" type="button" [disabled]="!addDataForm.valid"
                    (click)="done()">
                    Gravar
                </button>
                <button mat-raised-button class="botao-excluir" type="reset" fxFlexOffset="10px" (click)="cancel()">
                    Cancelar
                </button>
            </div>
        </form>
    </div>
</main>