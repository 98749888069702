import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HectaresService {
  private hectaresSource = new BehaviorSubject<any[]>([]);
  hectares$ = this.hectaresSource.asObservable();

  updateHectares(hectares: any[]) {
    this.hectaresSource.next(hectares);
  }
}
