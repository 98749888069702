import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';
import { HttpClient } from '@angular/common/http';
import { HttpOptionsService } from './header-options.service';

@Injectable({
  providedIn: 'root'
})
export class FaixasMapaService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  public faixasMapaPeriodo(params: any) {
    const url = `${this.BASE_URL_HIDROMETER}faixasMapa/buscarFaixasNoPeriodo/cliente/${params.cliente}/${params.dataInicial}/${params.dataFinal}/${params.fuso}/${params.operacaoId}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }
  
  public informacoesDaFaixa(idFaixa: number, tipoFaixa: string, fuso?: string) {
    let url = `${this.BASE_URL_HIDROMETER}faixasMapa/buscarDadoDaFaixa/idFaixa/${idFaixa}/${tipoFaixa}`;
    if (fuso) {
      url += `/${fuso}`;
    }
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }
}
