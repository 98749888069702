<div fxLayout="row" fxLayoutAlign="center center">
    <div fxLayout="column" fxLayoutAlign="center center">
        <div *ngIf="loading" class="spinner"></div>
        <br *ngIf="loading">
        <span *ngIf="loading" style="margin-top: 0;">A OS pode conter bastante faixas, o que demorará um pouco para serem concluídas</span>
    </div>
    <form *ngIf="!loading" [formGroup]="BocalForm"  fxLayout="column" fxLayoutAlign="space-evenly center">
        <h2 style="margin-top: 0;">Pintar faixas da OS {{os}}</h2>
        <mat-form-field appearance="outline" fxFlex>
            <mat-label>Pintar por:</mat-label>
            <mat-select formControlName="en_tipo_repintura">
                <mat-option value="lamina">
                    Lâmina
                </mat-option>
                <mat-option value="pressao">
                    Pressão
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="form-row" *ngIf="validacao.includes('pressao')">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Pressão máxima</mat-label>
                <label>
                    <input matInput formControlName="fl_maximo" autocomplete="off"  />
                </label>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex fxFlexOffset="1.5rem">
                <mat-label>Pressão mínima</mat-label>
                <label>
                    <input matInput formControlName="fl_minimo" autocomplete="off"  />
                </label>
            </mat-form-field>
        </div>
        <div class="form-row" *ngIf="validacao.includes('lamina')">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Lâmina máxima</mat-label>
                <label>
                    <input matInput formControlName="fl_maximo" autocomplete="off"  />
                </label>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex fxFlexOffset="1.5rem">
                <mat-label>Lâmina mínima</mat-label>
                <label>
                    <input matInput formControlName="fl_minimo" autocomplete="off"  />
                </label>
            </mat-form-field>
        </div>
        <div fxLayout="column" *ngIf="validacao.includes('lamina')" >
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Bocal dos carreteis</mat-label>
                <label>
                    <input matInput formControlName="fl_bocal" autocomplete="off"  />
                </label>
            </mat-form-field>
        </div>
        <div class="form-row_botoes" fxLayout="row" fxLayoutAlign="center" fxFlexOffset="3%">
            <button mat-raised-button class="botao-criar" (click)="enviarRepintarFaixas()">
                Gravar
            </button>
            <button mat-raised-button class="botao-excluir" type="reset" fxFlexOffset="10px" (click)="cancel()">
                Cancelar
            </button>
        </div>
    </form>
</div>