<div class="row w-100 h-100 pl-2 overflow-hidden" *ngIf="conteudo.length > 0">
    <div class="col-6 conteudo">
        <div class="d-flex align-items-start flex-column h-100">
            <div class="row logo"><img src="../../assets/Logo Hidrometer original.jpg" class="imagem-logo img-fluid"></div>
            <h3 class="titulo mt-2 mb-1">Versão {{data.it_versao_principal}}.{{data.it_feature}}.{{data.it_bug}}</h3>
            <h2 class="titulo">{{conteudo[pagina]['vc_titulo']}}</h2>
            <h4 class="subtitulo">{{conteudo[pagina]['vc_subtitulo']}}</h4>
            <p class="informacao text-justify pr-2 mb-1">{{conteudo[pagina]['vc_informacao']}}</p>
            <div class="form-check form-check-inline input-checkbox mt-auto mb-1" *ngIf="pagina+1 < conteudo.length">
                <input class="form-check-input" type="checkbox" id="naoMostrarNovamente" name="naoMostrarNovamente" value="sim" #naoMostrarNovamente>
                <label class="form-check-label" for="naoMostrarNovamente">Não mostrar novamente</label>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="midia" *ngIf="conteudo[pagina]['vc_midia'] != null">
            <img src="{{conteudo[pagina]['vc_midia']}}" alt="{{conteudo[pagina]['vc_legenda_midia']}}"
                title="{{conteudo[pagina]['vc_legenda_midia']}}">
        </div>
        <div class="midia" *ngIf="conteudo[pagina]['vc_midia'] == null">
            <img src="../../assets/RIANA.png" alt="Riana" title="Riana">
        </div>
    </div>
</div>
<div class="btn-proximo" *ngIf="pagina+1 < conteudo.length" (click)="proximaPagina()">
    <img src="assets/icones-gerais/seta_proximo.svg" alt="Proximo" class="icone-seta">
</div>
<div class="btn-anterior" *ngIf="pagina > 0" (click)="paginaAnterior()">
    <img src="assets/icones-gerais/seta_voltar.svg" alt="Anterior" class="icone-seta">
</div>
<p class="texto-fechar" (click)="finalizarTutorial()">Fechar</p>
