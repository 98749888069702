import { Component, Input, OnInit } from '@angular/core';
import * as _ from "underscore";
import { Chart } from "chart.js";
import { GraficosService } from 'src/app/service/graficos.service';

@Component({
    selector: 'app-controle-rpm-motobomba',
    templateUrl: './controle-rpm-motobomba.component.html',
    styleUrls: ['./controle-rpm-motobomba.component.css']
})
export class ControleRpmMotobombaComponent implements OnInit {
    constructor(
        private graficosService: GraficosService
    ) { }

    public idClienteDoFiltro = localStorage.getItem('cliente_filtro');
    public dataInicialDoFiltro = localStorage.getItem('DateStartGraficos');
    public dataFinalDoFiltro = localStorage.getItem('DateEndGraficos');
    public idsEquipamentosDoFiltro = localStorage.getItem('IdsEquipamentos');
    public loading = true;
    public erro = false;
    public tipoDeInformacaoAExibir = 'todasMensagens';
    public contemInformacoes: boolean = true;
    public informacoesFiltradas = [];
    public myChart: any;
    public cores = [
        '#8C51A5', '#CB5E98', '#F47B8A', '#FFA37E', '#FFD285',
        '#FFFFA5', '#B9E49F', '#78C59B', '#24635B', '#00838D',
        '#089951', '#0DA600', '#8DB309', '#BFA500', '#CC8B0A',
        '#D95900', '#E6270B', '#F20084', '#B20CF9', '#2700FD'
      ];

    ngOnInit(): void {
        this.graficosService.graficoRPMMotobomba(this.idClienteDoFiltro, this.idsEquipamentosDoFiltro, this.dataInicialDoFiltro, this.dataFinalDoFiltro).subscribe((info: any) => {
            this.loading = false
            if (info.length == 0) {
                this.contemInformacoes = false;
            } else {
                this.informacoesFiltradas = info;
                this.gerarGrafico();
            }
        }, err => {
            this.loading = false;
            this.erro = true;
        })
    }

    gerarGrafico() {
        var arrayDeInformacoesDeRPM: any = [];
        var arrayDeDatas: any = [];
        var arrayMensagemTooltip: any = [];

        this.informacoesFiltradas.forEach((informacoesRPM: any, index: number) => {
            const informacaoSelecionada = informacoesRPM.filter((objeto: any) => objeto.tipo === this.tipoDeInformacaoAExibir)[0];
            arrayDeDatas.push(informacaoSelecionada.arrayDeDatasDaMensagemDoEquipamento);
            arrayMensagemTooltip.push(informacaoSelecionada.arrayDeMensagemDoHover);
            var informacaoDoGrafico: any = [];
            var coresDaLinha = informacaoSelecionada.arrayDeCores;
            informacaoSelecionada.arrayDeValoresDoEquipamento.forEach((element: any, indice: any) => {
                informacaoDoGrafico.push({ x: informacaoSelecionada.arrayDeDatasDaMensagemDoEquipamento[indice], y: element })
            });

            var infoDoGrafico = {
                label: informacaoSelecionada.frotaEquipamento,
                data: informacaoDoGrafico,
                pointRadius: 3,
                borderColor: this.cores[index],
                pointBorderColor: coresDaLinha,
                pointBackgroundColor: coresDaLinha,
                borderWidth: 1,
                fill: false,
            };
            arrayDeInformacoesDeRPM.push(infoDoGrafico);
        });

        if (this.myChart != undefined) {
            this.myChart.destroy()
        }

        var labelEixoX: any = [];
        arrayDeDatas.forEach((arrayDeDatas: any) => {
            labelEixoX = labelEixoX.concat(arrayDeDatas);
        });
        labelEixoX = [... new Set(labelEixoX.sort())]

        var ctx = document.getElementById("graficoVelocidade") as HTMLCanvasElement;
        this.myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labelEixoX,
                datasets: arrayDeInformacoesDeRPM
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    callbacks: {
                        title: function (tooltipItem: any, data) {
                            var datasetIndex = tooltipItem[0].datasetIndex ?? 0;
                            var index: any = tooltipItem[0].index;
                            return 'Data: ' + arrayDeDatas[datasetIndex][index];
                        },
                        label: function (tooltipItem: any) {
                            var datasetIndex = tooltipItem.datasetIndex ?? 0;
                            var index: any = tooltipItem.index;
                            return arrayMensagemTooltip[datasetIndex][index];
                        }
                    },
                    backgroundColor: '#FFF',
                    titleFontSize: 14,
                    titleFontColor: '#0066ff',
                    bodyFontColor: '#000',
                    bodyFontSize: 10,
                    displayColors: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontColor: '#393939',
                            fontStyle: 'bold',
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Rotação por minuto',
                        }
                    }],
                    xAxes: [{
                        offset: false,
                        gridLines: {
                            offsetGridLines: false,
                            lineWidth: 0
                        },
                        display: true,
                        ticks: {
                            suggestedMax: 10,
                            fontColor: '#393939',
                            fontStyle: 'bold',
                        },
                    }]
                },
            }
        });
    }

    get ocultarGrafico(): boolean { return this.loading || this.erro || !this.contemInformacoes; }
    get mostrarBotoes(): boolean { return !this.loading && !this.erro && this.contemInformacoes; }
}
