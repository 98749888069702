import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-modal-horario',
  templateUrl: './modal-horario.component.html',
  styleUrls: ['./modal-horario.component.css']
})
export class ModalHorarioComponent implements OnInit {

  constructor() { }

  utc = localStorage.getItem('horario') == null || localStorage.getItem('horario') == 'brt';
  brt = localStorage.getItem('horario') == 'utc';

  semana: number = 0
  dia: number = 0
  mes: number = 0
  ano: number = 0
  meses: Array<any> = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
  semanas: Array<any> = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
  mesFinal: Array<any> = []
  semanaFinal: Array<any> = []

  dataAtual: any;

  fusos: Array<any> = [
    { id: 'btnBrasilia', nome: 'Brasília, DF - Brasil (BRT)' },
    { id: 'btnUniversal', nome: 'Tempo Universal Coordenada (UTC)' }
  ]

  ngOnInit(): void {
    this.setarHorario();
  }

  setarHorario() {
    setTimeout(() => {
      this.atualizaHorario();
    }, 1000);
  }

  atualizaHorario() {
    if (localStorage.getItem('horario') == null || localStorage.getItem('horario') == 'brt') {
      this.ativaEstiloBrt();
      this.definirModo('brt');
    } else if (localStorage.getItem('horario') == 'utc') {
      this.ativaEstiloUtc();
      this.definirModo('utc');
    }
    this.setarHorario();
  }

  trocarHorario(fuso: any) {
    let horario = localStorage.getItem('horario')
    if (horario != 'utc' && fuso == 'btnUniversal') {
      localStorage.setItem('horario', 'utc')
      window.location.reload();
    } else if (horario != 'brt' && fuso == 'btnBrasilia') {
      localStorage.setItem('horario', 'brt')
      window.location.reload();
    }
  }

  definirModo(modo: string) {
    this.dataAtual = new Date;
    this.semana = this.dataAtual.getDay()
    this.dia = this.dataAtual.getDate();
    this.mes = this.dataAtual.getMonth();
    this.ano = this.dataAtual.getFullYear();
    this.mesFinal = this.meses[this.mes]
    this.semanaFinal = this.semanas[this.semana]
    if (modo == 'utc') {
      this.dataAtual.setHours(this.dataAtual.getHours() + 3)
    }
  }

  ativaEstiloBrt() {
    let botaoUniversal = document.getElementById('btnUniversal')
    botaoUniversal?.classList.remove('ativo')
    let botaoBrt = document.getElementById('btnBrasilia')
    botaoBrt?.classList.add('ativo')
  }

  ativaEstiloUtc() {
    let botaoBrt = document.getElementById('btnBrasilia')
    botaoBrt?.classList.remove('ativo')
    let botaoUniversal = document.getElementById('btnUniversal')
    botaoUniversal?.classList.add('ativo')
  }

}
