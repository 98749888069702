import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BASE_URL_PREMOCENTER_FRONT } from 'src/app/app.constants';
import { VerificarPermissoes } from 'src/app/model/verificar-premissao.model';
import { AlertService } from 'src/app/service/alert.service';
import { EquipamentosService } from 'src/app/service/equipamentos.service';
import Swal from 'sweetalert2';
import { DialogEquipamentosComponent } from '../dialog-equipamentos/dialog-equipamentos.component';
import { RedirectService } from 'src/app/service/redirect.service';

@Component({
  selector: 'app-lista-equipamentos',
  templateUrl: './lista-equipamentos.component.html',
  styleUrls: ['./lista-equipamentos.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ListaEquipamentosComponent implements OnInit {

  public columnsToDisplay = ["ts_criado_em", "ts_atualizado_em", "vc_tipo", "vc_frota", "vc_mobileId", "fk_ultimo_operador", "control"]
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public formFilter: FormGroup = this.formBuilder.group({
    client: [null, Validators.required],
  });
  public expandedElement: any;
  public filtroVisibilidade: number | undefined;
  public innerDisplayedColumns: string[] = ["name", "value", "controls"];
  public dadosAdicionais: MatTableDataSource<any> = new MatTableDataSource();
  public equipmentIdFeatures: any | null;
  LocalStorageId = localStorage.getItem("cliente_filtro");
  clients = JSON.parse(localStorage.getItem('cliente') || "{}")
  comunicadores: any = JSON.parse(localStorage.getItem('comunicadores') || "{}");
  urlPremoplan = BASE_URL_PREMOCENTER_FRONT;
  length: any;
  equipamentos: any;
  next: any;
  loading = true;
  validaResp = false;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private equipmentService: EquipamentosService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private alertService: AlertService,
    private appService: RedirectService
  ) { }

  ngOnInit(): void {
    if (this.verificarPermissao(['campo_versão'])) {
      this.columnsToDisplay.splice(5, 0, "vc_versao" )
    }
    this.popular();
    this.onChange();
  }

  popular() {
    this.formFilter = this.formBuilder.group({
      client: [null],
    });
    if (this.LocalStorageId != null) {
      this.validaResp = false;
      this.formFilter.get("client")?.setValue(this.LocalStorageId);
      this.equipmentService.AcharPorClientePaginate(this.LocalStorageId, 100, this.filtroVisibilidade).subscribe((data: any) => {
        if (data.data.length == 0) {
          this.validaResp = true
        }else{
          data.data.forEach((element: any) => {
            element?.comunicadores.forEach((com: any) => {
              var mobileId = this.comunicadores.filter(function (e: any) {
                return e.id == com.it_id_premocenter
              })
              element.vc_mobileId = mobileId[0]?.vc_mobileId;
            });
            if(!element.vc_mobileId){
              element.vc_mobileId = null;
            }
          });
        }
        this.length = data.total;
        this.equipamentos = data.data;
        this.dataSource.data = this.equipamentos;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.next = data.next_page_url;
        this.loading = false;
      }, (err: any) => {
        if (err.statusText == "Unauthorized") {
          window.location.href = `${this.urlPremoplan}`
        }
      });
    }
    this.onChange();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onChange() {
    this.formFilter.get("client")?.valueChanges.subscribe((clientId: any) => {
      this.loading = true
      this.validaResp = false
      localStorage.setItem("cliente_filtro", clientId);
      this.appService.enviarMudancaDeCliente();
      this.LocalStorageId = clientId
      this.popular()
    })
  }
  
  public filtrarVisibilidade(filtroVisibilidade?: number) :void {
    this.filtroVisibilidade = filtroVisibilidade;
    this.loading = true;
    this.popular();
  }

  public compararFiltroVisibilidade(resp: number | null){
    return resp === this.filtroVisibilidade;
  }

  public delete(equipmentId: number): void {
    Swal.fire({
      title: 'Excluir equipamento?',
      showCancelButton: true,
      confirmButtonColor: '#41B6E6',
      cancelButtonColor: '#df4747',
      confirmButtonText: 'Confirmar!'
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.equipmentService.Desativar(equipmentId).subscribe((resp: any) => {
          if (resp.status) {
            this.alertService.error('Este equipamento não pode ser excluído, pois ele está vinculado a uma OS ativa')
          } else {
            this.alertService.success('Equipamento excluído com sucesso!')
          }
          this.popular()
        }, (err: any) => {
          this.alertService.exibirMensagemErro(err, 'Erro ao excluir equipamento, possívelmente o modem está ativo');
        })
      }
    })
  }

  trocarPagina(event: any) {
    this.equipmentService.Next(this.next).subscribe((data: any) => {
      this.equipamentos = this.equipamentos.filter(function (i: any) {
        return i;
      });
      this.equipamentos.push(...data.data)
      this.equipamentos.length = data.total
      this.dataSource.data = this.equipamentos;
      this.next = data.next_page_url
    });
  }  

  public verificarPermissao(permissoesFuncionalidades: string[]): boolean {
    const permissaoUsuario = localStorage.getItem('permissoes');
    return VerificarPermissoes.temPermissao(permissoesFuncionalidades, permissaoUsuario)
  }

  public acompanhar(equipamento: any, frota: any) {
    const dialogRef = this.dialog.open(DialogEquipamentosComponent, {
      height: "90vh",
      width: "90vw",
      data: [equipamento, this.LocalStorageId, frota]
    });
  }

  public alterarVisibilidade(equipamentoId: number): void {
    this.loading = true;
    this.equipmentService.alterarVisibilidade(equipamentoId).subscribe((resp: any) => {
      let equipamento: any = resp.equipamento;
      if (equipamento.bl_oculto) {
        this.alertService.success(`Agora o equipamento ${equipamento.vc_frota} está oculto no mapa!`)
      } else {
        this.alertService.success(`Agora o equipamento ${equipamento.vc_frota} está exibido no mapa!`)
      }
      this.popular()
    }, (error: any) => {
      this.popular()
      this.alertService.error(error.error.status)
    })

  }
}
