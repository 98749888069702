<main style="width: 96%; margin: 0 2rem;">
    <div class="cabecalho">
        <div class="d-flex">
            <h1>Operações</h1>
            <div class="dropdown">
                <clr-icon shape="info-standard" class="info-icone"></clr-icon>
                <div class="dropdown-content">
                    <p>Uma operação consiste na agregação da frente de trabalho, que especifica a relação
                        entre um trator, uma motobomba, carretéis e uma ordem de serviço específica. </p>
                </div>
            </div>
        </div>
        <span class="info-listas">lista de todas as operações</span>
        <div class="linha">
            <form [formGroup]="formFilter" fxFlex.lt-md="100" fxLayout="row" fxLayoutAlign="center center">
                <label> Operações da usina:
                    <select class="filtro-listas" formControlName="client">
                        <option *ngFor="let client of clients" [value]="client.id">
                            {{ client.nome }}
                        </option>
                    </select>
                </label>
            </form>
            <div id="botoesOperacoes">
                <button class="botao-criar" routerLink="../criar"
                    *ngIf="verificarPermissao(['cadastrar_operacoes_hidrometer'])">
                    <clr-icon shape="cog" size="20" class="margem-clr-icon is-solid"></clr-icon>
                    Nova operação
                </button>
            </div>
        </div>
    </div>


    <div fxLayout="column">
        <input matInput (keyup)="applyFilter($event)" placeholder="Filtrar" autocomplete="off" class="input-pesquisa">

        <div *ngIf="loading" style="width: auto; height: 60vh;" fxLayout="row" fxLayoutAlign="center center">
            <div *ngIf="loading" class="spinner"></div>
        </div>
        <div class="mensagem">
            <p *ngIf="validaResp" style="text-align: center;">Não há operações cadastrados dessa usina</p>
        </div>

        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort [hidden]="loading">
            <ng-container matColumnDef="ts_criado_em">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Criado em:
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.ts_criado_em | date: 'dd/MM/yyyy HH:mm:ss' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="ts_atualizado_em">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Atualizado em:
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.ts_atualizado_em | date: "dd/MM/yyyy HH:mm:ss" }}
                </td>
            </ng-container>
            <ng-container matColumnDef="vc_estado">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
                <td mat-cell *matCellDef="let element">
                    <mat-menu #appMenu="matMenu">
                        <button mat-menu-item (click)="changeState(element.sr_id, element, 'RUNNING')">
                            Operar
                        </button>
                        <button mat-menu-item (click)="changeState(element.sr_id, element, 'PAUSED')">
                            Parar
                        </button>
                        <button mat-menu-item (click)="changeState(element.sr_id, element, 'COMPLETED')">
                            Finalizar
                        </button>
                        <button mat-menu-item (click)="changeState(element.sr_id, element, 'CANCELED')">
                            Cancelar
                        </button>
                    </mat-menu>

                    <button mat-button [matMenuTriggerFor]="appMenu" [disabled]="
                              element.vc_estado === 'COMPLETED' ||
                              element.vc_estado === 'CANCELED' ">
                        <span *ngIf="element.vc_estado === 'COMPLETED'">
                            FINALIZADO
                        </span>
                        <span *ngIf="element.vc_estado === 'CANCELED'">
                            CANCELADO
                        </span>
                        <span *ngIf="element.vc_estado === 'PAUSED'"> PARADO </span>
                        <span *ngIf="element.vc_estado === 'RUNNING'"> OPERANDO </span>
                        <span *ngIf="element.vc_estado === 'NEW'"> NOVO </span>
                        <img src="assets/icones-gerais/expandir_seta_para_baixo.svg" alt="Expandir opções">
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="vc_service_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Serviço</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.vc_service_id ? element.vc_service_id : "" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="trator">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Trator (Frota)</th>
                <td mat-cell *matCellDef="let element">
                    <ul class="lista-sem-bolinha">
                        <li *ngIf="element.trator != null"
                            [ngClass]="{'excluido': element.trator_frota[0].ts_deletado_em != null}">
                            {{ element.trator }}
                        </li>
                    </ul>
                </td>
            </ng-container>

            <ng-container matColumnDef="motobomba">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Motobomba (Frota)</th>
                <td mat-cell *matCellDef="let element">
                    <ul class="lista-sem-bolinha" *ngFor="let item of element.motobomba">
                        <li [ngClass]="{'excluido': item.ts_deletado_em != null}">{{ item.vc_frota ?
                            item.vc_frota : "not found" }}</li>
                    </ul>
                </td>
            </ng-container>

            <ng-container matColumnDef="carretel">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Carretéis (Frotas)</th>
                <td mat-cell *matCellDef="let element">
                    <ul class="lista-sem-bolinha" *ngFor="let item of element.carretel">
                        <li [ngClass]="{'excluido': item.ts_deletado_em != null}">{{ item.vc_frota ?
                            item.vc_frota : "" }}</li>
                    </ul>
                </td>
            </ng-container>

            <ng-container matColumnDef="fk_ultimo_operador">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Último operador</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.operador }}
                </td>
            </ng-container>

            <ng-container matColumnDef="controls">
                <th mat-header-cell class="align-center" *matHeaderCellDef>
                </th>
                <td mat-cell class="align-center" *matCellDef="let element">
                    <div>
                        <mat-menu #appMenu2="matMenu" fxLayoutAlign="center center" >
                            <div class="itens-menu" fxLayout="column">
                                <button class="botao-editar botao-espaco" routerLink="../editar/{{ element.sr_id }}"
                                    *ngIf="verificarPermissao(['editar_operacoes_hidrometer'])">
                                    <clr-icon shape="pencil"></clr-icon>Editar
                                </button>
                                <button class="botao-editar botao-espaco" routerLink="../monitor/{{element.sr_id}}"
                                    *ngIf="verificarPermissao(['exibir_mensagens_hidrometer'])">
                                    <clr-icon shape="file-group"></clr-icon>Acompanhar
                                </button>
                                <button class="botao-editar botao-espaco" id="EditarPinturasDaOS"
                                    (click)="openDialog(element.sr_id,element.vc_service_id, element)"
                                    *ngIf="verificarPermissao(['editar_bocais_hidrometer'])">
                                    <clr-icon shape="wand"></clr-icon>Editar pinturas da OS
                                </button>
                                <button class="botao-excluir botao-espaco3" (click)="delete(element.sr_id)"
                                    *ngIf="verificarPermissao(['deletar_operacoes_hidrometer'])">
                                    <clr-icon shape="trash"></clr-icon>
                                    Desativar
                                </button>
                            </div>
                        </mat-menu>
                        <img [matMenuTriggerFor]="appMenu2" matSuffix src="assets/icones-gerais/menu.svg" alt="Expandir opções" class="icone-menu">
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                    <div class="example-element-detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                        *ngIf="!element.dadosAdicionais.status">
                        <table mat-table [dataSource]="element.dadosAdicionais" matSort>
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef>
                                    Id
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.sr_id }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>
                                    Caracteristica
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.vc_nome }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="value">
                                <th mat-header-cell *matHeaderCellDef>
                                    Valor
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.valor ? element.valor : "" }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="controls">
                                <th mat-header-cell class="align-center" *matHeaderCellDef>
                                    Controles
                                </th>
                                <td mat-cell class="align-center" *matCellDef="let element">
                                    <button class="botao-editar"
                                        routerLink="../../caracteristicas/valor/{{ element.sr_id }}/{{ element.idOperacao }}/operacao"
                                        *ngIf="verificarPermissao(['editar_caracteristicas_adicionais_hidrometer'])">
                                        <clr-icon shape="pencil"></clr-icon>
                                        Editar
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="innerDisplayedColumnsOp"></tr>
                            <tr mat-row *matRowDef="
                                  let row;
                                  columns: innerDisplayedColumnsOp
                              "></tr>
                        </table>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
    </div>
    <mat-paginator (page)="trocarPagina($event)" fxLayoutAlign="center center" [length]="length" [pageSize]="100"
        [hidden]="loading">
    </mat-paginator>
</main>