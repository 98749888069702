<div class="h-100" id="container-grafico">
    <div class="d-flex flex-wrap justify-content-center">
        <div class="d-flex">
            <h4>Tempo efetivo por Os de motobomba</h4>
            <div class="dropdown">
                <clr-icon shape="info-standard" class="info-icone cor-icone"></clr-icon>
                <div class="dropdown-content">
                    <p>Tempo de trabalho efetivo das Motobomba em horas por Ordem de serviço. Clicando no label pode ocultar
                        a Ordem de serviço. </p>
                </div>
            </div>
        </div>
        <div *ngIf="mostrarSelect">
            <select  [(ngModel)]='tipoGrafico' (change)="mudarGrafico()" class="filtro-listas filtro-especifico">
                <option class="mat-app-background" value="1">10 menos efetivas</option>
                <option class="mat-app-background" value="2">10 mais efetivas</option>
            </select>
        </div>
        
    </div>
    <ng-container *ngIf="validaResp" class="mensagem">
        <p style="text-align: center;">Não há mensagens nesse período</p>
    </ng-container>
    <div class="d-flex justify-content-center h-75 w-100 p-1"  [hidden]="loading || validaResp || erro" id="grafico">
        <div class="d-flex container-grafico h-100 w-50" [hidden]="ocultarGrafico">
            <canvas class="" baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
            [options]="pieChartOptions" [colors]="pieChartColors" [legend]="pieChartLegend">
            </canvas>
        </div>
        <div class="p-2 h-100 w-50" [ngClass]="{'w-100': ocultarGrafico}" id="div-tabela">
            <div class="tabela-graficos" id="tabela">
                <table mat-table [dataSource]="tabela" class="w-100 p-1">
                    <ng-container matColumnDef="operacao">
                        <th mat-header-cell *matHeaderCellDef class="largura-coluna" style="padding: 5px !important;"> OS </th>
                        <td style="padding: 5px !important;" mat-cell *matCellDef="let dado" [id]="dado.id ? dado.id  : ''"
                        [style.background-color]="dado.cor ? dado.cor : ''"
                        [style.text-decoration]="dado.hidden ? 'line-through' :''"
                            (click)="selecionarCelula(dado.id)">
                            {{dado.ordemServico ? dado.ordemServico : ""}} </td>
                    </ng-container>
                    <ng-container matColumnDef="recolhidos">
                        <th mat-header-cell *matHeaderCellDef class="largura-coluna" style="padding: 5px !important;"> Horas </th>
                        <td style="padding: 5px !important;" mat-cell *matCellDef="let dado" [id]="dado.id ? dado.id  : ''"
                        [style.background-color]="dado.cor ? dado.cor : ''"
                        [style.text-decoration]="dado.hidden ? 'line-through' :''"
                            (click)="selecionarCelula(dado.id)">
                            {{dado.dado ? dado.dado : ""}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <app-erro *ngIf="erro"></app-erro>
</div>