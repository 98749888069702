import { HttpOptionsService } from './header-options.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;
  token = localStorage.getItem('token');

  AcharPremocenterCliente(id: any) {
    const url = `${this.BASE_URL_HIDROMETER}clientePremocenter/${id}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  listarClientes(): Array<any> {
    let clientes = localStorage.getItem('cliente');
    return clientes ? JSON.parse(clientes) : [];
  }
}
