import { Component, OnInit, Input } from '@angular/core';
import { MensagensService } from 'src/app/service/mensagens.service';
import { OperacoesService } from 'src/app/service/operacoes.service';

@Component({
  selector: 'app-informacoes-trator',
  templateUrl: './informacoes-trator.component.html',
  styleUrls: ['./informacoes-trator.component.css'],
})
export class InformacoesTratorComponent implements OnInit {
  constructor(
    private mensagemService: MensagensService,
    private operacaoService: OperacoesService
  ) {}
  public ultimaMensagem: any;
  public apontamento = '';
  public tipoMensagem: any;
  public carretelFrota: any;
  public dataHoraUltimoCarretel: any;
  public velocidadeUltimoCarretel: any;
  public OS: any;
  public validaResp = false;
  public equipamentosVinculados: any = [];
  loading = true;
  @Input() informacoesTrator: any; 
  public LocalStorageFrota = localStorage.getItem('frota');
  public LocalStorageEquipamentoId = localStorage.getItem('equipamentoId');
  public LocalStorageId = localStorage.getItem('cliente_filtro');
  public LocalStorageOperationId = localStorage.getItem('operacaoId');
  public sinal: boolean = true;

  ngOnInit(): void {
    this.sinal = !this.informacoesTrator.url.includes('sem-sinal');
    this.mensagemService
      .ultimaMensagemPorOperacaoClienteFrotaeData(
        this.LocalStorageId,
        this.LocalStorageOperationId,
        this.LocalStorageEquipamentoId
      )
      .subscribe((mensagens: any) => {
        if (mensagens.length == 0) {
          this.validaResp = true;
          this.OS = 'Mensagem não vinculada a OS';
        } else {
          this.OS =
            mensagens[0].it_service_id == null
              ? 'Mensagem não vinculada a OS'
              : mensagens[0].it_service_id;
          this.tratarPayloadCronos(mensagens);
        }
        this.operacaoService
          .AcharPorFkTrator(this.LocalStorageEquipamentoId, this.LocalStorageId)
          .subscribe((equipamentosVinculados: any) => {
            this.equipamentosVinculados = equipamentosVinculados;
            this.loading = false;
          });
      });
  }

  tratarPayloadCronos(mensagens: any) {
    mensagens.forEach((element: any) => {
      element.js_payload_tratado = JSON.parse(element.js_payload_tratado);
    });
    this.ultimaMensagem = mensagens[0].js_payload_tratado.timestamp;
    this.tipoMensagem = mensagens[0].js_payload_tratado.descriptor;
    var EsticarCarretel = mensagens.filter(function (el: any) {
      return (
        el.js_payload_tratado.descriptor == 'Operacao para esticar o carretel'
      );
    });
    if (EsticarCarretel.length == 0) {
      this.carretelFrota = 'Sem esticamentos no período';
      this.dataHoraUltimoCarretel = 'Sem esticamentos no período';
      this.velocidadeUltimoCarretel = 'Sem esticamentos no período';
    } else {
      this.carretelFrota = EsticarCarretel[0].js_payload_tratado.reelFleet;
      this.dataHoraUltimoCarretel =
      EsticarCarretel[0].js_payload_tratado.timestamp;
      this.velocidadeUltimoCarretel = EsticarCarretel[0].js_payload_tratado.speed + ' km/h';
    }
    var MotivoDeParada = mensagens.filter(function (el: any) {
      return (
        el.js_payload_tratado.Descricao ==
        'Motivo programado de parada do implemento'
      );
    });
    if (MotivoDeParada.length != 0) {
      this.apontamento = MotivoDeParada[0].js_payload_tratado.CodigoParada;
    }
  }
}
