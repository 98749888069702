import { Component, Inject, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { MensagensService } from 'src/app/service/mensagens.service';
import pluginDataLabels from 'chartjs-plugin-datalabels';
import { Subscription } from 'rxjs';
import { DetalhesMotivoComponent } from '../detalhes-motivo/detalhes-motivo.component';

@Component({
  selector: 'app-tempos-tipos',
  templateUrl: './tempos-tipos.component.html',
  styleUrls: ['./tempos-tipos.component.css']
})
export class TemposTiposComponent implements OnInit {

  subscription!: Subscription;
  cliente: any
  token: any
  dataInicial: any
  dataFinal: any
  frotas = [] as any
  tipos = [] as any
  tempo = [] as any
  motivos = [] as any
  arrayCores = [
    '#8C51A5', '#CB5E98', '#F47B8A', '#FFA37E', '#FFD285',
    '#FFFFA5', '#B9E49F', '#78C59B', '#24635B', '#00838D',
    '#089951', '#0DA600', '#8DB309', '#BFA500', '#CC8B0A',
    '#D95900', '#E6270B', '#F20084', '#B20CF9', '#2700FD'
  ];
  arrayCoresGrafico = [] as any;
  frotaData: any;
  load = true;
  validaResp = false;
  value = 50;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';

  public pieChartLabels = this.tipos;
  public pieChartLegend = true;
  public pieChartType: ChartType = 'pie';
  public pieChartPlugins = [pluginDataLabels];
  public pieChartDataset: ChartDataSets[] = [{
    data: this.tempo,
    backgroundColor: this.arrayCores
  }];
  public options: ChartOptions = {
    legend: {
      labels: {
        usePointStyle: true,
        generateLabels(chart: any) {
          const data = chart.data;

          return data.labels.map((label: any, i: any) => {
            const meta = chart.getDatasetMeta(0);
            const style = meta.controller.getStyle(i);

            var d, h, m, s, dDisplay, hDisplay, mDisplay, sDisplay, display: any;
            d = Math.floor(chart.data.datasets[0].data[i] / 86400);
            h = Math.floor(chart.data.datasets[0].data[i] % 86400 / 3600);
            m = Math.floor(chart.data.datasets[0].data[i] % 3600 / 60);
            s = Math.floor(chart.data.datasets[0].data[i] % 3600 % 60);

            if (d > 0) {
              if (d < 10) {
                if (d == 1) {
                  dDisplay = d + ' dia, ';
                } else {
                  dDisplay = d + ' dias, ';
                }
              } else {
                dDisplay = d + ' dias, ';
              }
            } else {
              dDisplay = '';
            }
            if (h > 0) {
              if (h < 10) {
                if (h == 1) {
                  hDisplay = '0' + h + 'h';
                } else {
                  hDisplay = '0' + h + 'h';
                }
              } else {
                hDisplay = h + 'h';
              }
            } else {
              hDisplay = '';
            }
            if (m > 0) {
              if (m < 10) {
                mDisplay = '0' + m + 'min';
              } else {
                mDisplay = m + 'min';
              }
            } else {
              mDisplay = '';
            }
            if (s > 0) {
              if (s < 10) {
                sDisplay = '0' + s + 's';
              } else {
                sDisplay = s + 's';
              }
            } else {
              sDisplay = '';
            }
            display = dDisplay + hDisplay + mDisplay + sDisplay;

            return {
              text: label + ' (' + display + ')',
              fillStyle: style.backgroundColor,
              strokeStyle: style.borderColor,
              lineWidth: style.borderWidth,
              index: i
            }
          })
        },
      },
      position: 'left',
      onHover: function (e: any) {
        e.target.style.cursor = 'pointer';
      },
      onLeave: function (e: any) {
        e.target.style.cursor = 'default';
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          var total = 0;
          for (let dado of data.datasets[0].data) {
            total += dado;
          }
          var index = tooltipItem['index'];
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var primeiraLetraLegenda = data.labels[index].charAt(0).toUpperCase();
          var corpoLegenda = data.labels[index];
          var restoLegenda = corpoLegenda.substr(1, corpoLegenda.length - 1);
          var legenda = primeiraLetraLegenda + restoLegenda;
          var valorItem = dataset.data[tooltipItem.index];
          var porcentagem = (valorItem / total) * 100;
          return legenda + ': ' + porcentagem.toFixed(1) + '%';
        }
      },
    },
    hover: {
      onHover: function (e: any) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = 'pointer';
        else e.target.style.cursor = 'default';
      },
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
        color: '#fff',
        backgroundColor: '#404040',
        align: 'end',
        anchor: 'center',
        formatter: (value: any, ctx: any) => {
          return value + "%";
        },
      },
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mensagemService: MensagensService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.localStorage();
    this.buscarTemposPorTipo();
  }

  localStorage() {
    this.cliente = localStorage.getItem("cliente_filtro");
    this.token = localStorage.getItem('token');
    this.dataInicial = localStorage.getItem("DateStartGraficos");
    this.dataFinal = localStorage.getItem("DateEndGraficos");
  }

  buscarTemposPorTipo() {
    if (this.data.frota !== undefined) {
      this.frotas = this.data.frota;
    } else {
      this.frotas = localStorage.getItem(this.data.localStorage);
    }
    if(this.frotas == null){
      this.frotas = 0;
    }
    this.subscription = this.mensagemService.temposTrabalhadosPorGrupo(this.cliente, this.frotas, this.dataInicial, this.dataFinal, this.data.id_grupo).subscribe((resp: any) => {
      if (resp.length == 0) {
        this.load = false;
        this.validaResp = true;
      }
      resp.forEach((motivoTempo: any) => {
        if (motivoTempo.tempo > 0) {
          this.tipos.push(motivoTempo.grupo);
          this.motivos.push(motivoTempo);
          this.tempo.push(motivoTempo.tempo);
        }
        this.load = false;
      });
    })
  }

  unsubscribe() {
    this.subscription.unsubscribe();
  }

  chartClicked(e: any) {
    var id, motivo, frota;
    frota = this.frotas;
    for (let grupo of this.motivos) {
      if (e.active[0] !== undefined || e.active.length !== 0) {
        if (e.active[0]._model.label == grupo.grupo) {
          id = grupo.id_grupo;
          motivo = grupo.grupo;
        }
      }
    }
    this.openDialog(id, motivo, frota);
  }

  openDialog(id_tipo: any, motivo: any, frota: any) {
    if (id_tipo !== undefined && id_tipo !== '' && id_tipo !== null &&
      motivo !== undefined && motivo !== '' && motivo !== null) {
      this.dialog.open(DetalhesMotivoComponent, {
        width: '98vw',
        height: '90vh',
        data: {
          frota,
          id_tipo,
          motivo,
          localStorage: this.data.localStorage
        }
      });
    }
  }

}
