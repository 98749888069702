<div class="h-100" id="container-grafico">
    <div class="d-flex justify-content-center">
        <h3>Tempo efetivo por turno de motobomba</h3>
        <div class="dropdown">
            <clr-icon shape="info-standard" class="info-icone cor-icone"></clr-icon>
            <div class="dropdown-content">
                <p>Tempo de funcionamento efetivo da Motobomba em horas por turno. Clicando no label do gráfico pode
                    ocultar o turno. </p>
            </div>
        </div>
    </div>
    <ng-container *ngIf="validaResp" class="mensagem">
        <p style="text-align: center;">Não há mensagens nesse período</p>
    </ng-container>
    <div [hidden]="loading || validaResp || erro" class="d-flex justify-content-center h-75 w-100" id="grafico">
        <div class="d-flex container-grafico h-100 w-50 p-2" [hidden]="ocultarGrafico">
            <canvas class="h-100 w-50" baseChart class="" [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
            [options]="pieChartOptions" [colors]="pieChartColors" [plugins]="pieChartPlugins" [legend]="pieChartLegend">
            </canvas>
        </div>
        <div class="p-2 h-100 w-50" [ngClass]="{'w-100': ocultarGrafico}" id="div-tabela">
            <div class="d-flex tabela-graficos w-100 p-1" id="tabela">
                <table mat-table [dataSource]="tabela" class="w-100">
                    <ng-container matColumnDef="turnos">
                        <th mat-header-cell *matHeaderCellDef class="" style="padding: 5px !important;" >
                            Turno </th>
                        <td  mat-cell *matCellDef="let dado" style="padding: .5rem !important;"
                            [id]="dado.id ? dado.id  : ''" [style.background-color]="dado.cor ? dado.cor : ''"
                            [style.text-decoration]="dado.hidden ? 'line-through' :''"
                            (click)="selecionarCelula(dado.id)">
                            {{dado.turno ? dado.turno : ""}} </td>
                    </ng-container>
                    <ng-container matColumnDef="horas">
                        <th mat-header-cell *matHeaderCellDef style="padding: 5px !important;" class="">
                            Horas </th>
                        <td mat-cell *matCellDef="let dado;" style="padding: .5rem !important;"
                            [id]="dado.id ? dado.id  : ''" [style.background-color]="dado.cor ? dado.cor : ''"
                            [style.text-decoration]="dado.hidden ? 'line-through' :''"
                            (click)="selecionarCelula(dado.id)">
                            {{dado.horas ? dado.horas : ""}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <app-erro *ngIf="erro"></app-erro>
</div>