import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';
import { HttpOptionsService } from './header-options.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GraficosService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  public graficoAbastecimentoMotobomba(dataInicio: any, dataFinal: any, idCliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}graficoAbastecimentoMotobomba`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'dataInicio': dataInicio,
      'dataFinal': dataFinal,
      'idCliente': idCliente,
    }
    return this.http.get(url, headerOptions);
  }

  public graficoAlertasMotobomba(dataInicio: any, dataFinal: any, idCliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}graficoAlertasMotobomba`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'dataInicio': dataInicio,
      'dataFinal': dataFinal,
      'idCliente': idCliente,
    }
    return this.http.get(url, headerOptions);
  }

  public graficoConsumoMotobomba(dataInicio: any, dataFinal: any, idCliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}graficoConsumoMotobomba`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'dataInicio': dataInicio,
      'dataFinal': dataFinal,
      'idCliente': idCliente,
    }
    return this.http.get(url, headerOptions);
  }

  public graficoTempoEfetivoFrotaMotobomba(dataInicio: any, dataFinal: any, idCliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}graficoTempoEfetivoFrotaMotobomba`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'dataInicio': dataInicio,
      'dataFinal': dataFinal,
      'idCliente': idCliente,
    }
    return this.http.get(url, headerOptions);
  }

  public graficoTempoEfetivoOsMotobomba(dataInicio: any, dataFinal: any, idCliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}graficoTempoEfetivoOsMotobomba`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'dataInicio': dataInicio,
      'dataFinal': dataFinal,
      'idCliente': idCliente,
    }
    return this.http.get(url, headerOptions);
  }

  public graficoTempoEfetivoTurnoMotobomba(dataInicio: any, dataFinal: any, idCliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}graficoTempoEfetivoTurnoMotobomba`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'dataInicio': dataInicio,
      'dataFinal': dataFinal,
      'idCliente': idCliente,
    }
    return this.http.get(url, headerOptions);
  }

  public graficoTempoEfetivoOsCarretel(dataInicio: any, dataFinal: any, idCliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}graficoTempoEfetivoOsCarretel`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'dataInicio': dataInicio,
      'dataFinal': dataFinal,
      'idCliente': idCliente,
    }
    return this.http.get(url, headerOptions);
  }

  public graficoTempoEfetivoTurnoCarretel(dataInicio: any, dataFinal: any, idCliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}graficoTempoEfetivoTurnoCarretel`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'dataInicio': dataInicio,
      'dataFinal': dataFinal,
      'idCliente': idCliente,
    }
    return this.http.get(url, headerOptions);
  }

  public graficoProdutividadeCarretel(dataInicio: any, dataFinal: any, idCliente: any, fuso: string) {
    const url = `${this.BASE_URL_HIDROMETER}graficoProdutividadeCarretel`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'dataInicio': dataInicio,
      'dataFinal': dataFinal,
      'idCliente': idCliente,
      'fuso': fuso
    }
    return this.http.get(url, headerOptions);
  }

  public graficoProdutividadeOs(dataInicio: any, dataFinal: any, idCliente: any, fuso: string) {
    const url = `${this.BASE_URL_HIDROMETER}graficoProdutividadeOs`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'dataInicio': dataInicio,
      'dataFinal': dataFinal,
      'idCliente': idCliente,
      'fuso': fuso
    }
    return this.http.get(url, headerOptions);
  }

  public graficoProdutividadeTurno(dataInicio: any, dataFinal: any, idCliente: any, fuso: string) {
    const url = `${this.BASE_URL_HIDROMETER}graficoProdutividadeTurno`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'dataInicio': dataInicio,
      'dataFinal': dataFinal,
      'idCliente': idCliente,
      'fuso': fuso
    }
    return this.http.get(url, headerOptions);
  }

  public geralGraficosEquipamentos(cliente: any, equipamentos: any, dataInicial: any, dataFinal: any, chavePayload: any, tipoEquipamento: any) {
    const url = `${this.BASE_URL_HIDROMETER}geralGraficosEquipamentos`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'fks_equipamentos': equipamentos,
      'fk_cliente': cliente,
      'dataInicio': dataInicial,
      'dataFinal': dataFinal,
      'chavePayload': chavePayload,
      'tipoEquipamento': tipoEquipamento
    };
    return this.http.get(url, headerOptions);
  }

  public graficoRPMMotobomba(cliente: any, equipamentos: any, dataInicial: any, dataFinal: any) {
    const url = `${this.BASE_URL_HIDROMETER}graficoRPMMotobomba`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'fks_equipamentos': equipamentos,
      'fk_cliente': cliente,
      'dataInicio': dataInicial,
      'dataFinal': dataFinal,
    };
    return this.http.get(url, headerOptions);
  }

  public timelineMotobomba(idCliente: any, dataInicio: any, dataFinal: any, equipamentoId: any): Observable<any> {
    const url = `${this.BASE_URL_HIDROMETER}timelineMotobomba`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'idCliente': idCliente,
      'dataInicio': dataInicio,
      'dataFinal': dataFinal,
      'equipamentoId': equipamentoId
    };
    return this.http.get(url, headerOptions);
  }

  public graficoTemposTrabalhadosPorFrota(idCliente: number, dataInicio: string, dataFinal: string, frotas: string): Observable<any> {
    const url = `${this.BASE_URL_HIDROMETER}graficoTemposTrabalhadosPorFrota`;
    var headerOptions = this.httpOptionsService.getHttpOptions();
    headerOptions.params = {
      'cliente': idCliente,
      'dataInicial': dataInicio,
      'dataFinal': dataFinal,
      'frotas': frotas
    };
    return this.http.get(url, headerOptions);
  }

  public graficoTemposTrabalhados(cliente: string, frotas: string, dataIni: string, dataFin: string): Observable<any> {
    let url = `${this.BASE_URL_HIDROMETER}graficoTemposTrabalhados`
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'cliente': `${cliente}`,
      'frotas': `${frotas}`,
      'dataInicial': `${dataIni}`,
      'dataFinal': `${dataFin}`,
    }
    return this.http.get(url, headerOptions)
  }
}
