<div class="d-flex justify-content-between align-items-start">
    <div class="d-flex flex-row mb-3">
        <img src="assets/trator.png">
        <div class="d-flex flex-column justify-content-start align-items-start ml-3">
            <span class="titulo-frota">Frota {{LocalStorageFrota}}</span>
            <span class="texto-trator">Trator</span>
        </div>
    </div>
    <div class="mt-1">
        <img src="../../../assets/icones-status-equipamentos/com-conexao.svg" *ngIf="sinal" style="width: 1rem;">
        <img src="../../../assets/icones-status-equipamentos/sem-conexao.svg" *ngIf="!sinal" style="width: 1rem;">
        <span class="conexao-equipamento" [ngClass]="{'sem-conexao': !sinal}"> {{sinal ? 'com' : 'sem' }} conexão </span>
    </div>
</div>


<div *ngIf="!loading">
    <div class="row mb-3">
        <div class="col border-right">
            <div class="d-flex flex-column justify-content-start align-items-start mb-2" *ngFor="let equipamentoVinculado of equipamentosVinculados">
                <div>
                    <img src="assets/icone-carretel.png" style="width: 1rem;" *ngIf="equipamentoVinculado?.vc_tipo == 'Carretel'">
                    <img src="assets/icone-motobomba.png" style="width: 1.2rem;" *ngIf="equipamentoVinculado?.vc_tipo == 'Motobomba'">
                    <span class="texto-ultima-mensagem">  {{equipamentoVinculado?.vc_tipo}} (OS {{equipamentoVinculado?.vc_operacao}})</span>
                </div>
                <span class="texto-informacoes ml-4">{{equipamentoVinculado?.vc_frota}}</span>
            </div>
            <div *ngIf="equipamentosVinculados.length == 0" class="d-flex justify-content-center align-items-center h-100">
                <span class="texto-informacoes" style="text-align: center; color: #E14747;">Não foi encontrado equipamento com vinculo a esse trator</span>
            </div>
        </div>
        <div class="col">
            <div class="d-flex flex-column justify-content-start align-items-start mb-2">
                <div>
                    <img src="assets/icone-carretel.png" style="width: 1rem;">
                    <span class="texto-ultima-mensagem"> Último carretel esticado</span>
                </div>
                <span class="texto-informacoes ml-4">{{carretelFrota}}</span>
            </div>
            <div class="d-flex flex-column justify-content-start align-items-start mb-2">
                <div>
                    <img src="assets/icone-calendario.png" style="width: 1rem;">
                    <span class="texto-ultima-mensagem"> Data do último esticamento</span>
                </div>
                <span class="texto-informacoes ml-4">{{dataHoraUltimoCarretel == 'Sem esticamentos no período' ? dataHoraUltimoCarretel : dataHoraUltimoCarretel | date: "dd/MM/yyyy HH:mm:ss"}}</span>
            </div>
            <div class="d-flex flex-column justify-content-start align-items-start mb-2">
                <div>
                    <img src="assets/icone-velocidade.png" style="width: 1rem;">
                    <span class="texto-ultima-mensagem"> Velocidade do carretel esticado</span>
                </div>
                <span class="texto-informacoes ml-4">{{velocidadeUltimoCarretel == 'Sem esticamentos no período' ? "0 km/h" : velocidadeUltimoCarretel}}</span>
            </div>
        </div>
    </div>
    <div>
        <span class="texto-ultima-mensagem"><img src="assets/icone-chat.png" style="width: 1.2rem;"> Última mensagem</span>
        <hr/>
        <div class="d-flex justify-content-between">
            <span class="texto">{{tipoMensagem}} </span>
            <span class="texto-data">{{ultimaMensagem | date: "dd/MM/yyyy HH:mm:ss"}}</span>
        </div>
        <hr/>
    </div>
</div>
<div *ngIf="loading" style="width: auto;" fxLayout="row" fxLayoutAlign="center center">
    <div class="spinner"></div>
</div>