<div class="h-100 w-100" id="container-grafico">
    <div class="d-flex justify-content-center w-100">
        <div class="px-1">
            <h4 class="m-0">Tempo efetivo por turno de carretel</h4>
        </div>
        <div class="dropdown">
            <clr-icon shape="info-standard" class="info-icone cor-icone"></clr-icon>
            <div class="dropdown-content">
                <p>Exibe o tempo produtivo por turno dos carretéis da usina durante o período selecionado. É possível ocultar um turno clicando na legenda.</p>
            </div>
        </div>
    </div>
    <div class="mensagem">
        <p *ngIf="validaResp" style="text-align: center;">Não há mensagens nesse período</p>
    </div>
    <div [hidden]="loading || validaResp || erro" class="d-flex justify-content-center h-75 w-100" id="grafico">
        <div class="d-flex container-grafico h-100 w-50 p-2" [hidden]="ocultarGrafico">
            <canvas class="h-100" baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
                [options]="pieChartOptions" [colors]="pieChartColors" [plugins]="pieChartPlugins" [legend]="pieChartLegend">
            </canvas>
        </div>
        <div class="p-2 h-100 w-50" [ngClass]="{'w-100': ocultarGrafico}" id="div-tabela">
                <div class="d-flex tabela-graficos" id="tabela">
                    <table mat-table [dataSource]="tabela" class="w-100">
                        <ng-container matColumnDef="turnos">
                            <th mat-header-cell *matHeaderCellDef class="largura-coluna" style="padding: 5px !important;">
                                Turno </th>
                            <td style="padding: 5px !important;" mat-cell *matCellDef="let dado"
                                [id]="dado.id ? dado.id  : ''" [style.background-color]="dado.cor ? dado.cor : ''"
                                [style.text-decoration]="dado.hidden ? 'line-through' :''"
                                (click)="selecionarCelula(dado.id)">
                                {{dado.turno ? dado.turno : ""}} </td>
                        </ng-container>
                        <ng-container matColumnDef="horas">
                            <th mat-header-cell *matHeaderCellDef class="largura-coluna" style="padding: 5px !important;">
                                Horas </th>
                            <td style="padding: 5px !important;" mat-cell *matCellDef="let dado;"
                                [id]="dado.id ? dado.id  : ''" [style.background-color]="dado.cor ? dado.cor : ''"
                                [style.text-decoration]="dado.hidden ? 'line-through' :''"
                                (click)="selecionarCelula(dado.id)">
                                {{dado.horas ? dado.horas : ""}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
        </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <app-erro *ngIf="erro"></app-erro>
</div>
