<div class="d-flex justify-content-between align-items-start">
    <div class="d-flex flex-row mb-3">
        <img src="assets/icones-carretel/carretel.png">
        <div class="d-flex flex-column justify-content-start align-items-start ml-3">
            <span class="titulo-frota">Frota {{LocalStorageFrota}} (OS {{operacao}})</span>
            <span class="texto-motobomba">Carretel</span>
        </div>
    </div>
    <div class="mt-1">
        <img src="../../../assets/icones-status-equipamentos/com-conexao.svg" *ngIf="sinal" class="tamanho-icone-informacoes-equipamentos">
        <img src="../../../assets/icones-status-equipamentos/sem-conexao.svg" *ngIf="!sinal" class="tamanho-icone-informacoes-equipamentos">
        <span class="texto-infromacoes-equipamento conexao-equipamento mr-2" [ngClass]="{'sem-conexao': !sinal}"> {{sinal ? 'com' : 'sem' }} conexão </span>
        <img src="../../../assets/icones-status-equipamentos/parado.svg" *ngIf="!trabalhando" class="tamanho-icone-informacoes-equipamentos">
        <img src="../../../assets/icones-status-equipamentos/trabalhando.svg" *ngIf="trabalhando" class="tamanho-icone-informacoes-equipamentos">
        <span class="texto-infromacoes-equipamento status-equipamento" [ngClass]="{'parado': !trabalhando}"> {{trabalhando ? 'trabalhando' : 'parado' }} </span>
    </div>
</div>

<div class="container-fluid" id="conteudoCarretel">
    <app-informacoes-carretel #informacoes></app-informacoes-carretel>
</div>