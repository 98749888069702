import { HttpOptionsService } from './header-options.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class FazendasService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  public cadastrar(fazenda: any) {
    const url = `${this.BASE_URL_HIDROMETER}fazendas`;
    return this.http.post(url, fazenda, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorCliente(cliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}fazendas/cliente/${cliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorClientePaginate(cliente: any, size: any) {
    const url = `${this.BASE_URL_HIDROMETER}fazendas/paginate/cliente/${cliente}/size/${size}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorId(fazendaId: any) {
    const url = `${this.BASE_URL_HIDROMETER}fazendas/${fazendaId}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public Atualizar(fazenda: any, fazendaId: any) {
    const url = `${this.BASE_URL_HIDROMETER}fazendas/${fazendaId}`;
    return this.http.put(url, fazenda, this.httpOptionsService.getHttpOptions());
  }

  public Desativar(fazendaId: any) {
    const url = `${this.BASE_URL_HIDROMETER}fazendas/${fazendaId}`;
    return this.http.delete(url, this.httpOptionsService.getHttpOptions());
  }

  public Next(url: any) {
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }
}
