import { environment } from "src/environments/environment";

export const BASE_URL_PREMOCENTER_FRONT = environment.BASE_URL_PREMOCENTER_FRONT;
export const BASE_URL_PREMOCENTER = environment.BASE_URL_PREMOCENTER;
export const BASE_URL_HIDROMETER_FRONT = environment.BASE_URL_HIDROMETER_FRONT;
export const BASE_URL_HIDROMETER = environment.BASE_URL_HIDROMETER;
export const BASE_URL_CRONOS = environment.BASE_URL_CRONOS;
export const BASE_URL_HIDROMETER_BACK = environment.BASE_URL_HIDROMETER_BACK;
export const BASE_URL_HIDROMETER_RELATORIOS = environment.BASE_URL_HIDROMETER_RELATORIOS;

export const MAPBOX_TOKEN = environment.MAPBOX_TOKEN;
