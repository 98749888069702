import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BASE_URL_PREMOCENTER_FRONT } from 'src/app/app.constants';
import { DialogMensagensComponent } from 'src/app/operacoes/monitoramento-operacoes/dialog-mensagens/dialog-mensagens.component';
import { MensagensService } from 'src/app/service/mensagens.service';
import { TraduzirMensagemService } from 'src/app/service/traduzir-mensagem.service';

@Component({
  selector: 'app-dialog-equipamentos',
  templateUrl: './dialog-equipamentos.component.html',
  styleUrls: ['./dialog-equipamentos.component.css']
})
export class DialogEquipamentosComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogEquipamentosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mensagemService: MensagensService,
    public dialog: MatDialog,
    private traduzService: TraduzirMensagemService,
  ) { }

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  LocalStorageId = localStorage.getItem("cliente_filtro");
  urlPremoplan = BASE_URL_PREMOCENTER_FRONT;
  length: any;
  next: any
  public operationId!: number;
  returnMessages: any;
  public dataSource = new MatTableDataSource();
  public displayedColumns: string[] = ['timestamp',
    'vc_mobileId', 'it_tamanho_da_mensagem', 'vc_frota', 'descricao', 'operador',
    'fk_operacao', 'it_service_id', 'controls'];
  loading = true;
  validaResp = false
  comunicadores: any = JSON.parse(localStorage.getItem('comunicadores') || "{}");

  ngOnInit(): void {
    this.popularTabela();
  }

  popularTabela() {
    this.mensagemService.mensagensEquipamento(this.data[1], this.data[0], 100).subscribe((mensagens: any) => {
      if (mensagens.data.length == 0) {
        this.validaResp = true
      }else{
        mensagens.data.forEach((element: any) => {
          element.js_payload_tratado = JSON.parse(element.js_payload_tratado)
          if(Object.keys(element.js_payload_tratado).includes('timeStamp')){
            element.js_payload_tratado.timestamp = element.js_payload_tratado.timeStamp
          }
          element.timestamp = element.js_payload_tratado.timestamp || element.ts_timestamp_mensagem;
          element.descricao = element.js_payload_tratado.descriptor;
        });
        this.length = mensagens.total;
        this.returnMessages = mensagens.data;
        this.dataSource.data = this.returnMessages;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.next = mensagens.next_page_url;
      }
      this.loading = false
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  trocarPagina(event: any) {
    this.loading = true;
    this.mensagemService.Next(this.next).subscribe((mensagens: any) => {
      mensagens.data.forEach((element: any) => {
        element.js_payload_tratado = JSON.parse(element.js_payload_tratado)
        if(Object.keys(element.js_payload_tratado).includes('timeStamp')){
          element.js_payload_tratado.timestamp = element.js_payload_tratado.timeStamp
        }
        element.timestamp = element.js_payload_tratado.timestamp || element.ts_timestamp_mensagem;
        element.descricao = element.js_payload_tratado.descriptor;
      });
      this.returnMessages = this.returnMessages.filter(function (i: any) {
        return i;
      });
      this.returnMessages.push(...mensagens.data)
      this.returnMessages.length = mensagens.total
      this.dataSource.data = this.returnMessages;
      this.next = mensagens.next_page_url
      this.loading = false;
    });
  }

  public parseMessage(e: any, id: number) {
    let icon = e.target.style.color = '#fff';
    const message = this.returnMessages.find((x: any) => x.sr_id === id);
    var pay = message.js_payload_tratado;
    if (pay) {
      var mensagem = this.traduzService.TraduzirPay(pay)
      this.dialog.open(DialogMensagensComponent, {
        width: "450px",
        data: mensagem
      });
    }
    return icon;
  }
}
