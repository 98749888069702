import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogWhatsappComponent } from './dialog-whatsapp/dialog-whatsapp.component';

@Component({
  selector: 'app-botao-whatsapp',
  templateUrl: './botao-whatsapp.component.html',
  styleUrls: ['./botao-whatsapp.component.css']
})
export class BotaoWhatsappComponent implements OnInit {

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }
  exibirModal(): void {
    const element = document.getElementById('botao-whatsapp');
    const rect = element ? element.getBoundingClientRect() : { top: 0, left: 0 }
    this.dialog.open(DialogWhatsappComponent, {
      panelClass: 'whatsapp-dialog',
      position: { top: (rect.top - 535) + 'px', left: (rect.left - 275) + 'px' }
    });
  }
}
