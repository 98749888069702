import { UsuariosService } from 'src/app/service/usuarios.service';
import { RedirectService } from './../../service/redirect.service';
import { Component, Input, OnInit } from '@angular/core';
import { Usuarios } from 'src/app/interface/usuarios';
import { BASE_URL_HIDROMETER_BACK } from './../../app.constants';
import { MensagensRecebidas } from 'src/app/interface/mensagens-recebidas';
import { MensagensService } from 'src/app/service/mensagens.service';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
@Component({
  selector: 'app-botao-mensagens',
  templateUrl: './botao-mensagens.component.html',
  styleUrls: ['./botao-mensagens.component.css']
})
export class BotaoMensagensComponent implements OnInit {

  constructor(
    private redirectService: RedirectService,
    private usuariosService: UsuariosService,
    private mensagensService: MensagensService
  ) { }

  condicaoBadge: boolean = false;
  socket: WebSocket | null = null;;
  mensagens: Array<any> = [];

  quantidadeMensagens: number = 0;

  usuarios: Usuarios = {
    bl_mensagens_em_tempo_real: false
  }

  ngOnInit(): void {
    this.redirectService.descricaoElementoExpandidoSobreMapa.subscribe((elemento: string) => {
      if (elemento != 'botao-mensagens') this.closeContent()
    });
    this.conectarAoWebsocket();
    this.recuperarMensagensLocalStorage();
    this.recuperarUltimas20Mensagens(new Date().toLocaleDateString('en-CA'));
    this.verificarPrimeiroAcesso();
    this.verificarSeModalAberoOuFechadoAoIniciar();
    this.verificarBadge();
  }

  conectarAoWebsocket(): void {
    (window as any).Pusher = Pusher;
    const EchoInstance = new Echo({
      broadcaster: 'pusher',
      key: 123456,
      cluster: 123456,
      forceTLS: true,
      wsHost: BASE_URL_HIDROMETER_BACK,
      wsPort: 6001,
      encrypted: false,
      enabledTransports: ['ws', 'wss'],
    });
    (window as any).Echo = EchoInstance;
    const channel = (EchoInstance as any).channel('canal-do-usuario.' + this.recuperarUsuarioLocalStorage());
    channel.subscribed(() => { console.log('foi') }).listen('.server', (event: any) => {
      this.inserirMensagemNova(event)
    });
  }


  recuperarUltimas20Mensagens(data: any): void {
    let condicao: string | null = localStorage.getItem('mensagensCarregadas');
    if (condicao == 'True') {
      this.mensagensService.ultimas20MensagensWebSocket(this.recuperarUsuarioLocalStorage(), data).subscribe((mensagens: any) => {
        mensagens.forEach((element: any) => {
          this.inserirMensagemNova(element);
        });
        this.redirectService.exibirLoaderMensagemWebSocket(false);
      })
      localStorage.setItem('mensagensCarregadas', 'False');
    }
  }

  recuperarUsuarioLocalStorage(): number {
    let idLocalStorage: any = localStorage.getItem('usuarioId') != null ? localStorage.getItem('usuarioId') : localStorage.getItem('usuario');
    let id = parseInt(idLocalStorage)
    return id
  }

  recuperarMensagensLocalStorage(): void {
    let msg: any = localStorage.getItem('mensagensWebsocket');
    let mensagens = JSON.parse(msg);
    if (mensagens != null) {
      this.mensagens = mensagens
    }
  }

  inserirMensagemNova(msg: MensagensRecebidas): void {
    if (msg.hasOwnProperty('titulo') && msg.titulo != '') {
      this.mensagens.unshift(msg)
      if (this.mensagens.length == 21) {
        this.mensagens.pop()
      }
      let mensagemQuantidade: Array<any> = [];
      mensagemQuantidade.push(msg);
      this.manipularQtdMensagens(mensagemQuantidade.length);
      this.setarMensagensLocalStorage(this.mensagens);
      this.redirectService.novaMensagemWebSocket();
    }
  }

  manipularQtdMensagens(qtd: any): void {
    let quantidadeAtual: any = localStorage.getItem('quantidadeMsg')
    let number: number = 0
    if (quantidadeAtual != null) {
      number = parseInt(quantidadeAtual)
    }
    this.quantidadeMensagens = qtd + number
    localStorage.setItem('quantidadeMsg', this.quantidadeMensagens.toString())
    this.verificarBadge()
  }

  setarMensagensLocalStorage(msg: any): void {
    let mensasgem = JSON.stringify(msg)
    localStorage.setItem('mensagensWebsocket', mensasgem);
    this.recuperarMensagensLocalStorage();
  }

  verificarPrimeiroAcesso(): void {
    if (this.verificarMensagens() == null) {
      localStorage.setItem('modalMensagensAberto', 'true');
    }
  }

  verificarBadge(): void {
    let qtd: any = localStorage.getItem('quantidadeMsg')
    this.quantidadeMensagens = qtd != null ? parseInt(qtd) : 0
    this.condicaoBadge = this.quantidadeMensagens != 0 ? true : false
  }

  verificarSeModalAberoOuFechadoAoIniciar(): void {
    if (this.verificarMensagens() == true) {
      this.modificarBotao('modal-mensagens');
      this.modificarBotao('botao-branco-mensagens');
      this.modificarBotao('botao-close-mensagens');
    }
  }

  verificarMensagens(): boolean | null {
    let mensagensLocalStorage: string | null = localStorage.getItem('modalMensagensAberto');
    if (mensagensLocalStorage == null || mensagensLocalStorage == 'null') {
      return null;
    }
    if (mensagensLocalStorage == 'true') {
      return true;
    } else {
      return false;
    }
  }

  toggleContent(): void {
    this.modificarBotao('modal-mensagens');
    this.modificarBotao('botao-branco-mensagens');
    this.modificarBotao('botao-close-mensagens');
    let elemento: HTMLElement | null = document.getElementById('modal-mensagens');
    if (elemento?.classList.contains('aberto')) this.redirectService.elementoExpandidoSobreMapa('botao-mensagens');
    this.setarOpenCloseModalStorage();
    this.tirarBadge();
  }

  tirarBadge(): void {
    this.condicaoBadge = false;
    localStorage.setItem('quantidadeMsg', '0')
  }

  setarOpenCloseModalStorage(): void {
    let elemento: HTMLElement | null = document.getElementById('modal-mensagens');
    if (elemento?.classList.contains('aberto')) {
      localStorage.setItem('modalMensagensAberto', 'true');
      this.usuarios.bl_mensagens_em_tempo_real = true
      this.usuariosService.setarBlMensagens(this.usuarios).subscribe((response: any) => {

      });
    } else {
      localStorage.setItem('modalMensagensAberto', 'false');
      this.usuarios.bl_mensagens_em_tempo_real = false
      this.usuariosService.setarBlMensagens(this.usuarios).subscribe((response: any) => {

      });
    }
  }

  closeContent(): void {
    ['modal-mensagens', 'botao-branco-mensagens', 'botao-close-mensagens'].forEach(elementId => {
      let elemento: HTMLElement | null = document.getElementById(elementId);
      elemento?.classList.remove('aberto');
    });
  }

  modificarBotao(id: string): void {
    let elemento: HTMLElement | null = document.getElementById(id);
    elemento?.classList.toggle('aberto');
  }
}
