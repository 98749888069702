import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';
import { HttpClient } from '@angular/common/http';
import { HttpOptionsService } from './header-options.service';

@Injectable({
  providedIn: 'root'
})
export class VersaoService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  listarUltimasCincoMudancas() {
    const url = `${this.BASE_URL_HIDROMETER}listarVersaoComAtualizacoes`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  validaExibicaoAtualizacoes() {
    const url = `${this.BASE_URL_HIDROMETER}validaExibicaoAtualizacoes`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  marcarComoVisualizado() {
    const url = `${this.BASE_URL_HIDROMETER}marcarAtualizacaoComoVista`;
    return this.http.put(url, '', this.httpOptionsService.getHttpOptions());
  }

  buscarUltimaVersao() {
    const url = `${this.BASE_URL_HIDROMETER}versoes/ultima`;

    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }
}
