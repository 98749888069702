<div fxLayout="row" fxLayoutAlign="space-between end">
    <div>
        <a routerLink="../1">
            <img src="../../assets/icone-layout1.png" class="icone-layout" *ngIf="modelo!=1" (click)="reload()">
        </a>
        <a routerLink="../2">
            <img src="../../assets/icone-layout2.png" class="icone-layout" *ngIf="modelo!=2" (click)="reload()">
        </a>
        <a routerLink="../3">
            <img src="../../assets/icone-layout3.png" class="icone-layout" *ngIf="modelo!=3" (click)="reload()">
        </a>
        <a routerLink="../4">
            <img src="../../assets/icone-layout4.png" class="icone-layout" *ngIf="modelo!=4" (click)="reload()">
        </a>
        <a routerLink="../5">
            <img src="../../assets/icone-layout5.png" class="icone-layout" *ngIf="modelo!=5" (click)="reload()">
        </a>
        <a routerLink="../6">
            <img src="../../assets/icone-layout6.png" class="icone-layout" *ngIf="modelo!=6" (click)="reload()">
        </a>
    </div>
    <form [formGroup]="formLayout" (ngSubmit)="cadastrar()">
        <div fxLayout="row" fxLayoutAlign="center center">
            <span style="width: 18%; margin-right: 5px;">Nome do Layout:</span>
            <input matInput class="input-padrao botao-espaco" autocomplete="off" type="text" name="tempo"
                formControlName="vc_nome">
            <span style="margin-right: 5px;">Acesso:</span>
            <select class="filtro-listas botao-espaco" formControlName="vc_tipo">
                <option value="1">Pessoal</option>
                <option value="2">Para um grupo</option>
                <option value="3">Para a usina</option>
            </select>
            <div *ngIf="formLayout.value.vc_tipo==3" fxLayout="row" fxLayoutAlign="center center">
                <span>Usina:</span>
                <select class="filtro-listas botao-espaco" formControlName="vc_cliente">
                    <option [value]="cliente.id" *ngFor="let cliente of clientes">{{cliente.nome}}</option>
                </select>
            </div>
            <div *ngIf="formLayout.value.vc_tipo==2" fxLayout="row" fxLayoutAlign="center center">
                <span>Grupo:</span>
                <select class="filtro-listas botao-espaco" formControlName="vc_grupo">
                    <option [value]="grupo.id" *ngFor="let grupo of grupos">{{grupo.nome}}</option>
                </select>
            </div>
            <button class="botao-criar botao-espaco" type="submit"
                [disabled]="!disable || !formLayout.valid">Salvar</button>
        </div>
    </form>

</div>

<div>

    <!-- MODELO 1 -->
    <div class="modelo1" id="1" fxLayout="column" fxLayoutAlign="center center" *ngIf="modelo==1"
        (click)="openDialog(1, $event)" [style.background-image]="temSelecionado1 ? none : image">
        <ul>
            <li *ngFor="let componente of area1">{{componente}}</li>
        </ul>
    </div>



    <!-- MODELO 2 -->
    <div class="modelo2" fxLayout="row" *ngIf="modelo==2">
        <div class="modelo2-1" id="2" fxLayout="row" fxLayoutAlign="center center" (click)="openDialog(1, $event)"
            [style.background-image]="temSelecionado1 ? none : image">
            <ul>
                <li *ngFor="let componente of area1">{{componente}}</li>
            </ul>
        </div>
        <div class="modelo2-2" fxLayout="column">
            <div class="modelo2-2-1" id="3" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(2, $event)" [style.background-image]="temSelecionado2 ? none : image">
                <ul>
                    <li *ngFor="let componente of area2">{{componente}}</li>
                </ul>
            </div>
            <div class="modelo2-2-1" id="4" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(3, $event)" [style.background-image]="temSelecionado3 ? none : image">
                <ul>
                    <li *ngFor="let componente of area3">{{componente}}</li>
                </ul>
            </div>
        </div>
    </div>



    <!-- MODELO 3  -->
    <div class="modelo3" fxLayout="row" *ngIf="modelo==3">
        <div class="modelo3-1" id="5" fxLayout="row" fxLayoutAlign="center center" (click)="openDialog(1, $event)"
            [style.background-image]="temSelecionado1 ? none : image">
            <ul>
                <li *ngFor="let componente of area1">{{componente}}</li>
            </ul>
        </div>
        <div class="modelo3-2" fxLayout="column">
            <div class="modelo3-2-1" id="6" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(2, $event)" [style.background-image]="temSelecionado2 ? none : image">
                <ul>
                    <li *ngFor="let componente of area2">{{componente}}</li>
                </ul>
            </div>
            <div fxLayout="row">
                <div class="modelo3-2-2" id="7" fxLayout="column" fxLayoutAlign="center center"
                    (click)="openDialog(3, $event)" [style.background-image]="temSelecionado3 ? none : image">
                    <ul>
                        <li *ngFor="let componente of area3">{{componente}}</li>
                    </ul>
                </div>
                <div class="modelo3-2-2" id="8" fxLayout="column" fxLayoutAlign="center center"
                    (click)="openDialog(4, $event)" [style.background-image]="temSelecionado4 ? none : image">
                    <ul>
                        <li *ngFor="let componente of area4">{{componente}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>


    <!-- MODELO 4 -->
    <div class="modelo4" fxLayout="row" *ngIf="modelo==4">
        <div class="modelo4-1" fxLayout="column">
            <div class="modelo4-1-1" id="9" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(1, $event)" [style.background-image]="temSelecionado1 ? none : image"
                style="margin-left: 0">
                <ul>
                    <li *ngFor="let componente of area1">{{componente}}</li>
                </ul>
            </div>
            <div class="modelo4-1-1" id="10" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(2, $event)" [style.background-image]="temSelecionado2 ? none : image"
                style="margin-left: 0">
                <ul>
                    <li *ngFor="let componente of area2">{{componente}}</li>
                </ul>
            </div>
        </div>
        <div class="modelo4-1" fxLayout="column">
            <div class="modelo4-1-1" id="11" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(3, $event)" [style.background-image]="temSelecionado3 ? none : image">
                <ul>
                    <li *ngFor="let componente of area3">{{componente}}</li>
                </ul>
            </div>
            <div class="modelo4-1-1" id="12" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(4, $event)" [style.background-image]="temSelecionado4 ? none : image">
                <ul>
                    <li *ngFor="let componente of area4">{{componente}}</li>
                </ul>
            </div>
        </div>
    </div>


    <!-- MODELO 5 -->
    <div class="modelo5" fxLayout="row" *ngIf="modelo==5">
        <div class="modelo5-1" id="13" fxLayout="row" fxLayoutAlign="center center" (click)="openDialog(1, $event)"
            [style.background-image]="temSelecionado1 ? none : image">
            <ul>
                <li *ngFor="let componente of area1">{{componente}}</li>
            </ul>
        </div>
        <div class="modelo5-2" fxLayout="column">
            <div fxLayout="row">
                <div class="modelo5-2-1" id="14" fxLayout="column" fxLayoutAlign="center center"
                    (click)="openDialog(2, $event)" [style.background-image]="temSelecionado2 ? none : image">
                    <ul>
                        <li *ngFor="let componente of area2">{{componente}}</li>
                    </ul>
                </div>
                <div class="modelo5-2-1" id="15" fxLayout="column" fxLayoutAlign="center center"
                    (click)="openDialog(3, $event)" [style.background-image]="temSelecionado3 ? none : image">
                    <ul>
                        <li *ngFor="let componente of area3">{{componente}}</li>
                    </ul>
                </div>
            </div>
            <div fxLayout="row">
                <div class="modelo5-2-1" id="16" fxLayout="column" fxLayoutAlign="center center"
                    (click)="openDialog(4, $event)" [style.background-image]="temSelecionado4 ? none : image">
                    <ul>
                        <li *ngFor="let componente of area4">{{componente}}</li>
                    </ul>
                </div>
                <div class="modelo5-2-1" id="17" fxLayout="column" fxLayoutAlign="center center"
                    (click)="openDialog(5, $event)" [style.background-image]="temSelecionado5 ? none : image">
                    <ul>
                        <li *ngFor="let componente of area5">{{componente}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- MODELO 6  -->
    <div class="modelo6" fxLayout="column" *ngIf="modelo==6">
        <div fxLayout="row">
            <div class="modelo6-1" id="18" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(1, $event)" [style.background-image]="temSelecionado1 ? none : image"
                style="margin-left: 0">
                <ul>
                    <li *ngFor="let componente of area1">{{componente}}</li>
                </ul>
            </div>
            <div class="modelo6-1" id="19" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(2, $event)" [style.background-image]="temSelecionado2 ? none : image">
                <ul>
                    <li *ngFor="let componente of area2">{{componente}}</li>
                </ul>
            </div>
            <div class="modelo6-1" id="20" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(3, $event)" [style.background-image]="temSelecionado3 ? none : image">
                <ul>
                    <li *ngFor="let componente of area3">{{componente}}</li>
                </ul>
            </div>
        </div>
        <div fxLayout="row">
            <div class="modelo6-1" id="21" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(4, $event)" [style.background-image]="temSelecionado4 ? none : image"
                style="margin-left: 0">
                <ul>
                    <li *ngFor="let componente of area4">{{componente}}</li>
                </ul>
            </div>
            <div class="modelo6-1" id="22" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(5, $event)" [style.background-image]="temSelecionado5 ? none : image">
                <ul>
                    <li *ngFor="let componente of area5">{{componente}}</li>
                </ul>
            </div>
            <div class="modelo6-1" id="23" fxLayout="column" fxLayoutAlign="center center"
                (click)="openDialog(6, $event)" [style.background-image]="temSelecionado6 ? none : image">
                <ul>
                    <li *ngFor="let componente of area6">{{componente}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>