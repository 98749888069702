import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatRowDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { AlertService } from 'src/app/service/alert.service';
import { CaracteristicasService } from 'src/app/service/caracteristicas.service';

@Component({
  selector: 'app-criar-caracteristicas',
  templateUrl: './criar-caracteristicas.component.html',
  styleUrls: ['./criar-caracteristicas.component.css']
})
export class CriarCaracteristicasComponent implements OnInit
{
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild("extraRow", { read: MatRowDef }) extraRow: any;
  @ViewChild("inputCaract") inputCaract: any;
  public addDataForm!: FormGroup;
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public disabled = false;
  public listEquipmentForm!: FormGroup;
  public bool = true;
  public operationId?: number;
  public elementId?: number;
  public element: any;
  public Add: any;
  public value?: string;
  public tipo: any;
  status: string = 'ACTIVE';
  LocalStorageId = localStorage.getItem("cliente_filtro");
  clients = JSON.parse(localStorage.getItem('cliente') || "{}")
  loading = false;
  caracteristicaObrigatoria = false;
  mensagem: string = '';
  caracteristicasPredefinidas: Record<string, { obrigatoria: boolean, atribuicao: string, nomeFormatado: string, nomeOutraCaracteristica?: string }> = {
    bocal: { obrigatoria: false, atribuicao: 'equipamento', nomeFormatado: 'Bocal' },
    pressao_minima: { obrigatoria: true, atribuicao: 'operacao', nomeFormatado: 'Pressão mínima', nomeOutraCaracteristica: 'Pressão máxima' },
    pressao_maxima: { obrigatoria: true, atribuicao: 'operacao', nomeFormatado: 'Pressão máxima', nomeOutraCaracteristica: 'Pressão mínima' },
    lamina_minima: { obrigatoria: true, atribuicao: 'operacao', nomeFormatado: 'Lâmina mínima', nomeOutraCaracteristica: 'Lâmina máxima' },
    lamina_maxima: { obrigatoria: true, atribuicao: 'operacao', nomeFormatado: 'Lâmina máxima', nomeOutraCaracteristica: 'Lâmina mínima' },
  };

  constructor(private formBuilder: FormBuilder,
    private location: Location,
    private caracteristicaService: CaracteristicasService,
    private alertService: AlertService) { }

  ngOnInit(): void
  {
    this.addDataForm = this.formBuilder.group({
      client: [null, Validators.required],
      name: [null, Validators.required],
      tipo: [null, Validators.required],
      atribuicao: [null, Validators.required],
      name2: [{ value: null, disabled: true }],
      tipo2: [{ value: 'int', disabled: true }],
      atribuicao2: [{ value: 'operacao', disabled: true }]
    });
    this.onChange();
  }

  onChange()
  {
    this.addDataForm.get('name')?.valueChanges.subscribe(caracteristica =>
    {
      this.lidarComCaracteristicaInserida(caracteristica)
    })
  }

  lidarComCaracteristicaInserida(nomeCaracteristica: string): void
  {
    let nomeFormatadoCaracteristica = this.removerMaiusculasEAcentuacao(nomeCaracteristica);
    let caracteristicaPredefinida: any = this.caracteristicasPredefinidas[nomeFormatadoCaracteristica];

    if (caracteristicaPredefinida !== undefined) {
      this.desabilitarValoresFormulario();
      this.atualizarValoresCaracteristicaFormulario(caracteristicaPredefinida);
      this.status = 'BLOCKED';
      this.caracteristicaObrigatoria = caracteristicaPredefinida.obrigatoria;

      if (caracteristicaPredefinida.obrigatoria) {
        this.lidarComOutraCaracteristicaObrigatoria(caracteristicaPredefinida.nomeOutraCaracteristica);
      }
    } else {
      this.resetarDadosCaracteristica();
    }
  }

  removerMaiusculasEAcentuacao(texto: string): string 
  {
    let stringMinuscula = texto.toLowerCase();
    let stringNormalizada = stringMinuscula.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let stringSemEspacosPontas = stringNormalizada.trim();

    return stringSemEspacosPontas.replace(' ', '_');
  }

  desabilitarValoresFormulario(): void
  {
    this.addDataForm.get('tipo')?.disable();
    this.addDataForm.get('atribuicao')?.disable();
  }

  atualizarValoresCaracteristicaFormulario(caracteristicaPredefinida: any): void
  {
    this.addDataForm.get('name')?.setValue(caracteristicaPredefinida.nomeFormatado, { emitEvent: false })
    this.addDataForm.get('atribuicao')?.setValue(caracteristicaPredefinida.atribuicao)
    this.addDataForm.get('tipo')?.setValue('int')
  }

  lidarComOutraCaracteristicaObrigatoria(nomeOutraCaracteristicaObrigatoria: string): void
  {
    this.addDataForm.get('name2')?.setValue(nomeOutraCaracteristicaObrigatoria);
    this.mensagem = "A característica adicional \"" + nomeOutraCaracteristicaObrigatoria + "\" será cadastrada automaticamente.";
  }

  resetarDadosCaracteristica(): void
  {
    this.addDataForm.get('tipo')?.enable();
    this.addDataForm.get('atribuicao')?.enable();
    this.addDataForm.get('tipo')?.reset()
    this.addDataForm.get('atribuicao')?.reset()
    this.addDataForm.get('name2')?.reset()

    this.caracteristicaObrigatoria = false;
    this.mensagem = '';
    this.status = 'ACTIVE';
  }

  done()
  {
    let caracteristica: any;

    caracteristica = {
      "vc_nome": this.addDataForm.get("name")?.value,
      "vc_nome_outra_caracteristica": this.addDataForm.get("name2")?.value,
      "vc_tipo": this.addDataForm.get("tipo")?.value,
      "vc_estado": this.status,
      "fk_cliente": this.addDataForm.get("client")?.value,
      "fk_equipamento": this.addDataForm.get("atribuicao")?.value == "equipamento" ? 0 : null,
      "fk_operacao": this.addDataForm.get("atribuicao")?.value == "operacao" ? 0 : null,
    }

    this.loading = true

    if (this.addDataForm.valid) {
      this.cadastrarCaracteristica(caracteristica)
    } else {
      this.alertService.error('Formulário inválido. Por favor, verifique-o e tente novamente.')
    }
  }

  public cancel(): void
  {
    this.location.back();
    this.addDataForm.reset();
  }

  cadastrarCaracteristica(caracteristica: any): void
  {
    this.caracteristicaService.cadastrar(caracteristica).subscribe(() =>
    {
      this.loading = false
      var mensagemSucesso: string = this.retornarMensagemDeSucesso(caracteristica.vc_nome_outra_caracteristica !== null);
      this.alertService.success(mensagemSucesso)
      this.location.back()
    }, (erro: any) =>
    {
      this.lidarComErroCadastro(erro);
    })
  }

  retornarMensagemDeSucesso(plural: boolean): string
  {
    var primeiraPalavra: string = 'Característica' + (plural ? 's' : '');
    var segundaPalavra: string = 'criada' + (plural ? 's' : '');

    return primeiraPalavra + ' ' + segundaPalavra + ' com sucesso!';
  }

  lidarComErroCadastro(erro: any): void
  {
    this.loading = false
    this.alertService.warning(erro.error.status)
  }
}
