import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MensagensService } from 'src/app/service/mensagens.service';

@Component({
  selector: 'app-dialog-info-periodo-filtrado',
  templateUrl: './dialog-info-periodo-filtrado.component.html',
  styleUrls: ['./dialog-info-periodo-filtrado.component.css']
})
export class DialogInfoPeriodoFiltradoComponent implements OnInit {

  subscription!: Subscription;

    cliente = localStorage.getItem("cliente_filtro");
    frotas_filtro = localStorage.getItem("frotas_filtro");
    dataInicial: any = localStorage.getItem("DateStartGraficos");
    dataFinal: any = localStorage.getItem("DateEndGraficos");
    frotas = [] as any;
    periodoCalcular:any;
    emDias:any;
    emHoras:any;
    t_segundos:any;
    t_dias:any;
    t_horas:any;
    dias:any;
    horas:any;
    semanas:any;
    semMsg:any;
    textoSemanas:any;
    textoDias:any;
    textoHoras:any;
    qtdFrotasSemMsg:any;
    qtdFrotasFiltradas:any;
    hoverFrotasSemMsg = false;
    textoFrotas:any;
    loading = true;
    mostrarFrotasSemMsg = false;

    constructor(
        private mensagensService: MensagensService,
        private dialogRef: MatDialogRef<DialogInfoPeriodoFiltradoComponent>) {
    }

    ngOnInit(): void {
        this.periodoFiltrado();
    }
    ngAfterViewInit(): void {
    }

    periodoFiltrado() {
        this.subscription = this.mensagensService.detalhesPeriodoFiltrado(this.cliente, this.frotas_filtro, this.dataInicial, this.dataFinal).subscribe((resp: any) => {
            resp = resp[0];
            this.t_horas = resp.tempoEmHoras;
            this.t_dias = resp.tempoEmDias;
            this.dias = resp.diasFiltrados;
            this.horas = resp.horasFiltradas;
            this.semanas = resp.semanasFiltradas;
            this.semMsg = resp.frotasSemMensagem;

            this.formatar();
        });
    }

    unsubscribe() {
        this.subscription.unsubscribe();
    }

    formatar() {
        this.qtdFrotasSemMsg = this.semMsg.length;
        
        this.semMsg = this.semMsg.toString();
        this.semMsg = this.semMsg.replace(/,/g, '\n');

        this.qtdFrotasFiltradas = localStorage.getItem("frotas_filtro")?.split(',');
        this.qtdFrotasFiltradas = this.qtdFrotasFiltradas.length;
        this.textoFrotas = this.formatarData('frota', this.qtdFrotasFiltradas);

        this.dataInicial = this.dataInicial.replace(/T/, " ").replace(/\..+/, "");
        this.dataInicial = this.dataInicial.substring(0, 10).split('-').reverse().join('/');
        this.dataFinal = this.dataFinal.replace(/T/, " ").replace(/\..+/, "");
        this.dataFinal = this.dataFinal.substring(0, 10).split('-').reverse().join('/');

        this.textoSemanas = this.formatarData('semana', this.semanas);
        this.textoDias = this.formatarData('dia', this.dias);

        this.emDias = this.formatarData('dia', this.t_dias);
        this.emHoras = this.formatarData('hora', this.t_horas);

        this.loading = false;
    }

    formatarData(tipo: any, tempo: any) {
        var textoDisplay;
        if (tempo !== 1) {
            textoDisplay = tempo + ' ' + tipo + 's';
        }
        if (tempo == 1) {
            textoDisplay = tempo + ' ' + tipo;
        }

        return textoDisplay;
    }

    updateSize(height: any) {
        this.dialogRef.updateSize('30vw', height);
    }

    expandirSemMsg() {
        if (this.mostrarFrotasSemMsg == true) {
            this.mostrarFrotasSemMsg = false;
            this.updateSize('32vh');
        } else if (this.mostrarFrotasSemMsg == false) {
            if(this.qtdFrotasSemMsg < 6) {
                var height: any;
                height = 32 + 4 * this.qtdFrotasSemMsg; 
                height = height.toString() + 'vh';
                this.updateSize(height);
            } else {
                this.updateSize('52vh');
            }
            this.mostrarFrotasSemMsg = true;
        }
    }
}
