<main style="width: 96%; margin: 0 2rem;">
    <div class="cabecalho">
        <h1>Informações dos carreteis</h1>
        <div class="linha">
            <label> Carreteis da usina:
                <select class="filtro-listas" [(ngModel)]="clienteSelecionado" (ngModelChange)="buscarCarreteis()">
                    <option *ngFor="let cliente of clientes" [value]="cliente.id">
                        {{ cliente.nome }}
                    </option>
                </select>
            </label>
        </div>
    </div>
    <input matInput (keyup)="filtrar($event)" placeholder="Filtrar" autocomplete="off" class="input-pesquisa w-100" *ngIf="!loading">
    <table mat-table [dataSource]="informacoesTabela" multiTemplateDataRows matSort class="w-100 tamanho-font" *ngIf="!loading">
        <div *ngFor="let coluna of colunasDaTabela">
            <ng-container [matColumnDef]="coluna">
                <th mat-header-cell *matHeaderCellDef class="text-center">
                    {{coluna}}
                </th>
                <td mat-cell *matCellDef="let element" class="text-center tamanho-font">
                   <div *ngIf="coluna == 'OS'">{{ element.operacaoDoEquipamento }}</div> 
                   <div *ngIf="coluna == 'Frota'">{{ element.frotaEquipamento }}</div> 
                   <div *ngIf="coluna == 'Conexão'" [ngClass]="{ 'classe-verde': element.conexao == 'Conectado', 'classe-vermelha': element.conexao == 'Sem conexão' }">{{ element.conexao }}</div> 
                   <div *ngIf="coluna == 'Status'" [ngClass]="{ 'classe-verde': element.status == 'Trabalhando', 'classe-vermelha': element.status == 'Parado' }">{{ element.status }}</div> 
                   <div *ngIf="coluna == 'Hora'">{{ element.horaUltimaMensagem !== '-' ? (element.horaUltimaMensagem | date: 'dd/MM/yyyy HH:mm:ss') : '-' }}</div> 
                   <div *ngIf="coluna == 'última mensagem'">{{ element.ultimaMensagem }}</div> 
                   <div *ngIf="coluna == 'Alerta'" [ngClass]="{ 'classe-verde': element.alerta == 'Sem alertas', 'classe-vermelha': element.alerta == 'Parado e aplicando' }">{{ element.alerta }}</div> 
                   <div *ngIf="coluna === 'Bateria'" id="visor-bateria" [ngClass]="getBateriaClass(element.bateria)">
                        {{ element.bateria }}
                        <span *ngIf="element.bateria !== '-'">V</span>
                   </div>
                   <div *ngIf="coluna == 'Velocidade'">{{ element.velocidade }} <span *ngIf="element.velocidade != '-'">m/h</span></div> 
                   <div *ngIf="coluna == 'Pressão'" 
                   [ngClass]="{
                    'classe-azul': element.pressaoCadastrada && element.pressao > element.pressaoCadastrada.maxima,
                    'classe-verde': element.pressaoCadastrada && element.pressao >= element.pressaoCadastrada.minima && element.pressao <= element.pressaoCadastrada.maxima, 
                    'classe-vermelha': element.pressaoCadastrada && element.pressao < element.pressaoCadastrada.minima,
                    'classe-cinza': element.pressaoCadastrada == null && element.pressao != '-'
                    }">
                    {{ element.pressao }} <span *ngIf="element.pressao != '-'">kgf/cm2</span>
                </div> 
                   <div *ngIf="coluna == 'Lâmina'"
                   [ngClass]="{
                    'classe-azul': element.laminaCadastrada && element.lamina > element.laminaCadastrada.maxima,
                    'classe-verde': element.laminaCadastrada && element.lamina >= element.laminaCadastrada.minima && element.lamina <= element.laminaCadastrada.maxima, 
                    'classe-vermelha': element.laminaCadastrada && element.lamina < element.laminaCadastrada.minima,
                    'classe-cinza': element.laminaCadastrada == null && element.lamina != '-'
                    }">{{ element.lamina }}</div> 
                   <div *ngIf="coluna == 'Ponto A/B'" [ngClass]="{ 'classe-verde': element.pontoAB == 'Sim', 'classe-vermelha': element.pontoAB == 'Não' }">{{ element.pontoAB }}</div> 
                   <div *ngIf="coluna == 'Parada Informada'">{{ element.paradaInformada }}</div> 
                   <div *ngIf="coluna == 'Motivo da parada'">{{ element.motivoParada }}</div> 
                </td>
            </ng-container>
        </div>

        <tr mat-header-row *matHeaderRowDef="colunasDaTabela"></tr>
        <tr mat-row *matRowDef="let row; columns: colunasDaTabela;"></tr>
    </table>

    <div *ngIf="loading" style="width: auto; height: 60vh;" fxLayout="row" fxLayoutAlign="center center">
        <div class="spinner"></div>
    </div>
</main>