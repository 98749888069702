import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogEnviarMensagemSatelitalComponent } from 'src/app/dialog-enviar-mensagem-satelital/dialog-enviar-mensagem-satelital.component';
import { VerificarPermissoes } from 'src/app/model/verificar-premissao.model';
import { AlertService } from 'src/app/service/alert.service';
import { EquipamentosService } from 'src/app/service/equipamentos.service';
import { MensagensService } from 'src/app/service/mensagens.service';
import { OperacoesService } from 'src/app/service/operacoes.service';
import { RedirectService } from 'src/app/service/redirect.service';

@Component({
  selector: 'app-dialog-motobomba',
  templateUrl: './dialog-motobomba.component.html',
  styleUrls: ['./dialog-motobomba.component.css']
})
export class DialogMotobombaComponent implements OnInit {
  public LocalStorageFrota: any = localStorage.getItem("frota");
  public LocalStorageId: any = localStorage.getItem('cliente_filtro');
  public LocalStorageOperationId: any = localStorage.getItem('operacaoId');
  public LocalStorageDateStart: any = localStorage.getItem('DateStart');
  public LocalStorageDateEnd: any = localStorage.getItem('DateEnd');
  public LocalStorageEquipamentoId: string | null = localStorage.getItem("equipamentoId");

  mensagens: any = '';
  ultimaMensagem: any;
  loading = true;
  operacao: any;

  public sinal: boolean = !this.data.url.includes('sem-sinal');
  public trabalhando: boolean = this.data.url.includes('funcionando');

  public oqSeraExibido = 'InformacoesGerais';
  botoes = [
    { texto: 'Informações motobomba', acao: 'InformacoesGerais' },
    { texto: 'Timeline motobomba', acao: 'Timeline' },
  ];

  constructor(
    private mensagemService: MensagensService,
    private appService: RedirectService,
    public dialog: MatDialog,
    private alertaService: AlertService,
    private operacaoService: OperacoesService,
    private serviceEquipamento: EquipamentosService,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.regrasIniciaisDoComponente()
  }

  informacoesUltimaMensagem() {
    this.serviceEquipamento.recuperarUltimaMensagem(this.LocalStorageEquipamentoId).subscribe((mensagens: any) => {
      if(mensagens.ultima_mensagem.js_payload_tratado == "\"SIN diferente de 128\""){
        this.mensagens = mensagens.ultimaMensagemSinDiferente;
      }else{
        this.mensagens = mensagens.ultima_mensagem
      }
      if (this.mensagens.fk_operacao != null) {
        this.operacaoService.AcharPorId(this.mensagens.fk_operacao).subscribe((operacao: any) => {
          this.operacao = operacao.vc_service_id;
        });
      }
      this.enviarMensagemInformacoesMotobomba()
    })
  }

  enviarMensagemInformacoesMotobomba(): void {
    this.loading = false
    this.appService.mandarValorInformacoesUltimaMensagem(this.mensagens);
  }

  async Mensagem() {
    this.loading = true
    this.operacaoService.OsPorEquipamentoECliente(this.LocalStorageEquipamentoId, this.LocalStorageId).subscribe((resp: any) => {
      this.loading = false
      if (Object.keys(resp).length == 0) {
        this.alertaService.warning('Nenhuma operação em estado OPERANDO encontrada')
      } else {
        var dados = resp;
        dados.motobombaId = this.LocalStorageEquipamentoId;
        dados.trator_frota = [dados.trator_frota];
        const dialogRef = this.dialog.open(DialogEnviarMensagemSatelitalComponent, {
          width: '50rem',
          data: dados,
        });
      }
    })
  }

  public verificarPermissao(permissoesFuncionalidades: string[]): boolean {
    const permissaoUsuario = localStorage.getItem('permissoes');
    return VerificarPermissoes.temPermissao(permissoesFuncionalidades, permissaoUsuario)
  }

  regrasIniciaisDoComponente() {
    this.informacoesUltimaMensagem();
  }

  MudarExibicao(tipoParaExibir: any) {
    this.oqSeraExibido = tipoParaExibir;
    this.informacoesUltimaMensagem();
  }

}
