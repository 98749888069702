import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BASE_URL_PREMOCENTER_FRONT } from 'src/app/app.constants';
import { VerificarPermissoes } from 'src/app/model/verificar-premissao.model';
import { AlertService } from 'src/app/service/alert.service';
import { FazendasService } from 'src/app/service/fazendas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listar-fazendas',
  templateUrl: './listar-fazendas.component.html',
  styleUrls: ['./listar-fazendas.component.css']
})
export class ListarFazendasComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  urlPremoplan = BASE_URL_PREMOCENTER_FRONT;
  public displayedColumns: string[] = [
    "vc_nome",
    "ts_criado_em",
    "ts_atualizado_em",
    "controls",
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public formFilter!: FormGroup;
  LocalStorageId = localStorage.getItem("cliente_filtro");
  clients = JSON.parse(localStorage.getItem('cliente') || "{}")
  length: any;
  fazendas: any;
  next: any;
  loading = true;
  validaResp = false

  constructor(
    private formBuilder: FormBuilder,
    private fazendaService: FazendasService,
    private alertService: AlertService) { }


  ngOnInit(): void {
    this.initializeInvites();
  }

  initializeInvites() {
    this.formFilter = this.formBuilder.group({
      client: [null],
    });
    if (this.LocalStorageId != null) {
      this.formFilter.get("client")?.setValue(this.LocalStorageId);
      this.fazendaService.AcharPorClientePaginate(this.LocalStorageId, 100).subscribe((data: any) => {
        if (data == null) {
          this.validaResp = true
          this.length = 0;
          this.fazendas = [];
          this.dataSource.data = [];
          this.next = "";
        } else {
          this.length = data.total;
          this.fazendas = data.data;
          this.dataSource.data = this.fazendas;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.next = data.next_page_url;
        }
        this.loading = false
      }, (err) => {
        if (err.statusText == "Unauthorized") {
          window.location.href = `${this.urlPremoplan}`
        }
      });
    }
    this.onChange();
  }

  onChange() {
    this.loading = true
    this.validaResp = false
    this.formFilter.get("client")?.valueChanges.subscribe((clientId: any) => {
      localStorage.setItem("cliente_filtro", clientId);
      this.LocalStorageId = clientId
      this.initializeInvites();
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id: number): void {
    Swal.fire({
      title: 'Excluir fazenda?',
      showCancelButton: true,
      confirmButtonColor: '#41B6E6',
      cancelButtonColor: '#df4747',
      confirmButtonText: 'Confirmar!'
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.fazendaService.Desativar(id).subscribe(resp => {
          this.alertService.success('Fazenda excluída com sucesso!')
          this.initializeInvites();
        }, err => {
          this.alertService.error('Erro ao excluir fazenda')
        })
      }
    })
  }

  trocarPagina(event: any) {
    this.loading = true;
    this.fazendaService.Next(this.next).subscribe((data: any) => {
      this.fazendas = this.fazendas.filter(function (i: any) {
        return i;
      });
      this.fazendas.push(...data.data)
      this.fazendas.length = data.total
      this.dataSource.data = this.fazendas;
      this.next = data.next_page_url;
      this.loading = false;
    });
  }


  public verificarPermissao(permissoesFuncionalidades: string[]): boolean {
    const permissaoUsuario = localStorage.getItem('permissoes');
    return VerificarPermissoes.temPermissao(permissoesFuncionalidades, permissaoUsuario)
  }

}
