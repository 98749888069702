import { ExibicaoGrafico } from './../../model/exibicao-grafico.model';
import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modelo5',
  templateUrl: './modelo5.component.html',
  styleUrls: ['./modelo5.component.css']
})
export class Modelo5Component implements OnInit {

  layout!: any
  area1 = [] as any;
  tempoArea1!: any;
  area2 = [] as any;
  tempoArea2!: any;
  area3 = [] as any;
  tempoArea3!: any;
  area4 = [] as any;
  tempoArea4!: any;
  area5 = [] as any;
  tempoArea5!: any;
  ocultarGrafico: any = false
  ocultarGraficoTemposTrabalho: any = false
  slideAtual: any;
  numeroSlideAtual: any;
  redimensionarMapa: boolean = false;

  constructor(private config: NgbCarouselConfig) {
    this.config.showNavigationArrows = false;
    this.config.showNavigationIndicators = true;
    this.config.pauseOnFocus = true
  }

  ngOnInit(): void {
    this.layout = sessionStorage.getItem("layout_padrao")
    this.layout = JSON.parse(this.layout)
    this.componentes();
    this.escondeGraficoEmComponentePequeno(window.innerWidth)
    this.escondeGraficoTemposTrabalho(window.innerWidth)
  }

  @ViewChild('carousel', { static: true }) carousel!: NgbCarousel;
  onMenuEvent(event: string) {
    if(event){
      this.carousel.pause();
    }else{
      this.carousel.cycle();
    }
  }

  componentes() {
    this.layout.layouts_personalizados_itens.forEach((item: any) => {
      var json;
      if (item.it_area == 1) {
        this.tempoArea1 = item.it_tempo_componente * 1000
        if (item.vc_componente == "MAPA") {
          json = { "c1": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA") {
          json = { "c2": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ALERTA DA MOTOBOMBA") {
          json = { "c3": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE CONSUMO DA MOTOBOMBA") {
          json = { "c4": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA") {
          json = { "c5": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA") {
          json = { "c6": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA") {
          json = { "c7": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL") {
          json = { "c8": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL") {
          json = { "c9": true }
          this.area1.push(json);
        } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DA OS") {
          json = { "c10": true }
          this.area1.push(json);
        } else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO TURNO"){
          json = { "c11": true }
          this.area1.push(json);
        }else if(item.vc_componente == "KANBAN"){
          json = { "c12": true }
          this.area1.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA"){
          json = { "c13": true }
          this.area1.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS"){
          json = { "c14": true }
          this.area1.push(json);
        }else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO CARRETEL"){
          json = { "c15": true }
          this.area1.push(json);
        }
      } else if (item.it_area == 2) {
        this.tempoArea2 = item.it_tempo_componente * 1000
        if (item.vc_componente == "MAPA") {
          json = { "c1": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA") {
          json = { "c2": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ALERTA DA MOTOBOMBA") {
          json = { "c3": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE CONSUMO DA MOTOBOMBA") {
          json = { "c4": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA") {
          json = { "c5": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA") {
          json = { "c6": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA") {
          json = { "c7": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL") {
          json = { "c8": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL") {
          json = { "c9": true }
          this.area2.push(json);
        } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DA OS") {
          json = { "c10": true }
          this.area2.push(json);
        } else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO TURNO"){
          json = { "c11": true }
          this.area2.push(json);
        }else if(item.vc_componente == "KANBAN"){
          json = { "c12": true }
          this.area2.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA"){
          json = { "c13": true }
          this.area2.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS"){
          json = { "c14": true }
          this.area2.push(json);
        }else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO CARRETEL"){
          json = { "c15": true }
          this.area2.push(json);
        }
      } else if (item.it_area == 3) {
        this.tempoArea3 = item.it_tempo_componente * 1000
        if (item.vc_componente == "MAPA") {
          json = { "c1": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA") {
          json = { "c2": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ALERTA DA MOTOBOMBA") {
          json = { "c3": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE CONSUMO DA MOTOBOMBA") {
          json = { "c4": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA") {
          json = { "c5": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA") {
          json = { "c6": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA") {
          json = { "c7": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL") {
          json = { "c8": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL") {
          json = { "c9": true }
          this.area3.push(json);
        } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DA OS") {
          json = { "c10": true }
          this.area3.push(json);
        } else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO TURNO"){
          json = { "c11": true }
          this.area3.push(json);
        }else if(item.vc_componente == "KANBAN"){
          json = { "c12": true }
          this.area3.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA"){
          json = { "c13": true }
          this.area3.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS"){
          json = { "c14": true }
          this.area3.push(json);
        }else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO CARRETEL"){
          json = { "c15": true }
          this.area3.push(json);
        }
      } else if (item.it_area == 4) {
        this.tempoArea4 = item.it_tempo_componente * 1000
        if (item.vc_componente == "MAPA") {
          json = { "c1": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA") {
          json = { "c2": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ALERTA DA MOTOBOMBA") {
          json = { "c3": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE CONSUMO DA MOTOBOMBA") {
          json = { "c4": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA") {
          json = { "c5": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA") {
          json = { "c6": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA") {
          json = { "c7": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL") {
          json = { "c8": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL") {
          json = { "c9": true }
          this.area4.push(json);
        } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DA OS") {
          json = { "c10": true }
          this.area4.push(json);
        } else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO TURNO"){
          json = { "c11": true }
          this.area4.push(json);
        }else if(item.vc_componente == "KANBAN"){
          json = { "c12": true }
          this.area4.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA"){
          json = { "c13": true }
          this.area4.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS"){
          json = { "c14": true }
          this.area4.push(json);
        }else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO CARRETEL"){
          json = { "c15": true }
          this.area4.push(json);
        }
      } else if (item.it_area == 5) {
        this.tempoArea5 = item.it_tempo_componente * 1000
        if (item.vc_componente == "MAPA") {
          json = { "c1": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA") {
          json = { "c2": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ALERTA DA MOTOBOMBA") {
          json = { "c3": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE CONSUMO DA MOTOBOMBA") {
          json = { "c4": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA") {
          json = { "c5": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA") {
          json = { "c6": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA") {
          json = { "c7": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL") {
          json = { "c8": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL") {
          json = { "c9": true }
          this.area5.push(json);
        } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DA OS") {
          json = { "c10": true }
          this.area5.push(json);
        } else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO TURNO"){
          json = { "c11": true }
          this.area5.push(json);
        }else if(item.vc_componente == "KANBAN"){
          json = { "c12": true }
          this.area5.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA"){
          json = { "c13": true }
          this.area5.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS"){
          json = { "c14": true }
          this.area5.push(json);
        }else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO CARRETEL"){
          json = { "c15": true }
          this.area5.push(json);
        }
      }
    })
  }

  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.escondeGraficoEmComponentePequeno(window.innerWidth)
    this.escondeGraficoTemposTrabalho(window.innerWidth)
  }

  escondeGraficoEmComponentePequeno(tamanhoTela: number) {
    this.ocultarGrafico = ExibicaoGrafico.verificaSeEscondeGrafico(tamanhoTela)
  }
  escondeGraficoTemposTrabalho(tamanhoTela: number) {
    this.ocultarGraficoTemposTrabalho = ExibicaoGrafico.verificaSeEscondeGraficoTemposTrabalho(tamanhoTela)
  }

  onSlide(event: any) {
    this.slideAtual = event.current;
    this.numeroSlideAtual = this.slideAtual.slice(-1);
    
    if (this.area1[this.numeroSlideAtual].hasOwnProperty('c1')) {
      this.redimensionarMapa = true;
    } else {
      this.redimensionarMapa = false;
    }
  }

  VerificaSeSlideAtualTemMapa(): boolean {
    return this.area1[this.numeroSlideAtual].hasOwnProperty('c1');
  }
}
