import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/service/alert.service';
import { TurnosService } from 'src/app/service/turnos.service';

@Component({
  selector: 'app-criar-turno',
  templateUrl: './criar-turno.component.html',
  styleUrls: ['./criar-turno.component.css']
})
export class CriarTurnoComponent implements OnInit {

  formularioTurno !: FormGroup;
  clients = JSON.parse(localStorage.getItem('cliente') || "{}");

  constructor(
    private formBuilder: FormBuilder,
    private shiftService: TurnosService,
    private location: Location,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.montarFormulario();
  }

  montarFormulario() {
    this.formularioTurno = this.formBuilder.group({
      fk_cliente: [null, Validators.required],
      vc_nome: [null, Validators.required],
      tm_hora_inicio: [null, ""],
      tm_hora_fim: [null, ""],
    });
  }

  cadastrarTurno() {
    this.shiftService.cadastrar(this.formularioTurno.value).subscribe(() => {
      this.alertService.success('Turno criado com sucesso!')
      this.location.back();
    }, () => {
      this.alertService.error('Erro ao criar turno')
    });
  }

  public cancelar(): void {
    this.location.back();
  }
}
