import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modelos-layouts',
  templateUrl: './modelos-layouts.component.html',
  styleUrls: ['./modelos-layouts.component.css']
})
export class ModelosLayoutsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
