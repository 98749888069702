import { Component } from '@angular/core';
import { RedirectService } from 'src/app/service/redirect.service';

@Component({
  selector: 'app-botao-hectares-mobile',
  templateUrl: './botao-hectares-mobile.component.html',
  styleUrls: ['./botao-hectares-mobile.component.css']
})
export class BotaoHectaresMobileComponent {
  constructor(
    private redirectService: RedirectService
  ) { }

  ngOnInit(): void {
    this.redirectService.descricaoElementoExpandidoSobreMapa.subscribe((elemento: string) => {
      if (elemento != 'botao-hectares-mobile') this.closeContentMobile()
    });
  }

  toggleContentMobile() {
    this.modificarBotaoMobile();    
    let elemento = document.getElementById('modal-mobile');
    if (elemento?.classList.contains('aberto')) this.redirectService.elementoExpandidoSobreMapa('botao-hectares-mobile');
  }

  closeContentMobile() {
    ['modal-mobile', 'btn-branco', 'btn-close'].forEach(elementId => {
      let elemento = document.getElementById(elementId);
      elemento?.classList.remove('aberto')
    });
  }

  modificarBotaoMobile() {
    ['modal-mobile', 'btn-branco', 'btn-close'].forEach(elementId => {
      let elemento = document.getElementById(elementId);
      elemento?.classList.toggle('aberto')
    });
  }
}

