import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BASE_URL_PREMOCENTER_FRONT } from 'src/app/app.constants';
import { VerificarPermissoes } from 'src/app/model/verificar-premissao.model';
import { AlertService } from 'src/app/service/alert.service';
import { RedirectService } from 'src/app/service/redirect.service';
import { TurnosService } from 'src/app/service/turnos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listar-turno',
  templateUrl: './listar-turno.component.html',
  styleUrls: ['./listar-turno.component.css']
})
export class ListarTurnoComponent implements OnInit {
  urlPremoplan = BASE_URL_PREMOCENTER_FRONT;
  public dataSource = new MatTableDataSource();
  public displayedColumns: string[] = [
    "vc_nome",
    "ts_criado_em",
    "ts_atualizado_em",
    "tm_hora_inicio",
    "tm_hora_fim",
    "bl_status",
    "controles",
  ];
  colunas = [
    ['vc_nome', 'Nome do turno'],
    ['ts_criado_em', 'Criado em'],
    ['ts_atualizado_em', 'Atualizado em'],
    ['tm_hora_inicio', 'Hora de início'],
    ['tm_hora_fim', 'Hora fim'],
    ['bl_status', 'Status']
  ]
  LocalStorageId = localStorage.getItem("cliente_filtro");
  clients = JSON.parse(localStorage.getItem('cliente') || "{}");
  loading = true;

  @ViewChild(MatSort) sort !: MatSort;

  constructor(
    public dialog: MatDialog,
    public turnoService: TurnosService,
    public alertService: AlertService,
    public appService: RedirectService
  ) { }

  ngOnInit(): void {
    this.buscarTurnos();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  SoftDelete(idTurno: number) {
    this.chamarAlert('Deseja excluir o turno?', 'excluir', idTurno);
  }

  public verificarPermissao(permissoesFuncionalidades: string[]): boolean {
    const permissaoUsuario = localStorage.getItem('permissoes');
    return VerificarPermissoes.temPermissao(permissoesFuncionalidades, permissaoUsuario)
  }

  chamarAlert(titulo: string, metodo: string, idTurno: number) {
    Swal.fire({
      title: titulo,
      showCancelButton: true,
      confirmButtonColor: '#009DE3',
      cancelButtonColor: '#df4747',
      confirmButtonText: 'Confirmar!'
    }).then((result) => {
      if (result.isConfirmed) {
        metodo == 'desativar' ? this.inativarTurno(idTurno) : this.desativarTurno(idTurno);
      }
    })
  }

  Desativar(idTurno: number) {
    this.chamarAlert('Deseja desativar o turno?', 'desativar', idTurno);
  }

  buscarTurnos() {
    this.turnoService.AcharPorCliente(this.LocalStorageId).subscribe((data: any) => {
      this.dataSource.data = data != null ? data : [];
      this.dataSource.sort = this.sort;
      this.loading = false
    }, (err: any) => {
      if (err.statusText == "Unauthorized") {
        window.location.href = `${this.urlPremoplan}`
      }
    });
  }

  desativarTurno(turnoId: number) {
    this.turnoService.Desativar(turnoId).subscribe((data) => {
      this.alertService.success('Turno excluido!');
      this.buscarTurnos();
    }, (err) => {
      if (err.status == 'Há mensagens atribuidas a esse turno') {
        this.alertService.error('O turno possui mensagens vinculados, você poderá desativar ele, mas não excluir.', 'Erro ao excluir turno')
      } else if (err.statusText == "Unauthorized") {
        window.location.href = `${this.urlPremoplan}`;
      }
    });
  }

  inativarTurno(idTurno: number) {
    this.turnoService.Inativar(idTurno).subscribe(() => {
      this.alertService.success('Turno desativado!')
      this.buscarTurnos();
    }, (err: any) => {
      if (err.statusText == "Unauthorized") {
        window.location.href = `${this.urlPremoplan}`
      }
    });
  }

  mudarCliente() {
    this.loading = true
    localStorage.setItem("cliente_filtro", this.LocalStorageId || '');
    this.appService.enviarMudancaDeCliente();
    this.buscarTurnos();
  }

  formatarDataComHorario(dataString: string): string {
    const data = new Date(dataString);

    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    const horas = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');
    const segundos = String(data.getSeconds()).padStart(2, '0');

    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
  }

  exibirValorFormatado(coluna: string, valor: string): string {
    return coluna === 'ts_criado_em' || coluna === 'ts_atualizado_em' ? this.formatarDataComHorario(valor) : valor;
  }
}
