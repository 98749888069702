import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReloadGraficosService {

  public recarregarComponente = new EventEmitter();

  public recarregarListaGraficos(): void {
    this.recarregarComponente.emit();
  }
}
