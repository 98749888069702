import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-botoes-acao-mapa',
  templateUrl: './botoes-acao-mapa.component.html',
  styleUrls: ['./botoes-acao-mapa.component.css']
})
export class BotoesAcaoMapaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
