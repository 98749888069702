import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { ClienteService } from 'src/app/service/cliente.service';
import { FazendasService } from 'src/app/service/fazendas.service';

@Component({
  selector: 'app-editar-fazendas',
  templateUrl: './editar-fazendas.component.html',
  styleUrls: ['./editar-fazendas.component.css']
})
export class EditarFazendasComponent implements OnInit {

  public fazendaForm!: FormGroup;
  public clients = JSON.parse(localStorage.getItem('cliente') || "{}")
  public fazendaId: any;
  public fazenda: any;
  public validaEditar = false;

  constructor(
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private fazendaService: FazendasService,
    private location: Location,
    private alertService: AlertService,
    private clienteService: ClienteService) { }

  ngOnInit(): void {
    this.montarForm();
    this.popular();
  }

  montarForm() {
    this.fazendaForm = this.fb.group({
      nome: [null, Validators.required],
      client: [null, Validators.required],
    });
  }

  popular() {
    this.route.params.subscribe((params: Params) => {
      this.fazendaId = Number(params["id"]);
      if (this.fazendaId) {
        this.validaEditar = true
        this.fazendaService.AcharPorId(this.fazendaId).subscribe((data: any) => {
          this.fazenda = data;
          this.clienteService.AcharPremocenterCliente(this.fazenda.fk_cliente).subscribe((cliente: any) => {
            this.fazendaForm.get("client")?.setValue(cliente[0].it_id_premocenter);
          })
          this.fazendaForm.get("nome")?.setValue(this.fazenda.vc_nome);
        });
      }
    });
  }

  makeFazenda() {
      this.fazenda = {
        'vc_nome': this.fazendaForm.get("nome")?.value,
        'fk_cliente': this.fazendaForm.get("client")?.value
      }
      this.fazendaService.Atualizar(this.fazenda, this.fazendaId).subscribe(fazenda => {
        this.alertService.success('Fazenda atualizada com sucesso!')
        this.location.back();
      }, err => {
        this.alertService.error('Erro ao criar fazenda')
      })
    
  }

  public cancel(): void {
    this.location.back();
  }
}
