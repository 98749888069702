<main id="home">
    <div class="container">
        <div class="text-center">
            <h1>SELECIONE SEU DASHBOARD</h1>
        </div>
        <div class="cards">
            <section class="card" routerLink="../criar/1">
                <h3>MODELO 1</h3>
                <img src="../../assets/Layout-1.png">
            </section>
            <section class="card" routerLink="../criar/2">
                <h3>MODELO 2</h3>
                <img src="../../assets/Layout-2.png">
            </section>
            <section class="card" routerLink="../criar/3">
                <h3>MODELO 3</h3>
                <img src="../../assets/Layout-3.png">
            </section>
        </div>
        <div class="cards">
            <section class="card" routerLink="../criar/4">
                <h3>MODELO 4</h3>
                <img src="../../assets/Layout-4.png">
            </section>
            <section class="card" routerLink="../criar/5">
                <h3>MODELO 5</h3>
                <img src="../../assets/Layout-5.png">
            </section>
            <section class="card" routerLink="../criar/6">
                <h3>MODELO 6</h3>
                <img src="../../assets/Layout-6.png">
            </section>
        </div>
    </div>
</main>