import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modelo6',
  templateUrl: './modelo6.component.html',
  styleUrls: ['./modelo6.component.css']
})
export class Modelo6Component implements OnInit {

  layout!: any
  area1 = [] as any;
  tempoArea1!: any;
  area2 = [] as any;
  tempoArea2!: any;
  area3 = [] as any;
  tempoArea3!: any;
  area4 = [] as any;
  tempoArea4!: any;
  area5 = [] as any;
  tempoArea5!: any;
  area6 = [] as any;
  tempoArea6!: any;
  showNavigationIndicators = true

  constructor(private config: NgbCarouselConfig) {
    this.config.showNavigationArrows = false;
    this.config.showNavigationIndicators = true;
    this.config.pauseOnFocus = true
  }

  ngOnInit(): void {
    this.layout = sessionStorage.getItem("layout_padrao")
    this.layout = JSON.parse(this.layout)
    this.componentes();
  }

  @ViewChild('carousel', { static: true }) carousel!: NgbCarousel;
  onMenuEvent(event: string) {
    if(event){
      this.carousel.pause();
    }else{
      this.carousel.cycle();
    }
  }

  componentes() {
    this.layout.layouts_personalizados_itens.forEach((item: any) => {
      var json;
      if (item.it_area == 1) {
        this.tempoArea1 = item.it_tempo_componente * 1000
        if (item.vc_componente == "MAPA") {
          json = { "c1": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA") {
          json = { "c2": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ALERTA DA MOTOBOMBA") {
          json = { "c3": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE CONSUMO DA MOTOBOMBA") {
          json = { "c4": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA") {
          json = { "c5": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA") {
          json = { "c6": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA") {
          json = { "c7": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL") {
          json = { "c8": true }
          this.area1.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL") {
          json = { "c9": true }
          this.area1.push(json);
        } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DA OS") {
          json = { "c10": true }
          this.area1.push(json);
        } else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO TURNO"){
          json = { "c11": true }
          this.area1.push(json);
        }else if(item.vc_componente == "KANBAN"){
          json = { "c12": true }
          this.area1.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS"){
          json = { "c13": true }
          this.area1.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA"){
          json = { "c14": true }
          this.area1.push(json);
        }else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO CARRETEL"){
          json = { "c15": true }
          this.area1.push(json);
        }
      } else if (item.it_area == 2) {
        this.tempoArea2 = item.it_tempo_componente * 1000
        if (item.vc_componente == "MAPA") {
          json = { "c1": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA") {
          json = { "c2": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ALERTA DA MOTOBOMBA") {
          json = { "c3": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE CONSUMO DA MOTOBOMBA") {
          json = { "c4": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA") {
          json = { "c5": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA") {
          json = { "c6": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA") {
          json = { "c7": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL") {
          json = { "c8": true }
          this.area2.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL") {
          json = { "c9": true }
          this.area2.push(json);
        } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DA OS") {
          json = { "c10": true }
          this.area2.push(json);
        } else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO TURNO"){
          json = { "c11": true }
          this.area2.push(json);
        }else if(item.vc_componente == "KANBAN"){
          json = { "c12": true }
          this.area2.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS"){
          json = { "c13": true }
          this.area2.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA"){
          json = { "c14": true }
          this.area2.push(json);
        }else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO CARRETEL"){
          json = { "c15": true }
          this.area2.push(json);
        }
      } else if (item.it_area == 3) {
        this.tempoArea3 = item.it_tempo_componente * 1000
        if (item.vc_componente == "MAPA") {
          json = { "c1": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA") {
          json = { "c2": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ALERTA DA MOTOBOMBA") {
          json = { "c3": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE CONSUMO DA MOTOBOMBA") {
          json = { "c4": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA") {
          json = { "c5": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA") {
          json = { "c6": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA") {
          json = { "c7": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL") {
          json = { "c8": true }
          this.area3.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL") {
          json = { "c9": true }
          this.area3.push(json);
        } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DA OS") {
          json = { "c10": true }
          this.area3.push(json);
        } else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO TURNO"){
          json = { "c11": true }
          this.area3.push(json);
        }else if(item.vc_componente == "KANBAN"){
          json = { "c12": true }
          this.area3.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS"){
          json = { "c13": true }
          this.area3.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA"){
          json = { "c14": true }
          this.area3.push(json);
        }else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO CARRETEL"){
          json = { "c15": true }
          this.area3.push(json);
        }
      } else if (item.it_area == 4) {
        this.tempoArea4 = item.it_tempo_componente * 1000
        if (item.vc_componente == "MAPA") {
          json = { "c1": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA") {
          json = { "c2": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ALERTA DA MOTOBOMBA") {
          json = { "c3": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE CONSUMO DA MOTOBOMBA") {
          json = { "c4": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA") {
          json = { "c5": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA") {
          json = { "c6": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA") {
          json = { "c7": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL") {
          json = { "c8": true }
          this.area4.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL") {
          json = { "c9": true }
          this.area4.push(json);
        } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DA OS") {
          json = { "c10": true }
          this.area4.push(json);
        } else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO TURNO"){
          json = { "c11": true }
          this.area4.push(json);
        }else if(item.vc_componente == "KANBAN"){
          json = { "c12": true }
          this.area4.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS"){
          json = { "c13": true }
          this.area4.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA"){
          json = { "c14": true }
          this.area4.push(json);
        }else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO CARRETEL"){
          json = { "c15": true }
          this.area4.push(json);
        }
      } else if (item.it_area == 5) {
        this.tempoArea5 = item.it_tempo_componente * 1000
        if (item.vc_componente == "MAPA") {
          json = { "c1": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA") {
          json = { "c2": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ALERTA DA MOTOBOMBA") {
          json = { "c3": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE CONSUMO DA MOTOBOMBA") {
          json = { "c4": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA") {
          json = { "c5": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA") {
          json = { "c6": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA") {
          json = { "c7": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL") {
          json = { "c8": true }
          this.area5.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL") {
          json = { "c9": true }
          this.area5.push(json);
        } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DA OS") {
          json = { "c10": true }
          this.area5.push(json);
        } else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO TURNO"){
          json = { "c11": true }
          this.area5.push(json);
        }else if(item.vc_componente == "KANBAN"){
          json = { "c12": true }
          this.area5.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS"){
          json = { "c13": true }
          this.area5.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA"){
          json = { "c14": true }
          this.area5.push(json);
        }else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO CARRETEL"){
          json = { "c15": true }
          this.area5.push(json);
        }
      } else if (item.it_area == 6) {
        this.tempoArea6 = item.it_tempo_componente * 1000
        if (item.vc_componente == "MAPA") {
          json = { "c1": true }
          this.area6.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA") {
          json = { "c2": true }
          this.area6.push(json)
        } else if (item.vc_componente == "GRÁFICO DE ALERTA DA MOTOBOMBA") {
          json = { "c3": true }
          this.area6.push(json)
        } else if (item.vc_componente == "GRÁFICO DE CONSUMO DA MOTOBOMBA") {
          json = { "c4": true }
          this.area6.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA") {
          json = { "c5": true }
          this.area6.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA") {
          json = { "c6": true }
          this.area6.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA") {
          json = { "c7": true }
          this.area6.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL") {
          json = { "c8": true }
          this.area6.push(json)
        } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL") {
          json = { "c9": true }
          this.area6.push(json);
        } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DA OS") {
          json = { "c10": true }
          this.area6.push(json);
        } else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO TURNO"){
          json = { "c11": true }
          this.area6.push(json);
        }else if(item.vc_componente == "KANBAN"){
          json = { "c12": true }
          this.area6.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS"){
          json = { "c13": true }
          this.area6.push(json);
        }else if(item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA"){
          json = { "c14": true }
          this.area6.push(json);
        }else if(item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO CARRETEL"){
          json = { "c15": true }
          this.area6.push(json);
        }
      }
    })
  }

}
