import { Observable } from 'rxjs';
import { HttpOptionsService } from './header-options.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class EquipamentosService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  public cadastrar(equipamento: any) {
    const url = `${this.BASE_URL_HIDROMETER}equipamentos`;
    return this.http.post(url, equipamento, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorCliente(cliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}equipamentos/cliente/${cliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorClientePaginate(cliente: any, size: any, visibilidade?: any) {
    let url = `${this.BASE_URL_HIDROMETER}equipamentos/paginate/cliente/${cliente}/size/${size}`;
    if (visibilidade !== undefined){
      url = url+`?visibilidade=${visibilidade}`
    }
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorId(equipamentoID: any) {
    const url = `${this.BASE_URL_HIDROMETER}equipamentos/${equipamentoID}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public Atualizar(equipamento: any, equipamentoId: any) {
    const url = `${this.BASE_URL_HIDROMETER}equipamentos/${equipamentoId}`;
    return this.http.put(url, equipamento, this.httpOptionsService.getHttpOptions());
  }

  public Desativar(equipamentoId: any) {
    const url = `${this.BASE_URL_HIDROMETER}equipamentos/${equipamentoId}`;
    return this.http.delete(url, this.httpOptionsService.getHttpOptions());
  }

  public Next(url: any) {
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public equipamentoSemOperacao(cliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}equipamentos-classificados/cliente/${cliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public equipamentosSemOperacaoKanban(cliente: any): Observable<object>{
    const url = `${this.BASE_URL_HIDROMETER}equipamentos-sem-operacao-kanban/cliente/${cliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public equipamentosNoMapa(cliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}equipamentosNoMapa/cliente/${cliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public recuperarUltimaMensagem(id: number | string | null): Observable<object> {
    const url = `${this.BASE_URL_HIDROMETER}recuperarUltimaMensagem/${id}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public alterarVisibilidade(equipamentoId: Number): any {
    const url = `${this.BASE_URL_HIDROMETER}equipamentos/${equipamentoId}/ocultar-exibir`;
    return this.http.put(url, {}, this.httpOptionsService.getHttpOptions());
  }

  public informacoesDoEquipamento(idEquipamento: number) {
    const url = `${this.BASE_URL_HIDROMETER}informacoesDoEquipamento/${idEquipamento}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public informacoesDosEquipamentosParaMonitoramento(idCliente: number) {
    const url = `${this.BASE_URL_HIDROMETER}informacoesDosEquipamentosParaMonitoramento/cliente/${idCliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }
}
