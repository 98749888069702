<main>
    <div class="cabecalho">
        <div class="d-flex">
            <h1>Operadores</h1>
        </div>
        <span class="info-listas">lista de todos os operadores</span>
        <div class="linha">
            <form [formGroup]="formFilter" fxFlex.lt-md="100" fxLayout="row" fxLayoutAlign="center center">
                <label> Operadores da usina:
                    <select class="filtro-listas" formControlName="client">
                        <option *ngFor="let client of clients" [value]="client.id">
                            {{ client.nome }}
                        </option>
                    </select>
                </label>
            </form>
        </div>
    </div>


    <div fxLayout="column">
        <input matInput (keyup)="applyFilter($event)" placeholder="Filtrar" autocomplete="off" class="input-pesquisa">

        <div *ngIf="loading" class="loader" fxLayout="row" fxLayoutAlign="center center">
            <div *ngIf="loading" class="spinner"></div>
        </div>
        <div class="mensagem">
            <p *ngIf="validaResp" class="text-align-center">Não há operadores cadastrados dessa usina</p>
        </div>

        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort [hidden]="loading">
            <ng-container matColumnDef="it_identificador">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Identificador:
                </th>
                <td mat-cell *matCellDef="let operador">
                    {{ operador.it_identificador }}
                </td>
            </ng-container>

            <ng-container matColumnDef="vc_nome">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Nome:
                </th>
                <td mat-cell *matCellDef="let operador">
                    {{ operador.vc_nome ? operador.vc_nome : "Nome não especificado" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="ts_ultimo_login">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Último login em:
                </th>
                <td mat-cell *matCellDef="let operador">
                    {{ operador.ts_ultimo_login | date: 'dd/MM/yyyy HH:mm:ss' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="bl_cadastro_valido">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Operador válido:
                </th>
                <td mat-cell *matCellDef="let operador">
                    <div class="icone-status">
                        <clr-icon *ngIf="operador.bl_cadastro_valido" shape="check" size="20" class="green"></clr-icon>
                        <clr-icon *ngIf="!operador.bl_cadastro_valido" shape="times" size="20" class="red"></clr-icon>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="controls" class="d-flex justify-content-end">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let operador">
                    <div id="tableButton" class="d-flex justify-content-end">
                        <button class="botao-editar d-flex ml-2" routerLink="../editar/{{ operador.sr_id }}"
                            *ngIf="verificarPermissao(['editar_operadores'])">
                            <clr-icon shape="pencil" size="20"></clr-icon>Editar
                        </button>

                        <button class="botao d-flex ml-2" [ngClass]="{'operador-correto botao-sucesso': !operador.bl_cadastro_valido, 'operador-incorreto botao-excluir': operador.bl_cadastro_valido}"
                            (click)="alterarStatusOperador(operador)" *ngIf="verificarPermissao(['editar_operadores'])">
                            <clr-icon shape="exclamation-circle" size="20"></clr-icon>
                            {{"Marcar como " + statusOperador(!operador.bl_cadastro_valido)}}
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
        </table>
    </div>
    <mat-paginator (page)="trocarPagina($event)" fxLayoutAlign="center center" [length]="length" [pageSize]="100"
        [hidden]="loading">
    </mat-paginator>
</main>