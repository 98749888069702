import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissaoGuardService } from './service/permissao-guard.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalGuard implements CanActivate {

  constructor(private PermissaoGuardService: PermissaoGuardService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.PermissaoGuardService.isAuthenticated(route.data.permissao)) {
      this.router.navigate(['hidrometer']);
      return false;
    }

    return true;
  }

}
