import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerificarPermissoes } from 'src/app/model/verificar-premissao.model';
import { AlertService } from 'src/app/service/alert.service';
import { MensagensService } from 'src/app/service/mensagens.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-mensagens',
  templateUrl: './dialog-mensagens.component.html',
  styleUrls: ['./dialog-mensagens.component.css']
})
export class DialogMensagensComponent implements OnInit {

  public chave!: any;
  public valor: any;
  public tabela = 0;
  json = [] as any;
  idMensagem: any;
  mudancas: any;
  versao: any

  constructor(
      public dialogRef: MatDialogRef<DialogMensagensComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private mensagemService:MensagensService,
      private alertService: AlertService
  ) { }

  public onNoClick(): void {
      this.dialogRef.close();
  }

  ngOnInit(): void {
      this.Executar();
  }

  Executar() {
    if(this.data.Descrição == 'Operacao para esticar o carretel'){
        var metros = this.metrosEsticados(this.data['Latitude Inicial'], this.data['Longitude inicial'], this.data['Latitude'], this.data['Longitude'])
        this.data['Metros esticados'] = metros.toFixed(2) + " metros"
    }
    if (typeof this.data == "object") {
        if (this.data[0] != "-128") {
            if (this.data.versao) {
                this.mudancas = this.data.mudanças
                this.versao = this.data.versao
                this.tabela = 2
            } else {
                this.idMensagem = this.data.idMensagem
                this.chave = Object.keys(this.data);
                this.valor = Object.values(this.data);
                for (var i = 0; i < this.chave.length; i++) {
                    if(this.chave[i] != 'ID código de parada'){
                        this.json.push({ 'chave': this.chave[i], 'valor': this.valor[i] })
                    }
                }
                this.tabela = 1;
            }
        }
    }
  }

  async alterarBocal() {
      // const ipAPI = '//api.ipify.org?format=json'

      // const inputValue = fetch(ipAPI).then(response => response.json()).then(data => data.ip)

      // const { value: bocal } = await Swal.fire({
      //     title: 'Digite o valor do bocal desejado',
      //     input: 'text',
      //     inputValue: inputValue,
      //     showCancelButton: true,
      //     confirmButtonColor: '#41B6E6',
      //     cancelButtonColor: '#df4747',
      //     confirmButtonText: 'Confirmar!',
      //     inputValidator: (value: any) => {
      //         if (!value) {
      //             return 'You need to write something!'
      //         }
      //     }
      // })
      // if (bocal) {
          
      //     this.mensagemService.AlterarBocaisMensagem(this.idMensagem, bocal).subscribe(bocal=>{
      //         Swal.fire({
      //             icon:'success',
      //             title: 'Bocal modificado com sucesso',
      //             text:'Para visualizar a modificação feita aperte f5'
      //         })
      //     },(err) => {
      //           this.alertService.error('Erro ao modificar bocal da mensagem')
      //       })
      // }
  }

  public verificarPermissao(permissoesFuncionalidades: string[]): boolean {
      const permissaoUsuario = localStorage.getItem('permissoes');
      return VerificarPermissoes.temPermissao(permissoesFuncionalidades, permissaoUsuario)
  }

  public metrosEsticados(lat1:any, lon1:any, lat2:any, lon2:any){
    var R = 6378.137; // Radius of earth in KM
    var dLat =  lat2 * Math.PI / 180 -  lat1 * Math.PI / 180;
    var dLon =  lon2 * Math.PI / 180 -  lon1 * Math.PI / 180;
    var a = Math.sin( dLat / 2) * Math.sin( dLat / 2) +
    Math.cos( lat1 * Math.PI / 180) * Math.cos( lat2 * Math.PI / 180) *
    Math.sin( dLon / 2) * Math.sin( dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt( a), Math.sqrt(1 -  a));
    var d =  R * c;
    return  d * 1000; // meters
}
}
