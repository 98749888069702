<div class="modelo1" >
    <div class="borda-cards h-100">
        <ngb-carousel #carousel [interval]='tempoArea1' style="height: 100%" (slid)="onSlide($event)">
            <ng-template ngbSlide *ngFor="let componente of area1">
                <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2"></app-abastecimento-motobomba>
                <app-alertas-motobomba style="height: 100%; width: 100%" *ngIf="componente.c3"></app-alertas-motobomba>
                <app-consumo-motobomba style="height: 100%; width: 100%" *ngIf="componente.c4"></app-consumo-motobomba>
                <app-tempo-efetivo-frota-motobomba style="height: 100%; width: 100%" *ngIf="componente.c5"></app-tempo-efetivo-frota-motobomba>
                <app-tempo-efetivo-os-motobomba style="height: 100%; width: 100%" *ngIf="componente.c6"></app-tempo-efetivo-os-motobomba>
                <app-tempo-efetivo-turno-motobomba style="height: 100%; width: 100%" *ngIf="componente.c7"></app-tempo-efetivo-turno-motobomba>
                <app-tempo-efetivo-os-carretel *ngIf="componente.c8" style="height: 100%; width: 100%"></app-tempo-efetivo-os-carretel>
                <app-tempo-efetivo-turno-carretel *ngIf="componente.c9" style="height: 100%; width: 100%"></app-tempo-efetivo-turno-carretel>
                <app-produtividade-os *ngIf="componente.c10" style="height: 100%; width: 100%"></app-produtividade-os>
                <app-produtividade-turno *ngIf="componente.c11" style="height: 100%; width: 100%"></app-produtividade-turno>
                <app-tempos-trabalhados-frota *ngIf="componente.c13" style="height: 100%; width: 100%"></app-tempos-trabalhados-frota>
                <app-tempos-trabalhados *ngIf="componente.c14" style="height: 100%; width: 100%"></app-tempos-trabalhados>
                <app-produtividade-carretel *ngIf="componente.c15" style="height: 100%; width: 100%"></app-produtividade-carretel>
            </ng-template>
        </ngb-carousel>
    </div>
</div>
