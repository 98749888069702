import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { OperadoresService } from 'src/app/service/operadores.service';

@Component({
  selector: 'app-editar-operadores',
  templateUrl: './editar-operadores.component.html',
  styleUrls: ['./editar-operadores.component.css']
})
export class EditarOperadoresComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  public operadorId!: number;
  public operador: any;
  public operadorForm: FormGroup = new FormGroup({
    operadorForm: new FormControl(),
  });
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  valoresIniciais: any;
  carregado: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private operadorService: OperadoresService,
    private route: ActivatedRoute,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.montarForm();
    this.listarPorId();
  }

  montarForm() {
    this.operadorForm = this.formBuilder.group({
      identifier: [{ value: null, disabled: true }, Validators.required],
      name: [null, Validators.required],
    });
  }

  listarPorId(): void {
    this.carregado = false;
    this.route.params.subscribe((params: Params) => {
      this.operadorId = Number(params.id);
      if (this.operadorId) {
        this.operadorService.AcharPorId(this.operadorId).subscribe(resp => {
          if (resp == null) {
            this.alertService.error('Não é possível editar operador')
            this.cancelar()
          } else {
            this.operador = resp;
            this.operadorForm.get('identifier')?.setValue(this.operador.it_identificador)
            this.operadorForm.get('name')?.setValue(this.operador.vc_nome)
            this.carregado = true;
          }
        }, erro => {
          this.location.back();
        });
      }
    });
  }

  public atualizarOperador(): void {
    this.operador = {
      "it_identificador": this.operadorForm.get("identifier")?.value,
      "vc_nome": this.operadorForm.get("name")?.value
    }
    this.operadorService.AtualizarOperador(this.operador, this.operadorId).subscribe(() => {
      this.alertService.success('Operador atualizado com sucesso')
      this.location.back();
    }, err => {
      this.alertService.warning(err.error.mensagem)
    })
  }

  public cancelar(): void {
    this.location.back();
    this.operadorForm.reset();
  }
}
