import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MensagensService } from 'src/app/service/mensagens.service';

@Component({
  selector: 'app-dialog-mensagens-notificacao',
  templateUrl: './dialog-mensagens-notificacao.component.html',
  styleUrls: ['./dialog-mensagens-notificacao.component.css']
})
export class DialogMensagensNotificacaoComponent implements OnInit {

  LocalStorageDateStart = localStorage.getItem('DateStart');
  LocalStorageDateEnd = localStorage.getItem('DateEnd');
  LocalStorageId = localStorage.getItem('cliente_filtro');
  telemetrias: any;
  flag: any;
  loading = true;

  constructor(private mensagensService: MensagensService,
    public dialogRef: MatDialogRef<DialogMensagensNotificacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    if (this.data[0] == 'mensagemAeB') {
      this.flag = 1
      this.mostrarTelemetrias();
    } else if (this.data[0] == 'mensagemSemOS') {
      this.flag = 2
      this.mensagensSemOS();
    }
  }

  mostrarTelemetrias() {
    this.mensagensService.telemetriasDoAlerta(this.LocalStorageId, this.LocalStorageDateStart, this.LocalStorageDateEnd).subscribe(
      (resp: any) => {
        this.loading = false
        this.telemetrias = resp
      })
  }

  mensagensSemOS() {
    this.mensagensService.mensagensSemOs(this.LocalStorageId, this.LocalStorageDateStart, this.LocalStorageDateEnd).subscribe(
      (resp: any) => {
        this.loading = false
        this.telemetrias = resp
      })
  }
}
