import { Component, Input, OnInit } from '@angular/core';
import * as _ from "underscore";
import { Chart } from "chart.js";
import { GraficosService } from 'src/app/service/graficos.service';

@Component({
  selector: 'app-pressao-saida-motobomba',
  templateUrl: './pressao-saida-motobomba.component.html',
  styleUrls: ['./pressao-saida-motobomba.component.css']
})
export class PressaoSaidaMotobombaComponent implements OnInit {

  constructor(
    private graficosService: GraficosService
  ) { }

  public idClienteDoFiltro = localStorage.getItem('cliente_filtro');
  public dataInicialDoFiltro = localStorage.getItem('DateStartGraficos');
  public dataFinalDoFiltro = localStorage.getItem('DateEndGraficos');
  public idsEquipamentosDoFiltro = localStorage.getItem('IdsEquipamentos');
  public loading = true;
  public erro = false;
  public tipoDeInformacaoAExibir = 'todasMensagens';
  public contemInformacoes: boolean = true;
  public informacoesFiltradas = [];
  public myChart: any;
  public cores = [
    '#8C51A5', '#CB5E98', '#F47B8A', '#FFA37E', '#FFD285',
    '#FFFFA5', '#B9E49F', '#78C59B', '#24635B', '#00838D',
    '#089951', '#0DA600', '#8DB309', '#BFA500', '#CC8B0A',
    '#D95900', '#E6270B', '#F20084', '#B20CF9', '#2700FD'
  ];

  ngOnInit(): void {
    this.graficosService.geralGraficosEquipamentos(this.idClienteDoFiltro, this.idsEquipamentosDoFiltro, this.dataInicialDoFiltro, this.dataFinalDoFiltro, 'pumpPressure', 'Motobomba').subscribe((info: any) => {
      this.loading = false
      if (info.length == 0) {
        this.contemInformacoes = false;
      } else {
        this.informacoesFiltradas = info;
        this.gerarGrafico();
      }
    }, err => {
      this.loading = false;
      this.erro = true;
    })
  }

  gerarGrafico() {
    var arrayDeInformacoesDaPressaoDeSaida: any = [];
    var arrayDeDatas: any = [];

    this.informacoesFiltradas.forEach((informacoesMotobomba: any, index: number) => {
      const informacaoSelecionada = informacoesMotobomba.filter((objeto: any) => objeto.tipo === this.tipoDeInformacaoAExibir)[0];
      var corDaLinha = this.cores[index];
      arrayDeDatas.push(informacaoSelecionada.arrayDeDatasDaMensagemDoEquipamento);
      var informacaoDoGrafico: any = [];
      informacaoSelecionada.arrayDeValoresDoEquipamento.forEach((element: any, indice: any) => {
        informacaoDoGrafico.push({ x: informacaoSelecionada.arrayDeDatasDaMensagemDoEquipamento[indice], y: element })
      });
      var infoDoGrafico = {
        label: informacaoSelecionada.frotaEquipamento,
        data: informacaoDoGrafico,
        pointRadius: 5,
        borderColor: corDaLinha,
        pointBorderColor: corDaLinha,
        pointBackgroundColor: corDaLinha,
        borderWidth: 1,
        fill: false,
      };
      arrayDeInformacoesDaPressaoDeSaida.push(infoDoGrafico);
    });

    if (this.myChart != undefined) {
      this.myChart.destroy()
    }

    var labelEixoX: any = [];
    arrayDeDatas.forEach((arrayDeDatas: any) => {
      labelEixoX = labelEixoX.concat(arrayDeDatas);
    });
    labelEixoX = [... new Set(labelEixoX.sort())]

    var ctx = document.getElementById("graficoPressaoSaida") as HTMLCanvasElement;
    this.myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labelEixoX,
        datasets: arrayDeInformacoesDaPressaoDeSaida
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            title: function (tooltipItem: any, data) {
              var datasetIndex = tooltipItem[0].datasetIndex ?? 0;
              var index: any = tooltipItem[0].index;
              return 'Data: ' + arrayDeDatas[datasetIndex][index];
            },
            label: function (tooltipItem: any) {
              return 'Pressão saída em kgf/cm²: ' + tooltipItem.yLabel;
            }
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: '#393939',
              fontStyle: 'bold',
            },
            scaleLabel: {
              display: true,
              labelString: 'Pressão saída em kgf/cm²',
            }
          }],
          xAxes: [{
            offset: false,
            gridLines: {
              offsetGridLines: false,
              lineWidth: 0
            },
            display: true,
            ticks: {
              suggestedMax: 10,
              fontColor: '#393939',
              fontStyle: 'bold',
            },
          }]
        },
      }
    });
  }
}
