import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BASE_URL_PREMOCENTER_FRONT } from '../app.constants';
import { AlertService } from '../service/alert.service';
import { EquipamentosService } from '../service/equipamentos.service';
import { MensagensService } from '../service/mensagens.service';
import { OperacoesService } from '../service/operacoes.service';
import Swal from 'sweetalert2';
import { DialogEnviarMensagemSatelitalComponent } from '../dialog-enviar-mensagem-satelital/dialog-enviar-mensagem-satelital.component';
import { Router } from '@angular/router';
import { VerificarPermissoes } from '../model/verificar-premissao.model';
import { RedirectService } from '../service/redirect.service';
import { TraduzirMensagemService } from '../service/traduzir-mensagem.service';
import { DialogCarretelComponent } from '../mapa/dialog-carretel/dialog-carretel.component';
import { DialogMotobombaComponent } from '../mapa/dialog-motobomba/dialog-motobomba.component';
import { DialogTratorComponent } from '../mapa/dialog-trator/dialog-trator.component';

@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.css']
})
export class KanbanComponent implements OnInit {

  public formFilter!: FormGroup;
  LocalStorageId = localStorage.getItem("cliente_filtro");
  clients = JSON.parse(localStorage.getItem('cliente') || "{}")
  urlPremoplan = BASE_URL_PREMOCENTER_FRONT;
  public kanban: boolean = true;
  public equipamentos: any[] = [] as any;
  public zero: boolean = true;
  public mensagens = [] as any;
  public datakanban: any[] = [] as any;
  public todasOs: any[] = [] as any;
  public operation: any;
  public flag = 0;
  public osFiltrada = [] as any;
  equipamento: any = {
    'sr_id': 0,
    'vc_frota': "6060",
    'vc_tipo': "MOTOR_PUMP"
  }
  loading = false;
  loadingMudarOS = false;
  idOperacao: any;
  repeater: any;
  timeout: any;

  constructor(
    private operationService: OperacoesService,
    private equipmentService: EquipamentosService,
    public dialog: MatDialog,
    private alertService: AlertService,
    private mensagemService: MensagensService,
    private router: Router,
    private appService: RedirectService,
    private traduzMensagemService: TraduzirMensagemService,
  ) { }

  ngOnInit(): void {
    localStorage.setItem("kanban", "true")
    this.loadingMudarOS = true;
    this.initializeInvites();
    this.appService.mudancaCliente.subscribe(() => {
      this.resetarVariaveis();
      this.initializeInvites();
    });
  }

  public initializeInvites() {
    this.operationService.listarOperoesPorOrdemStatus(this.LocalStorageId).subscribe((operacao: any) => {
      this.dadosKanban(operacao);
    })
  }

  resetarVariaveis() {
    this.LocalStorageId = localStorage.getItem("cliente_filtro");
    this.kanban = true;
    this.equipamentos = [] as any;
    this.zero = true;
    this.mensagens = [] as any;
    this.datakanban = [] as any;
    this.todasOs = [] as any;
    this.flag = 0;
    this.osFiltrada = [] as any;
    this.equipamento = {
      'sr_id': 0,
      'vc_frota': "6060",
      'vc_tipo': "MOTOR_PUMP"
    }
    this.loading = false;
    this.loadingMudarOS = false;
  }

  public changeState(operationId: number, operation: any, state: any): void {
    Swal.fire({
      title: 'Deseja alterar o status da OS?',
      showCancelButton: true,
      confirmButtonColor: '#009DE3',
      cancelButtonColor: '#df4747',
      confirmButtonText: 'Confirmar!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.idOperacao = operationId
        this.loading = true;
        if (operation.equipamentos.length == 0) {
          operation = {
            "vc_service_id": operation.vc_service_id,
            "vc_estado": state,
            "cliente_hidrometer": operation.fk_cliente,
            "fk_equipamento_trator": operation.fk_equipamento_trator,
            "fk_fazenda": operation.fk_fazenda
          }
        } else {
          var equipamentos = operation.equipamentos.map((equipamento: any) => equipamento.sr_id);
          operation = {
            "vc_service_id": operation.vc_service_id,
            "vc_estado": state,
            "cliente_hidrometer": operation.fk_cliente,
            "fk_equipamento_trator": operation.fk_equipamento_trator,
            "fk_fazenda": operation.fk_fazenda,
            "fk_equipamentos": equipamentos
          }
        }
        this.operationService.AtualizarStatus(operation, operationId).subscribe(operacao => {
          this.alertService.success('Status da OS editada com sucesso')
          this.loading = false;
          this.initializeInvites()
        }, err => {
          this.alertService.exibirMensagemErro(err, 'Erro ao editar status da OS')
          this.loading = false;
        })
      }
    })

  }

  drop(event: CdkDragDrop<any>) {
    this.loadingMudarOS = true;
    var operacaoAntiga, operacaoNova, equipamento: any;

    operacaoAntiga = event.previousContainer.element.nativeElement.getAttribute('operacao-id');
    operacaoNova = event.container.element.nativeElement.getAttribute('operacao-id');
    equipamento = event.item.data.sr_id;

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.loadingMudarOS = false;
    } else {
      this.mudarEquipamentoDeOs([operacaoAntiga, operacaoNova, equipamento], [event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex]);
    }
  }

  mudarEquipamentoDeOs(dadosRequest: any, dadosArray: any) {
    this.operationService.removerEquipamentoDaOsAntigaEaddOsNova(dadosRequest[0], dadosRequest[1], dadosRequest[2]).subscribe((resp: any) => {
      this.loadingMudarOS = false;
      this.alertService.success(resp.mensagem)

      transferArrayItem(dadosArray[0], dadosArray[1], dadosArray[2], dadosArray[3]);
    }, (error: any) => {
      this.loadingMudarOS = false;
      this.alertService.error('Não foi possível completar a alteração.');
    });
  }

  dadosKanban(operacao: any): void {
    this.datakanban = operacao;
    this.equipmentService.equipamentosSemOperacaoKanban(this.LocalStorageId).subscribe((equipamentos: any) => {
      this.equipamentos = equipamentos;
    })
    if (operacao.numberOfElements == 0) {
      this.zero = false;
    } else {
      this.datakanban.forEach((operation: any) => {
        var todosEquipamentos = operation.equipamentos.map(function (op: any) {
          return op.sr_id
        })
        todosEquipamentos.push(operation.fk_equipamento_trator)
        if (operation.vc_estado != 'COMPLETED') {
          this.mensagemService.ultimaMensagemEquipamentoKanban(todosEquipamentos).subscribe((mensagens: any) => {
            mensagens.forEach((mensagem: any) => {
              operation.equipamentos.forEach((element: any) => {
                if (element.vc_frota == mensagem.vc_frota) {
                  element.cliqueDetalhes = false;
                  element.ultimaMensagem = mensagem.ultimaMensagem
                  element.estado = mensagem.estado
                  element.mensagemAlerta = mensagem.mensagemAlerta
                }
              });
            });
          });
        }
      });
    }
    this.todasOs = this.datakanban
    this.loadingMudarOS = false;
  }

  onChange(enable: boolean) {
    if (enable == true) {
      this.filtrados();
    } else {
      this.datakanban = this.todasOs;
    }
  }

  //mostrar somente operações com alerta
  filtrados() {
    var dadoExibir = [] as any
    this.datakanban.forEach((element: any) => {
      var map = element.equipamentos.filter(function (a: any) {
        return a.mensagemAlerta != undefined
      })
      if (map.length != 0) {
        dadoExibir.push(element)
      }
    });
    this.datakanban = dadoExibir
  }

  async Mensagem(dados: any, motobombaId: any) {
    dados.motobombaId = motobombaId
    const dialogRef = this.dialog.open(DialogEnviarMensagemSatelitalComponent, {
      width: '50rem',
      data: dados,
    });
  }

  tratarAlertas(alert: any, group: any) {
    if (group == "Eletrobomba") {
      let alertas;
      if (alert.includes("/")) {
        alertas = JSON.parse(alert);
      } else {
        alertas = alert;
      }
      if (alertas.includes("1")) {
        if (typeof alertas == 'string') {
          alertas = JSON.parse(alert);
        }
        alert = this.traduzMensagemService.alertasDaMotobomba(alertas);
      } else {
        alert = "Sem alertas";
      }
    } else if (group == "Motobomba") {
      let alertas
      if (alert.includes("/")) {
        alertas = JSON.parse(alert);
      } else {
        alertas = alert;
      }
      if (alertas.includes("1")) {
        if (typeof alertas == 'string') {
          alertas = JSON.parse(alert);
        }
        alert = this.traduzMensagemService.alertasDaMotobomba(alertas);
      } else {
        if (alert.includes("Restrição filtro de ar")) {
          alert = alert
        } else if (alert.includes("Pressão baixo do óleo")) {
          alert = alert
        } else if (alert.includes("Pressão de saída baixa")) {
          alert = alert
        } else if (alert.includes("Nível baixo arrefecimento")) {
          alert = alert
        } else if (alert.includes("Temperatura alta arrefecimento")) {
          alert = alert
        } else if (alert.includes("Tensão baixa bateria")) {
          alert = alert
        } else if (alert.includes("Nível baixo combustível")) {
          alert = alert
        } else if (alert.includes("Nível baixo sucção")) {
          alert = alert
        } else {
          alert = "Sem alertas";
        }
      }
    }
    return alert
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  naoPodeEntrarNaLista() {
    return false;
  }
  podeEntrarNaLista() {
    return true;
  }

  public verificarPermissao(permissoesFuncionalidades: string[]): boolean {
    const permissaoUsuario = localStorage.getItem('permissoes');
    return VerificarPermissoes.temPermissao(permissoesFuncionalidades, permissaoUsuario)
  }

  validarExibicaoDoComandoRemoto(tipo: string, estado: string): boolean {
    return tipo == 'MOTOR_PUMP' && estado == 'RUNNING' && this.verificarPermissao(['comando_remoto_motobomba_hidrometer'])
  }

  abrirModalInformacoesEquipamento(equipamentoKanban: any) {
    equipamentoKanban.cliqueDetalhes = true;

    this.equipmentService.informacoesDoEquipamento(equipamentoKanban.sr_id).subscribe((equipamento: any) => {
      equipamentoKanban.cliqueDetalhes = false;
      localStorage.setItem('equipamentoId', equipamento.sr_id);
      const configuracaoDialogo: any = this.obterConfiguracaoDialogo(equipamento);
      this.dialog.open(configuracaoDialogo.dialogoParaAbrir, configuracaoDialogo);
    });
  }

  private obterConfiguracaoDialogo(equipamento: any): object {
    const configuracaoBase = {
      minWidth: '35vw',
      maxHeight: '95vh',
      data: { 'dados': equipamento }
    };

    if (equipamento.vc_tipo == 'REEL' || equipamento.vc_tipo == 'MOTOR_PUMP') {
      const urlBase = equipamento.vc_tipo == 'REEL' ? '/assets/icones-carretel/carretel-' : '/assets/icone-motobomba/motobomba-';
      const url = `${urlBase}${equipamento.mensagemAlerta ? 'alerta-' : ''}${equipamento.estado === 'Sem informação' ? 'parado-' : 'funcionando-'}${equipamento.vc_ultimo_sin === 128 ? 'sinal.png' : 'sem-sinal.png'}`;
      return {
        ...configuracaoBase,
        dialogoParaAbrir: equipamento.vc_tipo === 'REEL' ? DialogCarretelComponent : DialogMotobombaComponent,
        data: { ...configuracaoBase.data, 'url': url }
      };
    }

    const url = equipamento.vc_ultimo_sin == 128 ? '/assets/icones-trator/trator-sinal.png' : '/assets/icones-trator/trator-sem-sinal.png';
    return {
      ...configuracaoBase,
      maxHeight: undefined,
      dialogoParaAbrir: DialogTratorComponent,
      data: { ...configuracaoBase.data, 'url': url }
    };
  }
}
