import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { ClienteService } from 'src/app/service/cliente.service';
import { FazendasService } from 'src/app/service/fazendas.service';

@Component({
  selector: 'app-criar-fazendas',
  templateUrl: './criar-fazendas.component.html',
  styleUrls: ['./criar-fazendas.component.css']
})
export class CriarFazendasComponent implements OnInit {

  public fazendaForm!: FormGroup;
  public clients = JSON.parse(localStorage.getItem('cliente') || "{}")
  public fazendaId: any;
  public fazenda: any;
  public validaEditar = false;

  constructor(
      public fb: FormBuilder,
      private route: ActivatedRoute,
      private fazendaService: FazendasService,
      private location: Location,
      private alertService: AlertService,
      private clienteService: ClienteService) { }

  ngOnInit(): void {
      this.montarForm();
  }

  montarForm() {
      this.fazendaForm = this.fb.group({
          nome: [null, Validators.required],
          client: [null, Validators.required],
      });
  }

  makeFazenda() {
          this.fazenda = {
              'vc_nome': this.fazendaForm.get("nome")?.value,
              'fk_cliente': this.fazendaForm.get("client")?.value
          }
          this.fazendaService.cadastrar(this.fazenda).subscribe((fazenda: any) => {
              this.alertService.success('Fazenda criada com sucesso!')
              this.location.back();
          }, err => {
              this.alertService.error('Erro ao criar fazenda')
          })
  }

  public cancel(): void {
      this.location.back();
  }
}
