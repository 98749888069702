import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogExportarGeojsonComponent } from 'src/app/dialog-exportar-geojson/dialog-exportar-geojson.component';
import { MensagensService } from 'src/app/service/mensagens.service';
import { RedirectService } from 'src/app/service/redirect.service';
import { TraduzirMensagemService } from 'src/app/service/traduzir-mensagem.service';

@Component({
  selector: 'app-botao-exportar',
  templateUrl: './botao-exportar.component.html',
  styleUrls: ['./botao-exportar.component.css']
})
export class BotaoExportarComponent implements OnInit {
  contentClosed: boolean = true;
  exibirExportacaoGeojson: boolean = true;

  constructor(
    public dialog: MatDialog,
    private mensagemService: MensagensService,
    private traduzService: TraduzirMensagemService,
    private redirectService: RedirectService
  ) { }

  ngOnInit(): void {
    this.redirectService.descricaoElementoExpandidoSobreMapa.subscribe((elemento: string) => {
      if (elemento != 'botao-exportar') this.closeContent()
    });
  }

  toggleContent() {
    this.contentClosed = !this.contentClosed;
    let card = document.querySelector('#botaoExportar');
    card?.classList.toggle('active')
    if (!this.contentClosed) this.redirectService.elementoExpandidoSobreMapa('botao-exportar');
  }

  closeContent() {
    this.contentClosed = true;
    let card = document.querySelector('#botaoExportar');
    card?.classList.remove('active')
  }

  abrirExportarGeoJson(): void {
    this.dialog.open(DialogExportarGeojsonComponent, {
      width: '30vw'
    });
  }

  exportExcel() {
    var mensagensJuntar = [[]] as any;
    var localStorageId = localStorage.getItem('cliente_filtro');
    var localStorageOperationId = localStorage.getItem('operacaoId');
    var localStorageDateStart = localStorage.getItem('DateStart');
    var localStorageDateEnd = localStorage.getItem('DateEnd');
    this.mensagemService.excel(localStorageId, localStorageDateStart, localStorageDateEnd, localStorageOperationId).subscribe((mensagens: any) => {
      mensagens.forEach((mensagem: any) => {
        var payload = JSON.parse(mensagem.js_payload_tratado);
        payload = this.traduzService.TraduzirPay(payload)
        var payKey = Object.keys(payload);
        payKey.forEach((key: any) => {
          if (!mensagensJuntar[0].includes(key)) {
            mensagensJuntar[0].push(key)
          }
        });
      });
      mensagens.forEach((mensagem: any) => {
        var array = [] as any;
        var payload = JSON.parse(mensagem.js_payload_tratado);
        payload = this.traduzService.TraduzirPay(payload)
        mensagensJuntar[0].forEach((element: any) => {
          if (payload.hasOwnProperty(element)) {
            array.push(payload[element])
          } else {
            array.push("Não se aplica")
          }
        });
        mensagensJuntar.push(array)
      })
      let csvContent = "data:text/csv;charset=utf-8,"
        + mensagensJuntar.map((e: any) => e.join(";")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Mensagens" + localStorageDateStart + "_" + localStorageDateEnd);
      document.body.appendChild(link);
      link.click();
    })
  }
}
