import { Component, OnInit } from '@angular/core';
import * as _ from "underscore";
import { TraduzirMensagemService } from 'src/app/service/traduzir-mensagem.service';
import { GraficosService } from 'src/app/service/graficos.service';
import { FormatarHoraService } from 'src/app/service/formatar-hora.service';

@Component({
    selector: 'app-funcionamento-motobomba',
    templateUrl: './funcionamento-motobomba.component.html',
    styleUrls: ['./funcionamento-motobomba.component.css']
})
export class FuncionamentoMotobombaComponent implements OnInit {
    constructor(
        private traduzService: TraduzirMensagemService,
        private graficosService: GraficosService,
        private formatarHora: FormatarHoraService
    ) { }

    datas: string[] = [];
    pontos = [] as any;
    dataFiltro: string = "";
    horas: string = "";
    texto: string | undefined = undefined;
    mensagensParaTabela = [] as any;
    idEquipamentoNoLocalStorage: string | null = localStorage.getItem("equipamentoId");
    idClienteNoLocalStorage: string | null = localStorage.getItem('cliente_filtro');
    dataInicialNoLocalStorage: string | null = localStorage.getItem('DateStart');
    dataFinalNoLocalStorage: string | null = localStorage.getItem('DateEnd');
    loading: boolean = true;
    informacoes: any;

    ngOnInit(): void {
        this.graficosService.timelineMotobomba(this.idClienteNoLocalStorage, this.dataInicialNoLocalStorage, this.dataFinalNoLocalStorage, this.idEquipamentoNoLocalStorage).subscribe((informacoes: any) => {
            this.informacoes = informacoes;
            this.datas = Object.keys(informacoes);
            this.dataFiltro = this.datas[0];
            this.preecherInformacoes();
        })
    }

    preecherInformacoes(): void {
        this.mensagensParaTabela = [];
        var informacaoDataFiltrada = this.informacoes[this.dataFiltro];

        if (informacaoDataFiltrada.pontosDaTimeline.length == 0) {
            this.texto = "Sem dados do dia " + this.dataFiltro;
            this.pontos = [];
        } else {
            this.horas = this.formatarHora.formatarSegundosParaHora(informacaoDataFiltrada.tempoTrabalhado);
            this.pontos = informacaoDataFiltrada.pontosDaTimeline;
            informacaoDataFiltrada.mensagensTabela.forEach((mensagem: any) => {
                this.mensagensParaTabela.push(this.traduzService.TraduzirPay(mensagem))
            });
        }
        this.loading = false;
    }

    clickFiltro(data: any): void {
        this.dataFiltro = data;
        this.preecherInformacoes();
    }
}