import { Params } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER, BASE_URL_PREMOCENTER } from '../app.constants';
import { HttpOptionsService } from './header-options.service';

@Injectable({
  providedIn: 'root'
})
export class LayoutsService {
  readonly BASE_URL = BASE_URL_HIDROMETER;
  readonly BASE_URL_PREMOCENTER = BASE_URL_PREMOCENTER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  Cadastrar(layout: any) {
    let url = `${this.BASE_URL}layouts-personalizados`
    return this.http.post(url, layout, this.httpOptionsService.getHttpOptions())
  }

  Listar(token: any, cliente: any, grupo: any) {
    const url = `${this.BASE_URL}layouts-personalizados?idClientes=${cliente}&idGrupos=${grupo}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions())
  }

  Desativar(id: any, token: any) {
    const url = `${this.BASE_URL}layouts-personalizados/${id}`;
    return this.http.delete<any>(url, this.httpOptionsService.getHttpOptions());
  }

  BuscarPorId(id: any) {
    const url = `${this.BASE_URL}layouts-personalizados/${id}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  Atualizar(id: any, layout: any) {
    let url = `${this.BASE_URL}layouts-personalizados/${id}`
    return this.http.put(url, layout, this.httpOptionsService.getHttpOptions())
  }

  CadastrarItemLayout(itemLayout: any, token: any) {
    let url = `${this.BASE_URL}layouts-personalizados-itens`
    return this.http.post(url, itemLayout, this.httpOptionsService.getHttpOptions())
  }

  DesativarItemLayout(id: any, token: any) {
    const url = `${this.BASE_URL}delete/layouts-personalizados-itens/${id}`;
    return this.http.delete<any>(url, this.httpOptionsService.getHttpOptions());
  }

  AtualizarItensLayout(id: any, layout: any) {
    let url = `${this.BASE_URL}layouts-personalizados-itens/${id}`
    return this.http.put(url, layout, this.httpOptionsService.getHttpOptions())
  }

  BuscarLayoutsPersonalizadosCliente(idClientes: string, idGrupos: string, idCliente: string, veioDaSideBar?: boolean) {
    const url = `${this.BASE_URL}layouts-personalizados`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'idClientes': idClientes,
      'idGrupos': idGrupos,
      'idCliente': idCliente
    }
    if (veioDaSideBar) {
      headerOptions.params.veioDaSideBar = true
    }
    return this.http.get(url, headerOptions);
  }
}
