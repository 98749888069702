import { style } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { AlertService } from 'src/app/service/alert.service';
import { RedirectService } from 'src/app/service/redirect.service';

@Component({
  selector: 'app-botao-busca-equipamento',
  templateUrl: './botao-busca-equipamento.component.html',
  styleUrls: ['./botao-busca-equipamento.component.css']
})
export class BotaoBuscaEquipamentoComponent implements OnInit {
  contentClosed: boolean = true;
  equipamentos: any;
  condicaoListaDeEquipamentos: boolean = false;
  pesquisa: any;
  placeholder: string = 'Carregando...'

  constructor(
    private alertService: AlertService,
    private redirectService: RedirectService,
    public elRef: ElementRef,
  ) { }

  ngOnInit(): void {
    this.redirectService.mudancaCliente.subscribe(() => {
      this.alterarVariaveisIniciais([], 'Carregando...');
    });

    this.redirectService.enviaEquipamentosCliente.subscribe((equipamentosCliente) => {
      this.alterarVariaveisIniciais(equipamentosCliente, 'Equipamentos');

    });

    this.redirectService.descricaoElementoExpandidoSobreMapa.subscribe((elemento: string) => {
      if (elemento != 'botao-busca-equipamento') this.closeContent()
    });
  }

  alterarVariaveisIniciais(equipamento: Array<object>, placeholder: string): void {
    this.equipamentos = equipamento;
    this.placeholder = placeholder
  }

  toggleContent() {
    this.pesquisa = '';
    this.contentClosed = !this.contentClosed;
    let card = document.querySelector('#botaoBuscaEquipamento');
    card?.classList.toggle('active')
    if (!this.contentClosed) {
      this.redirectService.elementoExpandidoSobreMapa('botao-busca-equipamento');
    }
  }

  closeContent() {
    this.contentClosed = true;
    let card = document.querySelector('#botaoBuscaEquipamento');
    card?.classList.remove('active')
  }

  acharEquipamento(event: any, equip: string) {
    this.pesquisa = equip
    const selectedValue = event.target.value;
    const equipamento = this.equipamentos.find((e: any) => Number(e.sr_id) === selectedValue);
    if (!equipamento || equipamento.vc_latitude === null || equipamento.vc_longitude === null) {
      this.alertService.warning('Não encontramos latlng para esse equipamento');
    } else {
      const latLng = { longitude: equipamento.vc_longitude, latitude: equipamento.vc_latitude };
      this.redirectService.mapaRecebeLatLngEquipamento(latLng);
    }
  }

  abrirEquipamentos(condition: boolean): void {
    setTimeout(() => {
      this.condicaoListaDeEquipamentos = condition;
    }, 130);
  }

  search() {
    let equipamentoPesquisado = this.elRef.nativeElement.querySelectorAll(".equip")
    for (let i = 0; i < equipamentoPesquisado.length; i++) {
      if (!equipamentoPesquisado[i].innerHTML.toLocaleLowerCase().includes(this.pesquisa)) {
        equipamentoPesquisado[i].style.display = 'none'
      } else {
        equipamentoPesquisado[i].style.display = 'list-item'
      }
    }
  }

}
