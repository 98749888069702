import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OperacoesService } from 'src/app/service/operacoes.service';
import { RedirectService } from 'src/app/service/redirect.service';

@Component({
  selector: 'app-dialog-carretel',
  templateUrl: './dialog-carretel.component.html',
  styleUrls: ['./dialog-carretel.component.css']
})
export class DialogCarretelComponent implements OnInit {
  public LocalStorageFrota = localStorage.getItem("frota");
  public LocalStorageEquipamentoId = localStorage.getItem("equipamentoId");
  public LocalStorageId = localStorage.getItem('cliente_filtro');
  public LocalStorageOperationId = localStorage.getItem('operacaoId');
  public LocalStorageDateStart = localStorage.getItem('DateStart');
  public LocalStorageDateEnd = localStorage.getItem('DateEnd');
  mensagens: any;
  ultimaMensagem: any;
  loading = true;
  operacao = '';

  public sinal: boolean = !this.data.url.includes('sem-sinal');
  public trabalhando: boolean = this.data.url.includes('funcionando');
  public oqSeraExibido = 'InformacoesGerais';

  constructor(
    public appService: RedirectService,
    public dialog: MatDialog,
    public operacaoService: OperacoesService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.informacoesUltimaMensagem();

  }

  informacoesUltimaMensagem() {
    if(this.data.dados.ultima_mensagem.js_payload_tratado == "\"SIN diferente de 128\""){
      var mensagens = [this.data.dados.ultimaMensagemSinDiferente];
    }else{
      var mensagens = [this.data.dados.ultima_mensagem];
    }
    this.ultimaMensagem = mensagens;
    if (mensagens.length > 0 && mensagens[0]?.fk_operacao != null) {
      this.operacaoService.AcharPorId(mensagens[0]?.fk_operacao).subscribe((operacao: any) => {
        this.operacao = operacao.vc_service_id
      })
    }
    setTimeout(() => {
      this.appService.mandarValorInformacoesUltimaMensagem(mensagens);
      this.loading = false;
    }, 1000)
  }
}