<div class="container-fluid h-100">
    <span class="titulo d-flex justify-content-center mb-1">Selecione um gráfico para visualizá-lo.</span>
    <div class="h-75 p-2 graficos-scroll" id="graficos">
        <div id="linkLayout" class="cards mt-2 p-1" routerLink="meusLayout/listar">
            <img class="mx-1" src="../../assets/icones-graficos/icone-layout-personalizado.svg"> Personalizar layout de
            gráficos
        </div>
        <div *ngIf="layouts.length > 0">
            <div *ngFor="let layout of layouts" class="cards mt-2 p-1" (click)="redirecionarParaGraficos(layout.sr_id)">
                <img class="mx-1" src="../../assets/icones-graficos/icone-layout-favorito.svg"> {{layout.vc_nome}}
            </div>
        </div>
        <div *ngFor="let grafico of graficos" class="cards mt-2 p-1" (click)="redirecionarParaGraficos(grafico.nome)">
            <img class="mx-1" src="../../assets/icones-graficos/{{grafico.icone}}"> {{grafico.nome}}
        </div>
    </div>
</div>