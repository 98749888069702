<main style="width: 96%;">
    <div class="cabecalho">
        <div class="d-flex">
            <h1>Mensagens do equipamento</h1>
        </div>
        <span class="info-listas">lista de todas as mensagens do equipamento {{data[2]}}</span>
    </div>
    <div fxLayout="column">
        <input matInput (keyup)="applyFilter($event)" placeholder="Filtrar" autocomplete="off" class="input-pesquisa">

        <div *ngIf="loading" style="width: auto; height: 60vh;" fxLayout="row" fxLayoutAlign="center center">
            <div class="spinner"></div>
        </div>
        <div class="mensagem">
            <p *ngIf="validaResp" style="text-align: center;">Não há mensagens para desse equipamento</p>
        </div>

        <table mat-table [dataSource]="dataSource" matSort [hidden]="loading">
            <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Horário da Msg
                </th>

                <td mat-cell *matCellDef="let element">
                    {{ element.timestamp | date: "dd/MM/yyyy HH:mm:ss":"UTC -3" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="vc_mobileId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Comunicador ID</th>
                <td mat-cell *matCellDef="let element">{{ element.vc_mobileId ? element.vc_mobileId : ""}}</td>
            </ng-container>

            <ng-container matColumnDef="it_tamanho_da_mensagem">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tamanho Msg</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.it_tamanho_da_mensagem ? element.it_tamanho_da_mensagem : ""}}
                </td>
            </ng-container>

            <ng-container matColumnDef="vc_frota">
                <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header>
                    Frota do Equipamento
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.vc_frota ? element.vc_frota : ""}}
                </td>
            </ng-container>


            <ng-container matColumnDef="descricao">
                <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header>
                    Descrição
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.descricao ? element.descricao : "Mensagem gerada
                    incorretamente"}}
                </td>
            </ng-container>

            <ng-container matColumnDef="operador">
                <th mat-header-cell mat-sort-header *matHeaderCellDef mat-sort-header>
                    Operador
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.operador_nome ? element.operador_nome : "Mensagem gerada
                    incorretamente"}}
                </td>
            </ng-container>

            <ng-container matColumnDef="fk_operacao">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    ID Operação
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.fk_operacao }}
                </td>
            </ng-container>

            <ng-container matColumnDef="it_service_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    OS
                </th>
                <td mat-cell *matCellDef="let element" style="padding-right:2rem">
                    {{ element.it_service_id }}
                </td>
            </ng-container>

            <ng-container matColumnDef="controls">
                <th mat-header-cell *matHeaderCellDef>
                    Dados
                </th>
                <td mat-cell *matCellDef="let element">
                    <button class="botao-icone-mensagem" (click)="parseMessage($event, element.sr_id)">
                        <clr-icon shape="clipboard" class="is-solid" size="25"></clr-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>

    <mat-paginator (page)="trocarPagina($event)" fxLayoutAlign="center center" [length]="length" [pageSize]="100"
        [hidden]="loading">
    </mat-paginator>
</main>