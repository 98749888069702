import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { GraficosService } from 'src/app/service/graficos.service';
import { MensagensService } from 'src/app/service/mensagens.service';
import * as _ from "underscore";

@Component({
  selector: 'app-alertas-motobomba',
  templateUrl: './alertas-motobomba.component.html',
  styleUrls: ['./alertas-motobomba.component.css']
})
export class AlertasMotobombaComponent implements OnInit {

  idCliente = localStorage.getItem('cliente_filtro');
  dataInicio = localStorage.getItem('DateStartGraficos');
  dataFinal = localStorage.getItem('DateEndGraficos');
  validaResp = false;
  loading = true;
  barChartLabels: any = [];
  barChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    "scales": {
      "yAxes": [{
        "ticks": {
          "beginAtZero": true,
        }
      }],
    }
  };
  barChartType: ChartType = 'bar';
  barChartData: ChartDataSets[] = [];
  erro = false;

  constructor(
    private graficosService: GraficosService
  ) { }

  ngOnInit(): void {
    this.alertasDaFrota();
  }

  alertasDaFrota() {
    this.graficosService.graficoAlertasMotobomba(this.dataInicio, this.dataFinal, this.idCliente).subscribe((resposta: any) => {
      this.loading = false
      if(resposta.frotas.length == 0){
        this.validaResp = true
      }else{
        let alertasComValor = resposta.alertas.filter((elemt:any) => {
          return elemt.data.some((valor:any) => valor > 0)
        })
        this.barChartLabels = resposta.frotas
        this.barChartData = alertasComValor
      }
    }, err =>{
      this.loading = false;
      this.erro = true;
    })
  }
}