import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';
import { HttpClient } from '@angular/common/http';
import { HttpOptionsService } from './header-options.service';

@Injectable({
  providedIn: 'root'
})
export class AgviewsConnectService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  public acharAGViewsConnectLivres(cliente: number) {
    const url = `${this.BASE_URL_HIDROMETER}agviews-connect-livres/${cliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }
}
