import { HttpOptionsService } from './header-options.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class CaracteristicasService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;
  token = localStorage.getItem('token');
  headerOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
  };

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
    ) { }

  public cadastrar(caracteristica: any) {
    const url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional`;
    return this.http.post(url, caracteristica, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorCliente(cliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional/cliente/${cliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorClientePaginate(cliente: any, size: any) {
    const url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional/paginate/cliente/${cliente}/size/${size}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorClienteeTipo(cliente: any, tipo: any, idOperacaoOuEquipamento?:any) {
    var url:string;
    if(idOperacaoOuEquipamento){
        url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional/cliente/${cliente}/tipo/${tipo}/${idOperacaoOuEquipamento}`;
    } else {
        url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional/cliente/${cliente}/tipo/${tipo}`;
    }
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharLamina(cliente: any, operacao: any) {
    const url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional/lamina/cliente/${cliente}/operacao/${operacao}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPressao(cliente: any, operacao: any) {
    const url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional/pressao/cliente/${cliente}/operacao/${operacao}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorId(caracteristicaId: any) {
    const url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional/${caracteristicaId}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharValorPorCaracteristicaEId(caracteristicaId: any, id: any, tipo: any) {
    const url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional/${caracteristicaId}/equipamentoOuoperacao/${id}/tipo/${tipo}`;
    return this.http.get(url, this.headerOptions);
  }

  public AtualizarValor(valor: any, caracId: any) {
    const url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional/atualizarValor/${caracId}`;
    return this.http.put(url, valor, this.headerOptions);
  }

  public Atualizar(caracteristica: any, caracId: any) {
    const url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional/${caracId}`;
    return this.http.put(url, caracteristica, this.headerOptions);
  }

  public Desativar(caracteristicaId: any) {
    const url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional/${caracteristicaId}`;
    return this.http.delete(url, this.headerOptions);
  }

  public cadastrarValor(valor: any, caracteristicaId: any) {
    const url = `${this.BASE_URL_HIDROMETER}caracteristicaAdicional/${caracteristicaId}`;
    return this.http.post(url, valor, this.headerOptions);
  }

  public Next(url: any) {
    return this.http.get(url, this.headerOptions);
  }
}
