import { Component, OnInit } from '@angular/core';
import { RedirectService } from 'src/app/service/redirect.service';

@Component({
  selector: 'app-informacoes-carretel',
  templateUrl: './informacoes-carretel.component.html',
  styleUrls: ['./informacoes-carretel.component.css']
})
export class InformacoesCarretelComponent implements OnInit {

  constructor(
    public appService: RedirectService) { }

  public ultimaMensagem: any;
  public tipoMensagem: any = 'Sem dados';
  public alerta = "";
  public velocidadeRecolhimento = "Sem dados";
  public pressao = "Sem dados";
  public odometro = "Sem dados";
  public status = "Sem dados";
  public bateria = "Sem dados";
  public OS: any;
  public LocalStorageFrota = localStorage.getItem("frota");
  public LocalStorageId = localStorage.getItem('cliente_filtro');
  public LocalStorageOperationId = localStorage.getItem('operacaoId');
  public LocalStorageDateStart = localStorage.getItem('DateStart');
  public LocalStorageDateEnd = localStorage.getItem('DateEnd');
  public load = true;

  ngOnInit(): void {
    this.buscarUltimaMensagemCarretel();
  }

  buscarUltimaMensagemCarretel() {
    this.appService.informacoesUltimaMensagem.subscribe(mensagens => {
      if (mensagens != '') {
        var payload = JSON.parse(mensagens[0].js_payload_tratado);
        if (typeof payload != 'string') {
          if (payload.it_service_id !== undefined) {
            this.OS = this.pegarItServiceIdDoObjeto(payload);
          } else {
            this.OS = this.pegarItServiceIdDoObjeto(mensagens[0]);
          }
          this.tratarPayloadCronos(payload);
        }
      } else {
        this.load = false;
        this.ultimaMensagem = '';
      }
    });
  }

  tratarPayloadCronos(mensagem: any) {
    this.ultimaMensagem = mensagem.timestamp
    this.tipoMensagem = mensagem.descriptor
    this.velocidadeRecolhimento = mensagem.speedAverage != undefined ? mensagem.speedAverage + ' m/h' : 0 + ' m/h';
    this.pressao = mensagem.pressure != undefined ? mensagem.pressure.toFixed(2) + ' kgf/cm²' : 0 + ' kgf/cm²';
    this.odometro = mensagem.odometer != undefined ? mensagem.odometer + ' m' : 0 + ' m';
    this.bateria = mensagem.battery != undefined ? mensagem.battery + ' V' : 0 + ' V';
    this.status = this.tratarStatusCarretel(mensagem.status);
    if (mensagem.descriptor == 'Alertas') {
      this.alerta = "Parado e aplicando";
    } else if (mensagem.descriptor == 'Telemetria' && mensagem.battery <= 10) {
      this.alerta = "Bateria fraca";
    }
    this.load = false
  }

  tratarStatusCarretel(status: any): any {
    if (status == 0) {
      return "Parado"
    } else if (status == 1) {
      return "Recolhendo"
    } else if (status == 2) {
      return "Esticando"
    } else {
      return "Sem informações"
    }
  }

  pegarItServiceIdDoObjeto(objeto: any) {
    if (objeto.it_service_id) {
      return objeto.it_service_id;
    }

    return "Mensagem não vinculada a OS";
  }

}
