import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { CaracteristicasService } from 'src/app/service/caracteristicas.service';

@Component({
  selector: 'app-valor-caracteristicas',
  templateUrl: './valor-caracteristicas.component.html',
  styleUrls: ['./valor-caracteristicas.component.css']
})
export class ValorCaracteristicasComponent implements OnInit {

  public addDataForm!: FormGroup;
  public idCaracteristica!: number;
  public id?: number;
  valor?: any;
  tipo?: any;
  editar = false;
  caracteristica: any = { 'vc_nome': null }
  input?: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private caractersticaService: CaracteristicasService,
    private location: Location,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.addDataForm = this.formBuilder.group({
      value: [null, Validators.required],
    })
    this.init();
  }

  init() {
    this.route.params.subscribe((params: Params) => {
      this.idCaracteristica = Number(params.id);
      this.id = Number(params.idEouO);
      this.tipo = params.tipo
      this.caractersticaService.AcharValorPorCaracteristicaEId(this.idCaracteristica, this.id, this.tipo).subscribe((carac: any) => {
        if (carac != null) {
          this.valor = carac
          if (carac.fl_valor != null) {
            this.addDataForm.get("value")?.setValue(carac.fl_valor);
            this.valor.vc_tipo = "int"
            this.input = 'number'
          } else if (carac.vc_valor != null) {
            this.addDataForm.get("value")?.setValue(carac.vc_valor);
            this.valor.vc_tipo = "string"
            this.input = 'text'
            this.input = 'text'
          } else {
            this.addDataForm.get("value")?.setValue(carac.ts_valor);
            this.valor.vc_tipo = "timestamp"
            this.input = 'datetime-local'
            this.input = 'datetime-local'
          }
          this.caracteristica = carac
          this.caracteristica.vc_nome = carac.caracteristica.vc_nome
        } else {
          this.editar = true;
          this.caractersticaService.AcharPorId(this.idCaracteristica).subscribe((carac: any) => {
            if (carac.vc_tipo == "timestamp") {
              this.input = 'datetime-local'
            } else if (carac.vc_tipo == 'string') {
              this.input = 'text'
            } else {
              this.input = 'number'
            }
            this.caracteristica = carac
          })
        }
      })
    });

  }

  done() {
    if (this.editar == true) {
      var valor = this.addDataForm.get("value")?.value;
      var valorCorpo;
      if (this.tipo == 'equipamento') {
        valorCorpo = {
          "vc_tipo": this.caracteristica.vc_tipo,
          "fk_caracteristica": this.caracteristica.sr_id,
          "valor": valor,
          "fk_cliente": this.caracteristica.fk_cliente,
          "fk_equipamento": this.id,
          "fk_operacao": null,
          "caracteristica": this.caracteristica.vc_nome
        }
      } else {
        valorCorpo = {
          "vc_tipo": this.caracteristica.vc_tipo,
          "fk_caracteristica": this.caracteristica.sr_id,
          "valor": valor,
          "fk_cliente": this.caracteristica.fk_cliente,
          "fk_equipamento": null,
          "fk_operacao": this.id,
          "caracteristica": this.caracteristica.vc_nome
        }
      }
      this.caractersticaService.cadastrarValor(valorCorpo, this.idCaracteristica).subscribe(carac => {
        this.alertService.success('Valor atualizado com sucesso')
        this.location.back();
      }, err => {
        this.alertService.warning(err.error.mensagem)
      })

    } else {
      var valor = this.addDataForm.get("value")?.value;
      this.valor.valor = valor
      this.valor.idValor = this.valor.sr_id
      this.caractersticaService.AtualizarValor(this.valor, this.valor.fk_caracteristica).subscribe(
        (carac) => {
          this.alertService.success('Valor atualizado com sucesso')
          this.location.back();
        }, err => {
          this.alertService.warning(err.error.mensagem)
        })
    }
  }

  public cancel(): void {
    this.location.back();
  }

}
