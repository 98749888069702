import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-novos-recusos',
  templateUrl: './dialog-novos-recusos.component.html',
  styleUrls: ['./dialog-novos-recusos.component.css']
})
export class DialogNovosRecusosComponent implements OnInit {

  mudancas: any;
  versao: any;

  constructor(
    public dialogRef: MatDialogRef<DialogNovosRecusosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

  ngOnInit(): void {
    this.mudancas = this.data.mudanças
    this.versao = this.data.versao
  }

}
