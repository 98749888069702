import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';
import { HttpOptionsService } from './header-options.service';

@Injectable({
  providedIn: 'root'
})
export class FiltroService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
    ) { }

  public filtros(idClientes: any, idGrupos: any, idCliente: any){
    const url = `${this.BASE_URL_HIDROMETER}popularFiltro`;
    var headerOptions = this.httpOptionsService.getHttpOptions()
    headerOptions.params = {
      'idClientes': idClientes,
      'idGrupos': idGrupos,
      'idCliente': idCliente
    }
    return this.http.get(url, headerOptions);
  } 
}
