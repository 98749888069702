import { HttpOptionsService } from './header-options.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_PREMOCENTER } from '../app.constants';
import { Observable } from 'rxjs';

interface User {
  sr_id: string;
  vc_telefone: string;
}

@Injectable({
  providedIn: 'root'
})

export class WhatsappService {

  readonly BASE_URL_PREMOCENTER = BASE_URL_PREMOCENTER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  public atualizar(user: User): Observable<any> {
    const url = `${this.BASE_URL_PREMOCENTER}usuarios/atualizar/whatsapp/${user.sr_id}`;
    return this.http.put(url, user, this.httpOptionsService.getHttpOptions());
  }
}
