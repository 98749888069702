import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FiltroService } from 'src/app/service/filtro.service';
import { RedirectService } from 'src/app/service/redirect.service';

@Component({
  selector: 'app-botao-filtros',
  templateUrl: './botao-filtros.component.html',
  styleUrls: ['./botao-filtros.component.css']
})
export class BotaoFiltrosComponent implements OnInit {
  contentClosed: boolean = true;
  arrayOperacoes: any = [];
  idCliente: any;
  arrayClientes: any;
  arrayGrupos: any;
  idOperacao: any;
  dataInicio: any;
  dataFinal: any;
  tipoMapa: any;
  formFilter!: FormGroup;
  mostrarFiltros: boolean = false;
  localStorageFormulario: any = {
    tipoMapa: 'tipoMapa',
    cliente_filtro: 'client',
    operacaoId: 'operacao',
    DateStart: 'start',
    DateEnd: 'end'
  };
  dataAtual: Date = new Date();
  allOperacoes: any[] = [
    { label: 'Operando', operacoes: [], estado: 'RUNNING' },
    { label: 'Finalizado', operacoes: [], estado: 'COMPLETED' },
    { label: 'Cancelado', operacoes: [], estado: 'CANCELED' },
    { label: 'Novo', operacoes: [], estado: 'NEW' }
  ];

  constructor(
    private formBuilder: FormBuilder,
    private filtroService: FiltroService,
    private redirectService: RedirectService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.formFilter = this.formBuilder.group({
      operacao: ['']
    });
    this.redirectService.descricaoElementoExpandidoSobreMapa.subscribe((elemento: string) => {
      if (elemento != 'botao-filtros') this.closeContent()
    });

    this.trazerValoresLocalStorage();
    this.acharDadosFiltro();
    this.onChangeCliente();
  }

  TamanhoTela(): boolean {
    return window.innerWidth < 768;
  }

  trazerValoresLocalStorage(): void {
    this.tipoMapa = localStorage.getItem("tipoMapa") == null ? "pressao" : localStorage.getItem("tipoMapa");
    this.idCliente = localStorage.getItem('cliente_filtro');
    this.arrayClientes = JSON.parse(localStorage.getItem('cliente') || "{}");
    this.arrayGrupos = JSON.parse(localStorage.getItem('grupos') || "{}");
    this.idOperacao = localStorage.getItem('operacaoId') == null ? 0 : localStorage.getItem('operacaoId');
    this.dataInicio = localStorage.getItem('DateStart') == null ? new Date().toISOString().slice(0, 10) + ' 03:00:00' : localStorage.getItem('DateStart');
    this.dataFinal = localStorage.getItem('DateEnd') == null ? new Date(new Date(this.dataInicio).setDate(new Date(this.dataInicio).getDate() + 1)).toISOString().slice(0, 10) + ' 03:00:00' : localStorage.getItem('DateEnd');

    this.criarForm();
  }

  criarForm(): void {
    var dataInicio = new Date(this.dataInicio)
    var dataFinal = new Date(this.dataFinal)
    dataFinal.setDate(dataFinal.getDate() - 1);
    this.formFilter = this.formBuilder.group({
      client: [this.idCliente, Validators.required],
      operacao: [{ value: this.idOperacao, disabled: true }, Validators.required],
      start: [dataInicio, [Validators.required]],
      end: [dataFinal, [Validators.required]],
      tipoMapa: [this.tipoMapa, Validators.required],
    });
  }

  categorizeOperacoes() {
    this.allOperacoes.forEach(categoria => categoria.operacoes = []);

    this.arrayOperacoes.forEach((operacao: any) => {
      const categoria = this.allOperacoes.find(cat => cat.estado === operacao.vc_estado);
      if (categoria) {
        categoria.operacoes.push(operacao);
      }
    });
  }

  acharDadosFiltro(): void {
    this.formFilter.get('operacao')?.setValue('default');
    var idClientes = this.arrayClientes.map((cliente: any) => cliente.id);
    var idGrupos = this.arrayGrupos.map((grupo: any) => grupo.id);
    this.filtroService.filtros(idClientes.toString(), idGrupos.toString(), this.idCliente).subscribe((resposta: any) => {
      this.arrayOperacoes = resposta.operacoes;
      this.categorizeOperacoes();
      this.habilitarCampoOperacao();
    })
  }

  habilitarCampoOperacao(): void {
    this.formFilter.get('operacao')?.enable();
    this.formFilter.get('operacao')?.setValue(0);
  }

  filtrar(): void {
    let nomeFormControlMapa: string = this.pegarNomeFormControlCorrespondente('tipoMapa');
    let nomeFormControlCliente: string = this.pegarNomeFormControlCorrespondente('cliente_filtro');
    let nomeFormControlOperacao: string = this.pegarNomeFormControlCorrespondente('operacaoId');
    let nomeFormControlDataInicio: string = this.pegarNomeFormControlCorrespondente('DateStart');
    let nomeFormControlDataFim: string = this.pegarNomeFormControlCorrespondente('DateEnd');

    let tipoMapaFormulario: string = this.pegarValorFormulario(nomeFormControlMapa);
    let clienteFormulario: string = this.pegarValorFormulario(nomeFormControlCliente);
    let operacaoFormulario: string = this.pegarValorFormulario(nomeFormControlOperacao);

    let dataInicio: any = this.retornarData(nomeFormControlDataInicio);
    let dataFim: any = this.retornarData(nomeFormControlDataFim);
    let dataInicioFormatada: string = this.formatarData(dataInicio);
    let dataFimFormatada: string = this.formatarData(dataFim);

    localStorage.setItem('tipoMapa', tipoMapaFormulario);
    localStorage.setItem('cliente_filtro', clienteFormulario);
    localStorage.setItem('operacaoId', operacaoFormulario);
    localStorage.setItem('DateStart', dataInicioFormatada);
    localStorage.setItem('DateEnd', dataFimFormatada);
    this.redirectService.enviarDadosFiltros();
    this.redirectService.enviarMudancaDeCliente();

    if (!this.contentClosed) {
      this.toggleFiltros();
    }

  }

  pegarNomeFormControlCorrespondente(chave: string): string {
    return this.localStorageFormulario[chave];
  }

  pegarValorFormulario(nomeFormControl: string): string {
    return this.formFilter.get(nomeFormControl)?.value;
  }

  retornarData(nomeFormControl: string): Date {
    let data: any = new Date(this.pegarValorFormulario(nomeFormControl));
    let diasSomar: number = 0;

    if (nomeFormControl == 'end') {
      diasSomar++;
    }

    data.setDate(data.getDate() + diasSomar);
    data.setHours(data.getHours() + 3);

    return new Date(data);
  }

  formatarData(data: string): string {
    return this.datePipe.transform(data, 'yyyy-MM-dd HH:mm:ss') || '';
  }

  onChangeCliente(): void {
    this.formFilter.get("client")?.valueChanges.subscribe(valor => {
      this.idCliente = valor;
      this.formFilter.get('operacao')?.setValue('default');
      this.formFilter.get('operacao')?.disable();

      this.acharDadosFiltro();
    });
  }

  toggleFiltros(): void {
    this.mostrarFiltros = !this.mostrarFiltros;
  }

  toggleContent(): void {
    this.contentClosed = !this.contentClosed;
    let card = document.querySelector('#botaoFiltros');
    card?.classList.toggle('active')
    if (!this.contentClosed) this.redirectService.elementoExpandidoSobreMapa('botao-filtros');
  }

  closeContent(): void {
    this.contentClosed = true;
    let card = document.querySelector('#botaoFiltros');
    card?.classList.remove('active')
  }

  onDateSelection(): void {
    const startDate = this.formFilter.get('start')?.value;
    const endDate = this.formFilter.get('end')?.value;

    if (startDate && endDate) {
      const diffInDays = this.calculateDateDifference(startDate, endDate);
      if (diffInDays > 90) {
        const newEndDate = new Date(startDate);
        newEndDate.setDate(startDate.getDate() + 90);
        this.formFilter.get('end')?.setValue(newEndDate);
      }
    }
  }

  calculateDateDifference(start: Date, end: Date): number {
    const timeDiff = end.getTime() - start.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  }
}
