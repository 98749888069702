<main class="container-fluid mt-4" fxLayout="column" fxLayoutAlign="center center">
  <div class="d-flex">
    <h1>Cadastrar característica adicional</h1>
  </div>
  <div class="row">
    <form [formGroup]="addDataForm" fxLayout="column" fxLayoutAlign="space-evenly center" fxShow.gt-xs fxHide.xs>
      <div class="form-row" fxLayout="column" fxLayoutAlign="space-evenly center">
        <div class="form-row">
          <mat-form-field color="primary" appearance="outline" fxFlex>
            <mat-label>Cliente</mat-label>
            <mat-select formControlName="client" required>
              <mat-option *ngFor="let client of clients" [value]="client.id">
                {{ client.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex fxFlexOffset="1.5rem">
            <mat-label>Característica</mat-label>
            <label class="faixa">
              <input matInput formControlName="name" autocomplete="off" required list="faixa" />
              <datalist id="faixa">
                <option value="Pressão mínima">Pressão mínima</option>
                <option value="Pressão máxima">Pressão máxima</option>
                <option value="Lâmina máxima">Lâmina máxima</option>
                <option value="Lâmina mínima">Lâmina mínima</option>
                <option value="Bocal">Bocal</option>
              </datalist>
            </label>
          </mat-form-field>

        </div>

        <div class="form-row">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="tipo" required>
              <mat-option value="string">Texto</mat-option>
              <mat-option value="int">Número</mat-option>
              <mat-option value="timestamp">Hora</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex fxFlexOffset="1.5rem">
            <mat-label>Atribuição</mat-label>
            <mat-select formControlName="atribuicao" required>
              <mat-option value="operacao">Operação</mat-option>
              <mat-option value="equipamento">Equipamento</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div class="form-row" *ngIf="caracteristicaObrigatoria">
          <mat-form-field appearance="outline" fxFlex fxFlexOffset="1.5rem">
            <mat-label>Característica</mat-label>
            <label>
              <input matInput formControlName="name2" autocomplete="off" required list="faixa" />
            </label>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex fxFlexOffset="1.5rem">
            <mat-label>Tipo</mat-label>
            <mat-select formControlName="tipo2" required>
              <mat-option value="string">Texto</mat-option>
              <mat-option value="int">Número</mat-option>
              <mat-option value="timestamp">Hora</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex fxFlexOffset="1.5rem">
            <mat-label>Atribuição</mat-label>
            <mat-select formControlName="atribuicao2" required>
              <mat-option value="operacao">Operação</mat-option>
              <mat-option value="equipamento">equipamento</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <span class="text-danger">{{mensagem}}</span>
        <pre></pre>
      </div>
      <div class="form-row" fxLayout="row" fxLayoutAlign="center" fxFlexOffset="3%">
        <button mat-raised-button class="botao-criar" type="button" [disabled]="!addDataForm.valid" (click)="done()">
          Gravar
        </button>
        <button mat-raised-button class="botao-excluir" type="reset" fxFlexOffset="10px" (click)="cancel()">
          Cancelar
        </button>
      </div>
    </form>
  </div>
</main>