<div fxLayoutAlign="center center" *ngIf="loading" class="spinner"></div>

<div class="button-container" style="float:right">
    <button mat-icon-button fxLayoutAlign="center center" mat-dialog-close>
        <img src="assets/icones-gerais/fechar-cor-preto.svg" alt="Fechar" class="icone-fechar">
    </button>
</div>
<div class="row d-flex justify-content-center">
    <h3 class="titulo" style="font-family:'Poppins', sans-serif" fxLayoutAlign="center center">Tempos de parada da frota: {{ data.frota }}</h3>
</div>
    <div class="mensagem" fxLayoutAlign="center center">
        <p *ngIf="validaResp" style="text-align: center;">Não há mensagens nesse período</p>
    </div>
    <div class="row justifiy-content-center" fxLayoutAlign="center center" style="margin-top: 3em;" *ngIf="!validaResp && !loading">
        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
        [options]="pieChartOptions" [legend]="pieChartLegend"  [colors]="pieChartColors" (chartClick)="chartClicked($event)"
        style="max-width: 45vw; max-height: 100%;">
      </canvas>
    </div>
