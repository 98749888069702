<main class="container mt-4" fxLayout="column" fxLayoutAlign="center center">
    <div class="d-flex">
        <h1>Cadastrar turno</h1>
        <div class="dropdown">
            <clr-icon shape="info-standard" class="info-icone"></clr-icon>
            <div class="dropdown-content">
                <p>Ao cadastrar seus turnos, é essencial prestar atenção para garantir que não haja lacunas
                    de tempo e evitar a sobreposição de turnos. Dado que os turnos desempenham um papel
                    fundamental nos gráficos de produtividade, um registro preciso se torna indispensável
                    para o funcionamento adequado da aplicação.</p>
            </div>
        </div>
    </div>
    <div class="row">
        <form [formGroup]="formularioTurno" class="form" fxLayout="column" fxLayoutAlign="space-evenly center">
            <mat-form-field appearance="outline" fxFlex>
                <mat-label>Cliente </mat-label>
                <mat-select formControlName="fk_cliente" required>
                    <mat-option *ngFor="let client of clients" [value]="client.id" id="clientSelection">
                        {{client.nome }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formularioTurno.get('fk_cliente')?.getError('required')">
                    Cliente é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex class="form-row">
                <mat-label>Nome do turno</mat-label>
                <input matInput formControlName="vc_nome" autocomplete="off" required />
                <mat-error *ngIf="formularioTurno.get('vc_nome')?.getError('required')">
                    Nome do turno é <strong>obrigatório</strong>
                </mat-error>
            </mat-form-field>

            <div class="form-row w-100" fxLayout="row" fxLayoutAlign="space-between center">
                <label fxLayout="column">
                    <span class="textoHora">Hora inicial *</span>
                    <input type="time" step="2" formControlName="tm_hora_inicio" class="escolherHora" />
                </label>
                <label fxLayout="column">
                    <span class="textoHora">Hora final *</span>
                    <input type="time" step="2" formControlName="tm_hora_fim" class="escolherHora" />
                </label>
            </div>
            <div class="form-row mt-3" fxLayout="row" fxLayoutAlign="center">
                <button mat-raised-button class="botao-criar" [disabled]="!formularioTurno.valid"
                    (click)="cadastrarTurno()">
                    Gravar
                </button>
                <button mat-raised-button class="botao-excluir" fxFlexOffset="10px" (click)="cancelar()">
                    Cancelar
                </button>
            </div>
        </form>
    </div>
</main>