import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BASE_URL_PREMOCENTER_FRONT } from 'src/app/app.constants';
import { VerificarPermissoes } from 'src/app/model/verificar-premissao.model';
import { AlertService } from 'src/app/service/alert.service';
import Swal from 'sweetalert2';
import * as _ from 'underscore';
import { RedirectService } from 'src/app/service/redirect.service';
import { OperadoresService } from 'src/app/service/operadores.service';

@Component({
  selector: 'app-listar-operadores',
  templateUrl: './listar-operadores.component.html',
  styleUrls: ['./listar-operadores.component.css']
})
export class ListarOperadoresComponent implements OnInit {
  public columnsToDisplay = [
    'it_identificador',
    'vc_nome',
    'ts_ultimo_login',
    'bl_cadastro_valido',
    'controls',
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public formFilter!: FormGroup;
  public client: any;
  LocalStorageId = localStorage.getItem('cliente_filtro');
  fuso = localStorage.getItem('horario') == 'utc' ? 'utc' : 'brt';
  clients = JSON.parse(localStorage.getItem('cliente') || '{}');
  urlPremoplan = BASE_URL_PREMOCENTER_FRONT;
  length: any;
  next: any;
  operador: any;
  loading = true;
  validaResp = false;
  text!: string;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Output() toggleChange!: EventEmitter<void>;

  constructor(
    private operadorService: OperadoresService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private alertService: AlertService,
    public appService: RedirectService
  ) { }

  ngOnInit(): void {
    localStorage.setItem('kanban', 'false');
    this.initializeInvites();
  }

  ngOnDestroy(): void { }

  onChange() {
    this.loading = true;
    this.validaResp = false;
    this.formFilter.get('client')?.valueChanges.subscribe((clientId: any) => {
      localStorage.setItem('cliente_filtro', clientId);
      this.LocalStorageId = clientId;
      this.appService.enviarMudancaDeCliente();
      this.initializeInvites();
    });
  }

  public initializeInvites() {
    this.length = 0;
    this.formFilter = this.formBuilder.group({
      client: [null, Validators.required],
    });
    if (this.LocalStorageId != null) {
      this.formFilter.get('client')?.setValue(this.LocalStorageId);
      this.operadorService
        .AcharPorClientePaginate(this.LocalStorageId, 100)
        .subscribe(
          (data: any) => {
            if (data.data.length == 0) {
              this.validaResp = true;
            }
            this.length = data.total;
            this.operador = data.data.map((operador: any) => {
              return {
                ...operador,
                ts_ultimo_login: this.definirFusoHorario(operador.ultimo_login.ts_criado_em)
              };
            });
            this.dataSource.data = this.operador;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.next = data.next_page_url;
            this.loading = false;
          },
          (err: any) => {
            if (err.statusText == 'Unauthorized') {
              window.location.href = `${this.urlPremoplan}`;
            }
          }
        );
    }
    this.onChange();
  }

  public trocarPagina(event: any): void {
    this.loading = true;
    this.operadorService.Next(this.next).subscribe((data: any) => {
      this.operador = this.operador.filter(function (i: any) {
        return i;
      });
      this.operador.push(...data.data);
      this.operador.length = data.total;
      this.dataSource.data = this.operador;
      this.next = data.next_page_url;
      this.loading = false;
    });
  }

  public alterarStatusOperador(operador: any): void {
    Swal.fire({
      title: `Deseja marcar este operador como ${this.statusOperador(!operador.bl_cadastro_valido)}?`,
      showCancelButton: true,
      confirmButtonColor: '#41B6E6',
      cancelButtonColor: '#df4747',
      confirmButtonText: 'Confirmar!'
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.operadorService.AlterarStatusOperador(operador.sr_id).subscribe((resp: any) => {
          this.alertService.success('Status do operador alterado com sucesso!');
          this.initializeInvites();
        }, (err: any) => {
          this.alertService.error('Erro ao alterar status do operador, tente novamente mais tarde.')
        })
      }
    })
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public verificarPermissao(permissoesFuncionalidades: string[]): boolean {
    const permissaoUsuario = localStorage.getItem('permissoes');
    return VerificarPermissoes.temPermissao(
      permissoesFuncionalidades,
      permissaoUsuario
    );
  }

  definirFusoHorario(data: string): Date {
    const dataCorreta = new Date(data);
    if (this.fuso == 'utc') {
      dataCorreta.setHours(dataCorreta.getHours() + 3)
    }
    return dataCorreta;
  }

  statusOperador(bl_cadastro_valido: boolean): string {
    return bl_cadastro_valido ? 'válido' : 'inválido';
  }
}
