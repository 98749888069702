<div class="h-100" #containerTempos id="container-grafico">

    <div class="d-flex justify-content-center">
        <h4 class="m-0">Tempos trabalhados</h4>
        <div class="dropdown">
            <clr-icon shape="info-standard" class="info-icone cor-icone"></clr-icon>
            <div class="dropdown-content">
                <p>Visualize em percentual quanto tempo cada grupo ocupou em relação ao período filtrado.</p>
                <p>Para visualizar mais detalhes de um grupo, clique nele.</p>
                <p>Clicar na legenda pode ocultar a parcela do gráfico relativa a ela.</p>
                <p>Os tempos exibidos na legenda são aproximados e podem não ser exatos.</p>
            </div>
        </div>
    </div>

    <ng-container class="mensagem" *ngIf="validaResp">
        <p style="text-align: center;">Não há tempos trabalhados nesse período</p>
    </ng-container>
    <div [hidden]="loading || validaResp || erro || !frotas || loadingFrotas" class="d-flex justify-content-center h-75"
        id="grafico">
        <div class="d-flex justify-content-center container-grafico h-100 w-50 p-1" [hidden]="ocultarGrafico">
            <canvas class="h-100" baseChart #grafico [data]="pieChartData" [labels]="pieChartLabels"
                [chartType]="pieChartType" [options]="pieChartOptions" [colors]="pieChartColors"
                [legend]="pieChartLegend" (chartClick)="chartClicked($event)">
            </canvas>
        </div>
        <div class="p-1 h-100 w-100" id="div-tabela">
            <div class="d-flex tabela-graficos" id="tabela">
                <table mat-table [dataSource]="dataSource" class="w-100">
                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef class=""> Tipo </th>
                        <td class="espaco" mat-cell *matCellDef="let dado" [id]="dado.id ? dado.id : ''"
                            [style.background-color]="dado.cor ? dado.cor : ''"
                            [style.text-decoration]="dado.hidden ? 'line-through' : ''"
                            (click)="selecionarCelula(dado)">
                            {{dado.grupo ? dado.grupo : ""}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tempo_horas">
                        <th mat-header-cell *matHeaderCellDef class=""> Horas </th>
                        <td class="espaco" mat-cell *matCellDef="let dado" [id]="dado.id ? dado.id : ''"
                            [style.background-color]="dado.cor ? dado.cor : ''"
                            [style.text-decoration]="dado.hidden ? 'line-through' : ''"
                            (click)="selecionarCelula(dado)">
                            {{dado.horas ? dado.horas : ""}} </td>
                    </ng-container>

                    <ng-container matColumnDef="percentual">
                        <th mat-header-cell *matHeaderCellDef class=""> % </th>
                        <td class="espaco" mat-cell *matCellDef="let dado" [id]="dado.id ? dado.id : ''"
                            [style.background-color]="dado.cor ? dado.cor : ''"
                            [style.text-decoration]="dado.hidden ? 'line-through' : ''"
                            (click)="selecionarCelula(dado)">
                            {{dado.percentual ? dado.percentual : ""}}% </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <app-loader *ngIf="loading || loadingFrotas"></app-loader>
    <app-erro *ngIf="erro"></app-erro>
</div>