<div class="h-100"  id="container-grafico">
    <div class="d-flex justify-content-center">
        <h4>Produtividade por turno</h4>
        <div class="dropdown">
            <clr-icon shape="info-standard" class="info-icone cor-icone"></clr-icon>
            <div class="dropdown-content">
                <p>Exibir a produtividade em hectares de cada turno do período selecionado.</p>
            </div>
        </div>
    </div>
    <ng-container *ngIf="validaResp" class="mensagem">
        <p style="text-align: center;">Não há mensagens nesse período</p>
    </ng-container>

    <div class="d-flex justify-content-center h-75 w-100" [hidden]="loading || validaResp || erro" id="grafico" >
        <div class="d-flex container-grafico h-100 w-50 p-2" [hidden]="ocultarGrafico">
            <canvas class="h-100" baseChart  [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
            [options]="pieChartOptions" [colors]="pieChartColors" [plugins]="pieChartPlugins" [legend]="pieChartLegend">
            </canvas>
        </div>
        <div class="p-2 h-100 w-50" [ngClass]="{'w-100': ocultarGrafico}" id="div-tabela">
            <div class="d-flex tabela-graficos" id="tabela">
                <table mat-table [dataSource]="tabela" class="w-100">
                    <ng-container matColumnDef="turno">
                        <th mat-header-cell *matHeaderCellDef class="largura-coluna" style="padding: 5px !important;">
                            Turno </th>
                        <td style="padding: 5px !important;" mat-cell *matCellDef="let dado"
                            [id]="dado.id ? dado.id  : ''" [style.background-color]="dado.cor ? dado.cor : ''"
                            [style.text-decoration]="dado.hidden ? 'line-through' :''"
                            (click)="selecionarCelula(dado.id)">
                            {{dado.turno ? dado.turno : ""}} </td>
                    </ng-container>
                    <ng-container matColumnDef="hectares">
                        <th mat-header-cell *matHeaderCellDef class="largura-coluna" style="padding: 5px !important;">
                            Hectares </th>
                        <td style="padding: 5px !important;" mat-cell *matCellDef="let dado;"
                            [id]="dado.id ? dado.id  : ''" [style.background-color]="dado.cor ? dado.cor : ''"
                            [style.text-decoration]="dado.hidden ? 'line-through' :''"
                            (click)="selecionarCelula(dado.id)">
                            {{dado.hectares ? dado.hectares : ""}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <app-erro *ngIf="erro"></app-erro>
</div>
