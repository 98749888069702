import { Component, OnInit } from '@angular/core';
import { EquipamentosService } from '../service/equipamentos.service';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from '../service/alert.service';

@Component({
  selector: 'app-listagem-informacoes-equipamentos',
  templateUrl: './listagem-informacoes-equipamentos.component.html',
  styleUrls: ['./listagem-informacoes-equipamentos.component.css']
})
export class ListagemInformacoesEquipamentosComponent implements OnInit {

  loading: boolean = true;
  clientes = JSON.parse(localStorage.getItem('cliente') || "{}")
  clienteSelecionado: number = localStorage.getItem("cliente_filtro") ? localStorage.getItem("cliente_filtro") : this.clientes[0].id;
  informacoesTabela: MatTableDataSource<any> = new MatTableDataSource();
  colunasDaTabela = ['OS', 'Frota', 'Conexão', 'Status', 'Hora', 'última mensagem', 'Alerta', 'Bateria', 'Velocidade', 'Pressão', 'Lâmina', 'Ponto A/B', 'Parada Informada', 'Motivo da parada'];

  constructor(
    private equipamentoService: EquipamentosService,
    private alertaService: AlertService,
  ) { }

  ngOnInit(): void {
    this.buscarCarreteis();
  }

  buscarCarreteis() {
    this.loading = true;
    this.equipamentoService.informacoesDosEquipamentosParaMonitoramento(this.clienteSelecionado).subscribe((informacoesEquipamentos: any) => {
      this.loading = false;
      localStorage.setItem('cliente_filtro', this.clienteSelecionado.toString());

      this.informacoesTabela.data = informacoesEquipamentos.Carretel;

    }, () => {
      this.loading = false;
      this.alertaService.error('Não foi possível buscar os carreteis, tente novamente.');
    })
  }

  filtrar(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.informacoesTabela.filter = filterValue.trim().toLowerCase();
  }

  getBateriaClass(paramBateria: string | number): string {
    if (paramBateria && paramBateria !== '-') {
      let bateria = typeof paramBateria === 'number' ? paramBateria : parseFloat(paramBateria);
      return bateria > 10.0 ? 'classe-verde' : 'classe-vermelha';
    }
    return '';
  }
}
