<main class="container editar" fxLayout="column" fxLayoutAlign="center center">
    <div class="row">
        <div class="col-12">
            <h1>Editar fazenda</h1>
        </div>
    </div>
    <div class="row">
        <form [formGroup]="fazendaForm" fxFlexOffset="30px" fxLayout="column" fxLayoutAlign="space-evenly center"
            fxShow.gt-sm fxHide.lt-md>
            <div class="form-row">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Nome</mat-label>
                    <label>
                        <input matInput formControlName="nome" autocomplete="off" required />
                    </label>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex fxFlexOffset="1.5rem">
                    <mat-label>Cliente</mat-label>
                    <mat-select formControlName="client" required [disabled]="validaEditar">
                        <mat-option *ngFor="let client of clients" [value]="client.id">
                            {{ client.nome }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-row" fxLayout="row" fxLayoutAlign="center center" fxFlexOffset="3%">
                <button mat-raised-button class="botao-criar" type="submit" (click)="makeFazenda()"
                    [disabled]="!fazendaForm.valid">
                    Gravar
                </button>
                <button mat-raised-button type="reset" fxFlexOffset="10px" (click)="cancel()" class="botao-excluir">
                    Cancelar
                </button>
            </div>
        </form>
    </div>
</main>