<main style="width: 96%; margin: 0 2rem;">
    <div class="cabecalho">
        <div class="d-flex">
            <h1>Características adicionais</h1>
            <div class="dropdown">
                <clr-icon shape="info-standard" class="info-icone"></clr-icon>
                <div class="dropdown-content">
                    <p>Se necessário, é possível realizar edições nas características, no entanto,
                        apenas aquelas que não são consideradas essenciais podem ser desativadas.</p>
                </div>
            </div>
        </div>
        <span class="info-listas">lista de todas as características adicionais</span>
        <div class="linha">
            <form [formGroup]="formFilter" fxFlex.lt-md="100" fxLayout="row" fxLayoutAlign="center center">
                <label> Características da usina:
                    <select class="filtro-listas" formControlName="client">
                        <option *ngFor="let client of clients" [value]="client.id">
                            {{ client.nome }}
                        </option>
                    </select>
                </label>
            </form>
            <button class="botao-criar" routerLink="../criar" *ngIf="verificarPermissao([permissaoNecessaria])">
                <clr-icon shape="add-text" size="20" class="margem-clr-icon is-solid"></clr-icon>
                Nova característica
            </button>
        </div>
    </div>

    <div fxLayout="column">
        <input matInput (keyup)="applyFilter($event)" placeholder="Filtrar" autocomplete="off" class="input-pesquisa">

        <div *ngIf="loading" style="width: auto; height: 60vh;" fxLayout="row" fxLayoutAlign="center center">
            <div *ngIf="loading" class="spinner"></div>
        </div>
        <div class="mensagem">
            <p *ngIf="validaResp" style="text-align: center;">Não há características dessa usina</p>
        </div>

        <table mat-table [dataSource]="dataSource" matSort [hidden]="loading">
            <ng-container matColumnDef="vc_nome">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Caracteristica</th>
                <td mat-cell *matCellDef="let element">{{ element.vc_nome }}</td>
            </ng-container>

            <ng-container matColumnDef="atribuicao">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Atribuição</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element">
                        {{ element.atribuicao ? element.atribuicao : "" }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="ts_criado_em">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Criado em:
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.ts_criado_em | date: "dd/MM/yyyy HH:mm:ss" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="ts_atualizado_em">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Atualizado em:
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.ts_atualizado_em | date: "dd/MM/yyyy HH:mm:ss" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="control">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div id="tableButton" class="d-flex justify-content-center">
                        <button class="botao-editar d-flex" routerLink="../editar/{{ element.sr_id }}"
                            *ngIf="verificarPermissao(['editar_caracteristicas_adicionais_hidrometer'])">
                            <clr-icon shape="pencil"></clr-icon>
                            Editar
                        </button>
                        <button class="botao-excluir d-flex" (click)="delete(element.sr_id)"
                            [disabled]="element.status == 'BLOCKED'"
                            *ngIf="verificarPermissao(['deletar_caracteristicas_adicionais_hidrometer'])">
                            <clr-icon shape="trash"></clr-icon>
                            Desativar 
                            <span *ngIf="element.status == 'BLOCKED'">(variável essencial)</span>
                        </button>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    <mat-paginator (page)="trocarPagina($event)" fxLayoutAlign="center center" [length]="length" [pageSize]="100"
        [hidden]="loading">
    </mat-paginator>
</main>