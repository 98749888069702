import { Component, OnInit } from '@angular/core';
import { HectaresService } from 'src/app/service/hectares.service';

@Component({
  selector: 'app-hectares-produzidos-mobile',
  templateUrl: './hectares-produzidos-mobile.html',
  styleUrls: ['./hectares-produzidos-mobile.css']
})
export class HectaresProduzidoMobileComponent implements OnInit {
  hectares = [] as any;

  constructor(private hectarService: HectaresService) { }

  ngOnInit(): void {
    this.hectarService.hectares$.subscribe(hectares => {
      this.hectares = hectares;
    });
  }
}
