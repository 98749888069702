import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { TemposTiposComponent } from './tempos-tipos/tempos-tipos.component';
import { GraficosService } from 'src/app/service/graficos.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tempos-trabalhados',
  templateUrl: './tempos-trabalhados.component.html',
  styleUrls: ['./tempos-trabalhados.component.css']
})
export class TemposTrabalhadosComponent implements OnInit {
  subscription!: Subscription
  @Input() valor = [] as any;
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  form!: FormGroup;
  cliente: string = '';
  public token: string | null = '';
  dataInicial: string = '';
  dataFinal: string = '';
  frotas: string = '';
  frotaData: any = null;
  grupos = [] as any;
  arrayCores = [] as any;
  dados = [] as any;
  d: any = null;
  h: any = null;
  dDisplay: any = null;
  hDisplay: any = null;
  loading = true;
  loadingFrotas = true;
  validaResp = false;
  @Input() ocultarGrafico: boolean = false
  data_grafico: any = null;
  data_elementos: any = null;
  frotasCliente: any = [];
  frotasSelecionadas: any = [];
  todasClicadas = false;

  public dataSource = new MatTableDataSource();
  public displayedColumns: string[] = [
    "tipo",
    "tempo_horas",
    "percentual"
  ];
  public pieChartOptions: ChartOptions = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          var total = 0;
          for (let dado of data.datasets[0].data) {
            total += dado;
          }
          var index = tooltipItem['index'];
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var primeiraLetraLegenda = data.labels[index].charAt(0).toUpperCase();
          var corpoLegenda = data.labels[index];
          var restoLegenda = corpoLegenda.substr(1, corpoLegenda.length - 1);
          var legenda = primeiraLetraLegenda + restoLegenda;
          var valorItem = dataset.data[tooltipItem.index];
          var porcentagem = (valorItem / total) * 100;
          return legenda + ': ' + porcentagem.toFixed(1) + '%';
        }
      },
    },
    hover: {
      onHover: function (e: any) {
        var point: any = this.getElementAtEvent(e);
        var element: any = point[0];
        if (element !== undefined) {
          var label = point[0]['_model']['label'];
        }
        if (point.length && label !== 'Injustificado' && label !== 'Produtivo') { e.target.style.cursor = 'pointer'; }
        else { e.target.style.cursor = 'default'; }
      }
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  public pieChartLabels = [] as any;
  public pieChartData = [] as any;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartColors = [
    {
      backgroundColor: this.arrayCores
    },
  ];
  erro = false;
  private graficosTemposTrabalhados: string[] = ['Tempos trabalhados por frota', 'Tempos trabalhados'];

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private graficosService: GraficosService,
    private activatedRoute: ActivatedRoute
  ) {
    this.form = this.formBuilder.group({
      id_frota: new FormControl(['', Validators.required])
    });
  }

  ngOnInit(): void {
    this.inserirDadosLocalStorage()
    if (this.frotas) {
      this.loadingFrotas = false;
      this.buscarTemposTrabalhados();
    } else {
      this.loading = false;
      this.loadingFrotas = false;
      this.validaResp = true;
    }
  }

  inserirDadosLocalStorage(): void {
    this.cliente = localStorage.getItem('cliente_filtro') || '';
    this.token = localStorage.getItem('token');
    this.dataInicial = localStorage.getItem('DateStartGraficos') || '';
    this.dataFinal = localStorage.getItem('DateEndGraficos') || '';
    var tipoGrafico: string = this.activatedRoute.snapshot.paramMap.get('tipo') || '';
    this.frotas = localStorage.getItem('tempos_trabalhados_frota') || '';
    if (!this.graficosTemposTrabalhados.includes(tipoGrafico) && this.frotas == '') {
      this.frotas = '0';
    }
  }

  buscarTemposTrabalhados()
  {
    this.graficosService.graficoTemposTrabalhados(this.cliente, this.frotas, this.dataInicial, this.dataFinal).subscribe((resp: any) => {
      if (resp == null || resp.length === 0) {
        this.validaResp = true;
        this.loading = false;
      } else {
        resp.forEach((tempo: any, index: any) => {
          tempo.id = index;
          this.h = (tempo.tempo / 3600).toFixed(1);

          if (this.h > 0.0) {
            if (this.h == 1) {
              this.hDisplay = this.h;
            } else {
              this.hDisplay = this.h;
            }
          } else {
            this.hDisplay = 0;
          }

          var percentual = parseFloat((tempo.percentual).toFixed(1));
          if (tempo.id_grupo !== null) {
            if (percentual !== 0.0) {
              this.grupos.push(tempo);
              this.pieChartLabels.push(tempo.grupo);
              this.pieChartData.push(tempo.tempo);
              tempo.horas = this.hDisplay;
              if (tempo.id_grupo == 4) {
                tempo.cor = '#8C51A5';
                this.arrayCores.push('#8C51A5');
              }
              if (tempo.id_grupo == 2) {
                tempo.cor = '#CB5E98';
                this.arrayCores.push('#CB5E98');
              }
              if (tempo.id_grupo == 1) {
                tempo.cor = '#F47B8A';
                this.arrayCores.push('#F47B8A');
              }
              if (tempo.id_grupo == 3) {
                tempo.cor = '#FFA37E';
                this.arrayCores.push('#FFA37E');
              }
              tempo.percentual = tempo.percentual.toFixed(1);
              this.dados.push(tempo);
            }
          } else {
            this.pieChartLabels.push(tempo.grupo);
            this.pieChartData.push(tempo.tempo);
            tempo.horas = this.hDisplay;

            if (tempo.grupo == 'Injustificado') {
              tempo.cor = '#FFD285';
              this.arrayCores.push('#FFD285');
            }
            if (tempo.grupo == 'Produtivo') {
              tempo.cor = '#FFFFA5';
              this.arrayCores.push('#FFFFA5');
            }

            tempo.percentual = tempo.percentual.toFixed(1);
            this.dados.push(tempo);
          }
        });
        this.dataSource.data = this.dados;
        this.loading = false;

        var indiceMeta = [] as any;
        var indiceTeste: any = this.chart?.datasets[0]
        indiceMeta = Object.getOwnPropertyNames(indiceTeste['_meta']);
        indiceMeta = indiceMeta[0];

        this.data_grafico = indiceTeste['_meta'][indiceMeta]['data'];
        this.data_elementos = indiceTeste['_meta'][indiceMeta]['controller']['_data'];
      }
    }, err => {
      this.loading = false;
      this.erro = true;
    });
  }

  selecionarCelula(elemento: any) {
    var data_grafico = this.data_grafico;
    var data_elementos = this.data_elementos;

    elemento.id = parseInt(elemento.id);
    data_elementos.forEach((dado: any, indice: any) => {
      if (dado == elemento.tempo && indice == elemento.id) {
        if (data_grafico[indice].hidden == true) {
          data_grafico[indice].hidden = false;
          elemento.hidden = false;
        } else if (this.data_grafico[indice].hidden == false) {
          data_grafico[indice].hidden = true;
          elemento.hidden = true;
        }
      }
    });
    this.dados.push(elemento);
    this.chart?.update();
  }

  chartClicked(e: any) {
    var legendaClicada: string = e.active[0]._model.label;
    var grupoClicado: any = this.grupos.filter((grupo: any) => {
      return legendaClicada == grupo.grupo && grupo.id_grupo !== null
    })[0]

    this.openDialog(grupoClicado.id_grupo, grupoClicado.grupo);
  }
  openDialog(id_grupo: any, grupo: any) {
    if (grupo !== undefined && grupo !== 'Injustificado' && grupo !== 'Produtivo' &&
      id_grupo !== undefined && id_grupo !== '' && id_grupo !== 0 && id_grupo !== null) {
      this.dialog.open(TemposTiposComponent, {
        minWidth: '80vw',
        height: '70vh',
        data: {
          id_grupo,
          grupo,
          localStorage: 'tempos_trabalhados_frota'
        }
      });
    }
  }

}
