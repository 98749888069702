import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as mapboxgl from 'mapbox-gl';
import { FeatureCollectionWithFilename } from 'shpjs';
import { MAPBOX_TOKEN } from '../app.constants';
import { ArquivosService } from '../service/arquivos.service';
import { EquipamentosService } from '../service/equipamentos.service';
import { MensagensService } from '../service/mensagens.service';
import { NotificacoesService } from '../service/notificacoes.service';
import { DialogCarretelComponent } from './dialog-carretel/dialog-carretel.component';
import { DialogInformacoesEsticamentoComponent } from './dialog-informacoes-esticamento/dialog-informacoes-esticamento.component';
import { DialogInformacoesIconesComponent } from './dialog-informacoes-icones/dialog-informacoes-icones.component';
import { DialogMensagensNotificacaoComponent } from './dialog-mensagens-notificacao/dialog-mensagens-notificacao.component';
import { DialogMotobombaComponent } from './dialog-motobomba/dialog-motobomba.component';
import { DialogTratorComponent } from './dialog-trator/dialog-trator.component';
import { FaixasMapaService } from '../service/faixas-mapa.service';
import { format } from 'date-fns';
import { RedirectService } from '../service/redirect.service';
import { HectaresProduzidoMobileComponent } from './hectares-produzidos-mobile/hectares-produzidos-mobile'; 

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {

  loadMapa = true;
  stringUltimaMensagem: any;
  tipoMapa = localStorage.getItem("tipoMapa");
  clients = JSON.parse(localStorage.getItem('cliente') || "{}")
  LocalStorageId: any = localStorage.getItem('cliente_filtro');
  LocalStorageOperationId: any = localStorage.getItem('operacaoId');
  LocalStorageDateStart: any = localStorage.getItem('DateStart');
  LocalStorageDateEnd: any = localStorage.getItem('DateEnd');
  fuso: string = localStorage.getItem('horario') == 'utc' ? 'utc' : 'brt';
  geoJson!: FeatureCollectionWithFilename;
  map!: mapboxgl.Map;
  equipamentos: any;
  aviso = false;
  avisoMensagemSemOs = false;
  shape: string | null = null;
  linhas: string | null = null;
  @Input() redimensionaMapa: any;

  mostrarExportacaoDeArquivos: boolean = false;
  mostrarEncontrarEquipamento: boolean = false;

  constructor(
    public dialog: MatDialog,
    private mensagemService: MensagensService,
    private shapeService: ArquivosService,
    private equipamentosService: EquipamentosService,
    private notificacaoService: NotificacoesService,
    private faixasMapaService: FaixasMapaService,
    private appService: RedirectService
  ) { }

  ngOnInit(): void {
    this.chamarMetodos();
    this.appService.carregarMapa.subscribe(() => {
      this.chamarMetodos();
    });
    this.appService.recebeLatLngEquipamento.subscribe((latLngEquipamento) => {
      this.acharEquipamento(latLngEquipamento);
    });
  }

  chamarMetodos() {
    this.resetarVariaveis();
    this.encontrarShapeDoCliente();
    this.encontrarLinhaDoCliente();
    this.ultimaMensagem();
    this.criarMapa();
  }

  @Output() menuEvent = new EventEmitter<string>();

  verificarMenuEquipamento(t: any) {
    this.menuEvent.emit(t.menuOpen);
  }

  resetarVariaveis() {
    this.loadMapa = true;
    this.tipoMapa = localStorage.getItem("tipoMapa");
    this.LocalStorageId = localStorage.getItem('cliente_filtro');
    this.LocalStorageOperationId = localStorage.getItem('operacaoId');
    this.LocalStorageDateStart = localStorage.getItem('DateStart');
    this.LocalStorageDateEnd = localStorage.getItem('DateEnd');
    this.clients = JSON.parse(localStorage.getItem('cliente') || "{}")
    this.aviso = false;
    this.avisoMensagemSemOs = false;
  }

  verificarNotificacoes() {
    this.notificacaoService.AcharNotificacoes(this.LocalStorageId, this.LocalStorageDateStart, this.LocalStorageDateEnd).subscribe(
      (resp: any) => {
        this.aviso = resp.telemetrias_sem_esticamento;
        this.avisoMensagemSemOs = resp.mensagens_sem_operacao;
        setTimeout(() => {
          this.aviso = false;
          this.avisoMensagemSemOs = false;
        }, 20000);
      })
  }

  ultimaMensagem() {
    this.mensagemService.ultimaMensagemTimestamp(this.LocalStorageId).subscribe((mensagem: any) => {
      var dataAtual = format(new Date(), 'dd/MM/yyyy HH:mm:ss');
      this.stringUltimaMensagem = `Atualizado em ${dataAtual.replace(' ', ' às ')}` + (Object.values(mensagem).length > 0 ? ` - Última mensagem em ${mensagem.replace(' ', ' às ')}` : '');
    })
  }

  criarMapa() {
    var cliente = this.clients.filter((cliente: any) => {
      return cliente.id == this.LocalStorageId
    });
    (mapboxgl as any).accessToken = MAPBOX_TOKEN;
    this.map = new mapboxgl.Map({
      container: 'map',
      center: [cliente[0].longitude, cliente[0].latitude],
      zoom: 12
    });
    if (localStorage.getItem('usuario') == '23') {
      this.map.setStyle('mapbox://styles/liloca13/cl6qq2vxt009b14lsu56l7639');
    } else {
      this.map.setStyle('mapbox://styles/mapbox/satellite-streets-v11?optimize=true');
    }
    this.map.on('load', () => {
      this.encontrarUltimaMensagemDoEquipamento();
      this.buscaFaixasMapa();
      this.loadMapa = false;
      this.verificarNotificacoes();
    });
  }

  criarStyleMarker(el: any) {
    el.className = 'marker';
    el.style.width = `3rem`;
    el.style.height = `3rem`;
    el.style.backgroundSize = '100%';
    el.style.color = '#fff'
    el.style.fontSize = '1.5vh'
    el.style.fontWeight = 'bold'
    el.style.fontFamily = "'Poppins', sans-serif"
    el.style.paddingTop = '3rem'
    el.style.backgroundRepeat = 'no-repeat';
    el.style.textAlign = 'center'
    el.style.cursor = 'pointer'
  }

  abrirInformacoesDoEsticamento(message: any): void {
    this.dialog.open(DialogInformacoesEsticamentoComponent, {
      data: message
    });
  }

  abrirNotificacoes(tipo: any): any {
    this.dialog.open(DialogMensagensNotificacaoComponent, {
      width: "50vw",
      height: "90vh",
      data: [tipo]
    });
  }

  savePolygon() {
    const arquivo = document.createElement('a');
    const url = window.URL.createObjectURL(new Blob([JSON.stringify(this.geoJson)], { type: "application/geojson" }));
    arquivo.href = url;
    arquivo.download = 'Geojson.geojson';
    arquivo.click();
    window.URL.revokeObjectURL(url);
    arquivo.remove();
  }

  fecharAviso(tipo: any) {
    tipo == 'mensagemAeB' ? this.aviso = false : tipo == 'mensagemSemOS' ? this.avisoMensagemSemOs = false : null;
  }

  acharEquipamento(latLngEquipamento: { latitude: number; longitude: number; }) {
    this.map.flyTo({
      center: [latLngEquipamento.longitude, latLngEquipamento.latitude],
      zoom: 18
    });
  }

  iconesMapa(elementoHTML: any, dados: any, tipoEquipamento: any, alerta: any, statusEquipamento: any) {
    var dialogoParaAbrir: any;
    var configuracaoDialogo: any = {
      minWidth: '35vw',
      maxHeight: '95vh'
    };
    if (tipoEquipamento == 'Carretel' || tipoEquipamento == 'Motobomba') {
      var url = tipoEquipamento == 'Carretel' ? '/assets/icones-carretel/carretel-' : '/assets/icone-motobomba/motobomba-';
      dialogoParaAbrir = tipoEquipamento == 'Carretel' ? DialogCarretelComponent : DialogMotobombaComponent;
      url += alerta ? 'alerta-' : '';
      url += statusEquipamento == 'Parado' ? 'parado-' : 'funcionando-';
      url += dados.vc_ultimo_sin == 128 ? 'sinal.png' : 'sem-sinal.png';
    } else {
      url = dados.vc_ultimo_sin == 128 ? '/assets/icones-trator/trator-sinal.png' : '/assets/icones-trator/trator-sem-sinal.png';
      dialogoParaAbrir = DialogTratorComponent
      configuracaoDialogo = {
        minWidth: '35vw'
      }
    }
    if (alerta) {
      elementoHTML.style.animation = 'blinker 1s linear infinite';
    }
    elementoHTML.style.backgroundImage = `url(${url})`;
    configuracaoDialogo.data = { 'url': url, 'dados': dados };
    elementoHTML.addEventListener('click', (e: any) => {
      localStorage.setItem("equipamentoId", dados.sr_id);
      localStorage.setItem("frota", dados.vc_frota);
      this.dialog.open(dialogoParaAbrir, configuracaoDialogo)
    });
  }

  abrirInformacoesSobreOsIconesDeEquipamentos() {
    const dialogRef = this.dialog.open(DialogInformacoesIconesComponent, {
      width: '30rem'
    });
  }
  abrirInformacoesSobreHectaresMobile() {
    const dialogRef = this.dialog.open(HectaresProduzidoMobileComponent, {

    });
  }

  colocarShapeDoClienteNoMapa(mapaPossuiFaixasDeIrrigacao: boolean): void {
    if (this.shape != null && Object.keys(this.shape).length != 0) {
      this.map.addSource('shape', {
        type: 'geojson',
        data: 'https://hidrometer.s3.sa-east-1.amazonaws.com/arquivos/' + this.shape
      });
      this.map.addLayer({
        'id': 'layerShape',
        'type': 'fill',
        'source': 'shape',
        'paint': {
          'fill-color': mapaPossuiFaixasDeIrrigacao ? "#B0E0E6" : "#499bbc",
          'fill-opacity': 0.3
        }
      });
      const popup = new mapboxgl.Popup({
        closeButton: false
      });
      this.map.on('mousemove', 'layerShape', (e: any) => {
        let fazenda = e.features[0].properties.FAZENDA ? e.features[0].properties.FAZENDA : "Não encontrada";
        let talhao = e.features[0].properties.TALHAO ? e.features[0].properties.TALHAO : "Não encontrado";
        popup.setLngLat(e.lngLat).setText('Fazenda: ' + fazenda + ' Talhão: ' + talhao).addTo(this.map);
      });
      this.map.on('mouseleave', 'layerShape', () => {
        this.map.getCanvas().style.cursor = '';
        popup.remove();
      });
    }
  }

  colocarLinhasDoClienteNoMapa(): void {
    this.shapeService.EncontrarLinha(this.LocalStorageId, this.LocalStorageDateStart, this.LocalStorageDateEnd).subscribe(linhas => {
      if (linhas != null && Object.keys(linhas).length != 0) {
        this.map.addSource('linhas', {
          type: 'geojson',
          data: 'https://hidrometer.s3.sa-east-1.amazonaws.com/arquivos/' + linhas,
        });
        this.map.addLayer({
          'id': 'layerLinhas',
          'type': 'line',
          'source': 'linhas',
          "paint": { "line-color": "#ffc0cb" }
        });
      }
    })
  }

  encontrarUltimaMensagemDoEquipamento() {
    this.equipamentosService.equipamentosNoMapa(this.LocalStorageId).subscribe((equipamentos: any) => {
      this.equipamentos = equipamentos;
      this.appService.mapaEnviaEquipamentos(this.equipamentos);
      equipamentos.forEach((equipamento: any) => {
        const el = document.createElement('div');
        el.appendChild(document.createTextNode(equipamento.vc_frota));
        this.criarStyleMarker(el);
        if (equipamento.vc_latitude != "0" && equipamento.vc_latitude != null) {
          if (equipamento.vc_tipo == 'REEL' || equipamento.vc_tipo == 'MOTOR_PUMP') {
            var tipoEquipamento = equipamento.vc_tipo == 'REEL' ? 'Carretel' : 'Motobomba';
            this.iconesMapa(el, equipamento, tipoEquipamento, equipamento.alerta, equipamento.situacaoEquipamento);
          } else {
            el.style.paddingTop = '2.6rem'
            this.iconesMapa(el, equipamento, 'Trator', false, false);
          }
          new mapboxgl.Marker(el).setLngLat([equipamento.vc_longitude, equipamento.vc_latitude]).addTo(this.map);
        }
      });
    })
  }

  buscaFaixasMapa() {
    var params = {
      'cliente': this.LocalStorageId,
      'dataInicial': this.LocalStorageDateStart,
      'dataFinal': this.LocalStorageDateEnd,
      'fuso': this.fuso,
      'operacaoId': this.LocalStorageOperationId
    }
    this.faixasMapaService.faixasMapaPeriodo(params).subscribe((arrayFaixas: any) => {
      this.colocarShapeDoClienteNoMapa(arrayFaixas != null);
      this.colocarLinhasDoClienteNoMapa();
      var array = [] as any;
      arrayFaixas?.forEach((faixa: any, indice: any) => {
        var coordenadas = JSON.parse(faixa.js_coordenadas)
        if (coordenadas.length > 0) {
          var image = this.tipoMapa === 'pressao' ? faixa.vc_base64_pressao : faixa.vc_base64_lamina;
          if (image != null) {
            var novaCoordenada = [[coordenadas[0][1], coordenadas[0][0]], [coordenadas[1][1], coordenadas[1][0]], [coordenadas[2][1], coordenadas[2][0]], [coordenadas[3][1], coordenadas[3][0]]]
            var nome = 'imagem' + indice
            this.map.addSource(nome, {
              'type': 'image',
              'url': image,
              'coordinates': novaCoordenada
            });
            this.map.addLayer({
              'id': nome,
              'type': 'raster',
              'source': nome,
              'paint': {
                'raster-opacity': 0.7,
              }
            });
            array.push(this.poligonoParaGeoJson(faixa, novaCoordenada, indice))
          }
        }
      });
      this.geoJson = {
        "type": "FeatureCollection",
        "features": array
      }
      this.map.addSource('states', {
        'type': 'geojson',
        'data': this.geoJson
      });
      this.map.addLayer({
        'id': 'poligono',
        'type': 'fill',
        'source': 'states',
        'paint': {
          'fill-color': 'transparent'
        }
      });
      this.map.on('mousemove', 'poligono', (e: any) => {
        this.map.getCanvas().style.cursor = 'pointer';
      });
      this.map.on('mouseleave', 'poligono', () => {
        this.map.getCanvas().style.cursor = '';
      });
      this.map.on('click', 'poligono', (e: any) => {
        this.abrirInformacoesDoEsticamento(arrayFaixas[e.features[0].id])
      })
    })
  }

  poligonoParaGeoJson(faixa: any, novaCoordenada: any, indice: number) {
    const { LatitudeInicial, LongitudeInicial, Latitude, Longitude } = JSON.parse(faixa.js_ponto_A_e_B);
    const PintarPrimeraTele = this.bearing(LatitudeInicial, LongitudeInicial, Latitude, Longitude);
    const novaPosicao = this.calcularNovaPosicao(Latitude, Longitude, PintarPrimeraTele, faixa.it_largura_faixa / 2);
    const path = this.circlePath(new google.maps.LatLng(novaPosicao.latitude, novaPosicao.longitude), faixa.it_largura_faixa / 2, PintarPrimeraTele);
    const pathSemiCirculo = path.map(element => [element.lng(), element.lat()]);
    const coordenadasFaixa = [novaCoordenada[3], novaCoordenada[2], ...pathSemiCirculo, novaCoordenada[3]];
    var arquivo = {
      'type': 'Feature',
      'geometry': {
        'type': "Polygon",
        'coordinates': [coordenadasFaixa]
      },
      'id': indice
    }
    return arquivo;
  }

  bearing(startLat: any, startLng: any, destLat: any, destLng: any) {
    startLat = this.toRadians(startLat);
    startLng = this.toRadians(startLng);
    destLat = this.toRadians(destLat);
    destLng = this.toRadians(destLng);
    var y = Math.sin(destLng - startLng) * Math.cos(destLat);
    var x = Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    var resultado = Math.atan2(y, x);
    return this.toDegrees(resultado);
  }

  circlePath(center: any, radius: any, bearing: any) {
    var a = [], p = 191 / 20, d = bearing + 265.5;
    for (var i = 0; i < 20; ++i, d += p) {
      a.push(google.maps.geometry.spherical.computeOffset(center, radius, d));
    }
    return a;
  }

  toRadians(degrees: any) {
    return (degrees * Math.PI) / 180;
  }

  toDegrees(radians: any) {
    return (radians * 180) / Math.PI;
  }

  haversine(lat1: any, lon1: any, lat2: any, lon2: any) {
    lat1 = lat1 * Math.PI / 180;
    lon1 = lon1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;

    const dlat = lat2 - lat1;
    const dlon = lon2 - lon1;
    const a = Math.sin(dlat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const R = 6371000;
    const distance = R * c;

    return distance;
  }

  calcularNovaPosicao(lat: any, lon: any, direcao: any, distancia: any) {
    const direcaoOposta = (direcao + 180) % 360;
    const direcaoRad = direcaoOposta * Math.PI / 180;
    const distanciaRad = distancia / 6371000;

    let latRad = lat * Math.PI / 180;
    let lonRad = lon * Math.PI / 180;

    const newLat = Math.asin(Math.sin(latRad) * Math.cos(distanciaRad) +
      Math.cos(latRad) * Math.sin(distanciaRad) * Math.cos(direcaoRad));
    const newLon = lonRad + Math.atan2(Math.sin(direcaoRad) * Math.sin(distanciaRad) * Math.cos(latRad),
      Math.cos(distanciaRad) - Math.sin(latRad) * Math.sin(newLat));
    const newLatDeg = newLat * 180 / Math.PI;
    const newLonDeg = newLon * 180 / Math.PI;

    return { latitude: newLatDeg, longitude: newLonDeg };
  }

  encontrarShapeDoCliente(): void {
    this.shapeService.EncontrarShape(this.LocalStorageId, this.LocalStorageDateStart, this.LocalStorageDateEnd).subscribe((shape: any) => {
      this.shape = shape;
    })
  }

  encontrarLinhaDoCliente(): void {
    this.shapeService.EncontrarLinha(this.LocalStorageId, this.LocalStorageDateStart, this.LocalStorageDateEnd).subscribe((linhas: any) => {
      this.linhas = linhas;
    })
  }
}