import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TraduzirMensagemService {
  constructor() { }

  TraduzirPay(pay: any) {
    if (pay.group == 'Eletrobomba') {
      if (pay.descriptor == 'Telemetria (a cada X minutos)') {
        pay.descriptor = pay.descriptor.substr(0, 10);
      }
    }
    if (pay.dataLength) {
      var string = pay.dataLength.toString();
      if (string.indexOf('bytes') < 0) {
        pay.dataLength += ' bytes';
      }
    }
    if (pay.speedAverage) {
      var string = pay.speedAverage.toString();
      if (string.indexOf('m/h') < 0) {
        pay.speedAverage += ' m/h';
      }
    }
    if (pay.timeAppliedWithSppedOutOfRange) {
      var string = pay.timeAppliedWithSppedOutOfRange.toString();
      if (string.indexOf('min') < 0) {
        pay.timeAppliedWithSppedOutOfRange += ' min';
      }
    }
    if (pay.timeAppliedWithSpeedOutOfRange) {
      var string = pay.timeAppliedWithSpeedOutOfRange.toString();
      if (string.indexOf('min') < 0) {
        pay.timeAppliedWithSpeedOutOfRange += ' min';
      }
    }
    if (pay.pressureAppliedMettersOutOfRange) {
      var string = pay.pressureAppliedMettersOutOfRange.toString();
      if (string.indexOf('min') < 0) {
        pay.pressureAppliedMettersOutOfRange += ' min';
      }
    }
    if (pay.MediumBattery) {
      var string = pay.MediumBattery.toString();
      if (string.indexOf('V') < 0) {
        pay.MediumBattery += ' V';
      }
    }

    if (pay.MediumPressure) {
      var string = pay.MediumPressure.toString();
      if (string.indexOf(' kgf/cm²') < 0) {
        pay.MediumPressure += ' kgf/cm²';
      }
    }

    if (pay.MediumSpeedAverage) {
      var string = pay.MediumSpeedAverage.toString();
      if (string.indexOf('m/h') < 0) {
        pay.MediumSpeedAverage += ' m/h';
      }
    }
    if (pay.statusValve == 0) {
      pay.statusValve = 'Aberta';
    }

    if (pay.statusValve) {
      switch (pay.statusValve) {
        case '0':
          pay.statusValve = 'Aberta';
          break;
        case 1:
          pay.statusValve = 'Fechada';
          break;
        case 2:
          pay.statusValve = 'Semi-aberta';
          break;
        case 3:
          pay.statusValve = 'Não existente';
          break;
      }
    }
    if (pay.velocity) {
      var string = pay.velocity.toString();
      if (string.indexOf('RPM') < 0) {
        pay.velocity += ' RPM';
      }
    }

    if (pay.hasOwnProperty('velocidadeRPM')) {
      var string = pay.velocidadeRPM.toString();
      if (string.indexOf('RPM') < 0) {
        pay.velocidadeRPM += ' RPM';
      }
    }

    if (pay.pumpPressure) {
      var string = pay.pumpPressure.toString();
      if (string.indexOf('kgf/cm²') < 0) {
        pay.pumpPressure += ' kgf/cm²';
      }
    }
    if (pay.fuelLevel) {
      var string = pay.fuelLevel.toString();
      if (string.indexOf('%') < 0) {
        pay.fuelLevel += ' %';
      }
    }
    if (pay.adductorPressure) {
      var string = pay.adductorPressure.toString();
      if (string.indexOf('kgf/cm²') < 0) {
        pay.adductorPressure += ' kgf/cm²';
      }
    }
    if (pay.hasOwnProperty('suctionLevel')) {
      var string = pay.suctionLevel.toString();
      if (string.indexOf('%') < 0) {
        pay.suctionLevel += ' %';
      }
    }
    if (pay.pressure) {
      var string = pay.pressure.toString();
      if (string.indexOf('kgf/cm²') < 0) {
        pay.pressure += ' kgf/cm²';
      }
    }
    if (pay.odometer) {
      var string = pay.odometer.toString();
      if (string.indexOf('m') < 0) {
        pay.odometer += ' m';
      }
    }
    if (pay.status) {
      if (pay.group == 'Carretel') {
        switch (pay.status) {
          case 0:
            pay.status = 'Parado';
            break;
          case 1:
            pay.status = 'Recolhendo';
            break;
          case 2:
            pay.status = 'Esticando';
            break;
        }
      } else if (pay.group == 'Motobomba') {
        switch (pay.status) {
          case 0:
            pay.status = 'Indefinido';
            break;
          case 1:
            pay.status = 'Desligada';
            break;
          case 2:
            pay.status = 'Marcha lenta';
            break;
          case 3:
            pay.status = 'Controle por velocidade (enchendo rede)';
            break;
          case 4:
            pay.status = 'Controle por pressão';
            break;
        }
      } else if (pay.group == 'Eletrobomba') {
        switch (pay.status) {
          case 0:
            pay.status = 'Indefinido';
            break;
          case 1:
            pay.status = 'Desligada';
            break;
          case 2:
            pay.status =
              'Baixa Rotação(tipo 1 –com inversor) ou Ligada(tipo 0 -básica)';
            break;
          case 3:
            pay.status = 'Controle por velocidade (Apenas com inversor)';
            break;
          case 4:
            pay.status = 'Controle por pressão (Apenas com inversor)';
            break;
        }
      }
    }
    if (pay.battery) {
      var string = pay.battery.toString();
      if (string.indexOf('V') < 0) {
        pay.battery += ' V';
      }
    }
    if (pay.stoppedTime) {
      var string = pay.stoppedTime.toString();
      if (string.indexOf('min') < 0) {
        pay.stoppedTime += ' min';
      }
    }
    if (pay.timeAppliedWithPressSureOutOfRange) {
      var string = pay.timeAppliedWithPressSureOutOfRange.toString();
      if (string.indexOf('min') < 0) {
        pay.timeAppliedWithPressSureOutOfRange += ' min';
      }
    }
    if (pay.pressureAppliedMetersOutOfRange) {
      var string = pay.pressureAppliedMetersOutOfRange.toString();
      if (string.indexOf('m') < 0) {
        pay.pressureAppliedMetersOutOfRange += ' m';
      }
    }
    if (pay.metersAppliedSpeedOutOfRange) {
      var string = pay.metersAppliedSpeedOutOfRange.toString();
      if (string.indexOf('m') < 0) {
        pay.metersAppliedSpeedOutOfRange += ' m';
      }
    }
    if (pay.totalElapsedTime) {
      var string = pay.totalElapsedTime.toString();
      if (string.indexOf('min') < 0) {
        pay.totalElapsedTime += ' min';
      }
    }
    if (pay.stoppedTimeApplying) {
      var string = pay.stoppedTimeApplying.toString();
      if (string.indexOf('min') < 0) {
        pay.stoppedTimeApplying += ' min';
      }
    }
    if (pay.nozzle) {
      var string = pay.nozzle.toString();
      if (string.indexOf('mm') < 0) {
        pay.nozzle += ' mm';
      }
    }
    if (pay.setPointVelocity) {
      var string = pay.setPointVelocity.toString();
      if (string.indexOf('RPM') < 0) {
        pay.setPointVelocity += ' RPM';
      }
    }
    if (pay.bandWith) {
      var string = pay.bandWith.toString();
      if (string.indexOf('m') < 0) {
        pay.bandWith += ' m';
      }
    }
    if (pay.speed) {
      var string = pay.speed.toString();
      if (string.indexOf('km/h') < 0) {
        pay.speed += ' km/h';
      }
    }
    if (pay.fuel) {
      var string = pay.fuel.toString();
      if (string.indexOf('L') < 0) {
        pay.fuel += ' L';
      }
    }
    if (pay.alerts) {
      if (pay.group == 'Carretel') {
        if (pay.alerts != 'Parado e aplicando' && pay.alerts != 'Sem alertas') {
          pay.alerts =
            JSON.parse(pay.alerts).indexOf('1') > -1
              ? 'Parado e aplicando'
              : 'Sem alertas';
        }
      } else if (pay.group == 'Eletrobomba') {
        let alertas;
        if (pay.alerts.includes('/')) {
          alertas = JSON.parse(pay.alerts);
        } else {
          alertas = pay.alerts;
        }
        if (alertas.includes('1')) {
          var alertaString = '';
          if (typeof alertas == 'string') {
            alertas = JSON.parse(pay.alerts);
          }
          alertas.forEach((alerta: any, indice: any) => {
            if (indice <= 7) {
              if (indice == 0 && alerta == '1') {
                alertaString += 'Restrição filtro de ar |';
              }
              if (indice == 1 && alerta == '1') {
                alertaString += 'Alimentação do motor desligada |';
              }
              if (indice == 2 && alerta == '1') {
                alertaString += 'Pressão de saída baixa |';
              }
              if (indice == 3 && alerta == '1') {
                alertaString += 'Nível baixo sucção |';
              }
              if (indice == 4 && alerta == '1') {
                alertaString += 'Tensão baixa da fonte de alimentação |';
              }
            }
          });
          pay.alerts = alertaString;
        } else {
          pay.alerts = 'Sem alertas';
        }
      } else if (pay.group == 'Motobomba') {
        let alertas;
        if (pay.alerts.includes('/')) {
          alertas = JSON.parse(pay.alerts);
        } else {
          alertas = pay.alerts;
        }
        if (alertas.includes('1')) {
          var alertaString = '';
          if (typeof alertas == 'string') {
            alertas = JSON.parse(pay.alerts);
          }
          pay.alerts = this.alertasDaMotobomba(alertas);
        } else {
          switch (true) {
            case pay.alerts.includes('Restrição filtro de ar'):
            case pay.alerts.includes('Pressão baixo do óleo'):
            case pay.alerts.includes('Pressão de saída baixa'):
            case pay.alerts.includes('Nível baixo arrefecimento'):
            case pay.alerts.includes('Temperatura alta arrefecimento'):
            case pay.alerts.includes('Tensão baixa bateria'):
            case pay.alerts.includes('Nível baixo combustível'):
            case pay.alerts.includes('Nível baixo sucção'):
              break;
            default:
              pay.alerts = 'Sem alertas';
              break;
          }
        }
      }
    }

    if (pay.alert && pay.group == 'Motobomba' && pay.alert.includes('[')) {
      pay.alert = this.alertasDaMotobomba(JSON.parse(pay.alert));
    }



    if (pay.Bocal) {
      var string = pay.Bocal.toString();
      if (string.indexOf('cadastrado') < 0) {
        if (string.indexOf('mm') < 0) {
          pay.Bocal += ' mm';
        }
      }
    }

    if (pay.Vazao) {
      var string = pay.Vazao.toString();
      if (string.indexOf('cadastrado') < 0) {
        if (string.indexOf('m³/h') < 0) {
          pay.Vazao += ' m³/h';
        }
      }
    }

    if (pay.lamina) {
      var string = pay.lamina.toString();
      if (string.indexOf('cadastrado') < 0) {
        if (string.indexOf('mm') < 0) {
          pay.lamina += ' mm';
        }
      }
    }

    if (pay.operationCode) {
      switch (pay.operationCode) {
        case 1:
          pay.operationCode = 'Dar partida / Ligar';
          break;
        case 2:
          pay.operationCode = 'Afogar / Desligar';
          break;
        case 3:
          pay.operationCode =
            'Passar para marcha lenta / Passar para baixa rotação';
          break;
      }
    }

    if (typeof pay.originCommand === 'number') {
      if (pay.group == 'Motobomba') {
        let origensComando: any = {
          0: 'Local',
          1: 'Remoto',
          2: 'Automático – pressão baixa do óleo',
          3: 'Automático – pressão de saída baixa',
          4: 'Automático – nível baixo arrefecimento',
          5: 'Automático – temperatura alta arrefecimento',
          6: 'Automático – nível baixo combustível',
          7: 'Automático – velocidade baixa motor',
          8: 'Automático – tensão baixa bateira',
          9: 'Automático – nível baixo sucção',
          10: 'Automático – feedback de velocidade baixo',
          11: 'Automático – feedback de pressão baixo',
          12: 'Automático – MWM',
          13: 'Automático - não mapeado',
        };
        pay.originCommand = origensComando[pay.originCommand] !== undefined ? origensComando[pay.originCommand] : 'Não identificada'
      } else if (pay.group == 'Eletrobomba') {
        switch (pay.originCommand) {
          case 0:
            pay.originCommand = 'Local';
            break;
          case 1:
            pay.originCommand = 'Remoto';
            break;
          case 2:
            pay.originCommand = 'Automático – pressão de saída baixa';
            break;
          case 3:
            pay.originCommand = 'Automático – falta de fase';
            break;
          case 4:
            pay.originCommand =
              'Automático – velocidade baixa motor na partida';
            break;
          case 5:
            pay.originCommand = 'Automático – tensão baixa bateria';
            break;
          case 6:
            pay.originCommand = 'Automático – nível baixo sucção';
            break;
          case 7:
            pay.originCommand = 'Automático – feedback de pressão baixo';
            break;
        }
      }
    }

    if (pay.reason) {
      if (pay.group == 'Eletrobomba' || pay.group == 'Motobomba') {
        switch (pay.reason) {
          case '1':
          case 1:
            pay.reason = 'Alerta Ativo';
            break;
          case '2':
          case 2:
            pay.reason = 'RPM baixo para Partida';
            break;
          case '3':
          case 3:
            pay.reason = 'RPM baixo para Controle';
            break;
          case '4':
          case 4:
            pay.reason = 'Pressão baixa para Controle';
            break;
          case '5':
          case 5:
            pay.reason = 'Estado inválido (Desligado)';
            break;
          case '6':
          case 6:
            if (pay.group == 'Eletrobomba') pay.reason = 'Estado inválido (Baixa Rotação)';
            else pay.reason = 'Estado inválido (Marcha Lenta)';
            break;
          case '7':
          case 7:
            pay.reason = 'Estado inválido (Controle RPM)';
            break;
          case '8':
          case 8:
            pay.reason = 'Estado inválido (Controle Pressão)';
            break;
          case '9':
          case 9:
            if (pay.group == 'Eletrobomba') pay.reason = 'Executando rotina (Ligando)';
            else pay.reason = pay.reason = 'Executando rotina (Partida)';
            break;
          case '10':
          case 10:
            pay.reason = 'Executando rotina (Controle RPM)';
            break;
          case '11':
          case 11:
            pay.reason = 'Executando rotina (Controle Pressão)';
            break;
          case '12':
          case 12:
            if (pay.group == 'Eletrobomba') pay.reason = 'Executando rotina (Baixa Rotação)';
            else pay.reason = 'Executando rotina (Marcha lenta)';
            break;
          case '13': case 13:
            if (pay.group == 'Eletrobomba') pay.reason = 'Executando rotina (Desligando)';
            else pay.reason = 'Executando rotina (Afogador)';
            break;
          case '14':
          case 14:
            pay.reason = 'Executando rotina (Parada Rápida)';
            break;
          case '15':
          case 15:
            pay.reason = 'ECU não responde';
            break;
          case '16':
          case 16:
            pay.reason = 'Frota inconsistente';
            break;
        }
      }
    }

    if (pay.hasOwnProperty('type')) {
      switch (pay.type) {
        case 0:
          pay.type = 'Eletrobomba Básica';
          break;
        case 1:
          pay.type = 'Eletrobomba com Inversor';
          break;
      }
    }

    if (pay.codigoDeParada) {
      switch (pay.codigoDeParada) {
        case 1:
          pay.codigoDeParada = 'Sem operador';
          break;
        case 2:
          pay.codigoDeParada = 'Manutenção carretel';
          break;
        case 3:
          pay.codigoDeParada = 'Manutenção trator';
          break;
        case 4:
          pay.codigoDeParada = 'Manutenção motobomba';
          break;
        case 5:
          pay.codigoDeParada = 'Aguardando ordem de serviço';
          break;
        case 6:
          pay.codigoDeParada = 'Vento forte';
          break;
        case 7:
          pay.codigoDeParada = 'Chuva/solo úmido';
          break;
        case 8:
          pay.codigoDeParada = 'Aguardando mecânico';
          break;
        case 9:
          pay.codigoDeParada = 'Limpeza para conservação do equipamento';
          break;
        case 10:
          pay.codigoDeParada = 'Troca de turno';
          break;
        case 11:
          pay.codigoDeParada = 'Encalhado';
          break;
        case 12:
          pay.codigoDeParada = 'Refeição';
          break;
        case 13:
          pay.codigoDeParada = 'Abastecimento combustível';
          break;
        case 14:
          pay.codigoDeParada = 'Lubrificação';
          break;
        case 15:
          pay.codigoDeParada = 'Aguardando hidrante';
          break;
        case 16:
          pay.codigoDeParada = 'Aguardando borracheiro';
          break;
        case 17:
          pay.codigoDeParada = 'Aguardando eletricista';
          break;
        case 18:
          pay.codigoDeParada = 'Manutenção elétrica';
          break;
        case 19:
          pay.codigoDeParada = 'Higiene pessoal';
          break;
        case 20:
          pay.codigoDeParada = 'Aguardando vinhaça';
          break;
        case 21:
          pay.codigoDeParada = 'Aguardando liberação de área';
          break;
        case 22:
          pay.codigoDeParada = 'Mudança de área';
          break;
        case 23:
          pay.codigoDeParada = 'Aguardando prancha/munck';
          break;
        case 24:
          pay.codigoDeParada = 'Teste carretel';
          break;
        case 25:
          pay.codigoDeParada = 'Teste motobomba';
          break;
        case 26:
          pay.codigoDeParada = 'Teste trator';
          break;
        case 27:
          pay.codigoDeParada = 'Folga operador';
          break;
        case 28:
          pay.codigoDeParada = 'Reparo pneu';
          break;
        case 29:
          pay.codigoDeParada = 'Verificação geral';
          break;
        case 30:
          pay.codigoDeParada = 'Verificação qualidade';
          break;
        case 31:
          pay.codigoDeParada = 'Manutenção GPS/bordo';
          break;
        case 32:
          pay.codigoDeParada = 'Sem sinal GPS';
          break;
        case 33:
          pay.codigoDeParada = 'Bateria Fraca';
          break;
        case 34:
          pay.codigoDeParada = 'Estouro de rede';
          break;
        case 35:
          pay.codigoDeParada = 'Carretel em espera';
          break;
        case 36:
          pay.codigoDeParada = 'Falta de energia';
          break;
        case 37:
          pay.codigoDeParada = 'Cintilação';
          break;
        case 38:
          pay.codigoDeParada = 'Manutenção preventiva';
          break;
        case 39:
          pay.codigoDeParada = 'Aguardando projeto';
          break;
        case 40:
          pay.codigoDeParada = 'Aguardando área liberada';
          break;
        case 41:
          pay.codigoDeParada = 'Abastecimento de insumo';
          break;
        case 42:
          pay.codigoDeParada = 'Falta de insumo';
          break;
        case 43:
          pay.codigoDeParada = 'Mudança de área';
          break;
        case 44:
          pay.codigoDeParada = 'Operador mantenedor';
          break;
        case 45:
          pay.codigoDeParada = 'Regulagem de implemento';
          break;
      }
    }

    var stringified = JSON.stringify(pay);
    stringified = stringified.replace('type', 'Tipo');
    stringified = stringified.replace('velocidadeRPM', 'Velocidade');
    stringified = stringified.replace('Hidrometer', 'HidroMeter');
    stringified = stringified.replace('name', 'Nome');
    stringified = stringified.replace('group', 'Grupo');
    stringified = stringified.replace('descriptor', 'Descrição');
    stringified = stringified.replace('dataLength', 'Tamanho da mensagem');
    stringified = stringified.replace('fleet', 'Frota');
    stringified = stringified.replace('timestamp', 'Horário');
    stringified = stringified.replace('timeStamp', 'Horário');
    stringified = stringified.replace('engineWorkTime', 'Tempo trabalhado');
    stringified = stringified.replace('statusValve', 'Status da válvula');
    if(stringified.includes('alerts')){
      stringified = stringified.replace('alerts', 'Alertas');
    }else{
      stringified = stringified.replace('alert', 'Alertas');
    }
    stringified = stringified.replace('velocity', 'Velocidade');
    stringified = stringified.replace('pumpPressure', 'Pressão de saída');
    stringified = stringified.replace('fuelLevel', 'Nível de combustível');
    stringified = stringified.replace('CombustívelNivel', 'Nível de combustível');
    stringified = stringified.replace('adductorPressure', 'Pressão de entrada');
    stringified = stringified.replace('aductorPressure', 'Pressão de entrada');
    stringified = stringified.replace('suctionLevel', 'Nível de sucção');
    stringified = stringified.replace('originCommand', 'Origem do comando');
    stringified = stringified.replace('pressure', 'Pressão');
    stringified = stringified.replace('odometer', 'Odômetro');
    stringified = stringified.replace('status', 'Estado');
    stringified = stringified.replace('battery', 'Bateria');
    stringified = stringified.replace('speedAverage', 'Velocidade');
    stringified = stringified.replace('stoppedTime', 'Tempo parado');
    stringified = stringified.replace(
      'timeAppliedWithPressureOutOfRange',
      'Tempo aplicado com a pressão fora da faixa'
    );
    stringified = stringified.replace(
      'timeAppliedWithSppedOutOfRange',
      'Tempo aplicado com a velocidade fora da faixa'
    );
    stringified = stringified.replace(
      'timeAppliedWithSpeedOutOfRange',
      'Tempo aplicado com a velocidade fora da faixa'
    );
    stringified = stringified.replace(
      'pressureAppliedMettersOutOfRange',
      'Metros de pressão aplicada fora da faixa'
    );
    stringified = stringified.replace(
      'PressãoAppliedMettersOutOfRange',
      'Metros de pressão aplicada fora da faixa'
    );
    stringified = stringified.replace(
      'metersAppliedSpeedOutOfRange',
      'Metros aplicado com a velocidade fora da faixa'
    );
    stringified = stringified.replace(
      'totalElapsedTime',
      'Tempo total decorrido'
    );
    stringified = stringified.replace(
      'stoppedTimeApplying',
      'Tempo parado aplicando'
    );
    stringified = stringified.replace('nozzle', 'Bocal');
    stringified = stringified.replace('reason', 'Razão');
    stringified = stringified.replace('codigoParada', 'Código de parada');
    stringified = stringified.replace('fleetTractor', 'Frota do trator');
    stringified = stringified.replace('fleetMotorPump', 'Frota da motobomba');
    stringified = stringified.replace(
      'setPointVelocity',
      'Velocidade definida'
    );
    stringified = stringified.replace('setPoint', 'Valor definido');
    stringified = stringified.replace('setpoint', 'Setpoint');
    stringified = stringified.replace('operationCode', 'Código da operação');
    stringified = stringified.replace('reelFleet', 'Frota do carretel');
    stringified = stringified.replace('bandWith', 'Largura da faixa');
    stringified = stringified.replace('bandWidth', 'Largura da faixa');
    if(!stringified.includes('Overspeed')){
      stringified = stringified.replace('speed', 'Velocidade');
    }
    stringified = stringified.replace('latitudeInit', 'Latitude Inicial');
    stringified = stringified.replace('longitudeInit', 'Longitude inicial');
    stringified = stringified.replace('setPointPressao', 'Pressão definida');
    stringified = stringified.replace('fuel', 'Combustível');
    stringified = stringified.replace('lamina', 'Lâmina');
    stringified = stringified.replace('Vazao', 'Vazão estimado');
    stringified = stringified.replace('latitude', 'Latitude');
    stringified = stringified.replace('longitude', 'Longitude');
    stringified = stringified.replace(
      'idCodigoDeParada',
      'ID código de parada'
    );
    stringified = stringified.replace('codigoDeParada', 'Motivo da parada');
    stringified = stringified.replace('MediumBattery', 'Média da bateria');
    stringified = stringified.replace('MediumPressure', 'Média de pressão');
    stringified = stringified.replace('MediumSpeedAverage', 'Velocidade média');
    stringified = stringified.replace('bocal', 'Bocal');
    stringified = stringified.replace('idOperador', 'ID do operador');
    stringified = JSON.parse(stringified);
    return stringified;
  }

  alertasDaMotobomba(arrayAlertas: any){
    const alertMapping: any = [
      'Restrição filtro de ar',
      'Pressão baixo do óleo',
      'Pressão de saída baixa',
      'Nível baixo arrefecimento',
      'Temperatura alta arrefecimento',
      'Tensão baixa bateria',
      'Nível baixo combustível',
      'Nível baixo sucção',
      'Pressão adução exorbitante',
      // 'Timeout controle pressão',
      // 'Timeout controle velocidade',
      'Outros alertas',
      'Velocidade exorbitante',
      // 'Timeout controle velocidade pressão baixa',
      'Overspeed físico',
      'Alerta temperatura auxiliar',
      'Alerta pressao do gás',
      'Alerta temperatura do gás',
      'Alerta Pré válvula borboleta',
      'Alerta MAP',
      'Alerta MAT',
      'Alerta sonda lambda',
      'Alerta atuador do turbo',
      'Alerta injetor 1',
      'Alerta injetor 2',
      'Alerta injetor 3',
      'Alerta bobina 1',
      'Alerta bobina 2',
      'Alerta bobina 3',
      'Alerta bobina 4',
      'Alerta bobina 5',
      'Alerta bobina 6',
      'Alerta sincronismo motor',
      'Posição válvula borboleta 1',
      'Posição válvula borboleta 2',
      'Timeout conexão CAN',
      'Umidade do ar'];

    var alertaString = arrayAlertas
      .map((alerta: any, indice: any) => (alerta === '1' ? alertMapping[indice] : null))
      .filter((mensagem: any) => mensagem !== null && mensagem !== undefined)
      .join(' | ');
    return alertaString == '' ? 'Sem Alertas' : alertaString;
  }
}