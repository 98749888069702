<div fxLayout="column" class="d-block d-md-none">
  <div>
    <div *ngFor="let hectare of hectares" class="hectare-container my-1">
      <div>
        <p class="turno">
          {{hectare.turno}}
        </p>
      </div>
      <div>
        <p class="hectares">
          {{hectare.hectares}}
          <span *ngIf="hectare.hectares!=null">
            HA
          </span>
        </p>
      </div>
    </div>
  </div>
</div>