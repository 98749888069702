import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BASE_URL_PREMOCENTER_FRONT } from 'src/app/app.constants';
import { VerificarPermissoes } from 'src/app/model/verificar-premissao.model';
import { AlertService } from 'src/app/service/alert.service';
import { CaracteristicasService } from 'src/app/service/caracteristicas.service';
import { RedirectService } from 'src/app/service/redirect.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-caracteristicas',
  templateUrl: './lista-caracteristicas.component.html',
  styleUrls: ['./lista-caracteristicas.component.css']
})
export class ListaCaracteristicasComponent implements OnInit {

  public displayedColumns: string[] = [
    "vc_nome",
    "atribuicao",
    "ts_criado_em",
    "ts_atualizado_em",
    "control",
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public total: any;
  public formFilter!: FormGroup;
  LocalStorageId = localStorage.getItem("cliente_filtro");
  clients = JSON.parse(localStorage.getItem('cliente') || "{}")
  urlPremoplan = BASE_URL_PREMOCENTER_FRONT;
  length: any;
  caracteristica: any
  next: any;
  loading = true;
  validaResp = false;
  permissaoNecessaria = 'cadastrar_caracteristicas_adicionais_hidrometer'

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private caracteristicaService: CaracteristicasService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private appService: RedirectService) { }

  ngOnInit(): void {
    this.popular();
    this.onChange()
  }

  onChange() {
    this.loading = true
    this.validaResp = false
    this.formFilter.get("client")?.valueChanges.subscribe((clientId: any) => {
      localStorage.setItem("cliente_filtro", clientId);
      this.LocalStorageId = clientId
      this.appService.enviarMudancaDeCliente();
      this.popular()
    });
  }

  popular() {
    this.formFilter = this.formBuilder.group({
      client: [null, Validators.required],
    });
    if (this.LocalStorageId != null) {
      this.formFilter.get("client")?.setValue(this.LocalStorageId);
      this.caracteristicaService.AcharPorClientePaginate(this.LocalStorageId, 100).subscribe((data: any) => {
        if (data == null) {
          this.validaResp = true
          this.length = 0;
          this.caracteristica = [];
          this.dataSource.data = [];
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.next = '';
        } else {
          data.data.forEach((element: any) => {
            if (element.fk_equipamento != null) {
              element.atribuicao = "Equipamento"
            } else {
              element.atribuicao = "Operação"
            }
          });
          this.length = data.total;
          this.caracteristica = data.data;
          this.dataSource.data = this.caracteristica;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.next = data.next_page_url;
        }
        this.loading = false
      }, (err) => {
        if (err.statusText == "Unauthorized") {
          window.location.href = `${this.urlPremoplan}`
        }
      });
    }
    this.onChange()
  }

  delete(id: any) {
    Swal.fire({
      title: 'Excluir Caracteristica?',
      showCancelButton: true,
      confirmButtonColor: '#41B6E6',
      cancelButtonColor: '#df4747',
      confirmButtonText: 'Confirmar!'
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.caracteristicaService.Desativar(id).subscribe(carac => {
          this.alertService.success('Caracteristica excluída com sucesso!')
          this.popular()
        }, err => {
          this.alertService.error('Erro ao excluir caracteristica', err)
        })
      }
    })
  }

  trocarPagina(event: any) {
    this.caracteristicaService.Next(this.next).subscribe((data: any) => {
      this.caracteristica = this.caracteristica.filter(function (i: any) {
        return i;
      });
      data.data.forEach((element: any) => {
        if (element.fk_equipamento != null) {
          element.atribuicao = "Equipamento"
        } else {
          element.atribuicao = "Operação"
        }
      });
      this.caracteristica.push(...data.data)
      this.caracteristica.length = data.total
      this.dataSource.data = this.caracteristica;
      this.next = data.next_page_url
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public verificarPermissao(permissoesFuncionalidades: string[]): boolean {
    const permissaoUsuario = localStorage.getItem('permissoes');
    return VerificarPermissoes.temPermissao(permissoesFuncionalidades, permissaoUsuario)
  }
}
