import { Component, OnInit } from '@angular/core';
import { RedirectService } from 'src/app/service/redirect.service';

@Component({
  selector: 'app-botao-informativo-icones',
  templateUrl: './botao-informativo-icones.component.html',
  styleUrls: ['./botao-informativo-icones.component.css']
})
export class BotaoInformativoIconesComponent implements OnInit {

  constructor(
    private redirectService: RedirectService
  ) { }

  ngOnInit(): void {
    this.redirectService.descricaoElementoExpandidoSobreMapa.subscribe((elemento: string) => {
      if (elemento != 'botao-informativo-icones') this.closeContent()
    });
  }

  toggleContent() {
    this.modificarBotao('modal');
    this.modificarBotao('botao-branco');
    this.modificarBotao('botao-close');
    let elemento = document.getElementById('modal');
    if (elemento?.classList.contains('aberto')) this.redirectService.elementoExpandidoSobreMapa('botao-informativo-icones');
  }

  closeContent() {
    ['modal', 'botao-branco', 'botao-close'].forEach(elementId => {
      let elemento = document.getElementById(elementId);
      elemento?.classList.remove('aberto')
    });
  }

  modificarBotao(id: string) {
    let elemento = document.getElementById(id);
    elemento?.classList.toggle('aberto')
  }

}
