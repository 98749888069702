<div class="container">
    <div id="top" class="d-flex justify-content-center">
        <div class="align">

            <div class="col d-flex flex-row justify-content-center">
                <h1>Importar arquivo</h1>
                <div class="dropdown p-0">
                    <clr-icon shape="info-standard" class="info-icone"></clr-icon>
                    <div class="dropdown-content">
                        <p>Carregue arquivos para fornecer informações no mapa.</p>
                        <p>Por favor, faça o upload apenas de arquivos com a extensão .geoJson ou .json.</p>
                    </div>
                </div>
            </div>

            <form [formGroup]="formShape" class="mt-3 d-flex mb-2">
                <div class="col-6">
                    <div class="d-flex justify-content-center">
                        <span class="">Usina:</span>
                        <select class="filtro-listas ml-2" style="width: 50%;" formControlName="fk_cliente">
                            <option [value]="cliente.id" *ngFor="let cliente of clients">
                                {{cliente.nome}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-6">
                    <div class="d-flex justify-content-center">
                        <span class="">Tipo do arquivo:</span>
                        <select class="filtro-listas ml-2" style="width: 50%;" formControlName="tipo_arquivo">
                            <option value="shape">Shape</option>
                            <option value="linhas"> Linhas </option>
                        </select>
                    </div>
                </div>
            </form>

            <div class="d-flex justify-content-center">
                <input class="hidden-file file-upload" #fileInput type="file" (change)="onFileSelected($event)"
                    multiple />
                <div class="well my-drop-zone mt-2" (click)="fileInput.click()" fxLayout="column"
                    fxLayoutAlign="center center">
                    <div class="d-flex justify-content-center">
                        <img src="assets/icones-gerais/importar.svg" alt="Importar" class="icone-importar">
                    </div>
                    <h2>Clique aqui para enviar o arquivo</h2>
                </div>
            </div>

            <div class="row file-upload mt-3" [hidden]="this.file.length==0">
                <div class="col-6 pl-0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th colspan="2" scope="col">Nome do arquivo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let file of fileName">
                                <td>{{file}}</td>
                                <td class="iconeDireita pb-0">
                                    <button (click)="tirarFile(file)">
                                        <img src="assets/icones-gerais/apagar-arquivo.svg" alt="Apagar arquivo" class="icone-apagar-arquivo">
                                        </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-6 pr-0">
                    <div class="match-parent" id="map"></div>
                </div>
            </div>

            <div class="row">
                <div class="col div-upload d-flex justify-content-end mt-3">
                    <button mat-raised-button color="primary" class="botao-criar" [disabled]="loading"
                        (click)="upload()">
                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"
                            *ngIf="loading"></span>
                        Fazer upload
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>