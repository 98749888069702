<div *ngIf="informacoes && !loader">
    <p>Estado do esticamento: {{informacoes.en_estado ? informacoes.en_estado : 'Estado não encontrado'}}</p>
    <p id="data-esticamento">Data do esticamento: {{informacoes.ts_data_esticamento + 'Z' | date: "dd/MM/yyyy
        HH:mm:ss":"UTC -3"}}</p>
    <p id="data-finalizacao">Data da finalização: {{informacoes.ts_data_finalizacao + 'Z' | date: "dd/MM/yyyy
        HH:mm:ss":"UTC -3"}}</p>
    <p *ngIf="data.tipo == 'dia'">Data do fragmento da faixa: {{informacoes.dt_data | date: "dd/MM/yyyy"}}</p>
    <p *ngIf="data.tipo == 'completa'">Tempo decorrido: {{informacoes.it_tempo_total}}</p>
    <p>Número do esticamento: {{informacoes.it_numero_esticamento}}°</p>
    <p>Largura da faixa: {{informacoes.it_largura_faixa}} m</p>
    <p>Turno em que foi esticado: {{informacoes.nome_turno ? informacoes.nome_turno : 'Carregando...'}}</p>
    <p>Metros esticados: {{informacoes.fl_metros_esticados}} m</p>
    <p>Hectares estimados: {{informacoes.fl_hectares_estimados.toFixed(2)}} HA</p>
    <p>Hectares irrigados: {{informacoes.fl_hectares_irrigados}} HA</p>
    <p>Frota do trator: {{informacoes.frota_trator ? informacoes.frota_trator : 'Carregando...'}}</p>
    <p>Frota do carretel: {{informacoes.frota_carretel ? informacoes.frota_carretel : 'Carregando...'}}</p>
    <p>OS: {{ informacoes.service_id_operacao ? informacoes.service_id_operacao : 'Carregando...' }}</p>
    <div id="mensagem" *ngIf="mostrarMensagem">
        <p>Não há dados de telemetria disponíveis para criar uma faixa</p>
    </div>
    <div id="legend" *ngIf="!mostrarMensagem">
        Legenda:
        <div class="d-flex flex-column align-items-start" *ngIf="tipoMapa=='pressao'">
            <div class="d-flex flex-column align-items-start ng-star-inserted">
                <div class="d-flex flex-row" *ngFor="let legenda of informacoes.legendaPressao">
                    <div class="legend-box" [style.background]="legenda.cor"></div> {{legenda.texto}}
                </div>
            </div>
        </div>
        <div class="d-flex flex-column align-items-start" *ngIf="tipoMapa=='lamina'">
            <div class="d-flex flex-column align-items-start ng-star-inserted">
                <div class="d-flex flex-row" *ngFor="let legenda of informacoes.legendaLamina">
                    <div class="legend-box" [style.background]="legenda.cor"></div> {{legenda.texto}}
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="loader" class="d-flex align-items-center justify-content-center">
    <div class="spinner"></div>
</div>
<div [hidden]="loader || mostrarMensagem" id="google_maps" style="margin:0;"></div>