<div id="botaoInformativoIcones" class="botao-expand" style="z-index: 1;">
    <div class="icon" (click)="toggleContent()">
        <img id="botao-open" src="assets/icones-botoes-acao-mapa/informativo_icones.svg"
            alt="Ícone de informativo de ícones" matTooltip="Informativo de ícones" matTooltipPosition="after"
            matTooltipShowDelay="500">

        <img src="assets/icones-botoes-acao-mapa/modal-aberto.svg" id="botao-branco" alt="Ícone de fechar"
            style="z-index: 2" class="fechado me-1">

        <img src="assets/icones-botoes-acao-mapa/close.svg" id="botao-close" alt="Ícone de fechar" style="z-index: 3"
            class="fechado">
    </div>
</div>

<div class="bg-white modal-fechado content" id="modal">
    <app-dialog-informacoes-icones></app-dialog-informacoes-icones>
</div>