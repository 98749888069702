// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  BASE_URL_PREMOCENTER_FRONT: "https://homologapremocenter.grupoagtech.com",
  BASE_URL_PREMOCENTER: "https://homologapremocenterback.grupoagtech.com/api/",
  BASE_URL_HIDROMETER_FRONT: "http://localhost:4200/hidrometer",
  // BASE_URL_HIDROMETER: "https://homologahidrometerback.grupoagtech.com/api/",
  BASE_URL_HIDROMETER: "http://127.0.0.1:8000/api/",
  BASE_URL_HIDROMETER_V2: "http://homologahidrometer.grupoagtech.com",
  BASE_URL_HIDROMETER_RELATORIOS: "https://hidrometerrelatorioshomolog.grupoagtech.com/api/",
  BASE_URL_CRONOS: "https://homologacronoscore.grupoagtech.com/api/",
  BASE_URL_HIDROMETER_BACK: "homologahidrometerws.grupoagtech.com",

  MAPBOX_TOKEN: "pk.eyJ1IjoiZ2V0cXVlaXJveiIsImEiOiJjanJpMXV6cTgwNDI4M3pvMmJwMng0ejlwIn0.Z9wEpMLKwe7Eb_uSudJM8A"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
