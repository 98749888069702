<div class="container h-100">
    <div class="d-flex flex-wrap justify-content-center">
        <div>
            <h4>Pressão do carretel</h4>
        </div>
        <div class="dropdown">
            <clr-icon shape="info-standard" class="info-icone cor-icone"></clr-icon>
            <div class="dropdown-content">
                <p class="m-0">Exibir os valores da pressão enviados pelo carretel, podendo ser exibido todas as
                    telemetrias, a média por dia e a média por hora.</p>
            </div>
        </div>
    </div>

    <div [hidden]="ocultarGrafico" id="grafico" class="d-flex container-grafico h-75 p-2">
        <canvas class="h-100" id="graficoPressao" style="background-color: #fff;"></canvas>
    </div>
    <div class="d-flex flex-wrap justify-content-end" *ngIf="mostrarBotoes">
        <mat-button-toggle-group class="ms-2" [(ngModel)]="tipoDeInformacaoAExibir" (ngModelChange)="gerarGrafico()">
            <mat-button-toggle class="font" value="mediaPorHora">Média/Hora</mat-button-toggle>
            <mat-button-toggle class="font" value="mediaPorDia">Média/Dia</mat-button-toggle>
            <mat-button-toggle class="font" value="todasMensagens">Todas telemetrias</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <div class="mensagem">
        <p *ngIf="!contemInformacoes" style="text-align: center;">Não há dados no período</p>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <app-erro *ngIf="erro"></app-erro>
</div>