<div class="d-flex justify-content-center" *ngIf="flag==1">
  <h4>Telemetrias sem ponto A e B</h4>
  <div class="dropdown">
      <clr-icon shape="info-standard" class="info-icone cor-icone"></clr-icon>
      <div class="dropdown-content">
          <p>Exibir as telemetrias que não tiveram marcação de ponto A e B. No campo "Frota", caso o equipamento
            que enviou a mensagem não tenha nenhum vinculo com operação a será pego a frota que veio na mensagem gerada pelo equipamento.
          </p>
      </div>
  </div>
</div>
<table class="table" *ngIf="flag==1">
    <thead>
        <tr>
            <th>Mensagem Id</th>
            <th>Comunicador</th>
            <th>Data</th>
            <th>Frota</th>
            <th>OS</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let mensagem of telemetrias">
            <td>{{mensagem.sr_id}}</td>
            <td>{{mensagem.vc_mobileId}}</td>
            <td>{{mensagem.ts_timestamp_mensagem + 'Z'  | date: "dd/MM/yyyy HH:mm:ss" }}</td>
            <td>{{mensagem.vc_frota ? mensagem.vc_frota : 'Frota da mensagem '+mensagem.fleet }}</td>
            <td>{{mensagem.it_service_id ? mensagem.it_service_id : 'Não foi póssivel encontrar a OS'}}</td>
        </tr>
    </tbody>
</table>

<div class="d-flex justify-content-center" *ngIf="flag==2">
    <h4>Mensagens de equipamentos sem vinculo com OS</h4>
    <div class="dropdown">
        <clr-icon shape="info-standard" class="info-icone cor-icone"></clr-icon>
        <div class="dropdown-content">
            <p>Exibi as mensagens das quais não foram possíveis encontrar a operação do equipamento que a enviou.</p>
        </div>
    </div>
  </div>
<table class="table" *ngIf="flag==2">
    <thead>
        <tr>
            <th>Equipamento</th>
            <th>Comunicador</th>
            <th>Descrição</th>
            <th>Frota</th>
            <th>Data</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let mensagem of telemetrias">
            <td>{{mensagem.equipamento}}</td>
            <td>{{mensagem.comunicador}}</td>
            <td>{{mensagem.descricao}}</td>
            <td>{{mensagem.frota}}</td>
            <td>{{mensagem.ts_timestamp_mensagem + 'Z' | date: "dd/MM/yyyy HH:mm:ss" }}</td>
        </tr>
    </tbody>
</table>

<div *ngIf="loading" style="width: auto; height: 60vh;" fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="loading" class="spinner"></div>
</div>
