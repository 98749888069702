import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }
  
  private showAlert(title: string, message: string, icon: SweetAlertIcon): void {
    Swal.fire(title, message, icon);
  }

  public success(message?: string, title?: string): void {
    this.showAlert(title!, message!, 'success');
  }

  public error(message?: string, title?: string): void {
    this.showAlert(title!, message!, 'error');
  }

  public warning(message?: string, title?: string): void {
    this.showAlert(title!, message!, 'warning');
  }

  public exibirMensagemErro(erro: any, mensagem?: string): void
  {
    if (erro.status === 503 && erro.error.mensagem === 'Serviço indisponível devido à manutenção programada.') {
      this.programmedBackupMessage();
    } else {
      this.warning(mensagem || erro.error.mensagem || erro.error.status);
    }
  }

  public programmedBackupMessage(): void
  {
    Swal.fire({
      title: `<h1> Ops! </h1><h3 class='m-0'> Estamos passando por uma manutenção programada, que acontece todos os dias da 01h55 às 03h30 (no horário de Brasília).
                <b>Apenas</b> os seguintes recursos ficam indisponíveis durante esse período:
                - Cadastro, edição e exclusão de equipamentos
                - Cadastro, edição, atualização de status e exclusão de operações</h3>`,
      imageUrl: '/assets/engrenagem.png',
      imageWidth: '18vh',
      imageHeight: '18vh'
    });
  }
}
