import { FormatarHoraService } from './../../service/formatar-hora.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import pluginDataLabels from 'chartjs-plugin-datalabels';
import { ChartOptions, ChartType } from 'chart.js';
import { GraficosService } from 'src/app/service/graficos.service';
import { BaseChartDirective } from 'ng2-charts';

@Component({
	selector: 'app-tempo-efetivo-turno-motobomba',
	templateUrl: './tempo-efetivo-turno-motobomba.component.html',
	styleUrls: ['./tempo-efetivo-turno-motobomba.component.css']
})
export class TempoEfetivoTurnoMotobombaComponent implements OnInit {

	@ViewChild(BaseChartDirective) chart?: BaseChartDirective;
	idCliente = localStorage.getItem('cliente_filtro');
	dataInicio = localStorage.getItem('DateStartGraficos');
	dataFinal = localStorage.getItem('DateEndGraficos');
	informacoesTabela = [] as any;
	tabela: any;
	loading = true;
	validaResp = false;
	public displayedColumns: string[] = [
		"turnos",
		"horas"
	];

	public pieChartOptions: ChartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			datalabels: {
				display: false,
			}
		},
		legend: {
			display: false
		},
		tooltips: {
			callbacks: {
				label: function (tooltipItem: any, data: any) {
					const segundos = data.datasets[0].data.filter((element: any, index: any) => {
						return tooltipItem.index == index
					})
					let hora = Math.floor(segundos / 3600);
					let minutos = '0' + (Math.floor((segundos - (hora * 3600)) / 60));
					let segundo = '0' + (Math.floor(segundos % 60));
					return hora + ":" + minutos.substr(-2) + ":" + segundo.substr(-2);
				}
			}
		}

	};
	public pieChartLabels = [] as any;
	public pieChartData = [] as any;
	public pieChartType: ChartType = 'pie';
	public pieChartLegend = true;
	public pieChartPlugins = [pluginDataLabels];
	public pieChartColors = [
		{
			backgroundColor: [
				'#8C51A5', '#CB5E98', '#F47B8A', '#FFA37E', '#FFD285',
				'#FFFFA5', '#B9E49F', '#78C59B', '#24635B', '#00838D',
				'#089951', '#0DA600', '#8DB309', '#BFA500', '#CC8B0A',
				'#D95900', '#E6270B', '#F20084', '#B20CF9', '#2700FD'
			],
		},
	];
	erro = false;
	static formatarSegundosEmHoras: any;
	@Input() ocultarGrafico: boolean = false

	constructor(
		private graficosService: GraficosService,
		private formatarHora: FormatarHoraService
	) { }

	ngOnInit(): void {
		this.mensagensPorTurno()
	}

	mensagensPorTurno() {
		this.graficosService.graficoTempoEfetivoTurnoMotobomba(this.dataInicio, this.dataFinal, this.idCliente).subscribe((resposta: any) => {
			if (resposta && resposta.turnos && resposta.segundosTrabalhados) {
				this.loading = false;
				const somaDosTempos = resposta.segundosTrabalhados.reduce((accumulator: any, value: any) => accumulator + value, 0);
				if (somaDosTempos == 0) {
					this.validaResp = true;
				} else {
					this.pieChartLabels = resposta.turnos;
					this.pieChartData = resposta.segundosTrabalhados;
					this.pieChartLabels.forEach((turno: any, index: any) => {
						const tempo = resposta.segundosTrabalhados[index];
						this.informacoesTabela.push({
							'turno': turno,
							'horas': this.formatarSegundosEmHoras(tempo),
							'id': index,
							'hidden': false,
							'cor': this.pieChartColors[0]['backgroundColor'][index]
						});
					});
					this.tabela = this.informacoesTabela;
				}
			}
		}, err => {
			this.loading = false;
			this.erro = true;
		});
	}

	selecionarCelula(id: any) {
		var indice: any = this.chart?.datasets[0]
		var indiceMeta: any = Object.getOwnPropertyNames(indice['_meta']);
		indiceMeta = indiceMeta[0];
		var id_dado = parseInt(id);
		if (this.tabela[id_dado].hidden) {
			indice['_meta'][indiceMeta]['data'][id_dado].hidden = false
			this.tabela[id_dado].hidden = false;
		} else {
			indice['_meta'][indiceMeta]['data'][id_dado].hidden = true
			this.tabela[id_dado].hidden = true;
		}
		this.chart?.update();
	}

	formatarSegundosEmHoras(segundos: any) {
		return this.formatarHora.formatarSegundosParaHora(segundos)
	}
}