import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { LogrecolhimentosService } from 'src/app/service/logrecolhimentos.service';
import pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { GraficosService } from 'src/app/service/graficos.service';

@Component({
  selector: 'app-produtividade-carretel',
  templateUrl: './produtividade-carretel.component.html',
  styleUrls: ['./produtividade-carretel.component.css']
})
export class ProdutividadeCarretelComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  idCliente = localStorage.getItem('cliente_filtro');
  dataInicio = localStorage.getItem('DateStartGraficos');
  dataFinal = localStorage.getItem('DateEndGraficos');
  fuso = localStorage.getItem('horario') == 'utc' ? 'utc' : 'brt';
  carretelMaisEfetivas: any;
  carretelMenosEfetivas: any;
  tempoCarretel: any;
  mostrarSelect = false;
  tipoGrafico = 1;
  informacoesTabela = [] as any;
  tabela: any;
  @Input() ocultarGrafico: boolean = false

  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      }
    },
    legend: {
      display: false
    }
  };
  public pieChartLabels = [] as any;
  public pieChartData = [] as any;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [
        '#8C51A5', '#CB5E98', '#F47B8A', '#FFA37E', '#FFD285',
        '#FFFFA5', '#B9E49F', '#78C59B', '#24635B', '#00838D',
        '#089951', '#0DA600', '#8DB309', '#BFA500', '#CC8B0A',
        '#D95900', '#E6270B', '#F20084', '#B20CF9', '#2700FD'
      ],
    },
  ];
  loading = true;
  validaResp = false;
  public displayedColumns: string[] = [
    "carretel",
    "hectares"
  ];
  erro = false;
  constructor(
    private graficosService: GraficosService
  ) { }

  ngOnInit(): void {
    this.popularGrafico();
  }

  popularGrafico() {
    this.graficosService.graficoProdutividadeCarretel(this.dataInicio, this.dataFinal, this.idCliente, this.fuso).subscribe((resposta: any) => {
      this.loading = false
      const indicesResposta = Object.keys(resposta);
      if (resposta.frotas && (resposta.frotas.length === 0)) {
        this.validaResp = true;
      } else {
        this.validaResp = false;
      }
      if (indicesResposta.length != 0) {
        this.tempoCarretel = resposta;

        if (indicesResposta.includes('carretelMaisEfetivas')) {
          this.carretelMaisEfetivas = resposta.carretelMaisEfetivas;
          this.carretelMenosEfetivas = resposta.carretelMenosEfetivas;
          this.mostrarSelect = true;
          this.mudarGrafico();
        } else {
          const carretelFrotas = this.tempoCarretel.frotas;
          const carretelTotalHectares = this.tempoCarretel.totalHectares;
          this.pieChartLabels = carretelFrotas;
          this.pieChartData = carretelTotalHectares;
          this.pieChartLabels.forEach((frota: any, index: any) => {
            this.informacoesTabela.push({ 'frota': frota, 'hectares': this.pieChartData[index], 'id': index, 'hidden': false, cor: this.pieChartColors[0]['backgroundColor'][index] })
          });
          this.tabela = this.informacoesTabela;
        }
      }
    }, err => {
      this.loading = false;
      this.erro = true;
    })
  }

  mudarGrafico() {
    this.tipoGrafico
    let filtroOs = this.carretelMaisEfetivas

    if (this.tipoGrafico == 1) {
      filtroOs = this.carretelMenosEfetivas
    }

    this.informacoesTabela = [];

    const frotas = filtroOs.frotas;
    const totalHectares = filtroOs.totalHectares;
    this.pieChartLabels = frotas;
    this.pieChartData = totalHectares;
    this.pieChartLabels.forEach((frota: any, index: any) => {
      this.informacoesTabela.push({ 'frota': frota, 'hectares': this.pieChartData[index], 'id': index, 'hidden': false, cor: this.pieChartColors[0]['backgroundColor'][index] })
    });
    this.tabela = this.informacoesTabela;
  }

  selecionarCelula(id: any) {
    var indice: any = this.chart?.datasets[0]
    var indiceMeta: any = Object.getOwnPropertyNames(indice['_meta']);
    indiceMeta = indiceMeta[0];
    var id_dado = parseInt(id);
    if (this.tabela[id_dado].hidden) {
      indice['_meta'][indiceMeta]['data'][id_dado].hidden = false
      this.tabela[id_dado].hidden = false;
    } else {
      indice['_meta'][indiceMeta]['data'][id_dado].hidden = true
      this.tabela[id_dado].hidden = true;
    }
    this.chart?.update();
  }
}
