import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/service/alert.service';
import { ExportacaoService } from 'src/app/service/exportacao.service';

@Component({
    selector: 'app-dialog-editar-faixas',
    templateUrl: './dialog-editar-faixas.component.html',
    styleUrls: ['./dialog-editar-faixas.component.css']
})
export class DialogEditarFaixasComponent implements OnInit {

    public BocalForm!: FormGroup;
    os = this.data[1];
    carreteis = this.data[2].carretel;
    validacao = [] as any;
    loading = false;

    constructor(
        public dialogRef: MatDialogRef<DialogEditarFaixasComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private alertService: AlertService,
        private exportacaoService: ExportacaoService
    ) { }

    ngOnInit(): void {
        this.Form();
        this.OnChange();
    }

    Form() {
        this.BocalForm = this.fb.group({
            vc_email_enviar: [localStorage.getItem('usuario_email'), [Validators.required, Validators.email]],
            it_id_cliente_premocenter: [localStorage.getItem('cliente_filtro'), Validators.required],
            it_id_operacao_hidrometer: [this.data[2].sr_id, Validators.required],
            en_tipo_repintura: [null, Validators.required],
            fl_maximo: [null, Validators.required],
            fl_minimo: [null, Validators.required],
            fl_bocal: [null],
        });
    }

    OnChange() {
        this.BocalForm.get('en_tipo_repintura')?.valueChanges.subscribe(valor => {
            this.validacao = valor
        })
    }

    enviarRepintarFaixas(): void {
        this.loading = true;
        var dadosDaExportacao = this.BocalForm.value;
        dadosDaExportacao.it_id_solicitado_por = localStorage.getItem('usuario');
        this.exportacaoService.adicionarRepintarFaixasParaFila(dadosDaExportacao).subscribe((resposta: any) => {
            this.loading = false;
            this.alertService.success('Sucesso ao solicitar repintura das faixas')
            this.dialogRef.close()
        }, (erro: any) => {
            this.loading = false;
            this.alertService.error('Erro ao solicitar repintura das faixas')
            this.dialogRef.close()
        })
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
