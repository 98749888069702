<main>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="60" viewBox="0 0 91 80" fill="none">
            <g id="logo--circle">
                <path
                    d="M36 67.9709C32.1534 66.4431 34.4581 67.3596 33.5 66.9709C29.9683 65.5385 27.2288 63.7855 24.5258 61.1387C21.8228 58.492 19.6786 55.3498 18.2158 51.8916C16.7529 48.4334 16 44.7269 16 40.9838C16 37.2407 16.7529 33.5342 18.2158 30.0761C19.6786 26.6179 21.8228 23.4757 24.5258 20.8289C27.2288 18.1821 30.4377 16.0826 33.9694 14.6501C36 14 33.9694 14.6501 36 14V22C33.4863 23.0195 33.4863 23.0195 36 22C33.4292 23.0427 32.0933 24.3855 30.1256 26.3122C28.158 28.2389 26.5972 30.5263 25.5323 33.0436C24.4674 35.561 23.9194 38.2591 23.9194 40.9838C23.9194 43.7086 24.4674 46.4067 25.5323 48.924C26.5972 51.4414 28.158 53.7287 30.1256 55.6554C32.0933 57.5821 33.4292 58.4282 36 59.4709C36 62.9412 31.6336 57.7 36 59.4709V67.9709Z"
                    fill="#2FB4E5" />
                <path
                    d="M54 67.9709C57.8466 66.4431 55.5419 67.3596 56.5 66.971C60.0317 65.5385 62.7712 63.7856 65.4742 61.1389C68.1772 58.4921 70.3214 55.35 71.7842 51.8919C73.2471 48.4337 74 44.7273 74 40.9843C74 37.2412 73.2471 33.5348 71.7842 30.0767C70.3214 26.6186 68.1772 23.4764 65.4742 20.8297C62.7712 18.183 59.5623 16.0835 56.0306 14.6511C54 14.0009 56.0306 14.6511 54 14.0009V22.0008C56.5137 23.0203 56.5137 23.0203 54 22.0008C56.5708 23.0435 57.9067 24.3863 59.8744 26.313C61.842 28.2396 63.4028 30.5269 64.4677 33.0442C65.5326 35.5615 66.0806 38.2596 66.0806 40.9843C66.0806 43.709 65.5326 46.407 64.4677 48.9244C63.4028 51.4417 61.842 53.7289 59.8744 55.6556C57.9067 57.5823 56.5708 58.4284 54 59.4711C54 62.9413 58.3664 57.7001 54 59.4711L54 67.9709Z"
                    fill="#2FB4E5" />
                <path d="M20 36.5H35L33 41L31 45.5H20V36.5Z" fill="#2FB4E5" />
                <path d="M71 36.5H55L57.1333 41L59.2667 45.5H71V36.5Z" fill="#2FB4E5" />
                <circle cx="45" cy="46.5" r="12" fill="#2FB4E5" />
                <path d="M45 23.5L55 40H35L45 23.5Z" fill="#2FB4E5" />
            </g>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 709 98" fill="none">
            <g id="logo--circle-1">
                <path d="M1 95V7.5H22V40.5H52.5V7.5H71.5V95H52.5V60H22V95H1Z" fill="#7C7B7B" />
                <path d="M90.5 95V27.5H108.5V95H90.5Z" fill="#7C7B7B" />
                <path d="M108.5 19H90.5V1H108.5V19Z" fill="#7C7B7B" />
                <path d="M175 4.5V30.5V43L177.5 95H192V4.5H175Z" fill="#7C7B7B" />
                <path d="M1 95V7.5H22V40.5H52.5V7.5H71.5V95H52.5V60H22V95H1Z" stroke="#7C7B7B" />
                <path d="M90.5 95V27.5H108.5V95H90.5Z" stroke="#7C7B7B" />
                <path d="M108.5 19H90.5V1H108.5V19Z" stroke="#7C7B7B" />
                <path d="M175 4.5V30.5V43L177.5 95H192V4.5H175Z" stroke="#7C7B7B" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M155.5 96C174.554 96 190 80.33 190 61C190 41.67 174.554 26 155.5 26C136.446 26 121 41.67 121 61C121 80.33 136.446 96 155.5 96ZM156.472 79C165.865 79 173.479 71.165 173.479 61.5C173.479 51.835 165.865 44 156.472 44C147.079 44 139.465 51.835 139.465 61.5C139.465 71.165 147.079 79 156.472 79Z"
                    fill="#7C7B7B" />
                <path
                    d="M229 96H212.5H211V26.5H224V36C226.848 29.341 229.471 27.25 236 26.5H251V45H236C231.2 46.4196 229.761 48.2486 229 53V96Z"
                    fill="#7C7B7B" />
                <path
                    d="M356 96H336.5H335L349 8H369L393 59.5L415 8H434.5L448.5 96H428.5L420 38.5L395 93H389L363.5 38.5L356 96Z"
                    fill="#7C7B7B" />
                <path
                    d="M579.5 79V94.5H553.5C545.873 91.0723 543.516 87.9022 542 80.5V44H530V38.5L555.5 10.5H560V27.5H578V44H560V72C560.227 76.4191 561.406 77.8701 565 79H579.5Z"
                    fill="#7C7B7B" />
                <path
                    d="M686.5 94.5H668V27.5H680.5L682 35C683.693 29.7233 685.808 28.1238 691.5 27.5H708.5V44H693.5C688.554 45.6924 686.628 47.3391 686.5 53V94.5Z"
                    fill="#7C7B7B" />
                <path
                    d="M229 96H212.5H211V26.5H224V36C226.848 29.341 229.471 27.25 236 26.5H251V45H236C231.2 46.4196 229.761 48.2486 229 53V96Z"
                    stroke="#7C7B7B" />
                <path
                    d="M356 96H336.5H335L349 8H369L393 59.5L415 8H434.5L448.5 96H428.5L420 38.5L395 93H389L363.5 38.5L356 96Z"
                    stroke="#7C7B7B" />
                <path
                    d="M579.5 79V94.5H553.5C545.873 91.0723 543.516 87.9022 542 80.5V44H530V38.5L555.5 10.5H560V27.5H578V44H560V72C560.227 76.4191 561.406 77.8701 565 79H579.5Z"
                    stroke="#7C7B7B" />
                <path
                    d="M686.5 94.5H668V27.5H680.5L682 35C683.693 29.7233 685.808 28.1238 691.5 27.5H708.5V44H693.5C688.554 45.6924 686.628 47.3391 686.5 53V94.5Z"
                    stroke="#7C7B7B" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M291.5 98C312.211 98 329 81.2107 329 60.5C329 39.7893 312.211 23 291.5 23C270.789 23 254 39.7893 254 60.5C254 81.2107 270.789 98 291.5 98ZM291.5 80C301.717 80 310 71.7173 310 61.5C310 51.2827 301.717 43 291.5 43C281.283 43 273 51.2827 273 61.5C273 71.7173 281.283 80 291.5 80Z"
                    fill="#7C7B7B" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M490.5 97C506.64 97 520.334 86.5237 525.151 72H506.336C503.167 78.0002 497.484 82 491 82C482.231 82 474.927 74.6849 473.326 65H508.674H523H526.725C526.907 63.5254 527 62.0236 527 60.5C527 40.3416 510.658 24 490.5 24C470.342 24 454 40.3416 454 60.5C454 80.6584 470.342 97 490.5 97ZM507.648 53C504.951 45.3702 498.513 40 491 40C483.487 40 477.049 45.3702 474.352 53H507.648Z"
                    fill="#7C7B7B" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M620.5 97C636.64 97 650.334 86.5237 655.151 72H636.336C633.167 78.0002 627.484 82 621 82C612.231 82 604.927 74.6849 603.326 65H638.674H653H656.725C656.907 63.5254 657 62.0236 657 60.5C657 40.3416 640.658 24 620.5 24C600.342 24 584 40.3416 584 60.5C584 80.6584 600.342 97 620.5 97ZM637.648 53C634.951 45.3702 628.513 40 621 40C613.487 40 607.049 45.3702 604.352 53H637.648Z"
                    fill="#7C7B7B" />
            </g>
        </svg>
    </div>
</main>