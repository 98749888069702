import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Event, NavigationStart, Params, Router } from '@angular/router';
import { BASE_URL_PREMOCENTER_FRONT } from 'src/app/app.constants';
import { AlertService } from 'src/app/service/alert.service';
import { LayoutsService } from 'src/app/service/layouts.service';
import * as _ from "underscore";
import { DialogLayoutComponent } from '../dialog-layout/dialog-layout.component';
import { ReloadGraficosService } from 'src/app/service/reload-graficos.service';

@Component({
  selector: 'app-criar-layouts',
  templateUrl: './criar-layouts.component.html',
  styleUrls: ['./criar-layouts.component.css']
})
export class CriarLayoutsComponent implements OnInit {
  premocenter = BASE_URL_PREMOCENTER_FRONT
  modelo: any;
  dados!: any;
  mapa = false;
  formLayout!: FormGroup;
  token!: any;
  usuario!: any;
  clientes!: any;
  grupos!: any;
  componentes = [] as any;
  disable = false;
  area1 = [] as any;
  area2 = [] as any;
  area3 = [] as any;
  area4 = [] as any;
  area5 = [] as any;
  area6 = [] as any;
  teste = [] as any
  itensLayout = [] as any
  none: any = 'none';
  image: any = "url('../../assets/add.png')";
  temSelecionado1 = false;
  temSelecionado2 = false;
  temSelecionado3 = false;
  temSelecionado4 = false;
  temSelecionado5 = false;
  temSelecionado6 = false;
  nome_classe: any;
  id: any;
  nomeArrayC: any = 'arrayComponentes';
  nomeArrayT: any = 'tempoComponentes';
  numeros = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private dashboardService: LayoutsService,
    private router: Router,
    private serviceReload: ReloadGraficosService
  ) {
    this.route.params.subscribe((params: Params) => {
      this.modelo = params.modelo
    });
  }

  ngOnInit(): void {
    this.localStorage();
    this.createForm();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.excluirLocalStorage();
      }
    });
    this.excluirLocalStorage();
  }

  localStorage() {
    this.token = localStorage.getItem('token');
    this.usuario = localStorage.getItem('usuario');
    this.clientes = localStorage.getItem('cliente');
    this.grupos = localStorage.getItem('grupos');
    this.clientes = JSON.parse(this.clientes)
    this.grupos = JSON.parse(this.grupos)
  }

  excluirLocalStorage() {
    localStorage.removeItem('arrayComponentes');
    localStorage.removeItem('tempoComponentes');
    this.numeros.forEach((numero: any) => {
      localStorage.removeItem('arrayComponentes' + numero);
      localStorage.removeItem('tempoComponentes' + numero);
    });
    this.temSelecionado1 = false;
    this.temSelecionado2 = false;
    this.temSelecionado3 = false;
    this.temSelecionado4 = false;
    this.temSelecionado5 = false;
    this.temSelecionado6 = false;
  }

  createForm() {
    this.formLayout = this.formBuilder.group({
      token: [this.token],
      vc_nome: [null, Validators.required],
      vc_tipo: [null, Validators.required],
      vc_grupo: [null],
      vc_cliente: [null]
    })
  }

  cadastrar() {
    var layout;
    if (this.formLayout.value.vc_tipo == 1) {
      layout = {
        "token": this.token,
        "vc_nome": this.formLayout.value.vc_nome,
        "fk_layout": this.modelo,
        "fk_usuario": this.usuario
      }
    } else if (this.formLayout.value.vc_tipo == 2) {
      layout = {
        "token": this.token,
        "vc_nome": this.formLayout.value.vc_nome,
        "fk_layout": this.modelo,
        "it_id_grupo_premocenter": this.formLayout.value.vc_grupo
      }
    } else {
      layout = {
        "token": this.token,
        "vc_nome": this.formLayout.value.vc_nome,
        "fk_layout": this.modelo,
        "fk_cliente": this.formLayout.value.vc_cliente
      }
    }

    this.dashboardService.Cadastrar(layout).subscribe((response: any) => {
      this.componentes.forEach((componente: any) => {

        var itemLayout = {
          "token": this.token,
          "it_area": componente.area,
          "vc_componente": componente.componente,
          "it_tempo_componente": componente.tempo,
          "fk_layout_personalizado": response.sr_id
        }
        this.itensLayout.push(itemLayout)

      });
      this.dashboardService.CadastrarItemLayout(this.itensLayout, this.token).subscribe(response => {
        this.alertService.success('Layout cadastrado com sucesso!')
        this.serviceReload.recarregarListaGraficos()
        this.router.navigate(['/hidrometer/meusLayout/listar']);
      }, error => {
        this.dashboardService.Desativar(response.sr_id, this.token).subscribe(response => {
          this.alertService.error('Erro ao cadastrar layout!')
        },
          error => {
            if (error.status === 401) {
              window.location.href = `${this.premocenter}`
            }
          })
        if (error.status === 401) {
          window.location.href = `${this.premocenter}`
        }
      })
    },
      error => {
        this.alertService.error('Erro ao cadastrar layout!')
      })
  }

  public openDialog(area: any, event: any): void {
    if (event.srcElement.localName == 'li') {
      this.nome_classe = event.path[2].classList[0];
      this.id = event.path[2].id;
    } else if (event.srcElement.localName == 'ul') {
      this.nome_classe = event.path[1].classList[0];
      this.id = event.path[1].id;
    } else {
      this.nome_classe = event.srcElement.classList[0];
      this.id = event.srcElement.id;
    }

    localStorage.setItem('nome_div_modelo', '');
    localStorage.setItem('nome_div_modelo', this.nome_classe + '.' + this.id);

    var dialog: any
    var width = "510px"

    if (area == 1) {
      dialog = this.dialog.open(DialogLayoutComponent, {
        minWidth: width,
        data: this.area1,
        disableClose: true
      });
    } else if (area == 2) {
      dialog = this.dialog.open(DialogLayoutComponent, {
        minWidth: width,
        data: this.area2,
        disableClose: true
      });
    } else if (area == 3) {
      dialog = this.dialog.open(DialogLayoutComponent, {
        minWidth: width,
        data: this.area3,
        disableClose: true
      });
    } else if (area == 4) {
      dialog = this.dialog.open(DialogLayoutComponent, {
        minWidth: width,
        data: this.area4,
        disableClose: true
      });
    } else if (area == 5) {
      dialog = this.dialog.open(DialogLayoutComponent, {
        minWidth: width,
        data: this.area5,
        disableClose: true
      });
    } else if (area == 6) {
      dialog = this.dialog.open(DialogLayoutComponent, {
        minWidth: width,
        data: this.area6,
        disableClose: true
      });
    }
    dialog.afterClosed().subscribe((result: any) => {
      if (result !== undefined) {
        if (this.componentes.length != 0) {
          var indicesRemover = [] as any
          this.componentes.forEach((element: any, indice: any) => {
            if (element.area == area) {
              indicesRemover.push(indice)
            }
          });
          if (indicesRemover.length > 0) {
            var novoComponente = [] as any
            indicesRemover.forEach((element: any) => {

            });
            this.componentes
          }
        }
        if (area == 1) {
          this.area1 = result.ar_componentes
          if (result.ar_componentes !== undefined && result.ar_componentes.length > 0) {
            this.temSelecionado1 = true;
          } else {
            this.temSelecionado1 = false;
          }
        } else if (area == 2) {
          this.area2 = result.ar_componentes
          if (result.ar_componentes !== undefined && result.ar_componentes.length > 0) {
            this.temSelecionado2 = true;
          } else {
            this.temSelecionado2 = false;
          }
        } else if (area == 3) {
          this.area3 = result.ar_componentes
          if (result.ar_componentes !== undefined && result.ar_componentes.length > 0) {
            this.temSelecionado3 = true;
          } else {
            this.temSelecionado3 = false;
          }
        } else if (area == 4) {
          this.area4 = result.ar_componentes
          if (result.ar_componentes !== undefined && result.ar_componentes.length > 0) {
            this.temSelecionado4 = true;
          } else {
            this.temSelecionado4 = false;
          }
        } else if (area == 5) {
          this.area5 = result.ar_componentes
          if (result.ar_componentes !== undefined && result.ar_componentes.length > 0) {
            this.temSelecionado5 = true;
          } else {
            this.temSelecionado5 = false;
          }
        } else if (area == 6) {
          this.area6 = result.ar_componentes
          if (result.ar_componentes !== undefined && result.ar_componentes.length > 0) {
            this.temSelecionado6 = true;
          } else {
            this.temSelecionado6 = false;
          }
        }

        var json;
        var arrayUnicoComponentes = [] as any
        var filtro = this.componentes.filter(function (el: any) {
          return el.area == area
        })
        filtro.forEach((element: any) => {
          var indice = this.componentes.indexOf(element);
          this.componentes.splice(indice, 1)
        });
        if (result.tempo == 0) {
          json = {
            "area": area,
            "componente": result.ar_componentes[0],
            "tempo": 0
          }
          this.componentes.push(json)
        } else {
          result.ar_componentes.forEach((element: any) => {
            json = {
              "area": area,
              "componente": element,
              "tempo": result.tempo
            }
            this.componentes.push(json)
          });
        }
        var array = this.componentes
        var arrayAreaUnico = [] as any
        array.forEach((element: any) => {
          arrayAreaUnico = _.uniq(array, function (item: any) {
            return item.area
          })
        });
        if (this.modelo == 1) {
          if (arrayAreaUnico.length == 1) {
            this.disable = true
          }
        } else if (this.modelo == 2) {
          if (arrayAreaUnico.length == 3) {
            this.disable = true
          }
        } else if (this.modelo == 3) {
          if (arrayAreaUnico.length == 4) {
            this.disable = true
          }
        } else if (this.modelo == 4) {
          if (arrayAreaUnico.length == 4) {
            this.disable = true
          }
        } else if (this.modelo == 5) {
          if (arrayAreaUnico.length == 5) {
            this.disable = true
          }
        } else if (this.modelo == 6) {
          if (arrayAreaUnico.length == 6) {
            this.disable = true
          }
        }
      }
    });
  }

  reload() {
    this.area1 = [] as any;
    this.area2 = [] as any;
    this.area3 = [] as any;
    this.area4 = [] as any;
    this.area5 = [] as any;
    this.area6 = [] as any;
    this.componentes = [] as any
    this.formLayout.reset(true)
  }

}
