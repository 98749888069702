import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutsService } from 'src/app/service/layouts.service';
import { ReloadGraficosService } from 'src/app/service/reload-graficos.service';

@Component({
  selector: 'app-modal-graficos',
  templateUrl: './modal-graficos.component.html',
  styleUrls: ['./modal-graficos.component.css']
})
export class ModalGraficosComponent implements OnInit {

  public graficos = [
    { nome: 'Abastecimento da motobomba', icone: 'icone-grafico-barra.svg' },
    { nome: 'Alertas da motobomba', icone: 'icone-grafico-barra.svg' },
    { nome: 'Consumo da motobomba', icone: 'icone-grafico-barra.svg' },
    { nome: 'Tempo efetivo por frota da motobomba', icone: 'icone-grafico-pizza.svg' },
    { nome: 'Tempo efetivo por OS da motobomba', icone: 'icone-grafico-pizza.svg' },
    { nome: 'Tempo efetivo por turno da motobomba', icone: 'icone-grafico-pizza.svg' },
    { nome: 'Tempo efetivo por OS do carretel', icone: 'icone-grafico-pizza.svg' },
    { nome: 'Tempo efetivo por turno do carretel', icone: 'icone-grafico-pizza.svg' },
    { nome: 'Produtividade por carretel', icone: 'icone-grafico-pizza.svg' },
    { nome: 'Produtividade por OS', icone: 'icone-grafico-pizza.svg' },
    { nome: 'Produtividade por turno', icone: 'icone-grafico-pizza.svg' },
    { nome: 'Tempos trabalhados', icone: 'icone-grafico-pizza.svg' },
    { nome: 'Tempos trabalhados por frota', icone: 'icone-grafico-barra.svg' },
    { nome: 'Nível de combustível da motobomba', icone: 'icone-grafico-linha.svg' },
    { nome: 'Controle de RPM da motobomba', icone: 'icone-grafico-linha.svg' },
    { nome: 'Pressão de entrada da motobomba', icone: 'icone-grafico-linha.svg' },
    { nome: 'Pressão de saída da motobomba', icone: 'icone-grafico-linha.svg' },
    { nome: 'Odômetro do carretel', icone: 'icone-grafico-linha.svg' },
    { nome: 'Pressão do carretel', icone: 'icone-grafico-linha.svg' },
    { nome: 'Bateria do carretel', icone: 'icone-grafico-linha.svg' },
    { nome: 'Velocidade de recolhimento do carretel', icone: 'icone-grafico-linha.svg' }
  ];
  public layouts = [] as any;
  public idCliente: string = localStorage.getItem('cliente_filtro') || "";
  public arrayClientes = JSON.parse(localStorage.getItem('cliente') || "{}").slice();
  public arrayGrupos = JSON.parse(localStorage.getItem('grupos') || "{}");

  constructor(
    private router: Router,
    private layoutService: LayoutsService,
    private serviceReload: ReloadGraficosService
  ) { }

  ngOnInit(): void {
    this.buscarLayouts();
    this.serviceReload.recarregarComponente.subscribe(() => {
      this.reload();
    })
  }

  reload(): void {
    this.idCliente = localStorage.getItem('cliente_filtro') || "";
    this.arrayClientes = JSON.parse(localStorage.getItem('cliente') || "{}").slice();
    this.arrayGrupos = JSON.parse(localStorage.getItem('grupos') || "{}");
    this.layouts = [];
    this.buscarLayouts();
  }

  buscarLayouts() {
    var idCliente = this.arrayClientes.map((cliente: any) => cliente.id).toString();
    var idGrupo = this.arrayGrupos.map((grupo: any) => grupo.id).toString();
    this.layoutService.BuscarLayoutsPersonalizadosCliente(idCliente, idGrupo, this.idCliente, true).subscribe((resposta: any) => {
      this.atribuirResposta(resposta);
    });
  }

  atribuirResposta(resposta: any) {
    if (resposta) {
      this.layouts = resposta;
      sessionStorage.setItem('layouts', JSON.stringify(resposta));
    }
  }

  redirecionarParaGraficos(grafico: string) {
    // TODO: voltar para `this.router.navigate(['hidrometer/graficos/' + grafico]);` assim que o problema do router link for resolvido
    window.location.href = '/hidrometer/graficos/' + grafico
  }
}