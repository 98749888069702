<div class="conteudo-grafico">
    <div class="mt-2 container">
        <form [formGroup]="formFilter">
            <div id="filtros" class="row">
                <div class="col pl-0">
                    <label class="m-0 cor-azul">Período</label>
                    <div class="d-inline-flex align-items-center d-flex tamanho-filtro-data">
                        <mat-date-range-input [rangePicker]="picker"
                            class="form-control form-control-sm input-padrao input-mapa w-75 d-flex justify-content-center alinhamento-data">
                            <input matStartDate formControlName="start" placeholder="Data inicial" readonly>
                            <input matEndDate formControlName="end" placeholder="Data final" readonly>
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="picker" class="cor-azul"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker class="cor-azul"></mat-date-range-picker>
                    </div>
                </div>

                <div class="col pl-0">
                    <label class="m-0 cor-azul">Cliente</label>
                    <select formControlName="client" required class="form-control form-control-sm filtro-listas mt-1">
                        <option fxFlex="90vw" *ngFor="let client of clients" [value]="client.id">
                            {{ client.nome }}
                        </option>
                    </select>
                </div>

                <div class="col pl-0">
                    <label class="m-0 cor-azul">Gráfico</label>
                    <select formControlName="tipo" required
                        class="form-control form-control-sm filtro-listas w-100 mt-1">
                        <option fxFlex="90vw" *ngFor="let grafico of tipos" [value]="grafico.valor">
                            {{grafico.nome}}
                        </option>
                    </select>
                </div>

                <div *ngIf="graficoSelecionado.grupo !== null || graficoSelecionado.nome == 'Tempos trabalhados por frota' || graficoSelecionado.nome == 'Tempos trabalhados'"
                    class="col m-0 p-0" id="filtroEquipamento">
                    <label class="m-0 cor-azul">Equipamentos</label>

                    <mat-select *ngIf="formFilter.value.tipo != 'Controle de RPM da motobomba'"
                        formControlName="equipment" multiple [placeholder]="placeholder"
                        class="form-control form-control-sm filtro-listas w-100 mt-1 p-1" id="select-multiplo" required
                        (selectionChange)="onSelectionChange()">

                        <div *ngIf="graficoSelecionado.nome != 'Tempos trabalhados por frota' && graficoSelecionado.nome != 'Tempos trabalhados'"
                            id="optionFiltroEquip">
                            <mat-option color="accent" *ngIf="placeholder != 'Cliente sem equipamentos'"
                                (click)="condicaoFuncaoSelecionar20Equipamentos != true ? selecionarAs20() : reverterSelecao()"
                                #todasAs20 value="0">
                                <span class="cor-azul">
                                    Todos 20 Primeiros
                                </span>
                            </mat-option>
                            <mat-option color="primary" *ngFor="let item of equipamentoFiltro" [value]="item.sr_id"
                                class="cor-azul">
                                <span class="nome-equipamento cor-azul">{{ item.vc_frota}}</span>
                            </mat-option>
                        </div>

                        <div *ngIf="graficoSelecionado.nome == 'Tempos trabalhados por frota' || graficoSelecionado.nome == 'Tempos trabalhados'"
                            id="optionFiltroFrota">
                            <mat-option #todasSelecionadas color="accent" value="0"
                                (click)="conditionFrotas ? selecionarTodasFrotas() : removerTodasFrotas()"
                                class="cor-azul">Todas</mat-option>
                            <mat-option color="primary" *ngFor="let item of frotasCliente" [value]="item.sr_id"
                                class="cor-azul" (click)="frotaSelecionada()">
                                <span class="nome-equipamento cor-azul">{{ item.vc_frota}}</span>
                            </mat-option>
                        </div>

                    </mat-select>

                    <mat-select id="select-unico" *ngIf="formFilter.value.tipo=='Controle de RPM da motobomba'"
                        formControlName="equipment" [placeholder]="placeholder"
                        class="form-control form-control-sm filtro-listas w-100 mt-1 p-1" required>
                        <mat-option color="primary" *ngFor="let item of equipamentoFiltro" [value]="item.sr_id"
                            class="cor-azul">
                            <span class="cor-azul">{{ item.vc_frota }}</span>
                        </mat-option>
                    </mat-select>
                </div>

                <div class="col-1">
                    <button mat-raised-button id="botaoFiltrar" class="botao-criar" (click)="Filtrar()"
                        [disabled]="formFilter.invalid">Filtrar</button>
                </div>

            </div>

        </form>
    </div>

    <div class="mt-3 altura-padrao" *ngIf="exibirGrafico">
        <app-abastecimento-motobomba *ngIf="grafico=='Abastecimento da motobomba'"></app-abastecimento-motobomba>
        <app-alertas-motobomba *ngIf="grafico=='Alertas da motobomba'"></app-alertas-motobomba>
        <app-consumo-motobomba *ngIf="grafico=='Consumo da motobomba'"></app-consumo-motobomba>
        <app-tempo-efetivo-frota-motobomba
            *ngIf="grafico=='Tempo efetivo por frota da motobomba'"></app-tempo-efetivo-frota-motobomba>
        <app-tempo-efetivo-os-motobomba
            *ngIf="grafico=='Tempo efetivo por OS da motobomba'"></app-tempo-efetivo-os-motobomba>
        <app-tempo-efetivo-turno-motobomba
            *ngIf="grafico=='Tempo efetivo por turno da motobomba'"></app-tempo-efetivo-turno-motobomba>
        <app-tempo-efetivo-os-carretel
            *ngIf="grafico=='Tempo efetivo por OS do carretel'"></app-tempo-efetivo-os-carretel>
        <app-tempo-efetivo-turno-carretel
            *ngIf="grafico=='Tempo efetivo por turno do carretel'"></app-tempo-efetivo-turno-carretel>
        <app-produtividade-carretel *ngIf="grafico=='Produtividade por carretel'"></app-produtividade-carretel>
        <app-produtividade-os *ngIf="grafico=='Produtividade por OS'"></app-produtividade-os>
        <app-produtividade-turno *ngIf="grafico=='Produtividade por turno'"></app-produtividade-turno>
        <app-tempos-trabalhados *ngIf="grafico=='Tempos trabalhados'"></app-tempos-trabalhados>
        <app-tempos-trabalhados-frota *ngIf="grafico=='Tempos trabalhados por frota'"></app-tempos-trabalhados-frota>

        <app-combustivel-motobomba *ngIf="grafico=='Nível de combustível da motobomba'"></app-combustivel-motobomba>
        <app-controle-rpm-motobomba *ngIf="grafico=='Controle de RPM da motobomba'"></app-controle-rpm-motobomba>
        <app-pressao-entrada-motobomba
            *ngIf="grafico=='Pressão de entrada da motobomba'"></app-pressao-entrada-motobomba>
        <app-pressao-saida-motobomba *ngIf="grafico=='Pressão de saída da motobomba'"></app-pressao-saida-motobomba>
        <app-odometro-carretel *ngIf="grafico=='Odômetro do carretel'"></app-odometro-carretel>
        <app-pressao-carretel *ngIf="grafico=='Pressão do carretel'"></app-pressao-carretel>
        <app-bateria-carretel *ngIf="grafico=='Bateria do carretel'"></app-bateria-carretel>
        <app-velocidade-recolhimento-carretel
            *ngIf="grafico=='Velocidade de recolhimento do carretel'"></app-velocidade-recolhimento-carretel>

        <div class="mt-3 altura-padrao" *ngIf="modeloLayout">
            <app-modelo1 *ngIf="modeloLayout == 1"></app-modelo1>
            <app-modelo2 *ngIf="modeloLayout == 2"></app-modelo2>
            <app-modelo3 *ngIf="modeloLayout == 3"></app-modelo3>
            <app-modelo4 *ngIf="modeloLayout == 4"></app-modelo4>
            <app-modelo5 *ngIf="modeloLayout == 5"></app-modelo5>
            <app-modelo6 *ngIf="modeloLayout == 6"></app-modelo6>
        </div>
    </div>


</div>