<main class="container" fxLayout="column" fxLayoutAlign="center center">
    <div class="row">
        <div class="col-12">
            <h1>Característica adicional (valor)</h1>
        </div>
    </div>
    <div class="row">
        <form [formGroup]="addDataForm" fxLayout="column" fxLayoutAlign="space-evenly center" fxShow.gt-xs fxHide.xs>
            <div class="form-row" fxLayout="column" fxLayoutAlign="space-evenly center">
                <div class="form-row">
                    <mat-form-field appearance="outline" fxFlex style="width: 35vw">
                        <mat-label>Valor para {{caracteristica.vc_nome}}</mat-label>
                        <label id="input" class="w-100">
                            <input matInput formControlName="value" [type]="input" autocomplete="off" required />
                        </label>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-row" fxLayout="row" fxLayoutAlign="center" fxFlexOffset="3%">
                <button mat-raised-button class="botao-criar" type="button" [disabled]="addDataForm.invalid"
                    (click)="done()">
                    Gravar
                </button>
                <button mat-raised-button class="botao-excluir" type="reset" fxFlexOffset="10px" (click)="cancel()">
                    Cancelar
                </button>
            </div>
        </form>
    </div>
</main>