<div fxLayout="row" fxLayoutAlign="center center" class="turno">
    <div class="turno1 d-none d-md-block" fxLayout="column" *ngFor="let hectare of hectares">
        {{hectare.turno}}
        <span>
            {{hectare.hectares}}
            <span *ngIf="hectare.hectares!=null" class="hectares">
                HA
            </span>
        </span>
    </div>
</div>