import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Event, NavigationStart, Params, Router } from '@angular/router';
import { BASE_URL_PREMOCENTER_FRONT } from 'src/app/app.constants';
import { AlertService } from 'src/app/service/alert.service';
import { LayoutsService } from 'src/app/service/layouts.service';
import { DialogLayoutComponent } from '../dialog-layout/dialog-layout.component';
import * as _ from "underscore";
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ReloadGraficosService } from 'src/app/service/reload-graficos.service';

@Component({
  selector: 'app-editar-layouts',
  templateUrl: './editar-layouts.component.html',
  styleUrls: ['./editar-layouts.component.css']
})
export class EditarLayoutsComponent implements OnInit {
  load = true;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;
  premocenter = BASE_URL_PREMOCENTER_FRONT
  id!: any;
  token!: any;
  formLayout!: FormGroup;
  modelo!: any;
  usuario!: any;
  clientes!: any;
  grupos!: any;
  area1 = [] as any;
  area2 = [] as any;
  area3 = [] as any;
  area4 = [] as any;
  area5 = [] as any;
  area6 = [] as any;
  novasArea1 = [] as any;
  novasArea2 = [] as any;
  novasArea3 = [] as any;
  novasArea4 = [] as any;
  novasArea5 = [] as any;
  novasArea6 = [] as any;
  componentes = [] as any;
  deletarIntensLayout = [] as any
  itensLayout = [] as any
  id_div: any;
  nome_classe: any;
  tempo1 = [] as any;
  tempo2 = [] as any;
  tempo3 = [] as any;
  tempo4 = [] as any;
  tempo5 = [] as any;
  tempo6 = [] as any;

  constructor(
    private route: ActivatedRoute,
    private dashboardService: LayoutsService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private alertService: AlertService,
    private router: Router,
    private location: Location,
    private serviceReload: ReloadGraficosService

  ) {
    this.route.params.subscribe((params: Params) => {
      this.id = params.id
    });
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        localStorage.removeItem("comp_area1");
        localStorage.removeItem("comp_area2");
        localStorage.removeItem("comp_area3");
        localStorage.removeItem("comp_area4");
        localStorage.removeItem("comp_area5");
        localStorage.removeItem("comp_area6");
        localStorage.removeItem("tempo_area1");
        localStorage.removeItem("tempo_area2");
        localStorage.removeItem("tempo_area3");
        localStorage.removeItem("tempo_area4");
        localStorage.removeItem("tempo_area5");
        localStorage.removeItem("tempo_area6");
      }
    });
  }

  ngOnInit(): void {
    this.localStorage();
    this.acharValores();
    this.createForm();
  }

  createForm() {
    this.formLayout = this.formBuilder.group({
      token: [this.token],
      vc_nome: [null, Validators.required],
      vc_tipo: [null, Validators.required],
      vc_grupo: [null, Validators.required],
      vc_cliente: [null, Validators.required]
    })
  }

  localStorage() {
    this.token = localStorage.getItem('token');
    this.usuario = localStorage.getItem('usuario');
    this.clientes = localStorage.getItem('cliente');
    this.grupos = localStorage.getItem('grupos');
    this.clientes = JSON.parse(this.clientes)
    this.grupos = JSON.parse(this.grupos)
  }

  acharValores() {
    this.dashboardService.BuscarPorId(this.id).subscribe((resp: any) => {
      this.modelo = resp.fk_layout
      this.formLayout.get('vc_nome')?.setValue(resp.vc_nome);
      if (resp.fk_cliente) {
        this.formLayout.get('vc_tipo')?.setValue(3)
        this.formLayout.get('vc_cliente')?.setValue(resp.fk_cliente)
      } else if (resp.it_id_grupo_premocenter) {
        this.formLayout.get('vc_tipo')?.setValue(2)
        this.formLayout.get('vc_grupo')?.setValue(resp.it_id_grupo_premocenter)
      } else {
        this.formLayout.get('vc_tipo')?.setValue(1)
      }

      if (this.modelo == 1) {
        this.modelo1(resp);
      } else if (this.modelo == 2) {
        this.modelo2(resp);
      } else if (this.modelo == 3) {
        this.modelo3(resp);
      } else if (this.modelo == 4) {
        this.modelo4(resp);
      } else if (this.modelo == 5) {
        this.modelo5(resp);
      } else if (this.modelo == 6) {
        this.modelo6(resp);
      }
      this.load = false
    })
  }

  modelo1(resp: any) {
    resp.layouts_personalizados_itens.forEach((element: any) => {
      this.area1.push(element.vc_componente)
      this.tempo1 = element.it_tempo_componente;
    });
    localStorage.setItem("comp_area" + 1, this.area1);
    localStorage.setItem("tempo_area" + 1, this.tempo1);
  }

  modelo2(resp: any) {
    resp.layouts_personalizados_itens.forEach((element: any) => {
      if (element.it_area == 1) {
        this.area1.push(element.vc_componente)
        this.tempo1 = element.it_tempo_componente;
      } else if (element.it_area == 2) {
        this.area2.push(element.vc_componente)
        this.tempo2 = element.it_tempo_componente;
      } else {
        this.area3.push(element.vc_componente)
        this.tempo3 = element.it_tempo_componente;
      }
    });
    localStorage.setItem("comp_area" + 1, this.area1);
    localStorage.setItem("tempo_area" + 1, this.tempo1);
    localStorage.setItem("comp_area" + 2, this.area2);
    localStorage.setItem("tempo_area" + 2, this.tempo2);
    localStorage.setItem("comp_area" + 3, this.area3);
    localStorage.setItem("tempo_area" + 3, this.tempo3);
  }
  modelo3(resp: any) {
    resp.layouts_personalizados_itens.forEach((element: any) => {
      if (element.it_area == 1) {
        this.area1.push(element.vc_componente)
        this.tempo1 = element.it_tempo_componente;
      } else if (element.it_area == 2) {
        this.area2.push(element.vc_componente)
        this.tempo2 = element.it_tempo_componente;
      } else if (element.it_area == 3) {
        this.area3.push(element.vc_componente)
        this.tempo3 = element.it_tempo_componente;
      } else {
        this.area4.push(element.vc_componente)
        this.tempo4 = element.it_tempo_componente;
      }
    });
    localStorage.setItem("comp_area" + 1, this.area1);
    localStorage.setItem("tempo_area" + 1, this.tempo1);
    localStorage.setItem("comp_area" + 2, this.area2);
    localStorage.setItem("tempo_area" + 2, this.tempo2);
    localStorage.setItem("comp_area" + 3, this.area3);
    localStorage.setItem("tempo_area" + 3, this.tempo3);
    localStorage.setItem("comp_area" + 4, this.area4);
    localStorage.setItem("tempo_area" + 4, this.tempo4);
  }
  modelo4(resp: any) {
    resp.layouts_personalizados_itens.forEach((element: any) => {
      if (element.it_area == 1) {
        this.area1.push(element.vc_componente)
        this.tempo1 = element.it_tempo_componente;
      } else if (element.it_area == 2) {
        this.area2.push(element.vc_componente)
        this.tempo2 = element.it_tempo_componente;
      } else if (element.it_area == 3) {
        this.area3.push(element.vc_componente)
        this.tempo3 = element.it_tempo_componente;
      } else {
        this.area4.push(element.vc_componente)
        this.tempo4 = element.it_tempo_componente;
      }
    });
    localStorage.setItem("comp_area" + 1, this.area1);
    localStorage.setItem("tempo_area" + 1, this.tempo1);
    localStorage.setItem("comp_area" + 2, this.area2);
    localStorage.setItem("tempo_area" + 2, this.tempo2);
    localStorage.setItem("comp_area" + 3, this.area3);
    localStorage.setItem("tempo_area" + 3, this.tempo3);
    localStorage.setItem("comp_area" + 4, this.area4);
    localStorage.setItem("tempo_area" + 4, this.tempo4);
  }
  modelo5(resp: any) {
    resp.layouts_personalizados_itens.forEach((element: any) => {
      if (element.it_area == 1) {
        this.area1.push(element.vc_componente)
        this.tempo1 = element.it_tempo_componente;
      } else if (element.it_area == 2) {
        this.area2.push(element.vc_componente)
        this.tempo2 = element.it_tempo_componente;
      } else if (element.it_area == 3) {
        this.area3.push(element.vc_componente)
        this.tempo3 = element.it_tempo_componente;
      } else if (element.it_area == 4) {
        this.area4.push(element.vc_componente)
        this.tempo4 = element.it_tempo_componente;
      } else {
        this.area5.push(element.vc_componente)
        this.tempo5 = element.it_tempo_componente;
      }
    });
    localStorage.setItem("comp_area" + 1, this.area1);
    localStorage.setItem("tempo_area" + 1, this.tempo1);
    localStorage.setItem("comp_area" + 2, this.area2);
    localStorage.setItem("tempo_area" + 2, this.tempo2);
    localStorage.setItem("comp_area" + 3, this.area3);
    localStorage.setItem("tempo_area" + 3, this.tempo3);
    localStorage.setItem("comp_area" + 4, this.area4);
    localStorage.setItem("tempo_area" + 4, this.tempo4);
    localStorage.setItem("comp_area" + 5, this.area5);
    localStorage.setItem("tempo_area" + 5, this.tempo5);
  }
  modelo6(resp: any) {
    resp.layouts_personalizados_itens.forEach((element: any) => {
      if (element.it_area == 1) {
        this.area1.push(element.vc_componente)
        this.tempo1 = element.it_tempo_componente;
      } else if (element.it_area == 2) {
        this.area2.push(element.vc_componente)
        this.tempo2 = element.it_tempo_componente;
      } else if (element.it_area == 3) {
        this.area3.push(element.vc_componente)
        this.tempo3 = element.it_tempo_componente;
      } else if (element.it_area == 4) {
        this.area4.push(element.vc_componente)
        this.tempo4 = element.it_tempo_componente;
      } else if (element.it_area == 5) {
        this.area5.push(element.vc_componente)
        this.tempo5 = element.it_tempo_componente;
      } else {
        this.area6.push(element.vc_componente)
        this.tempo6 = element.it_tempo_componente;
      }
    });
    localStorage.setItem("comp_area" + 1, this.area1);
    localStorage.setItem("tempo_area" + 1, this.tempo1);
    localStorage.setItem("comp_area" + 2, this.area2);
    localStorage.setItem("tempo_area" + 2, this.tempo2);
    localStorage.setItem("comp_area" + 3, this.area3);
    localStorage.setItem("tempo_area" + 3, this.tempo3);
    localStorage.setItem("comp_area" + 4, this.area4);
    localStorage.setItem("tempo_area" + 4, this.tempo4);
    localStorage.setItem("comp_area" + 5, this.area5);
    localStorage.setItem("tempo_area" + 5, this.tempo5);
    localStorage.setItem("comp_area" + 6, this.area6);
    localStorage.setItem("tempo_area" + 6, this.tempo6);
  }

  public openDialog(area: number, event: any): void {
    if (event.srcElement.localName == 'li') {
      this.nome_classe = event.path[2].classList[0];
      this.id_div = event.path[2].id;
    } else if (event.srcElement.localName == 'button' || event.srcElement.localName == 'ul') {
      this.nome_classe = event.path[1].classList[0];
      this.id_div = event.path[1].id;
    } else {
      this.nome_classe = event.srcElement.classList[0];
      this.id_div = event.srcElement.id;
    }

    var dado: any;
    if (area == 1) {
      dado = this.area1
    } else if (area == 2) {
      dado = this.area2
    } else if (area == 3) {
      dado = this.area3
    } else if (area == 4) {
      dado = this.area4
    } else if (area == 5) {
      dado = this.area5
    } else if (area == 6) {
      dado = this.area6
    }

    localStorage.setItem('nome_div_modelo', '');
    localStorage.setItem('nome_div_modelo', this.nome_classe + '.' + this.id_div);

    var dialog = this.dialog.open(DialogLayoutComponent, {
      minWidth: "510px",
      data: dado,
      disableClose: true
    });
    dialog.afterClosed().subscribe(result => {
      if (area == 1) {
        this.area1 = result.ar_componentes
        this.tempo1 = result.tempo
        localStorage.setItem("comp_area1", this.area1);
        localStorage.setItem("tempo_area1", this.tempo1);
      } else if (area == 2) {
        this.novasArea2 = result.ar_componentes
        this.tempo2 = result.tempo
        localStorage.setItem("comp_area2", this.area2);
        localStorage.setItem("tempo_area2", this.tempo2);
      } else if (area == 3) {
        this.novasArea3 = result.ar_componentes
        this.tempo3 = result.tempo
        localStorage.setItem("comp_area3", this.area3);
        localStorage.setItem("tempo_area3", this.tempo3);
      } else if (area == 4) {
        this.novasArea4 = result.ar_componentes
        this.tempo4 = result.tempo
        localStorage.setItem("comp_area4", this.area4);
        localStorage.setItem("tempo_area4", this.tempo4);
      } else if (area == 5) {
        this.novasArea5 = result.ar_componentes
        this.tempo5 = result.tempo
        localStorage.setItem("comp_area5", this.area5);
        localStorage.setItem("tempo_area5", this.tempo5);
      } else if (area == 6) {
        this.novasArea6 = result.ar_componentes
        this.tempo6 = result.tempo
        localStorage.setItem("comp_area6", this.area6);
        localStorage.setItem("tempo_area6", this.tempo6);
      }

      var json;
      var filtro = this.componentes.filter(function (el: any) {
        return el.area == area
      })
      filtro.forEach((element: any) => {
        var indice = this.componentes.indexOf(element);
        this.componentes.splice(indice, 1)
      });
      if (result.tempo == 0) {
        json = {
          "area": area,
          "componente": result.ar_componentes[0],
          "tempo": 0
        }
        this.dashboardService.BuscarPorId(this.id).subscribe((layout: any) => {
          layout.layouts_personalizados_itens.forEach((itens: any) => {
            if (itens.it_area == area) {
              this.deletarIntensLayout.push(itens.sr_id)
            }
          });
        })
        this.componentes.push(json)
      } else {
        result.ar_componentes.forEach((element: any) => {
          json = {
            "area": area,
            "componente": element,
            "tempo": result.tempo
          }
          this.componentes.push(json)
        });
        this.dashboardService.BuscarPorId(this.id).subscribe((layout: any) => {
          layout.layouts_personalizados_itens.forEach((itens: any) => {
            if (itens.it_area == area) {
              this.deletarIntensLayout.push(itens.sr_id)
            }
          });
        })
      }
    })
  }

  atualizar() {
    this.load = true
    var layout;

    if (this.formLayout.value.vc_tipo == 1) {
      layout = {
        "token": this.token,
        "vc_nome": this.formLayout.value.vc_nome,
        "fk_layout": this.modelo,
        "fk_usuario": this.usuario
      }
    } else if (this.formLayout.value.vc_tipo == 2) {
      layout = {
        "token": this.token,
        "vc_nome": this.formLayout.value.vc_nome,
        "fk_layout": this.modelo,
        "it_id_grupo_premocenter": this.formLayout.value.vc_grupo
      }
    } else {
      layout = {
        "token": this.token,
        "vc_nome": this.formLayout.value.vc_nome,
        "fk_layout": this.modelo,
        "fk_cliente": this.formLayout.value.vc_cliente
      }
    }
    var parametros = [] as any;
    parametros.push(layout);

    var comp_area1: any = localStorage.getItem('comp_area1');
    var tempo_area1: any = localStorage.getItem('tempo_area1');
    var comp_area2: any = localStorage.getItem('comp_area2');
    var tempo_area2: any = localStorage.getItem('tempo_area2');
    var comp_area3: any = localStorage.getItem('comp_area3');
    var tempo_area3: any = localStorage.getItem('tempo_area3');
    var comp_area4: any = localStorage.getItem('comp_area4');
    var tempo_area4: any = localStorage.getItem('tempo_area4');
    var comp_area5: any = localStorage.getItem('comp_area5');
    var tempo_area5: any = localStorage.getItem('tempo_area5');
    var comp_area6: any = localStorage.getItem('comp_area6');
    var tempo_area6: any = localStorage.getItem('tempo_area6');

    if (this.componentes.length != 0) {
      const unique = [...new Set(this.componentes.map((item: any) => item.area))];
      if (this.modelo == 1) {
        if (unique.length < 1 && comp_area1 && tempo_area1) {
          this.validarComponentes(unique, 1);
        }
      } else if (this.modelo == 2) {
        if (unique.length < 3 && comp_area1 && tempo_area1 && comp_area2 &&
          tempo_area2 && comp_area3 && tempo_area3) {
          this.validarComponentes(unique, 1);
          this.validarComponentes(unique, 2);
          this.validarComponentes(unique, 3);
        }
      } else if (this.modelo == 3 || this.modelo == 4) {
        if (unique.length < 4 && comp_area1 && tempo_area1 && comp_area2 && tempo_area2
          && comp_area3 && tempo_area3 && comp_area4 && tempo_area4) {
          this.validarComponentes(unique, 1);
          this.validarComponentes(unique, 2);
          this.validarComponentes(unique, 3);
          this.validarComponentes(unique, 4);
        }
      } else if (this.modelo == 5) {
        if (unique.length < 5 && comp_area1 && tempo_area1 && comp_area2 && tempo_area2 && comp_area3
          && tempo_area3 && comp_area4 && tempo_area4 && comp_area5 && tempo_area5) {
          this.validarComponentes(unique, 1);
          this.validarComponentes(unique, 2);
          this.validarComponentes(unique, 3);
          this.validarComponentes(unique, 4);
          this.validarComponentes(unique, 5);
        }
      } else if (this.modelo == 6) {
        if (unique.length < 6 && comp_area1 && tempo_area1 && comp_area2 && tempo_area2
          && comp_area3 && tempo_area3 && comp_area4 && tempo_area4 && comp_area5
          && tempo_area5 && comp_area6 && tempo_area6) {
          this.validarComponentes(unique, 1);
          this.validarComponentes(unique, 2);
          this.validarComponentes(unique, 3);
          this.validarComponentes(unique, 4);
          this.validarComponentes(unique, 5);
          this.validarComponentes(unique, 6);
        }
      }
    } else if (this.componentes.length <= 0) {
      if (this.modelo == 1) {
        if (comp_area1 && tempo_area1) {
          this.validarComponentesLocalStorage(comp_area1, tempo_area1, 1);
        } else {
          this.alertService.error('Erro ao atualizar layout!');
        }
      } else if (this.modelo == 2) {
        if (comp_area1 && tempo_area1 && comp_area2 && tempo_area2 && comp_area3 && tempo_area3) {
          this.validarComponentesLocalStorage(comp_area1, tempo_area1, 1);
          this.validarComponentesLocalStorage(comp_area2, tempo_area2, 2);
          this.validarComponentesLocalStorage(comp_area3, tempo_area3, 3);
        } else {
          this.alertService.error('Erro ao atualizar layout!');
        }
      } else if (this.modelo == 3 || this.modelo == 4) {
        if (comp_area1 && tempo_area1 && comp_area2 && tempo_area2
          && comp_area3 && tempo_area3 && comp_area4 && tempo_area4) {
          this.validarComponentesLocalStorage(comp_area1, tempo_area1, 1);
          this.validarComponentesLocalStorage(comp_area2, tempo_area2, 2);
          this.validarComponentesLocalStorage(comp_area3, tempo_area3, 3);
          this.validarComponentesLocalStorage(comp_area4, tempo_area4, 4);
        } else {
          this.alertService.error('Erro ao atualizar layout!');
        }
      } else if (this.modelo == 5) {
        if (comp_area1 && tempo_area1 && comp_area2 && tempo_area2 && comp_area3
          && tempo_area3 && comp_area4 && tempo_area4 && comp_area5 && comp_area5) {
          this.validarComponentesLocalStorage(comp_area1, tempo_area1, 1);
          this.validarComponentesLocalStorage(comp_area2, tempo_area2, 2);
          this.validarComponentesLocalStorage(comp_area3, tempo_area3, 3);
          this.validarComponentesLocalStorage(comp_area4, tempo_area4, 4);
          this.validarComponentesLocalStorage(comp_area5, tempo_area5, 5);
        } else {
          this.alertService.error('Erro ao atualizar layout!');
        }
      } else if (this.modelo == 6) {
        if (comp_area1 && tempo_area1 && comp_area2 && tempo_area2
          && comp_area3 && tempo_area3 && comp_area4 && tempo_area4
          && comp_area5 && comp_area5 && comp_area6 && tempo_area6) {
          this.validarComponentesLocalStorage(comp_area1, tempo_area1, 1);
          this.validarComponentesLocalStorage(comp_area2, tempo_area2, 2);
          this.validarComponentesLocalStorage(comp_area3, tempo_area3, 3);
          this.validarComponentesLocalStorage(comp_area4, tempo_area4, 4);
          this.validarComponentesLocalStorage(comp_area5, tempo_area5, 5);
          this.validarComponentesLocalStorage(comp_area6, tempo_area6, 6);
        } else {
          this.alertService.error('Erro ao atualizar layout!');
        }
      }
    }

    this.componentes.forEach((componente: any) => {
      var itemLayout = {
        "token": this.token,
        "it_area": componente.area,
        "vc_componente": componente.componente,
        "it_tempo_componente": componente.tempo,
        "fk_layout_personalizado": this.id
      }
      parametros.push(itemLayout);
    });

    this.dashboardService.Atualizar(this.id, parametros).subscribe((element: any) => {
      this.load = false;
      this.serviceReload.recarregarListaGraficos();
      this.alertService.success('Layout atualizado com sucesso!');
      this.router.navigate(['hidrometer/meusLayout/listar']);
    }, error => {
      this.load = false;
      this.alertService.error('Erro ao atualizar layout!');
      return;
    })
  }

  validarComponentes(unique: any, area: any) {
    var localStorage_comp: any = localStorage.getItem("comp_area" + area);
    var localStorage_tempo: any = localStorage.getItem("tempo_area" + area);
    if (!unique.includes(area)) {
      this.validarComponentesLocalStorage(localStorage_comp, localStorage_tempo, area);
    }
  }

  validarComponentesLocalStorage(area_componentes: any, tempo: any, area: any) {
    if (area_componentes.indexOf(',') > 0) {
      area_componentes = area_componentes.split(',');
      for (let componente of area_componentes) {
        this.componentes.push({ "area": area, "tempo": tempo, "componente": componente });
      }
    } else {
      this.componentes.push({ "area": area, "tempo": tempo, "componente": area_componentes });
    }
  }
}
