<div class="button-container" style="float:right;">
    <button mat-icon-button #botao fxLayoutAlign="center center" mat-dialog-close>
        <img src="assets/icones-gerais/fechar-cor-preto.svg" alt="Fechar" class="icone-fechar">
    </button>
</div>
<div class="d-flex" fxLayoutAlign="center center">
    <h3 style="font-family:'Poppins', sans-serif; margin: 0" fxLayoutAlign="center center">Mensagens do motivo de
        parada: "{{ data.motivo }}"</h3>
    <div class="dropdown">
        <clr-icon shape="info-standard" class="info-icone cor-icone"></clr-icon>
        <div class="dropdown-content">
            <p>Visualize todas as mensagens relativas ao motivo de parada selecionado no gráfico anterior. </p>
        </div>
    </div>
</div>
<div fxLayoutAlign="center center" *ngIf="load" class="spinner"></div>
<div fxLayout="column" style="margin-top: 2em;" *ngIf="!load && invalido">
    <p style="text-align: center; font-size: 1.5em;">Não há mensagens deste motivo de parada.</p>
</div>
<div *ngIf="!invalido" fxLayout="column">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="motivo">
            <th mat-header-cell *matHeaderCellDef class="largura-coluna-motivo">
                <span style="width: 100%; text-align: center">Motivo da parada</span>
            </th>
            <td mat-cell *matCellDef="let detalhe">
                {{ detalhe.motivo ? detalhe.motivo : "" }}
                <span *ngIf="!detalhe.motivo" style="color: rgba(0, 0, 0, 0.5);">sem informação</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="frota">
            <th mat-header-cell *matHeaderCellDef class="largura-coluna-numero">
                Frota
            </th>
            <td mat-cell *matCellDef="let detalhe">
                {{ detalhe.frota ? detalhe.frota : "" }}
                <span *ngIf="!detalhe.frota" style="color: rgba(0, 0, 0, 0.5);">sem informação</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="os">
            <th mat-header-cell *matHeaderCellDef class="largura-coluna-numero">
                Ordem de serviço
            </th>
            <td mat-cell *matCellDef="let detalhe">
                {{ detalhe.ordemServico ? detalhe.ordemServico : "" }}
                <span *ngIf="!detalhe.ordemServico" style="color: rgba(0, 0, 0, 0.5);">sem informação</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="inicio">
            <th mat-header-cell *matHeaderCellDef class="largura-coluna-tempo">
                Início da parada
            </th>
            <td mat-cell *matCellDef="let detalhe" id="detalhe-inicio">
                {{ detalhe.inicio ? (detalhe.inicio + 'Z'  | date: "dd/MM/yyyy HH:mm:ss":"UTC -3") : "" }}
                <span *ngIf="!detalhe.inicio" style="color: rgba(0, 0, 0, 0.5);">sem informação</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="fim">
            <th mat-header-cell *matHeaderCellDef class="largura-coluna-tempo">
                Fim da parada
            </th>
            <td mat-cell *matCellDef="let detalhe" id="detalhe-fim">
                {{ detalhe.fim ? (detalhe.fim + 'Z'  | date: "dd/MM/yyyy HH:mm:ss":"UTC -3") : "" }}
                <span *ngIf="!detalhe.fim" style="color: rgba(0, 0, 0, 0.5);">sem informação</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="tempo">
            <th mat-header-cell *matHeaderCellDef class="largura-coluna-tempo">
                Tempo parada
            </th>
            <td mat-cell *matCellDef="let detalhe">
                {{ detalhe.duracaoParada ? detalhe.duracaoParada : "" }}
                <span *ngIf="!detalhe.duracaoParada" style="color: rgba(0, 0, 0, 0.5);">sem informação</span>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div class="mat-paginator-div">
        <mat-paginator [length]="length" [pageSizeOptions]="[20, 50, 100]">
        </mat-paginator>
    </div>
</div>