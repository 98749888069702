import { HttpOptionsService } from './header-options.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class NotificacoesService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;
  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  AcharNotificacoes(cliente: any, dataIni: any, dataFin: any) {
    const url = `${this.BASE_URL_HIDROMETER}temNotificacao/cliente/${cliente}/${dataIni}/${dataFin}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }
}
