import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modelo1',
  templateUrl: './modelo1.component.html',
  styleUrls: ['./modelo1.component.css']
})
export class Modelo1Component implements OnInit {

  layout!: any
  area1 = [] as any;
  tempoArea1!: any;
  c1 = false;
  c2 = false;
  c3 = false;
  c4 = false;
  c5 = false;
  c6 = false;
  c7 = false;
  c8 = false;
  c9 = false;
  c10 = false;
  slideAtual: any;
  numeroSlideAtual: any;
  redimensionarMapa: boolean = false;

  constructor(private config: NgbCarouselConfig) {
    this.config.showNavigationArrows = false;
    this.config.showNavigationIndicators = true;
    this.config.pauseOnFocus = true
  }

  ngOnInit(): void {
    this.layout = sessionStorage.getItem("layout_padrao")
    this.layout = JSON.parse(this.layout)
    this.componentes();
  }

  @ViewChild('carousel', { static: true }) carousel!: NgbCarousel;
  @Output() redimensionaMapa = new EventEmitter<void>();


  onMenuEvent(event: string) {
    if (event) {
      this.carousel.pause();
    } else {
      this.carousel.cycle();
    }
  }

  onSlide(event: any) {
    this.slideAtual = event.current;
    this.numeroSlideAtual = this.slideAtual.slice(-1);
  }

  componentes() {
    this.layout.layouts_personalizados_itens.forEach((item: any) => {
      var json;
      this.tempoArea1 = item.it_tempo_componente * 1000
      if (item.vc_componente == "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA") {
        json = { "c2": true }
        this.area1.push(json)
      } else if (item.vc_componente == "GRÁFICO DE ALERTA DA MOTOBOMBA") {
        json = { "c3": true }
        this.area1.push(json)
      } else if (item.vc_componente == "GRÁFICO DE CONSUMO DA MOTOBOMBA") {
        json = { "c4": true }
        this.area1.push(json)
      } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA") {
        json = { "c5": true }
        this.area1.push(json)
      } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA") {
        json = { "c6": true }
        this.area1.push(json)
      } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA") {
        json = { "c7": true }
        this.area1.push(json)
      } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL") {
        json = { "c8": true }
        this.area1.push(json)
      } else if (item.vc_componente == "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL") {
        json = { "c9": true }
        this.area1.push(json);
      } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DA OS") {
        json = { "c10": true }
        this.area1.push(json);
      } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO TURNO") {
        json = { "c11": true }
        this.area1.push(json);
      } else if (item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA") {
        json = { "c13": true }
        this.area1.push(json);
      } else if (item.vc_componente == "GRÁFICO DE TEMPOS TRABALHADOS") {
        json = { "c14": true }
        this.area1.push(json);
      } else if (item.vc_componente == "GRÁFICO DE PRODUTIVIDADE DO CARRETEL") {
        json = { "c15": true }
        this.area1.push(json);
      }
    })
  }
}
