import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipamentosService } from '../service/equipamentos.service';
import { ReloadGraficosService } from '../service/reload-graficos.service';
import { MatOption } from '@angular/material/core';
@Component({
    selector: 'app-graficos',
    templateUrl: './graficos.component.html',
    styleUrls: ['./graficos.component.css']
})
export class GraficosComponent implements OnInit {
    @ViewChild('todasSelecionadas') todasSelecionadas!: MatOption;
    public clients = JSON.parse(localStorage.getItem('cliente') || '{}');
    public dataInicio: any = localStorage.getItem('DateStartGraficos') == null ? new Date().toISOString().slice(0, 10) + ' 03:00:00' : localStorage.getItem('DateStartGraficos');
    public dataFinal: any = localStorage.getItem('DateEndGraficos') == null ? new Date(new Date(this.dataInicio).setDate(new Date(this.dataInicio).getDate() + 1)).toISOString().slice(0, 10) + ' 03:00:00' : localStorage.getItem('DateEndGraficos');
    public formFilter!: FormGroup;
    public grafico: any = '';
    public motobomba: Array<any> = []
    public carretel: Array<any> = []
    public equipamentoFiltro: Array<any> = []
    public placeholder: string = 'Carregando...'
    public tipos: { nome: string, valor: string, grupo: 'carretel' | 'motobomba' | null }[] = [
        { nome: 'Abastecimento da motobomba', valor: 'Abastecimento da motobomba', grupo: null },
        { nome: 'Alertas da motobomba', valor: 'Alertas da motobomba', grupo: null },
        { nome: 'Consumo da motobomba', valor: 'Consumo da motobomba', grupo: null },
        { nome: 'Tempo efetivo por frota da motobomba', valor: 'Tempo efetivo por frota da motobomba', grupo: null },
        { nome: 'Tempo efetivo por OS da motobomba', valor: 'Tempo efetivo por OS da motobomba', grupo: null },
        { nome: 'Tempo efetivo por turno da motobomba', valor: 'Tempo efetivo por turno da motobomba', grupo: null },
        { nome: 'Tempo efetivo por OS do carretel', valor: 'Tempo efetivo por OS do carretel', grupo: null },
        { nome: 'Tempo efetivo por turno do carretel', valor: 'Tempo efetivo por turno do carretel', grupo: null },
        { nome: 'Produtividade por carretel', valor: 'Produtividade por carretel', grupo: null },
        { nome: 'Produtividade por OS', valor: 'Produtividade por OS', grupo: null },
        { nome: 'Produtividade por turno', valor: 'Produtividade por turno', grupo: null },
        { nome: 'Tempos trabalhados', valor: 'Tempos trabalhados', grupo: null },
        { nome: 'Tempos trabalhados por frota', valor: 'Tempos trabalhados por frota', grupo: null },
        { nome: 'Nível de combustível da motobomba', valor: 'Nível de combustível da motobomba', grupo: 'motobomba' },
        { nome: 'Controle de RPM da motobomba', valor: 'Controle de RPM da motobomba', grupo: 'motobomba' },
        { nome: 'Pressão de entrada da motobomba', valor: 'Pressão de entrada da motobomba', grupo: 'motobomba' },
        { nome: 'Pressão de saída da motobomba', valor: 'Pressão de saída da motobomba', grupo: 'motobomba' },
        { nome: 'Odômetro do carretel', valor: 'Odômetro do carretel', grupo: 'carretel' },
        { nome: 'Pressão do carretel', valor: 'Pressão do carretel', grupo: 'carretel' },
        { nome: 'Bateria do carretel', valor: 'Bateria do carretel', grupo: 'carretel' },
        { nome: 'Velocidade de recolhimento do carretel', valor: 'Velocidade de recolhimento do carretel', grupo: 'carretel' }
    ];
    public modeloLayout: any;
    public layouts = JSON.parse(sessionStorage.getItem("layouts") || '{}');
    public frotas: any = [];
    public condicaoFuncaoSelecionar20Equipamentos: boolean = false
    public graficoSelecionado: any = this.tipos[0];
    public equipamentosLocalStorage = localStorage.getItem('IdsEquipamentos') || '';
    public exibirFiltroEquipamento: boolean = false;
    private maxSelections: number = 20
    public exibirGrafico: boolean = false;
    public frotasCliente: Array<any> = [];
    public conditionFrotas: boolean = true;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private serviceEquipamento: EquipamentosService,
        private serviceReload: ReloadGraficosService
    ) { }

    ngOnInit(): void {
        this.criarFormulario();
        this.serviceReload.recarregarComponente.subscribe(() => {
            this.reload();
        })
        if (Object.keys(this.layouts).length !== 0) this.acrescentarLayoutsPersonalizadosNoFormulario();
        this.route.paramMap.subscribe(params => {
            this.modeloLayout = null;
            this.grafico = params.get('tipo');
            if (!this.verificarSeGraficoEstaEmTipos()) {
                this.buscarLayout();
            }
            this.graficoSelecionado = this.tipos.find((element: any) => element.valor == this.grafico);
            this.exibirFiltroEquipamento = this.graficoSelecionado.grupo == 'carretel' || this.graficoSelecionado.grupo == 'motobomba';
            this.exibirGrafico = this.graficoSelecionado.grupo == null;
            this.formFilter.get("tipo")?.setValue(this.graficoSelecionado.valor);
            this.acharDadosDoCliente(parseInt(localStorage.getItem("cliente_filtro") || ''));
            this.onChange();
        });
        this.verificarValorForm()
    }

    verificarValorForm(): void {
        if (this.graficoSelecionado.nome == 'Tempos trabalhados por frota' || this.graficoSelecionado.nome == 'Tempos trabalhados') {
            let frotasLocalStorage = localStorage.getItem('tempos_trabalhados_frota')
            if (frotasLocalStorage) {
                let frotaArray = frotasLocalStorage.split(',').map(Number);
                this.formFilter.get('equipment')?.setValue(frotaArray);
            }
        } else {
            localStorage.removeItem('tempos_trabalhados_frota')
        }
    }

    reload(): void {
        this.layouts = JSON.parse(sessionStorage.getItem("layouts") || '{}');
        this.acrescentarLayoutsPersonalizadosNoFormulario();
    }

    selecionarAs20(): void {
        let check = document.querySelector('mat-pseudo-checkbox')
        check?.classList.toggle('mat-pseudo-checkbox-checked')
        let result: Array<any> = []
        this.frotas = [...this.equipamentoFiltro.map((item: any) => item.sr_id), 0];
        for (let i = 0; i < 20; i++) {
            if (this.frotas[i]) {
                result.push(this.frotas[i])
            }
        }
        this.checkarOpcoes(result)
        this.condicaoFuncaoSelecionar20Equipamentos = true
    }

    onSelectionChange(): void {
        if (this.formFilter.get('equipment')?.value.length > this.maxSelections) {
            this.formFilter.get('equipment')?.setValue(this.formFilter.get('equipment')?.value.slice(0, -1));
        }
    }

    reverterSelecao(): void {
        let check = document.querySelector('mat-pseudo-checkbox')
        check?.classList.toggle('mat-pseudo-checkbox-checked')
        this.frotas = [];
        this.checkarOpcoes(this.frotas);
        this.condicaoFuncaoSelecionar20Equipamentos = false
    }

    checkarOpcoes(frotas: any): void {
        this.formFilter.get('equipment')?.patchValue(frotas);
    }

    verificarSeGraficoEstaEmTipos(): boolean {
        return this.tipos.some(tipo => tipo.valor === this.grafico);
    }

    buscarLayout(): void {
        const layoutSelecionado = this.layouts.find((item: any) => item.sr_id == this.grafico);
        sessionStorage.setItem("layout_padrao", JSON.stringify(layoutSelecionado));
        this.modeloLayout = layoutSelecionado.fk_layout;
    }

    reloadComponent(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['hidrometer/graficos/' + this.formFilter.value.tipo]);
    }

    Filtrar(): void {
        localStorage.setItem("cliente_filtro", this.formFilter.value.client);
        localStorage.setItem("DateStartGraficos", new Date(new Date(this.formFilter.get('start')?.value).setDate(new Date(this.formFilter.get('start')?.value).getDate())).toISOString().slice(0, 10) + ' 03:00:00');
        localStorage.setItem("DateEndGraficos", new Date(new Date(this.formFilter.get('end')?.value).setDate(new Date(this.formFilter.get('end')?.value).getDate() + 1)).toISOString().slice(0, 10) + ' 03:00:00');
        localStorage.setItem("IdsEquipamentos", this.formFilter.value.equipment);
        if (this.graficoSelecionado.nome == 'Tempos trabalhados por frota' || this.graficoSelecionado.nome == 'Tempos trabalhados') {
            this.cadastrarFrotasLocalStorage()
        } else {
            localStorage.removeItem('tempos_trabalhados_frota')
        }
        this.reloadComponent();
    }

    criarFormulario(): void {
        var dataFinal = new Date(this.dataFinal)
        dataFinal.setDate(dataFinal.getDate() - 1);
        this.formFilter = this.formBuilder.group({
            client: [parseInt(localStorage.getItem("cliente_filtro") || ''), Validators.required],
            start: [new Date(this.dataInicio), Validators.required],
            end: [dataFinal, Validators.required],
            equipment: ['', []],
            tipo: ['', Validators.required]
        });
    }

    acrescentarLayoutsPersonalizadosNoFormulario(): void {
        const nomeLayouts = this.layouts.map((layout: any) => ({ nome: layout.vc_nome, valor: layout.sr_id, grupo: null }));
        this.tipos = nomeLayouts.concat(this.tipos);
    }

    onChange(): void {
        this.formFilter.get("client")?.valueChanges.subscribe(valor => {
            this.acharDadosDoCliente(valor);
        });

        this.formFilter.get("tipo")?.valueChanges.subscribe(valor => {
            this.graficoSelecionado = this.tipos.filter(grafico => grafico.valor == valor)[0];
            this.exibirFiltroEquipamento = this.graficoSelecionado.grupo == 'carretel' || this.graficoSelecionado.grupo == 'motobomba';
            this.acharEquipamentosParaOFiltro();
        });
    }

    acharDadosDoCliente(idCliente: number): void {
        this.placeholder = 'Carregando...';
        this.equipamentoFiltro = []
        this.motobomba = []
        this.carretel = []
        this.frotasCliente = []
        this.serviceEquipamento.AcharPorCliente(idCliente).subscribe((result: any) => {
            this.frotasCliente = result;
            this.motobomba = result.filter((element: any) => element.vc_tipo === 'MOTOR_PUMP');
            this.carretel = result.filter((element: any) => element.vc_tipo === 'REEL');
            this.acharEquipamentosParaOFiltro();
        })
    }

    acharEquipamentosParaOFiltro(): void {
        this.placeholder = "Selecione";
        switch (this.graficoSelecionado.grupo) {
            case 'carretel':
            case 'motobomba':
                this.atribuirEquipamentos(this.graficoSelecionado.grupo);
                break
            default:
                this.formFilter.get('equipment')?.clearValidators();
                this.formFilter.get('equipment')?.updateValueAndValidity();
        }
    }

    atribuirEquipamentos(grupo: 'carretel' | 'motobomba'): void {
        this.formFilter.get('equipment')?.setValidators([Validators.required]);
        this.equipamentoFiltro = grupo === 'carretel' ? this.carretel : this.motobomba;
        this.placeholder = this.equipamentoFiltro.length == 0 ? 'Cliente sem equipamentos' : 'Selecione';
        const todosIdsPresentes = this.equipamentosLocalStorage.split(',').map(Number).every(id => this.equipamentoFiltro.some(equipamento => equipamento.sr_id === id));
        if (this.graficoSelecionado.nome == 'Controle de RPM da motobomba') {
            this.formFilter.get('equipment')?.setValue(this.equipamentosLocalStorage.split(',').map(Number))
        } else {
            if (todosIdsPresentes) {
                this.checkarOpcoes(this.equipamentosLocalStorage.split(',').map(Number))
            } else {
                this.checkarOpcoes([])
            }
        }
        this.exibirGrafico = this.formFilter.valid;
    }

    selecionarTodasFrotas(): void {
        let check = document.querySelector('mat-pseudo-checkbox')
        check?.classList.toggle('mat-pseudo-checkbox-checked')
        var frotas: Array<any> = [];
        if (this.todasSelecionadas.selected) {
            frotas = [...this.frotasCliente.map((item: any) => item.sr_id), 0];
        }
        this.alterarFrotasFormulario(frotas);
        this.conditionFrotas = false
    }

    removerTodasFrotas(): void {
        let check = document.querySelector('mat-pseudo-checkbox')
        check?.classList.toggle('mat-pseudo-checkbox-checked')
        var frotas: Array<any> = [];
        this.alterarFrotasFormulario(frotas);
        this.conditionFrotas = true
    }

    frotaSelecionada(): void {
        if (this.todasSelecionadas.selected) {
            this.todasSelecionadas.deselect();
        }
        if (this.formFilter.get('equipment')?.value.length == this.frotasCliente.length) {
            this.todasSelecionadas.select();
        }
    }

    alterarFrotasFormulario(frotas: any): void {
        this.formFilter.get('equipment')?.patchValue(frotas);
    }

    cadastrarFrotasLocalStorage(): void {
        var frotasFormulario: any = this.formFilter.get('equipment')?.value;
        var frotasFiltradas: any = this.todasSelecionadas.selected ? 0 :
            frotasFormulario.filter((frota: any) => frota !== 0);

        localStorage.setItem('tempos_trabalhados_frota', frotasFiltradas);
    }
}