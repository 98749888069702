import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartOptions, ChartType } from 'chart.js';
import { TemposTiposComponent } from '../../tempos-trabalhados/tempos-tipos/tempos-tipos.component';
import { GraficosService } from 'src/app/service/graficos.service';

@Component({
    selector: 'app-dialog-tempos-frotas',
    templateUrl: './dialog-tempos-frotas.component.html',
    styleUrls: ['./dialog-tempos-frotas.component.css']
})
export class DialogTemposFrotasComponent implements OnInit {

    public cliente: string = localStorage.getItem('cliente_filtro') ?? '';
    public dataInicial: string = localStorage.getItem('DateStartGraficos') ?? '';
    public dataFinal: string = localStorage.getItem('DateEndGraficos') ?? '';
    frotaData: any;
    grupos = [] as any;
    arrayCores = [] as any;
    d: any;
    h: any;
    dDisplay: any;
    hDisplay: any;
    loading = true;
    validaResp = false;

    public pieChartOptions: ChartOptions = {
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var total = 0;
                    for (let dado of data.datasets[0].data) {
                        total += dado;
                    }
                    var index = tooltipItem['index'];
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var primeiraLetraLegenda = data.labels[index].charAt(0).toUpperCase();
                    var corpoLegenda = data.labels[index];
                    var restoLegenda = corpoLegenda.substr(1, corpoLegenda.length - 1);
                    var legenda = primeiraLetraLegenda + restoLegenda;
                    var valorItem = dataset.data[tooltipItem.index];
                    var porcentagem = (valorItem / total) * 100;
                    return legenda + ': ' + porcentagem.toFixed(1) + '%';
                }
            },
        },
        hover: {
            onHover: function (e: any) {
                var point = this.getElementAtEvent(e);
                if (point.length) { e.target.style.cursor = 'pointer'; }
                else { e.target.style.cursor = 'default'; }
            }
        },
        legend: {
            labels: {
                usePointStyle: true,
                generateLabels(chart: any) {
                    const data = chart.data;

                    return data.labels.map((label: any, i: any) => {
                        const meta = chart.getDatasetMeta(0);
                        const style = meta.controller.getStyle(i);

                        var d, h, m, s, dDisplay, hDisplay, mDisplay, sDisplay, display: any;
                        d = Math.floor(chart.data.datasets[0].data[i] / 86400);
                        h = Math.floor(chart.data.datasets[0].data[i] % 86400 / 3600);
                        m = Math.floor(chart.data.datasets[0].data[i] % 3600 / 60);
                        s = Math.floor(chart.data.datasets[0].data[i] % 3600 % 60);
                        if (d > 0) {
                            if (d == 1) {
                                dDisplay = d + ' dia, ';
                            } else {
                                dDisplay = d + ' dias, ';
                            }
                        } else {
                            dDisplay = '';
                        }
                        if (h > 0) {
                            if (h < 10) {
                                if (h == 1) {
                                    hDisplay = '0' + h + 'h';
                                } else {
                                    hDisplay = '0' + h + 'h';
                                }
                            } else {
                                hDisplay = h + 'h';
                            }
                        } else {
                            hDisplay = '';
                        }
                        if (m > 0) {
                            if (m < 10) {
                                mDisplay = '0' + m + 'min';
                            } else {
                                mDisplay = m + 'min';
                            }
                        } else {
                            mDisplay = '';
                        }
                        if (s > 0) {
                            if (s < 10) {
                                sDisplay = '0' + s + 's';
                            } else {
                                sDisplay = s + 's';
                            }
                        } else {
                            sDisplay = '';
                        }
                        display = dDisplay + hDisplay + mDisplay + sDisplay;

                        return {
                            text: label + ' (' + display + ')',
                            fillStyle: style.backgroundColor,
                            strokeStyle: style.borderColor,
                            lineWidth: style.borderWidth,
                            index: i
                        }
                    })
                },
            },
            position: 'left',
            display: true,
            onHover: function (e: any) {
                e.target.style.cursor = 'pointer'
            },
            onLeave: function (e: any) {
                e.target.style.cursor = 'default'
            }
        },
        responsive: true,
    };
    public pieChartLabels = [] as any;
    public pieChartData = [] as any;
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartColors = [
        {
            backgroundColor: this.arrayCores
        },
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        private graficosService: GraficosService,
    ) { }

    ngOnInit(): void {
        this.buscarTemposTrabalhados();
        this.frotaData = this.data.id_frota;
    }

    buscarTemposTrabalhados() {
        this.graficosService.graficoTemposTrabalhados(this.cliente, this.data.id_frota, this.dataInicial, this.dataFinal).subscribe((resp: any) => {
            if (resp.length === 0) {
                this.validaResp = true;
                this.loading = false;
            } else {
                resp.forEach((tempo: any, index: any) => {
                    tempo.id = parseInt(index);
                    this.d = Math.floor(tempo.tempo / 86400);
                    this.h = Math.floor(tempo.tempo / 3600);
                    this.dDisplay = this.d > 0 ? (this.d + (this.d == 1 ? ' dia' : ' dias')) : "";
                    if (this.h > 0) {
                        if (this.h < 10) {
                            if (this.h == 1) {
                                this.hDisplay = '0' + this.h + ' hora';
                            } else {
                                this.hDisplay = '0' + this.h + ' horas';
                            }
                        } else {
                            this.hDisplay = this.h + ' horas';
                        }
                    } else {
                        this.hDisplay = "";
                    }

                    if (tempo.id_grupo !== null) {
                        this.grupos.push(tempo);
                        this.pieChartLabels.push(tempo.grupo);
                        this.pieChartData.push(tempo.tempo);
                        tempo.dias = this.dDisplay;
                        tempo.horas = this.hDisplay;
                        var percentual = parseFloat((tempo.percentual).toFixed(1));
                        if (tempo.id_grupo == 4) {
                            tempo.cor = '#8C51A5';
                            this.arrayCores.push('#8C51A5');
                        }
                        if (tempo.id_grupo == 2) {
                            tempo.cor = '#CB5E98';
                            this.arrayCores.push('#CB5E98');
                        }
                        if (tempo.id_grupo == 1) {
                            tempo.cor = '#F47B8A';
                            this.arrayCores.push('#F47B8A');
                        }
                        if (tempo.id_grupo == 3) {
                            tempo.cor = '#FFA37E';
                            this.arrayCores.push('#FFA37E');
                        }
                        if (percentual !== 0.0) {
                            tempo.percentual = tempo.percentual.toFixed(1);
                        }
                    }
                    if (tempo.length <= 2) {
                        this.validaResp = true;
                    }
                });
                this.loading = false;
            }
        });
    }

    chartClicked(e: any) {
        var id_grupo, grupo;
        for (let item of this.grupos) {
            if (e.active[0] !== undefined) {
                if (e.active[0]._model.label == item.grupo && item.id_grupo !== null) {
                    id_grupo = item.id_grupo;
                    grupo = item.grupo;
                }
            }
        }
        this.openDialog(id_grupo, grupo, this.frotaData);
    }

    openDialog(id_grupo: any, grupo: any, frota: any) {
        if (grupo !== undefined && grupo !== 'Injustificado' && grupo !== 'Produtivo' &&
            id_grupo !== undefined && id_grupo !== '' && id_grupo !== 0 && id_grupo !== null) {
            this.dialog.open(TemposTiposComponent, {
                minWidth: '80vw',
                height: '70vh',
                data: {
                    frota,
                    id_grupo,
                    grupo,
                    localStorage: 'tempos_trabalhados_frota'
                }
            });
        }
    }
}
