<div class="modelo6 p-2">
  <div class="row" style="height: 50%;">
    <div class="p-1 col-4 h-100">
      <div class="modelo6-1  overflow">
        <ngb-carousel #carousel [interval]='tempoArea1'>
          <ng-template ngbSlide *ngFor="let componente of area1">
            <div class=" centralizar">
              <div class="h-100 w-100">
                <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                </app-abastecimento-motobomba>
                <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                </app-alertas-motobomba>
                <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                </app-consumo-motobomba>
                <app-tempo-efetivo-frota-motobomba *ngIf="componente.c5" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-frota-motobomba>
                <app-tempo-efetivo-os-motobomba *ngIf="componente.c6" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-os-motobomba>
                <app-tempo-efetivo-turno-motobomba *ngIf="componente.c7" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-turno-motobomba>
                <app-tempo-efetivo-os-carretel *ngIf="componente.c8" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-os-carretel>
                <app-tempo-efetivo-turno-carretel *ngIf="componente.c9" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-turno-carretel>
                <app-produtividade-os *ngIf="componente.c10" style="height: 100%; width: 100%;">
                </app-produtividade-os>
                <app-produtividade-turno *ngIf="componente.c11" style="height: 100%; width: 100%;">
                </app-produtividade-turno>
                <app-tempos-trabalhados *ngIf="componente.c13" style="height: 100%;"></app-tempos-trabalhados>
                <app-tempos-trabalhados-frota *ngIf="componente.c14"
                  style="height: 100%;"></app-tempos-trabalhados-frota>
                <app-produtividade-carretel *ngIf="componente.c15" style="height: 100%;"></app-produtividade-carretel>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
    <div class="p-1 col-4 h-100">
      <div class="modelo6-1">
        <ngb-carousel #carousel class="tamanho" [interval]='tempoArea2'>
          <ng-template ngbSlide *ngFor="let componente of area2">
            <div class=" centralizar">
              <div class="h-100 w-100">
                <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                </app-abastecimento-motobomba>
                <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                </app-alertas-motobomba>
                <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                </app-consumo-motobomba>
                <app-tempo-efetivo-frota-motobomba *ngIf="componente.c5" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-frota-motobomba>
                <app-tempo-efetivo-os-motobomba *ngIf="componente.c6" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-os-motobomba>
                <app-tempo-efetivo-turno-motobomba *ngIf="componente.c7" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-turno-motobomba>
                <app-tempo-efetivo-os-carretel *ngIf="componente.c8" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-os-carretel>
                <app-tempo-efetivo-turno-carretel *ngIf="componente.c9" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-turno-carretel>
                <app-produtividade-os *ngIf="componente.c10" style="height: 100%; width: 100%;">
                </app-produtividade-os>
                <app-produtividade-turno *ngIf="componente.c11" style="height: 100%; width: 100%;">
                </app-produtividade-turno>
                <app-tempos-trabalhados *ngIf="componente.c13" style="height: 100%;"></app-tempos-trabalhados>
                <app-tempos-trabalhados-frota *ngIf="componente.c14"
                  style="height: 100%;"></app-tempos-trabalhados-frota>
                <app-produtividade-carretel *ngIf="componente.c15" style="height: 100%;"></app-produtividade-carretel>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
    <div class="p-1 col-4 h-100">
      <div class="modelo6-1">
        <ngb-carousel #carousel class="tamanho" [interval]='tempoArea3'>
          <ng-template ngbSlide *ngFor="let componente of area3">
            <div class=" centralizar">
              <div class="h-100 w-100">
                <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                </app-abastecimento-motobomba>
                <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                </app-alertas-motobomba>
                <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                </app-consumo-motobomba>
                <app-tempo-efetivo-frota-motobomba *ngIf="componente.c5" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-frota-motobomba>
                <app-tempo-efetivo-os-motobomba *ngIf="componente.c6" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-os-motobomba>
                <app-tempo-efetivo-turno-motobomba *ngIf="componente.c7" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-turno-motobomba>
                <app-tempo-efetivo-os-carretel *ngIf="componente.c8" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-os-carretel>
                <app-tempo-efetivo-turno-carretel *ngIf="componente.c9" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-turno-carretel>
                <app-produtividade-os *ngIf="componente.c10" style="height: 100%; width: 100%;">
                </app-produtividade-os>
                <app-produtividade-turno *ngIf="componente.c11" style="height: 100%; width: 100%;">
                </app-produtividade-turno>
                <app-tempos-trabalhados *ngIf="componente.c13" style="height: 100%;"></app-tempos-trabalhados>
                <app-tempos-trabalhados-frota *ngIf="componente.c14"
                  style="height: 100%;"></app-tempos-trabalhados-frota>
                <app-produtividade-carretel *ngIf="componente.c15" style="height: 100%;"></app-produtividade-carretel>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
  <div class="row" style="height: 50%;">
    <div class="p-1 col-4 h-100">
      <div class="modelo6-1">
        <ngb-carousel #carousel class="tamanho" [interval]='tempoArea4'>
          <ng-template ngbSlide *ngFor="let componente of area4">
            <div class=" centralizar">
              <div class="h-100 w-100">
                <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                </app-abastecimento-motobomba>
                <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                </app-alertas-motobomba>
                <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                </app-consumo-motobomba>
                <app-tempo-efetivo-frota-motobomba *ngIf="componente.c5" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-frota-motobomba>
                <app-tempo-efetivo-os-motobomba *ngIf="componente.c6" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-os-motobomba>
                <app-tempo-efetivo-turno-motobomba *ngIf="componente.c7" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-turno-motobomba>
                <app-tempo-efetivo-os-carretel *ngIf="componente.c8" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-os-carretel>
                <app-tempo-efetivo-turno-carretel *ngIf="componente.c9" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-turno-carretel>
                <app-produtividade-os *ngIf="componente.c10" style="height: 100%; width: 100%;">
                </app-produtividade-os>
                <app-produtividade-turno *ngIf="componente.c11" style="height: 100%; width: 100%;">
                </app-produtividade-turno>
                <app-tempos-trabalhados *ngIf="componente.c13" style="height: 100%;"></app-tempos-trabalhados>
                <app-tempos-trabalhados-frota *ngIf="componente.c14"
                  style="height: 100%;"></app-tempos-trabalhados-frota>
                <app-produtividade-carretel *ngIf="componente.c15" style="height: 100%;"></app-produtividade-carretel>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
    <div class="p-1 col-4 h-100">
      <div class="modelo6-1">
        <ngb-carousel #carousel class="tamanho" [interval]='tempoArea5'>
          <ng-template ngbSlide *ngFor="let componente of area5">
            <div class=" centralizar">
              <div class="h-100 w-100">
                <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                </app-abastecimento-motobomba>
                <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                </app-alertas-motobomba>
                <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                </app-consumo-motobomba>
                <app-tempo-efetivo-frota-motobomba *ngIf="componente.c5" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-frota-motobomba>
                <app-tempo-efetivo-os-motobomba *ngIf="componente.c6" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-os-motobomba>
                <app-tempo-efetivo-turno-motobomba *ngIf="componente.c7" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-turno-motobomba>
                <app-tempo-efetivo-os-carretel *ngIf="componente.c8" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-os-carretel>
                <app-tempo-efetivo-turno-carretel *ngIf="componente.c9" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-turno-carretel>
                <app-produtividade-os *ngIf="componente.c10" style="height: 100%; width: 100%;">
                </app-produtividade-os>
                <app-produtividade-turno *ngIf="componente.c11" style="height: 100%; width: 100%;">
                </app-produtividade-turno>
                <app-tempos-trabalhados *ngIf="componente.c13" style="height: 100%;"></app-tempos-trabalhados>
                <app-tempos-trabalhados-frota *ngIf="componente.c14"
                  style="height: 100%;"></app-tempos-trabalhados-frota>
                <app-produtividade-carretel *ngIf="componente.c15" style="height: 100%;"></app-produtividade-carretel>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
    <div class="p-1 col-4 h-100">
      <div class="modelo6-1">
        <ngb-carousel #carousel class="tamanho" [interval]='tempoArea6'>
          <ng-template ngbSlide *ngFor="let componente of area6">
            <div class=" centralizar">
              <div class="h-100 w-100">
                <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                </app-abastecimento-motobomba>
                <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                </app-alertas-motobomba>
                <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                </app-consumo-motobomba>
                <app-tempo-efetivo-frota-motobomba *ngIf="componente.c5" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-frota-motobomba>
                <app-tempo-efetivo-os-motobomba *ngIf="componente.c6" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-os-motobomba>
                <app-tempo-efetivo-turno-motobomba *ngIf="componente.c7" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-turno-motobomba>
                <app-tempo-efetivo-os-carretel *ngIf="componente.c8" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-os-carretel>
                <app-tempo-efetivo-turno-carretel *ngIf="componente.c9" style="height: 100%; width: 100%;">
                </app-tempo-efetivo-turno-carretel>
                <app-produtividade-os *ngIf="componente.c10" style="height: 100%; width: 100%;">
                </app-produtividade-os>
                <app-produtividade-turno *ngIf="componente.c11" style="height: 100%; width: 100%;">
                </app-produtividade-turno>
                <app-tempos-trabalhados *ngIf="componente.c13" style="height: 100%;"></app-tempos-trabalhados>
                <app-tempos-trabalhados-frota *ngIf="componente.c14"
                  style="height: 100%;"></app-tempos-trabalhados-frota>
                <app-produtividade-carretel *ngIf="componente.c15" style="height: 100%;"></app-produtividade-carretel>
              </div>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
</div>
