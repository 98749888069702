import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { CaracteristicasService } from 'src/app/service/caracteristicas.service';

@Component({
    selector: 'app-edita-caracteristicas',
    templateUrl: './edita-caracteristicas.component.html',
    styleUrls: ['./edita-caracteristicas.component.css']
})
export class EditaCaracteristicasComponent implements OnInit {

    public addDataForm!: FormGroup;
    public idCaracteristica!: number;
    caracteristica: any;
    status: any;
    public cliente: any;
    public idValor: any;
    public disabled = true;

    constructor(
        private formBuilder: FormBuilder,
        private location: Location,
        private route: ActivatedRoute,
        private caractersticaService: CaracteristicasService,
        private alertService: AlertService) { }

    ngOnInit(): void {

        this.addDataForm = this.formBuilder.group({
            name: [null, Validators.required],
            tipo: [null, Validators.required],
            atribuicao: [null, Validators.required]
        })
        this.route.params.subscribe((params: Params) => {
            this.idCaracteristica = Number(params.id);
            this.caractersticaService.AcharPorId(this.idCaracteristica).subscribe((carac: any) => {
                this.status = carac.vc_estado;
                this.cliente = carac.fk_cliente;
                this.idValor = carac.sr_id;
                this.addDataForm.get("name")?.setValue(carac.vc_nome);
                this.addDataForm.get("tipo")?.setValue(carac.vc_tipo);
                if (carac.fk_equipamento == 0) {
                    this.addDataForm.get("atribuicao")?.setValue("equipamento");
                } else {
                    this.addDataForm.get("atribuicao")?.setValue("operacao");
                }
            })
        })

    }

    done() {
        if (this.addDataForm.get("atribuicao")?.value == "equipamento") {
            this.caracteristica = {
                "vc_nome": this.addDataForm.get("name")?.value,
                "vc_tipo": this.addDataForm.get("tipo")?.value,
                "vc_estado": this.status,
                "fk_cliente": this.cliente,
                "fk_equipamento": 0,
                "fk_operacao": null,
                "idValor": this.idValor
            }
        } else {
            this.caracteristica = {
                "vc_nome": this.addDataForm.get("name")?.value,
                "vc_tipo": this.addDataForm.get("tipo")?.value,
                "vc_estado": this.status,
                "fk_cliente": this.cliente,
                "fk_equipamento": null,
                "fk_operacao": 0,
                "idValor": this.idValor
            }
        }
        this.caractersticaService.Atualizar(this.caracteristica, this.idCaracteristica).subscribe(caracteristica => {
            this.alertService.success('Caracteristica atualizada com sucesso')
            this.location.back()
        }, err => {
        })
    }

    public cancel(): void {
        this.location.back();
    }
}
