<main style="width: 96%;margin: 0 2rem;">
    <div class="cabecalho">
        <div class="d-flex">
            <h1>Layouts</h1>
            <div class="dropdown">
                <clr-icon shape="info-standard" class="info-icone"></clr-icon>
                <div class="dropdown-content">
                    <p>Listagem de todos os layouts que o usuários tem acesso, sejam eles de grupos, pessoais ou da
                        usina</p>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <span class="info-listas">lista de todos os layouts</span>
            <div>
                <button class="botao-criar" routerLink="../modelos">
                    Novo layout
                </button>
            </div>
        </div>
    </div>
    <div fxLayout="column" style="margin: 1rem 0;">
        <div *ngIf="loading" style="width: auto; height: 60vh;" fxLayout="row" fxLayoutAlign="center center">
            <div class="spinner"></div>
        </div>
        <div class="mensagem">
            <p *ngIf="validaResp" style="text-align: center;">Não há layouts cadastrados</p>
        </div>
        <table mat-table [dataSource]="layouts" matSort *ngIf="!loading" class="mt-3">
            <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef>
                    Nome
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.vc_nome ? element.vc_nome : "" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="modelo">
                <th mat-header-cell *matHeaderCellDef>
                    Modelo
                </th>
                <td mat-cell *matCellDef="let element">
                    <img src="../../assets/icone-layout1.png" *ngIf="element.fk_layout==1">
                    <img src="../../assets/icone-layout2.png" *ngIf="element.fk_layout==2">
                    <img src="../../assets/icone-layout3.png" *ngIf="element.fk_layout==3">
                    <img src="../../assets/icone-layout4.png" *ngIf="element.fk_layout==4">
                    <img src="../../assets/icone-layout5.png" *ngIf="element.fk_layout==5">
                    <img src="../../assets/icone-layout6.png" *ngIf="element.fk_layout==6">
                </td>
            </ng-container>

            <ng-container matColumnDef="componentes">
                <th mat-header-cell *matHeaderCellDef>
                    Componentes
                </th>
                <td mat-cell *matCellDef="let element" style="padding-right:2rem">
                    {{ element.layouts_personalizados_itens ? element.layouts_personalizados_itens : "" }}
                </td>
            </ng-container>


            <ng-container matColumnDef="criado">
                <th mat-header-cell *matHeaderCellDef>
                    Criado em:
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.ts_criado_em | date: "dd/MM/yyyy HH:mm:ss" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="atualizado">
                <th mat-header-cell *matHeaderCellDef>
                    Atualizado em:
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.ts_atualizado_em | date: "dd/MM/yyyy HH:mm:ss" }}
                </td>
            </ng-container>

            <ng-container matColumnDef="controls">
                <th mat-header-cell class="align-center" *matHeaderCellDef>
                </th>
                <td mat-cell class="align-center" *matCellDef="let element" class="td-botoes">
                    <div class="d-flex justify-content-between">
                        <button class="botao-editar d-flex" routerLink="../editar/{{ element.sr_id }}">
                            <clr-icon shape="pencil"></clr-icon> Editar
                        </button>
                        <button class="botao-excluir d-flex" (click)="desativar(element.sr_id)" *ngIf="verificarPermissao(['deletar_fazendas_hidrometer'])">
                            <clr-icon shape="trash"></clr-icon>Desativar
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</main>