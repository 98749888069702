import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BASE_URL_PREMOCENTER_FRONT } from 'src/app/app.constants';
import { VerificarPermissoes } from 'src/app/model/verificar-premissao.model';
import { AlertService } from 'src/app/service/alert.service';
import { CaracteristicasService } from 'src/app/service/caracteristicas.service';
import { OperacoesService } from 'src/app/service/operacoes.service';
import Swal from 'sweetalert2';
import * as _ from 'underscore';
import { DialogEditarFaixasComponent } from '../dialog-editar-faixas/dialog-editar-faixas.component';
import { RedirectService } from 'src/app/service/redirect.service';

@Component({
  selector: 'app-listar-operacoes',
  templateUrl: './listar-operacoes.component.html',
  styleUrls: ['./listar-operacoes.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ListarOperacoesComponent implements OnInit {
  public columnsToDisplay = [
    'ts_criado_em',
    'ts_atualizado_em',
    'vc_estado',
    'vc_service_id',
    'trator',
    'motobomba',
    'carretel',
    'fk_ultimo_operador',
    'controls',
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public expandedElement: any | null;
  public formFilter!: FormGroup;
  public innerDisplayedColumnsOp: string[] = [
    'id',
    'name',
    'value',
    'controls',
  ];
  public client: any;
  public tractorId!: number;
  public equipmentIdFeatures: any;
  LocalStorageId = localStorage.getItem('cliente_filtro');
  clients = JSON.parse(localStorage.getItem('cliente') || '{}');
  urlPremoplan = BASE_URL_PREMOCENTER_FRONT;
  length: any;
  next: any;
  operacao: any;
  loading = true;
  validaResp = false;
  text!: string;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Output() toggleChange!: EventEmitter<void>;

  constructor(
    private operationService: OperacoesService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public caracteristicaService: CaracteristicasService,
    private alertService: AlertService,
    public appService: RedirectService
  ) { }

  ngOnInit(): void {
    localStorage.setItem('kanban', 'false');
    this.initializeInvites();
  }

  ngOnDestroy(): void { }

  onChange() {
    this.loading = true;
    this.validaResp = false;
    this.formFilter.get('client')?.valueChanges.subscribe((clientId: any) => {
      localStorage.setItem('cliente_filtro', clientId);
      this.LocalStorageId = clientId;
      this.appService.enviarMudancaDeCliente();
      this.initializeInvites();
    });
  }

  public initializeInvites() {
    this.length = 0;
    this.formFilter = this.formBuilder.group({
      client: [null, Validators.required],
    });
    if (this.LocalStorageId != null) {
      this.formFilter.get('client')?.setValue(this.LocalStorageId);
      this.operationService
        .AcharPorClientePaginate(this.LocalStorageId, 100)
        .subscribe(
          (data: any) => {
            if (data.data.length == 0) {
              this.validaResp = true;
            }
            data.data.forEach((element: any) => {
              element.trator = element.trator_frota[0]?.vc_frota;
              element.motobomba = element.equipamentos.filter(function (
                equip: any
              ) {
                return equip.vc_tipo == 'MOTOR_PUMP';
              });
              element.carretel = element.equipamentos.filter(function (
                equip: any
              ) {
                return equip.vc_tipo == 'REEL';
              });
              var moto = element.motobomba.map((item: any) => item.sr_id);
              var car = element.carretel.map((item: any) => item.sr_id);
              element.equipamentos = [...moto, ...car];
            });
            this.length = data.total;
            this.operacao = data.data;
            this.dataSource.data = this.operacao;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.next = data.next_page_url;
            this.loading = false;
          },
          (err: any) => {
            if (err.statusText == 'Unauthorized') {
              window.location.href = `${this.urlPremoplan}`;
            }
          }
        );
    }
    this.onChange();
  }

  public trocarPagina(event: any) {
    this.loading = true;
    this.operationService.Next(this.next).subscribe((data: any) => {
      this.operacao = this.operacao.filter(function (i: any) {
        return i;
      });
      data.data.forEach((element: any) => {
        element.trator = element.trator_frota[0]?.vc_frota;
        element.motobomba = element.equipamentos.filter(function (equip: any) {
          return equip.vc_tipo == 'MOTOR_PUMP';
        });
        element.carretel = element.equipamentos.filter(function (equip: any) {
          return equip.vc_tipo == 'REEL';
        });
        var moto = element.motobomba.map((item: any) => item.sr_id);
        var car = element.carretel.map((item: any) => item.sr_id);
        element.equipamentos = [...moto, ...car];
      });
      this.operacao.push(...data.data);
      this.operacao.length = data.total;
      this.dataSource.data = this.operacao;
      this.next = data.next_page_url;
      this.loading = false;
    });
  }

  public delete(operationId: number): void {
    Swal.fire({
      title: 'Excluir OS?',
      showCancelButton: true,
      confirmButtonColor: '#41B6E6',
      cancelButtonColor: '#df4747',
      confirmButtonText: 'Confirmar!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.desativarOperacao(operationId);
      }
    });
  }

  desativarOperacao(operationId: number) {
    this.operationService.Desativar(operationId).subscribe(
      (object: any) => {
        this.alertService.success('OS excluída com sucesso!');
        this.initializeInvites();
      },
      (err: any) => {
        this.alertService.exibirMensagemErro(err);
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public changeState(operationId: number, operation: any, state: any): void {
    Swal.fire({
      title: 'Deseja alterar o status da OS?',
      showCancelButton: true,
      confirmButtonColor: '#41B6E6',
      cancelButtonColor: '#df4747',
      confirmButtonText: 'Confirmar!',
    }).then((result: any) => {
      if (result.isConfirmed) {
        if (operation.equipamentos.length == 0) {
          operation = {
            vc_service_id: operation.vc_service_id,
            vc_estado: state,
            cliente_hidrometer: operation.fk_cliente,
            fk_equipamento_trator: operation.fk_equipamento_trator,
            fk_fazenda: operation.fk_fazenda,
          };
        } else {
          operation = {
            vc_service_id: operation.vc_service_id,
            vc_estado: state,
            cliente_hidrometer: operation.fk_cliente,
            fk_equipamento_trator: operation.fk_equipamento_trator,
            fk_fazenda: operation.fk_fazenda,
            fk_equipamentos: operation.equipamentos,
          };
        }
        this.operationService.AtualizarStatus(operation, operationId).subscribe(
          (operacao: any) => {
            this.alertService.success('Status da OS editada com sucesso');
            this.initializeInvites();
          },
          (err: any) => {
            this.alertService.exibirMensagemErro(err, 'Erro ao editar status da OS');
          }
        );
      }
    });
  }

  public openDialog(operacaoid: any, serviceId: any, operacao: any): void {
    const dialogRef = this.dialog.open(DialogEditarFaixasComponent, {
      minWidth: '510px',
      data: [operacaoid, serviceId, operacao],
    });
  }

  public verificarPermissao(permissoesFuncionalidades: string[]): boolean {
    const permissaoUsuario = localStorage.getItem('permissoes');
    return VerificarPermissoes.temPermissao(
      permissoesFuncionalidades,
      permissaoUsuario
    );
  }

  public AbrirModalDeErroComMensagem(mensagem: string) {
    this.alertService.error(mensagem);
  }
}
