<div class="d-flex justify-content-center">
  <div class="bg-white modal-fechado content d-block d-md-none" id="modal-mobile">
    <app-hectares-produzidos-mobile></app-hectares-produzidos-mobile>
  </div>
  <div id="botaoMobile" class="d-md-none botao-expand" style="z-index: 1;">
    <div class="icon" (click)="toggleContentMobile()">
      <img id="btn-open" src="assets/icones-botoes-acao-mapa/hectares-mobile-icone.svg" alt="Turnos"
        matTooltip="Produtividade Por Turnos" matTooltipPosition="after" matTooltipShowDelay="500">

      <img src="assets/icones-botoes-acao-mapa/modal-aberto.svg" id="btn-branco" alt="Ícone de fechar"
        style="z-index: 2" class="fechado me-1">

      <img src="assets/icones-botoes-acao-mapa/close.svg" id="btn-close" alt="Ícone de fechar" style="z-index: 3"
        class="fechado">
    </div>
  </div>
</div>