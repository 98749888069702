import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { MensagensService } from 'src/app/service/mensagens.service';
import { MensagemParadaMapaComponent } from '../mensagem-parada-mapa/mensagem-parada-mapa.component';

@Component({
  selector: 'app-detalhes-motivo',
  templateUrl: './detalhes-motivo.component.html',
  styleUrls: ['./detalhes-motivo.component.css']
})
export class DetalhesMotivoComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;

    public displayedColumns: string[] = [
        "motivo",
        "frota",
        "os",
        "inicio",
        "fim",
        "tempo",
    ];
    public detalhes = [] as any;
    public dataSource = new MatTableDataSource();
    length: any;
    invalido = false;
    cliente:any
    token:any
    dataInicial:any
    dataFinal:any
    frotas = [] as any
    load = true;
    temLocalizacao = false;
    color: ThemePalette = 'primary';
    mode: ProgressSpinnerMode = 'indeterminate';
    
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mensagemService: MensagensService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.localStorage();
    this.buscarDetalhesMotivo();
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  localStorage() {
    this.cliente = localStorage.getItem("cliente_filtro");
    this.frotas = localStorage.getItem("frotas_filtro");
    this.dataInicial = localStorage.getItem("DateStartGraficos");
    this.dataFinal = localStorage.getItem("DateEndGraficos");
  }

  buscarDetalhesMotivo() {
    if(this.data.frota !== undefined) {
        this.frotas = this.data.frota;
    } else {
        this.frotas = localStorage.getItem(this.data.localStorage);
    }
      this.mensagemService.detalhesParada(this.cliente, this.frotas, this.dataInicial, this.dataFinal, this.data.id_tipo).subscribe((resp: any) => {
        var detalhesFormatado = [] as any;
        var sem, d, h, m, s, semDisplay, dDisplay, hDisplay, mDisplay, sDisplay, detalheduracaoParada, dataHoraInicio, dataHoraFim: any;
        for(let detalhe of resp) {
            detalhe.motivo = !detalhe.motivo ? "" : detalhe.motivo;
            detalhe.ordemServico = !detalhe.ordemServico ? "" : detalhe.ordemServico;
            detalhe.frota = !detalhe.frota ? "" : detalhe.frota;
            detalhe.lat = !detalhe.lat ? "" : detalhe.lat;
            detalhe.lng = !detalhe.lng ? "" : detalhe.lng;
            detalhe.inicio = !detalhe.inicio ? "" : detalhe.inicio;
            detalhe.fim = !detalhe.fim ? "" : detalhe.fim;
            detalhe.duracaoParada = !detalhe.duracaoParada ? "" : detalhe.duracaoParada;

            if(detalhe.motivo == "" && detalhe.ordemServico == "" && detalhe.frota == "" && detalhe.lat == ""
                && detalhe.lng == "" && detalhe.inicio == "" && detalhe.fim == "" && detalhe.duracaoParada == "") {
                this.invalido = true;
            }
            d = Math.floor(detalhe.duracaoParada / 86400);
            if(d >= 7) {
                var resto = d % 7;
                sem = resto == 0 ? d / 7 : ((d - resto)/ 7);
                d = resto !== 0 ? resto : "";
            } else {
                sem = 0;
            }
            h = Math.floor(detalhe.duracaoParada % 86400 / 3600);
            m = Math.floor(detalhe.duracaoParada % 3600 / 60);
            s = Math.floor(detalhe.duracaoParada % 3600 % 60);
            
            if(sem > 0) {
                if(sem == 1) {
                    semDisplay = s > 0 || m > 0  || h > 0 || d > 0 ? sem + ' semana, ' : sem + ' semana';
                } else {
                    semDisplay = s > 0 || m > 0  || h > 0 || d > 0 ? sem + ' semanas, ' : sem + ' semanas';
                }
            } else {
                semDisplay = "";
            }

            if(d > 0) {
                if(d == 1) {
                    dDisplay = s > 0 || m > 0  || h > 0 ? d + ' dia, ' : d + ' dia';
                } else {
                    dDisplay = s > 0 || m > 0  || h > 0 ? d + ' dias, ' : d + ' dias';
                }
            } else {
                dDisplay = "";
            }

            if(h > 0) {
                if(h < 10) {
                    hDisplay = s > 0 || m > 0 ? '0' + h + 'h ' : '0' + h + 'h';
                } else {
                    hDisplay = s > 0 || m > 0 ? h + 'h ' : h + 'h';
                }
            } else {
                hDisplay = "";
            }

            if(m > 0) {
                if(m < 10) {
                    mDisplay = s > 0 ? '0' + m + 'min ' : '0' + m + 'min';
                } else {
                    mDisplay = s > 0 ? m + 'min ' : m + 'min';
                }
            } else {
                mDisplay = "";
            }
            
            if(s > 0) {
                if(s < 10) {
                    sDisplay = '0' + s + 's';
                } else {
                    sDisplay = s + 's';
                }
            } else {
                sDisplay = "";
            }
            detalheduracaoParada = semDisplay + dDisplay + hDisplay + mDisplay + sDisplay;
            if(detalhe.inicio == detalhe.fim) {
                detalheduracaoParada = "0s";
            }
            detalhe.duracaoParada = detalheduracaoParada;
            detalhesFormatado.push(detalhe);
        }
        this.detalhes = detalhesFormatado;
        this.length = this.detalhes.length;
        this.dataSource.data = this.detalhes;
      
        this.load = false;
    })
  }

  chartClicked(e: any){
    this.openDialog(e);
  }

  openDialog(e:any){
        this.dialog.open(MensagemParadaMapaComponent, {
            width: '40vw',
            height: '70vh',
            data: e
        });
    }

}
