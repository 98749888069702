import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BASE_URL_PREMOCENTER_FRONT } from '../app.constants';
import { VerificarPermissoes } from '../model/verificar-premissao.model';
import { AlertaSessaoService } from '../service/alerta-sessao.service';
import { TutorialComponent } from '../tutorial/tutorial.component';
import { VersaoService } from '../service/versao.service';
import { AnimationEvent } from '@angular/animations';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  modalAbrindo: boolean = false;
  modalAberto: string = '';
  mapaLoader: any;
  utc = false;
  brt = false;
  urlPremocenter = BASE_URL_PREMOCENTER_FRONT;
  token = localStorage.getItem('token');
  versao = '';
  open: boolean = false;
  conteudoSideNav = [
    {
      "nome": "Fuso horário",
      "icone": "assets/icones-sideBar/timezoneGlobe.svg",
      "toggle": "collapse",
      "target": '#collapseModalHorario',
      "controls": "collapseModalGrafico",
    },
    {
      "nome": "Gráficos",
      "icone": "assets/icones-sideBar/pizza.svg",
      "toggle": "collapse",
      "target": '#collapseModalGrafico',
      "controls": "collapseModalGrafico",
    },
    {
      "nome": "Carac. adicionais",
      "icone": "assets/icones-sideBar/caracteristicas.svg",
      "link": "hidrometer/caracteristicas/listar",
    },
    {
      "nome": "Operações",
      "icone": "assets/icones-sideBar/operacoes.svg",
      "link": "hidrometer/operacoes/listar",
    },
    {
      "nome": "Kanban de operações",
      "icone": "assets/icones-sideBar/kanban.svg",
      "toggle": "collapse",
      "target": '#collapseModalKanban',
      "controls": "collapseModalGrafico",
    },
    {
      "nome": "Turnos",
      "icone": "assets/icones-sideBar/turnos.svg",
      "link": "hidrometer/turnos/listar",
    },
    {
      "nome": "Importar shape",
      "icone": "assets/icones-sideBar/shape.svg",
      "link": "hidrometer/importarArquivos",
    },
    {
      "nome": "Equipamentos",
      "icone": "assets/icones-sideBar/equipamentos.svg",
      "link": "hidrometer/equipamentos/listar",
    },
    {
      "nome": "Operadores",
      "icone": "assets/icones-sideBar/operadores.svg",
      "link": "hidrometer/operadores/listar",
      "permissao": "listar_operadores",
    },
    {
      "nome": "Histórico de atualizações",
      "icone": "assets/icones-sideBar/updates.svg",
      "link": "/hidrometer/changeLog",
    },
    {
      "nome": "Retornar ao mapa",
      "icone": "assets/icones-sideBar/mapa.svg",
      "link": "/hidrometer",
    },
    {
      "nome": "Metas",
      "icone": "assets/icones-sideBar/icone-metas-hidrometer.svg",
      "link": "/hidrometer/metas",
      "permissao": "visualizar_meta",
    }
  ];

  private modalMap: { [key: string]: string } = {
    'collapseModalHorario': 'botaoSetaFecharHorario',
    'collapseModalGrafico': 'botaoSetaFecharGrafico',
    'collapseModalKanban': 'botaoSetaFecharKanban'
  };

  constructor(
    public dialog: MatDialog,
    public versaoService: VersaoService,
    private alertaSessao: AlertaSessaoService,
  ) { }

  ngOnInit(): void {
    this.tutorial();
    const horario = localStorage.getItem('horario');
    this.brt = horario == null || horario == 'brt';
    this.utc = horario == 'utc';
    this.iniciarValidacaoDaSessao();
  }
  iniciarValidacaoDaSessao() {
    this.alertaSessao.alertaSessaoService();
  }

  alterarEstadoModal(e: Event) {
    const modal = e.target as HTMLElement;
    this.modalAbrindo = true;

    modal.addEventListener('shown.bs.collapse', () => {
      this.modalAbrindo = false;
    })
  }

  shownModal(event: Event): void {
    this.closeModal();
    if (this.modalAbrindo) {
      event.preventDefault();
      return
    }
    this.alterarEstadoModal(event);
    const targetElement = event.target as HTMLElement;
    this.modalAberto = targetElement.id;
  }

  closeModal(): void {
    const botaoFecharId = this.modalMap[this.modalAberto];
    const botaoFechar = document.getElementById(botaoFecharId);

    if (botaoFechar) {
      const eventoClique = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      });

      botaoFechar.dispatchEvent(eventoClique);
    }
  }

  tutorial(): void {
    this.versaoService.validaExibicaoAtualizacoes().subscribe((resp: any) => {
      this.versao = resp.versao;
      if (resp.exibir && resp.dadosDaVersao != null) {
        const dialogRef = this.dialog.open(TutorialComponent, {
          width: '45vw',
          height: '45vh',
          data: resp.dadosDaVersao,
          disableClose: true,
          panelClass: 'no-padding-dialog',
        });
      }
    })
  }

  trocarHorario(): void {
    localStorage.setItem('horario', localStorage.getItem('horario') === 'brt' ? 'utc' : 'brt');
    window.location.reload();
  }

  retornarPremocenter(): void {
    localStorage.removeItem('cliente');
    localStorage.removeItem('token');
    localStorage.removeItem('permissoes');
    this.redireciona();
  }

  redireciona(): void {
    window.location.href = `${this.urlPremocenter}/redirect/${this.token}`;
  }

  verificarPermissao(permissoesFuncionalidades: string[]): boolean {
    const permissaoUsuario = localStorage.getItem('permissoes');
    return VerificarPermissoes.temPermissao(
      permissoesFuncionalidades,
      permissaoUsuario
    );
  }

  acaoMenu(condition: boolean): void {
    this.abrirOufecharMenu(condition)
    this.open = condition
    if (condition) {
      this.atualizarBotoesMenu('Abrir')
      this.moverModal('Abrir')
    } else {
      this.atualizarBotoesMenu('Fechar')
      this.moverModal('Fechar')
    }
  }

  abrirOufecharMenu(option: boolean): void {
    if (option) {
      let nav = document.getElementById('nav')
      nav?.classList.remove('nav-fechada')
      nav?.classList.add('nav-aberta')
    } else {
      let nav = document.getElementById('nav')
      nav?.classList.remove('nav-aberta')
      nav?.classList.add('nav-fechada')
    }
  }


  atualizarBotoesMenu(acao: 'Abrir' | 'Fechar'): void {
    ['botaoSetaHorario', 'botaoSetaGrafico', 'botaoSetaKanban'].forEach((id) => {
      let botao = document.getElementById(id);
      botao?.classList.toggle('botaoSetaAbrir', acao === 'Abrir');
      botao?.classList.toggle('botaoSetaFechar', acao === 'Fechar');
    });
  }

  moverModal(acao: 'Abrir' | 'Fechar'): void {
    ['modalGraficoHorario', 'modalGraficoGrafico', 'modalKanban'].forEach((id) => {
      let modal = document.getElementById(id);
      if (id === 'modalKanban') {
        modal?.classList.toggle('kanbanMenuAberto', acao === 'Abrir');
      } else {
        modal?.classList.toggle('modalAberto', acao === 'Abrir');
      }
    });
  }

  irParaMapa(): void {
    window.location.href = '/hidrometer';
  }

  irParaChangelog(): void {
    window.location.href = '/hidrometer/changeLog';
  }

  itemDeveAparecer(item: any): boolean {
    let permissao: string = item['permissao'];
    var temPermissao: boolean = permissao ? this.verificarPermissao([permissao]) : true;
    if (temPermissao && item?.link) {
      return true;
    }
    return false;
  }
}
