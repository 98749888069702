import { error } from 'console';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BASE_URL_PREMOCENTER_FRONT } from '../app.constants';
import { AlertService } from '../service/alert.service';
import { ArquivosService } from '../service/arquivos.service';
import { MAPBOX_TOKEN } from '../app.constants';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-importar-arquivos',
  templateUrl: './importar-arquivos.component.html',
  styleUrls: ['./importar-arquivos.component.css']
})
export class ImportarArquivosComponent implements OnInit {

  premocenter = BASE_URL_PREMOCENTER_FRONT
  formShape!: FormGroup;
  token!: any;
  clients = JSON.parse(localStorage.getItem('cliente') || "{}")
  fileName = [] as any;
  file = [] as any;
  LocalStorageId = localStorage.getItem("cliente_filtro");
  loading = false;
  map!: mapboxgl.Map;

  constructor(
    private formBuilder: FormBuilder,
    public shapeService: ArquivosService,
    public alertService: AlertService) { }

  ngOnInit(): void {
    this.createForm();
    this.criarMapa();
  }

  createForm() {
    this.formShape = this.formBuilder.group({
      fk_cliente: [null, Validators.required],
      tipo_arquivo: [null, Validators.required]
    })
    this.formShape.get("fk_cliente")?.setValue(this.LocalStorageId);
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      if (this.isValidFile(file)) {
        this.fileName.push(file.name);
        this.file.push(file);

        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileContent: string = e.target.result as string;
          this.inserirNoMapa(fileContent, file.name);
        };
        reader.readAsText(file);
      } else {
        console.error('Arquivo inválido. Apenas arquivos .json ou .geojson são permitidos.');
        this.alertService.error('Arquivo inválido.');
      }
    }
  }

  isValidFile(file: File): boolean {
    const alloweExtensions = ['.json', '.geojson'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
    return alloweExtensions.includes(`.${fileExtension}`);
  }

  upload() {
    this.loading = true;
    if (this.formShape.value.tipo_arquivo == "shape") {
      this.file.forEach((file: any) => {
        var formData: any = new FormData();
        formData.append("arquivo", file);
        formData.append("fk_cliente", this.formShape.value.fk_cliente);
        this.shapeService.importarShape(formData).subscribe((resp: any) => {
          this.alertService.success("Arquivo " + file.name + " enviado com sucesso");
          this.loading = false;
          this.tirarFile(file.name);
        }, error => {
          this.alertService.error(error.error.mensagem, error.error.status)
          this.loading = false;
        })
      });
    } else {
      this.file.forEach((file: any) => {
        var formData: any = new FormData();
        formData.append("arquivo", file);
        formData.append("fk_cliente", this.formShape.value.fk_cliente);
        this.shapeService.importarLinha(formData).subscribe((resp: any) => {
          this.alertService.success("Arquivo " + file.name + " enviado com sucesso");
          this.loading = false;
          this.tirarFile(file.name);
        }, error => {
          this.alertService.error(error.error.mensagem, error.error.status)
          this.loading = false;
        })
      });
    }
  }

  tirarFile(file: any) {
    var indice = this.fileName.indexOf(file);
    console.log("Estou no tirarFile", file);
    this.removerGeojsonDoMapa(file);
    this.fileName.splice(indice, 1);
    this.file.splice(indice, 1);
  }

  criarMapa() {
    var cliente = this.clients.filter((cliente: any) => {
      return cliente.id == this.LocalStorageId
    });
    (mapboxgl as any).accessToken = MAPBOX_TOKEN;
    this.map = new mapboxgl.Map({
      container: 'map',
      center: [cliente[0].longitude, cliente[0].latitude],
      zoom: 12
    });
    this.map.setStyle('mapbox://styles/mapbox/satellite-streets-v11?optimize=true');
  }

  inserirNoMapa(arquivo: string, nomeArquivo: string) {
    const geojson = JSON.parse(arquivo);
    var nome = 'arquivo' + nomeArquivo;
    console.log("Estou no inserirNoMapa", nome);
    this.map.addSource(nome, {
      type: 'geojson',
      data: geojson
    });
    this.map.addLayer({
      'id': nome,
      'type': 'fill',
      'source': nome,
      'paint': {
        'fill-color': this.gerarCorAleatoria(),
        'fill-opacity': 0.5
      }
    });
    var coordenadas = geojson.features[0].geometry.coordinates[0][0];
    this.map.setCenter(coordenadas.length == 3 ? coordenadas : coordenadas[0])
  }

  gerarCorAleatoria(): string {
    const letrasHex = '0123456789ABCDEF';
    let cor = '#';
    for (let i = 0; i < 6; i++) {
      cor += letrasHex[Math.floor(Math.random() * 16)];
    }
    return cor;
  }

  removerGeojsonDoMapa(nomeArquivo: string) {
    var nome = 'arquivo' + nomeArquivo;
    console.log("Estou no removerGeoJsonDoMapa", nome);
    this.map.removeLayer(nome);
    this.map.removeSource(nome);
  }
}