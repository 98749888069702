import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { BASE_URL_HIDROMETER, BASE_URL_PREMOCENTER } from '../app.constants';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  BASE_URL_PREMOCENTER = BASE_URL_PREMOCENTER;
  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;
  public informacoesUltimaMensagem = new EventEmitter();
  public carregarMapa = new EventEmitter();
  public mudancaCliente = new EventEmitter();
  public mensagemWebSocket = new EventEmitter();
  public loaderMensagemWebSocket = new EventEmitter();

  public enviaEquipamentosCliente = new EventEmitter();
  public recebeLatLngEquipamento = new EventEmitter();
  public descricaoElementoExpandidoSobreMapa = new EventEmitter();

  constructor(
    private http: HttpClient
  ) { }

  CarregarDados(token: any) {
    const url = `${this.BASE_URL_PREMOCENTER}usuarios/informacoes/hidrometer`;
    let tokenJson = new HttpParams().set('token', token);
    return this.http.get(url, { params: tokenJson });
  }

  temUsuarioLogado() {
    const token = this.obterToken();
    if (!token) {
      return false;
    } else if (this.tokenExpirado(token)) {
      return false;
    } else {
      return true;
    }
  }

  obterToken() {
    const token = window.localStorage.getItem('token')
    return token
  }

  tokenExpirado(token?: string): boolean {
    if (!token) {
      true
    }
    const date = this.obterValidadeToken(token!);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  obterValidadeToken(token: string): Date {
    const decoded: any = jwt_decode.default(token);

    if (decoded.exp === undefined) {
      return null!;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  Autenticar(token: any) {
    var jwt = new Object();
    jwt = { 'token': token }
    let tokenJson = new HttpParams().set('token', token);
    let url = `${this.BASE_URL_PREMOCENTER}validatoken`;
    return this.http.get(url, { params: tokenJson });
  }

  reconectar(usuario: any) {
    var url = `${BASE_URL_PREMOCENTER}login`
    return this.http.post(url, usuario)
  }

  mandarValorInformacoesUltimaMensagem(valor: any) {
    this.informacoesUltimaMensagem.emit(valor);
  }

  enviarDadosFiltros() {
    this.carregarMapa.emit();
  }

  novaMensagemWebSocket() {
    this.mensagemWebSocket.emit();
  }

  exibirLoaderMensagemWebSocket(exibirLoader: boolean) {
    this.loaderMensagemWebSocket.emit(exibirLoader);
  }

  usuarioDados(token: any) {
    const url = `${this.BASE_URL_HIDROMETER}usuariosInformações`;
    var headerOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })
    };
    return this.http.get(url, headerOptions);
  }

  enviarMudancaDeCliente() {
    this.mudancaCliente.emit();
  }

  mapaEnviaEquipamentos(equipamentos: any) {
    this.enviaEquipamentosCliente.emit(equipamentos);
  }

  mapaRecebeLatLngEquipamento(latLng: { latitude: number; longitude: number; }) {
    this.recebeLatLngEquipamento.emit(latLng);
  }

  elementoExpandidoSobreMapa(elemento: String) {
    this.descricaoElementoExpandidoSobreMapa.emit(elemento);
  }

}