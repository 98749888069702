<main id="componente-meta">
  <div class="row superior align-items-center mx-4">
    <div class="col-12 col-md-6 flex-md-column p-0">
      <div class="row">
        <div class="col-12 flex-column col-md-6">
          <h1 class="my-0">Metas e indicadores</h1>
          <p class="mt-2">Última atualização: {{ meta ? meta.ultimaAtualizacao : '--' }}</p>
        </div>

        <div *ngIf="verificarPermissao(['cadastrar_meta'])" id="btnCadastro"
          class="col-12 d-flex flex-md-column align-items-md-start col-md-6">
          <button mat-icon-button fxLayoutAlign="center center" mat-dialog-close (click)="cadastrar()"
            class="d-flex flex-column align-items-center justify-content-start full-height">
            <img src="assets/icones-metas/adicionar.svg" alt="Fechar" class="icone-fechar">
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 flex-md-column p-0">
      <form [formGroup]="formFilter" class="row m-0 justify-content-between align-items-center filtros-container">
        <select class="col-12 col-lg-5 filtro-listas" formControlName="cliente" (change)="obterMetasDoCliente()">
          <option *ngFor="let cliente of clients" [value]="cliente.id">
            {{ cliente.nome }}
          </option>
        </select>

        <select class="col-12 col-md-7 col-lg-4 my-2 my-lg-0 filtro-listas" id="meta" formControlName="idMeta">
          <option value="0" [selected] disabled>
            Selecione ...
          </option>

          <option *ngFor="let meta of metas" [value]="meta.sr_id">
            {{ meta.vc_nome }}
          </option>
        </select>

        <button mat-raised-button class="col-12 col-md-4 col-lg-2 w-100 botao-criar filtrar" color="primary"
          [disabled]="formFilter.invalid || loading" (click)="obterMeta()">
          Filtrar
        </button>
      </form>
    </div>

  </div>

  <div *ngIf="loading" class="loading">
    <div class="spinner"></div>
  </div>

  <div *ngIf="!loading">

    <div *ngIf="!metas.length && !meta" class="selecione">
      <span>Selecione uma usina e uma meta acima...</span>
    </div>

    <div *ngIf="meta" class="row container-exibir-metas justify-content-between mx-4">
      <div class="col-xl-5 col-12 table-container metas-container" id="metas">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h2 class="m-0">Progresso das metas</h2>
            <p class="m-0">{{ meta.nome }}</p>
            <p class="m-0" id="duracao">Duração: {{ meta.inicioMeta | date: "dd/MM/yyyy"}} - {{ meta.fimMeta | date:
              "dd/MM/yyyy"}}</p>
          </div>
          <div id="btn-opcoes" class="col-12 col-lg-6 d-flex justify-content-end align-items-start">
            <span *ngIf="verificarPermissao(['excluir_meta'])" class="btn-excluir no-wrap" (click)="deletarMeta()">
              <img src="../../../assets/icones-metas/lixo.svg" alt="Lixeira"> Excluir meta
            </span>
            <button class="btn ml-2 rounded btn-exportar" (click)="downloadCsv()"><img
                src="../../../assets/icones-metas/exportar-metas.svg" alt="Exportar">Exportar</button>
          </div>
        </div>
        <div class="container-progress-bar">
          <div class="progress-wrapper">
            <progress id="progressBar" [attr.value]="progressoAteOMomentoDaBarraDeProgresso"
              [attr.max]="100"></progress>
            <svg class="progress-icon" [style.left.%]="progressoPercent" width="55" height="44" viewBox="0 0 57 46"
              fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_2242_529)">
                <path d="M28 42L19.3397 27.75L36.6603 27.75L28 42Z" fill="white" />
                <rect x="4" y="4" width="49" height="25.48" fill="white" />
              </g>
              <text class="progress-text" x="50%" y="40%" dominant-baseline="middle" text-anchor="middle">
                {{this.meta.graficoProduzido.produtividadeTotal}} HA
              </text>
              <defs>
                <filter id="filter0_d_2242_529" x="0" y="0" width="57" height="46" filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                  <feOffset />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2242_529" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2242_529" result="shape" />
                </filter>
              </defs>
            </svg>
            <div class="d-flex justify-content-between mt-0">
              <p class="mt-0 legenda-progress-bar">0 HA</p>
              <p class="mt-0 legenda-progress-bar">{{progressoTotalDaBarraDeProgresso}} HA</p>
            </div>
          </div>
        </div>
        <div class="container-cards row">
          <div class="row col-12 gap-md-3">
            <div class="card metas-card hora col-12 col-md align-items-center">
              <div class="icon">
                <img src="../../../assets/icones-metas/hora.svg" alt="Icone de hora">
              </div>
              <div class="ml-3">
                <p class="m-0"><span id="produtividadePorHora" class="meta-produzido">{{ meta.mediaProdutividadePorHora
                    }} HA / </span><span class="meta-expectativa">{{ meta.metas.hora }} HA</span></p>
                <p class="m-0 mt-1 periodo-meta">Meta por hora</p>
              </div>
            </div>
            <div class="card metas-card dia col-12 col-md align-items-center">
              <div class="icon">
                <img src="../../../assets/icones-metas/dia.svg" alt="Icone de dia">
              </div>
              <div class="ml-3">
                <p class="m-0"><span class="meta-produzido">{{ meta.indiceProdutividade.produzido }} HA / </span><span
                    class="meta-expectativa">{{ meta.metas.dia }} HA</span></p>
                <p class="m-0 mt-1 periodo-meta">Meta por dia</p>
              </div>
            </div>
          </div>
          <div class="row col-12 gap-md-3 cards-row">
            <div class="card metas-card semana col-12 col-md align-items-center">
              <div class="icon">
                <img src="../../../assets/icones-metas/semana.svg" alt="Icone de semana">
              </div>
              <div class="ml-3">
                <p class="m-0"><span class="meta-produzido">{{ meta.produtividadeSemana }} HA / </span><span
                    class="meta-expectativa">{{ meta.metas.semana }} HA</span></p>
                <p class="m-0 mt-1 periodo-meta">Meta por semana</p>
              </div>
            </div>
            <div *ngIf="meta.diasCorridos>=32" class="card metas-card mes col-12 col-md align-items-center">
              <div class="icon">
                <img src="../../../assets/icones-metas/mes.svg" alt="Icone de mes">
              </div>
              <div class="ml-3 gap-1">
                <p class="m-0"><span class="meta-produzido">{{ meta.produtividadeMes }} HA / </span><span
                    class="meta-expectativa">{{ meta.metas.mes }} HA</span></p>
                <p class="m-0 mt-1 periodo-meta">Meta por mês</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-5 col-12 table-container" id="carreteis-ativos">
        <h2 class="carreteis-titulo">Carreteis ativos</h2>
        <div class="carreteis row ms-1">
          <span *ngFor="let carretel of meta.carreteisAtivos" class="carretel"
            [ngClass]="{'carretel-alerta': carretel.alerta}">
            {{ carretel.frota }}
            <span class="ps-1" *ngIf="carretel.alerta"><img src="../../../assets/icones-gerais/alert.svg"
                alt="Alerta"></span>
          </span>
        </div>
      </div>

      <div class="col-xl-3 col-md-5 col-12 table-container" id="previsao-produtividade">
        <div class="container-produtividade">
          <div class="card-produtividade">

            <div class="title">Previsão do dia</div>
            <div class="value">{{ meta.previsaoProdutividade.previsao }} HA</div>
            <div class="status" *ngIf="meta.previsaoProdutividade.abaixoDaMeta">Abaixo da meta diária</div>
          </div>
          <div class="content">
            <div class="row produzido">
              <span>Produzido</span>
              <span>{{ meta.indiceProdutividade.produzido }} HA</span>
            </div>
            <div class="row ideal">
              <span>Abaixo do ideal</span>
              <span>{{ meta.indiceProdutividade.abaixo }} HA</span>
            </div>
            <div class="row irrigado">
              <span>Deveria ter irrigado</span>
              <span>{{ meta.indiceProdutividade.ideal }} HA</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-5 col-12 pb-0 table-container " id="grafico">
        <div class="row w-100">
          <div class="headerGrafico">
            <div class="graficoTitle col-md-8">
              <h2>Meta VS Produção</h2>
            </div>
            <div class="displayAlcancada col-md-4">
              <p class="alcancada" *ngIf="alcancada">Meta alcançada</p>
              <p class="naoAlcancada" *ngIf="alcancada === false">Meta não alcançada</p>
            </div>
          </div>
          <div class="chart-container col-12">
            <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
              [legend]="lineChartLegend" [chartType]="lineChartType">
            </canvas>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-5 col-12 table-container carreteis-container" id="top-carreteis">
        <h2 class="carreteis-title">Top carreteis</h2>
        <table *ngIf="meta.topCarreteis && meta.topCarreteis.length > 0; else noCarreteisData">
          <thead class="carreteis-thead">
            <tr>
              <th>#</th>
              <th>Frota</th>
              <th>Produtividade</th>
            </tr>
          </thead>
          <tbody class="carreteis-tbody">
            <tr *ngFor="let item of meta.topCarreteis; let id = index;">
              <td>0{{ id + 1 }}</td>
              <td>{{ item.frota }}</td>
              <td><span [ngClass]="colorirHectares(id)">{{ item.produtividade }} HA</span></td>
            </tr>
          </tbody>
        </table>
        <ng-template #noCarreteisData>
          <p class="no-data-message">Não há dados para serem exibidos</p>
        </ng-template>
      </div>

      <div class="col-xl-3 col-md-5 col-12 table-container operacoes-container" id="top-operacoes">
        <h2 class="operacoes-title">Top ordens de serviço</h2>
        <table *ngIf="meta.topOperacoes && meta.topOperacoes.length > 0; else noOperacoesData">
          <thead class="operacoes-thead">
            <tr>
              <th>#</th>
              <th>Service id</th>
              <th>Produtividade</th>
            </tr>
          </thead>
          <tbody class="operacoes-tbody">
            <tr *ngFor="let item of meta.topOperacoes; let id = index;">
              <td>0{{ id + 1 }}</td>
              <td>{{ item.operacao }}</td>
              <td><span [ngClass]="colorirHectares(id)">{{ item.produtividade }} HA</span></td>
            </tr>
          </tbody>
        </table>
        <ng-template #noOperacoesData>
          <p class="no-data-message">Não há dados para serem exibidos</p>
        </ng-template>
      </div>
    </div>
  </div>
</main>