import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FiltroService } from '../service/filtro.service';
import { AlertService } from '../service/alert.service';
import { ExportacaoService } from '../service/exportacao.service';
import { format } from 'date-fns';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-exportar-geojson',
  templateUrl: './dialog-exportar-geojson.component.html',
  styleUrls: ['./dialog-exportar-geojson.component.css']
})
export class DialogExportarGeojsonComponent implements OnInit {
  arrayOperacoes: any = [];
  idCliente: any;
  arrayClientes: any;
  arrayGrupos: any;
  idOperacao: any;
  dataInicio: any;
  dataFinal: any;
  formExportar!: FormGroup;
  dataAtual!: Date;
  email!: string | null;

  constructor(
    private dialogRef: MatDialogRef<DialogExportarGeojsonComponent>,
    private formBuilder: FormBuilder,
    private filtroService: FiltroService,
    private alertService: AlertService,
    private exportacaoService: ExportacaoService
  ) { }

  ngOnInit(): void {
    this.localstorage();
    this.acharDadosFiltro();
    this.onChangeCliente();

    this.dataAtual = new Date();
  }

  localstorage() {
    this.idCliente = localStorage.getItem('cliente_filtro');
    this.arrayClientes = JSON.parse(localStorage.getItem('cliente') || "{}").slice();
    this.arrayGrupos = JSON.parse(localStorage.getItem('grupos') || "{}");
    this.idOperacao = localStorage.getItem('operacaoId') == null ? 0 : localStorage.getItem('operacaoId');
    this.dataInicio = localStorage.getItem('DateStart') == null ? new Date().toISOString().slice(0, 10) + ' 03:00:00' : localStorage.getItem('DateStart');
    this.dataFinal = localStorage.getItem('DateEnd') == null ? new Date(new Date(this.dataInicio).setDate(new Date(this.dataInicio).getDate() + 1)).toISOString().slice(0, 10) + ' 03:00:00' : localStorage.getItem('DateEnd');
    this.email = localStorage.getItem('usuario_email');
    if (this.email == '') {
      this.alertService.error('Tente novamente mais tarde. Caso persistir, contate o suporte.', 'Houve um erro ao buscar seu email');
    }
    this.CriarForm();
  }

  CriarForm() {
    var dataInicio = new Date(this.dataInicio)
    var dataFinal = new Date(this.dataFinal)
    dataFinal.setDate(dataFinal.getDate() - 1);
    this.formExportar = this.formBuilder.group({
      vc_email_enviar: [this.email, [Validators.required, Validators.email]],
      it_id_cliente_premocenter: [this.idCliente, Validators.required],
      it_id_operacao_hidrometer: [this.idOperacao, Validators.required],
      ts_data_inicial: [dataInicio, [Validators.required]],
      ts_data_final: [dataFinal, [Validators.required]],
    });
  }

  onChangeCliente() {
    this.formExportar.get("client")?.valueChanges.subscribe(valor => {
      this.idCliente = valor;
      this.acharDadosFiltro();
    });
  }

  acharDadosFiltro() {
    var idClientes = this.arrayClientes.map((cliente: any) => cliente.id);
    var idGrupos = this.arrayGrupos.map((grupo: any) => grupo.id);
    this.filtroService.filtros(idClientes.toString(), idGrupos.toString(), this.idCliente).subscribe((resposta: any) => {
      this.arrayOperacoes = resposta.operacoes.slice();
    })
  }

  enviarGeoJson() {
    var dadosDaExportacao = this.formExportar.value;
    dadosDaExportacao.it_id_solicitado_por = localStorage.getItem('usuario');
    var dataInicio = new Date(dadosDaExportacao.ts_data_inicial);
    dadosDaExportacao.ts_data_inicial = format(dataInicio, "yyyy-MM-dd HH:mm:ss");
    var dataFinal = new Date(dadosDaExportacao.ts_data_final);
    dadosDaExportacao.ts_data_final = format(dataFinal, "yyyy-MM-dd HH:mm:ss");

    this.exportacaoService.adicionarGeojsonParaFila(dadosDaExportacao).subscribe((resposta: any) => {
      this.alertService.success('Exportação solicitada com sucesso');
      this.dialogRef.close();
    }, (erro: any) => {
      this.alertService.error('Erro ao solicitar exportação')
    })
  }
}
