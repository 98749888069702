import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import pluginDataLabels from 'chartjs-plugin-datalabels';
import { DialogTemposFrotasComponent } from './dialog-tempos-frotas/dialog-tempos-frotas.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { GraficosService } from 'src/app/service/graficos.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tempos-trabalhados-frota',
  templateUrl: './tempos-trabalhados-frota.component.html',
  styleUrls: ['./tempos-trabalhados-frota.component.css']
})
export class TemposTrabalhadosFrotaComponent implements OnInit {
  @ViewChild('todasSelecionadas') todasSelecionadas!: MatOption;
  @ViewChild(BaseChartDirective) chart!: BaseChartDirective;
  form: FormGroup;
  cliente: any = null;
  dataInicial: any = null;
  dataFinal: any = null;
  frotas: any = null;
  frotasCliente: any = null;
  frotasSemMensagem = [] as any
  temposFrota = [] as any;
  produtivo = [] as any;
  justificado = [] as any;
  injustificado = [] as any;
  tempoFrota = [] as any;
  frotasDadosInsuficientes = [] as any;
  arrayCores = [] as any;
  loading = true;
  validaResp = false;
  contagem = 0;
  erro = false;
  @Input() ocultarGrafico: boolean = false
  loadingFrotas = true;

  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    tooltips: {
      callbacks: {
        title(item: any, data: any) {
          var frota = item[0].label;

          return 'Frota ' + frota;
        },
        label: function (tooltipItem: any, data: any) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var legenda = dataset.label;
          var valorItem = dataset.data[tooltipItem.index];
          return legenda + ': ' + valorItem + '%';
        }
      },
    },
    hover: {
      onHover: function (e: any, ativo: any) {
        var indexDado = ativo.length ? ativo[0]['_index'] : undefined;

        if (indexDado !== undefined) {
          var dado = ativo[1]['_chart']['config']['data']['datasets'][1]['data'][indexDado];
        }

        if (ativo.length && dado !== '0.0') { e.target.style.cursor = 'pointer'; }
        else { e.target.style.cursor = 'default'; }
      }
    },
    "scales": {
      "yAxes": [{
        "ticks": {
          "beginAtZero": true,
          callback: function (value) {
            return value + '%';
          },
          "max": "100"
        }
      }],
    }
  };

  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartColors = [];
  public barChartPlugins = [pluginDataLabels];
  public barChartData: ChartDataSets[] = [
    {
      data: this.produtivo,
      label: "Produtivo",
      stack: 'a',
      backgroundColor: '#8C51A5',
      hoverBackgroundColor: '#8C51A5'
    },
    {
      data: this.justificado,
      label: "Justificado",
      stack: 'a',
    },
    {
      data: this.injustificado,
      label: "Injustificado",
      stack: 'a',
      backgroundColor: '#FFD285',
      hoverBackgroundColor: '#FFD285'
    },
  ];
  private graficosTemposTrabalhados: string[] = ['Tempos trabalhados por frota', 'Tempos trabalhados'];

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private graficosService: GraficosService,
    private activatedRoute: ActivatedRoute
  ) {
    this.form = this.formBuilder.group({
      id_frota: new FormControl(['', Validators.required])
    });
  }

  ngOnInit(): void {
    this.inserirDadosLocalStorage();
    if (this.frotas) {
      this.loadingFrotas = false;
      this.buscarTemposTrabalhadosPorFrota();
    } else {
      this.loading = false;
      this.loadingFrotas = false;
      this.validaResp = true;
    }
  }

  inserirDadosLocalStorage() {
    this.cliente = localStorage.getItem('cliente_filtro');
    this.dataInicial = localStorage.getItem('DateStartGraficos');
    this.dataFinal = localStorage.getItem('DateEndGraficos');
    var tipoGrafico: string = this.activatedRoute.snapshot.paramMap.get('tipo') || '';
    this.frotas = localStorage.getItem('tempos_trabalhados_frota');
    if (!this.graficosTemposTrabalhados.includes(tipoGrafico) && !this.frotas) {
      this.frotas = '0';
    }
  }

  buscarTemposTrabalhadosPorFrota() {
    this.graficosService.graficoTemposTrabalhadosPorFrota(this.cliente, this.dataInicial, this.dataFinal, this.frotas).subscribe((resp: any) => {
      if (resp == null || resp.length === 0) {
        this.loading = false;
        this.validaResp = true;
      } else {
        resp.forEach((tempo: any) => {
          this.tempoFrota.push(tempo);
          if (tempo.percentualProdutivo !== undefined && tempo.percentualJustificado !== undefined &&
            tempo.percentualInjustificado !== undefined && tempo.frota !== undefined) {
            this.produtivo.push((tempo.percentualProdutivo).toFixed(1));
            this.justificado.push((tempo.percentualJustificado).toFixed(1));
            this.injustificado.push((tempo.percentualInjustificado).toFixed(1));
            this.barChartLabels.push(tempo.frota);
          }
        });

        this.loading = false;
      }
    }, err => {
      this.loading = false;
      this.erro = true;
    })
  }

  chartClicked(e: any) {
    var grupo, id, frota;
    if (e.active.length !== 0) {
      var label = e.active[1]._model !== undefined ? e.active[1]._model : '';
      var index = e.active[1]['_index'];
      var indexDado = e.active[1]['_datasetIndex'];
      var elemento: any = this.chart?.datasets[indexDado]['data']![index];
      if (label.datasetLabel !== undefined) {
        grupo = label.datasetLabel;
        for (let tempo of this.tempoFrota) {
          frota = label.label;
          if (frota == tempo.frota) {
            id = tempo.id_frota;
          }
        }
      }
      if (elemento !== "0.0") {
        this.openDialog(grupo, id, frota);
      }
    }
  }

  openDialog(grupo: any, id_frota: any, frota: any) {
    if (grupo == 'Justificado') {
      this.dialog.open(DialogTemposFrotasComponent, {
        minWidth: '80vw',
        height: '70vh',
        data: {
          id_frota,
          frota,
          grupo
        }
      });
    }
  }


}
