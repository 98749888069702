import { Component, OnInit } from '@angular/core';
import { GraficosService } from 'src/app/service/graficos.service';
import { ProdutividadeHectaresService } from 'src/app/service/produtividade-hectares.service';
import { HectaresService } from 'src/app/service/hectares.service';

@Component({
  selector: 'app-hectares-produzidos',
  templateUrl: './hectares-produzidos.component.html',
  styleUrls: ['./hectares-produzidos.component.css']
})
export class HectaresProduzidosComponent implements OnInit {

  LocalStorageId: any = localStorage.getItem('cliente_filtro');
  LocalStorageDateStart: any = localStorage.getItem('DateStart');
  LocalStorageDateEnd: any = localStorage.getItem('DateEnd');
  fuso: string = localStorage.getItem('horario') === 'utc' ? 'utc' : 'brt';
  hectares = [] as any;

  constructor(
    private prodService: ProdutividadeHectaresService,
    private graficosService: GraficosService,
    private hectarService: HectaresService
  ) { }

  ngOnInit(): void {
    if (this.LocalStorageDateStart.substr(0, 4) <= '2021') {
      this.produtividade2021();
    } else {
      this.produtividade();
    }
  }

  produtividade(): void {
    this.graficosService.graficoProdutividadeTurno(this.LocalStorageDateStart, this.LocalStorageDateEnd, this.LocalStorageId, this.fuso).subscribe((resposta: any) => {
      let total = 0;
      this.hectares = resposta.turnos.map((nomeTurno: string, indice: number) => {
        const hectares = parseFloat(resposta.hectares[indice].replace(',', ''));
        total += hectares;
        return { turno: nomeTurno, hectares };
      });
      this.ordenarTurnos();
      this.hectares.push({ turno: 'TOTAL', hectares: total.toFixed(2) });
      this.hectarService.updateHectares(this.hectares);
    });
  }

  produtividade2021(): void {
    this.prodService.AcharHectares(this.LocalStorageId, this.LocalStorageDateStart.substr(0, 10), this.LocalStorageDateEnd.substr(0, 10)).subscribe((turnos) => {
      var nomeTurnos = Object.keys(turnos);
      var hectaresTurnos = Object.values(turnos);
      hectaresTurnos.forEach((element: any, indice: any) => {
        var json = {
          turno: nomeTurnos[indice],
          hectares: parseFloat(element)
        };
        this.hectares.push(json);
      });
      this.hectares.push({ turno: 'TOTAL', hectares: this.hectares.reduce((accumulator: any, value: any) => parseFloat(accumulator) + value.hectares, 0) });
      this.hectarService.updateHectares(this.hectares);
    });
  }

  ordenarTurnos(): void {
    this.hectares.sort((a: any, b: any) => (a.turno > b.turno) ? 1 : (b.turno > a.turno) ? -1 : 0);
  }
}
