<div class="button-container" style="float:right" (click)="unsubscribe()">
    <button mat-icon-button fxLayoutAlign="center center" mat-dialog-close>
        <img src="assets/icones-gerais/fechar-cor-preto.svg" alt="Fechar" class="icone-fechar">
    </button>
</div>
<div fxLayoutAlign="center center" *ngIf="loading" class="spinner"></div>
<div class="tabela" *ngIf="!loading">
    <table>
        <tbody>
            <tr class="linha">
                <td><b>
                    <span class="texto">Período filtrado:&nbsp;</span>
                </b></td>
                <td>
                    <span class="texto">{{dataInicial}} - {{dataFinal}}</span>
                </td>
            </tr>
            <tr class="linha" style="justify-content: center;">
                <td colspan="2">
                    <span class="texto">({{textoSemanas}} e {{textoDias}})</span>
                </td>
            </tr>
            <tr class="linha">
                <td><b>
                    <span class="texto">Qtd. de dias:&nbsp;</span>
                </b></td> 
                <td>{{emDias}}</td>
            </tr>
            <tr class="linha">
                <td><b>
                    <span class="texto">Qtd. de horas:&nbsp;</span>
                </b></td> 
                <td>
                    <span class="texto">{{emHoras}}</span>
                </td>
            </tr>
            <tr class="linha">
                <td><b>
                    <span class="texto">Qtd. de frotas filtradas:&nbsp;</span>
                </b></td>
                <td>
                    <span class="texto">{{textoFrotas}}</span>
                </td>
            </tr>
            <tr class="linha" style="justify-content: center;" (click)="expandirSemMsg()" class="linha-clicavel" 
            [style.background-color]="mostrarFrotasSemMsg && qtdFrotasSemMsg > 0 ? '#f5f5f5' : ''" 
            [style.color]="!mostrarFrotasSemMsg && qtdFrotasSemMsg > 0 ? '#FF4D4D' : '#545454'" 
            [matTooltip]="!mostrarFrotasSemMsg && qtdFrotasSemMsg > 0 ? 'Clique para ver as frotas' :
            ''">
                <td colspan="2">
                <span *ngIf="qtdFrotasSemMsg > 0">Frotas sem mensagem ({{qtdFrotasSemMsg}}):</span>
                <span *ngIf="qtdFrotasSemMsg == 0" style="color: darkgray">Não há frotas sem mensagem</span>
                    <span *ngIf="!mostrarFrotasSemMsg && qtdFrotasSemMsg > 0" class="material-symbols-outlined">
                        expand_more
                    </span>
                    <span *ngIf="mostrarFrotasSemMsg && qtdFrotasSemMsg > 0" class="material-symbols-outlined">
                        <span class="texto">expand_less</span>
                    </span>
                    &nbsp;
                </td>
            </tr>
            <tr (click)="expandirSemMsg()" [style.cursor]="qtdFrotasSemMsg > 0 ? 'pointer' : 'default'" *ngIf="mostrarFrotasSemMsg" 
            [style.background-color]="mostrarFrotasSemMsg && qtdFrotasSemMsg > 0 ? '#f5f5f5' : ''"
            matTooltip="Clique para ocultar as frotas">
                <td colspan="2">
                    <div class="frotas" [style.height]="qtdFrotasSemMsg >= 6 ? '10.2em' : ''">
                        <span class="texto">{{semMsg}}</span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
