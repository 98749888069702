import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RedirectService } from '../service/redirect.service';
import { BASE_URL_HIDROMETER_FRONT } from '../app.constants';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css'],
})
export class RedirectComponent implements OnInit {
  usuarioPremocenter: any = this.activatedRoute.snapshot.paramMap.get('sr_id');
  horaInicio: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private redirectService: RedirectService
  ) { }

  ngOnInit(): void {
    this.horaInicio = new Date();
    localStorage.setItem('token', this.usuarioPremocenter);
    this.dadosUsuario(this.activatedRoute.snapshot.paramMap.get('sr_id'));
  }

  dadosUsuario(usuario: any) {
    this.redirectService.CarregarDados(usuario).subscribe((resp: any) => {
      this.redirectService.usuarioDados(usuario).subscribe((usuario: any) => {
        localStorage.setItem('cliente_filtro', resp.clientes[0].id);
        localStorage.setItem(
          'cliente',
          JSON.stringify(
            this.ordenaArrayDeClientesEmOrdemAlfabetica(resp.clientes)
          )
        );
        localStorage.setItem('permissoes', JSON.stringify(resp.permissoes));
        localStorage.setItem('grupos', JSON.stringify(resp.grupos));
        localStorage.setItem('usuario', JSON.stringify(resp.usuarioId));
        localStorage.setItem('usuario_email', resp.usuarioEmail);
        localStorage.setItem('mensagensCarregadas', 'True');
        localStorage.setItem('quantidadeMsg', '0');
        localStorage.setItem(
          'comunicadores',
          JSON.stringify(resp.comunicadores)
        );
        localStorage.setItem('frotas_filtro', '0');
        var dataInicio: any = localStorage.getItem('DateStart') == null ? new Date().toISOString().slice(0, 10) + ' 03:00:00' : localStorage.getItem('DateStart');
        var dataFinal: any = localStorage.getItem('DateEnd') == null ? new Date(new Date(dataInicio).setDate(new Date(dataInicio).getDate() + 1)).toISOString().slice(0, 10) + ' 03:00:00' : localStorage.getItem('DateEnd');
        localStorage.setItem('DateStart', dataInicio);
        localStorage.setItem('DateEnd', dataFinal);
        this.redireciona();
      });
    });
  }

  redireciona() {
    var horaFinal: any = new Date();
    const diferencaEmMilisegundos = horaFinal - this.horaInicio;
    var milisegundosParaDormir = 4000 - diferencaEmMilisegundos;
    if (milisegundosParaDormir < 0) {
      milisegundosParaDormir = 0;
    }
    setTimeout(function () {
      window.location.href = BASE_URL_HIDROMETER_FRONT;
    }, milisegundosParaDormir);
  }

  ordenaArrayDeClientesEmOrdemAlfabetica(arrayClientes: any) {
    var dadosOrdenados = arrayClientes.sort(function (a: any, b: any) {
      return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
    });
    return dadosOrdenados;
  }
}
