import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { EditarFormulario } from 'src/app/model/editar-formulario.model';
import { AgviewsConnectService } from 'src/app/service/agviews-connect.service';
import { AlertService } from 'src/app/service/alert.service';
import { CaracteristicasService } from 'src/app/service/caracteristicas.service';
import { ComunicadoresService } from 'src/app/service/comunicadores.service';
import { EquipamentosService } from 'src/app/service/equipamentos.service';

@Component({
  selector: 'app-editar-equipamentos',
  templateUrl: './editar-equipamentos.component.html',
  styleUrls: ['./editar-equipamentos.component.css']
})
export class EditarEquipamentosComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  public equipmentForm: FormGroup = this.formBuilder.group({
    client: [null, Validators.required],
    type: [null, Validators.required],
    fleet: [null, Validators.required],
    mobileId: [null, Validators.required],
    agviewConnectId: [null, Validators.required],
    ar_caracteristicas: this.formBuilder.array([])
  });
  public modems!: any[];
  public agviewsConnect!: any[];
  public equipment: any;
  public displayedColumns: string[] = [
    "id",
    "caracteristica",
    "valor"
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public modemId?: number;
  public equipmentId?: number;
  public clientId?: number;
  public caracteristicas: MatTableDataSource<any> = new MatTableDataSource();
  public caracteristica: any;
  public mobileIdAntigo: string = '';
  public idComunicadorAntigo?: number;
  LocalStorageId = localStorage.getItem("cliente_filtro");
  clients = JSON.parse(localStorage.getItem('cliente') || "{}")
  comunicadores = JSON.parse(localStorage.getItem('comunicadores') || "{}")
  validaEditar = false;
  loading = false;
  editarDesabilitado: boolean = true;
  valoresIniciais: any;

  constructor(
    private formBuilder: FormBuilder,
    private modemService: ComunicadoresService,
    private equipmentService: EquipamentosService,
    private route: ActivatedRoute,
    private location: Location,
    private alertService: AlertService,
    private caracteristicaService: CaracteristicasService,
    private agviewsService: AgviewsConnectService,
  ) { }

  ngOnInit(): void {
    this.verificaSeEdita();
    this.equipmentForm.valueChanges.subscribe(() => {
      this.atualizarEstadoBotao();
    });
  }

  atualizarEstadoBotao() {
    if (this.valoresIniciais) {
      this.editarDesabilitado = !EditarFormulario.temAlteracaoFormulario(this.valoresIniciais, this.equipmentForm);
    }
  }

  verificaSeEdita() {
    this.route.params.subscribe((params: Params) => {
      this.equipmentId = Number(params.id);
      if (this.equipmentId) {
        this.equipmentService.AcharPorId(this.equipmentId).subscribe(
          (equipment: any) => {
            this.validaEditar = true
            this.equipment = equipment
            equipment?.comunicadores.forEach((com: any) => {
              var mobileId = this.comunicadores.filter(function (e: any) {
                return e.id == com.it_id_premocenter
              })
              if (mobileId.length > 0) {
                equipment.mobile = []
                equipment.mobile.push({ 'vc_mobileId': mobileId[0].vc_mobileId })
                this.mobileIdAntigo = equipment.mobile[0].vc_mobileId;
                this.idComunicadorAntigo = equipment.comunicadores[0].it_id_premocenter
              }
            });

            if (equipment.comunicadores.length == 0) {
              this.equipmentForm.get("mobileId")?.setValue('semModem');
            } else {
              this.equipmentForm.get("mobileId")?.setValue(equipment.comunicadores[0].it_id_premocenter);
            }

            if (equipment.hasOwnProperty('agviewConnect')) {
              this.equipmentForm.get("agviewConnectId")?.setValue(equipment.agviewConnect.sr_id);
            } else {
              this.equipmentForm.get("agviewConnectId")?.setValue('semAGViewConnect');
            }

            this.equipmentForm.get("client")?.setValue(equipment.cliente.it_id_premocenter);
            this.equipment = equipment
            this.equipmentForm.get("type")?.setValue(this.equipment.vc_tipo);
            this.equipmentForm.get("fleet")?.setValue(this.equipment.vc_frota);
            this.onChange();
          },
          (err) => { this.location.back(); }
        );
      }
    });
  }

  onChange() {
    this.loading = true
    this.modemService.comunicadoresLivres(this.equipmentForm.get("client")?.value).subscribe((modems: any) => {
      let comunicadores = this.atribuirMobileId(modems)
      if (this.equipment.comunicadores.length != 0) {
        var comunicadorDoEquipamento = {
          'vc_mobileId': this.equipment.mobile[0].vc_mobileId,
          'sr_id': this.equipment.comunicadores[0].sr_id,
          'it_id_premocenter': this.equipment.comunicadores[0].it_id_premocenter
        }
        comunicadores.push(comunicadorDoEquipamento)
      }
      this.modems = comunicadores;
    });

    this.agviewsService.acharAGViewsConnectLivres(this.equipmentForm.get("client")?.value).subscribe((agviewsConnect: any) => {
      this.agviewsConnect = agviewsConnect;
      if (this.equipment.hasOwnProperty('agviewConnect')) {
        this.agviewsConnect.push(this.equipment.agviewConnect);
      }
    });

    this.caracteristicaService.AcharPorClienteeTipo(this.equipmentForm.get("client")?.value, "equipamento", this.equipmentId).subscribe((carac: any) => {
      this.loading = false
      carac.forEach((element: any) => {
        if (element.vc_tipo == "timestamp") {
          element['input'] = 'datetime-local'
        } else if (element.vc_tipo == 'string') {
          element['input'] = 'text'
        } else {
          element['input'] = 'number'
        }
      });
      var control = this.retornaControlArCaracteristicaSemDuplicidade(<FormArray>this.equipmentForm.get('ar_caracteristicas'), carac);
      this.caracteristicas.data = control.value
      this.valoresIniciais = JSON.parse(JSON.stringify(this.equipmentForm.getRawValue()))
      this.atualizarEstadoBotao();
    });
  }

  public atribuirMobileId(comunicadores: any) {
    var comunicadoresComMobileId = [] as any;
    comunicadores.forEach((com: any) => {
      let comunicadorPremocenter = this.comunicadores.filter(function (e: any) {
        return e.id == com.it_id_premocenter
      })
      if (comunicadorPremocenter.length != 0) {
        com.vc_mobileId = comunicadorPremocenter[0].vc_mobileId
        comunicadoresComMobileId.push(com)
      }
    });
    return comunicadoresComMobileId;
  }

  public makeEquipment(equip: any, mobileIdAntigo: string) {
    this.equipment = {
      "vc_frota": equip.get("fleet")?.value,
      "vc_tipo": equip.get("type")?.value,
      "fk_cliente": equip.get("client")?.value,
      "ar_caracteristicas": equip.get("ar_caracteristicas")?.value
    }

    let mobileId = equip.get("mobileId").value;
    if (mobileId != undefined && mobileId != 'semModem') {
      this.equipment.fk_comunicadores = mobileId
    }

    if (mobileIdAntigo != '' && this.equipment.fk_comunicadores !== this.idComunicadorAntigo) {
      this.equipment.vc_mobileId_antigo = this.mobileIdAntigo
    }

    if (equip.get("agviewConnectId")?.value !== undefined && equip.get("agviewConnectId")?.value !== 'semAGViewConnect') {
      this.equipment["it_id_agview_connect"] = equip.get("agviewConnectId")?.value;
    }

    return this.equipment
  }

  public atualizarEquipamento() {
    this.equipmentService.Atualizar(this.makeEquipment(this.equipmentForm, this.mobileIdAntigo), this.equipmentId).subscribe((data: any) => {
      this.alertService.success('Equipamento atualizado com sucesso')
      this.location.back()
    }, (err: any) => {
      this.alertService.exibirMensagemErro(err)
    });
  }

  public cancel(): void {
    this.location.back();
  }

  patchValues(id: any, carac: any, valor: any, sr_id: any, vc_tipo: any, input: any) {
    return this.formBuilder.group({
      idValor: [id],
      carac: [carac],
      valor: [valor],
      idCaracteristica: [sr_id],
      vc_tipo: [vc_tipo],
      input: [input]
    })
  }

  retornaControlArCaracteristicaSemDuplicidade(control: FormArray, caracteristicas: any) {
    control.clear()
    caracteristicas?.forEach((x: any) => {
      control.push(this.patchValues(x.idValor, x.vc_nome, x.valor, x.sr_id, x.vc_tipo, x.input))
    });
    return control
  }
}
