<div style="display: flex; flex-direction: row; align-items: center; justify-content: center;" *ngIf="tabela==1">
    <table>
        <tbody>
            <tr *ngFor='let valores of json'>
                <td class="espacamento-linhas limitacao-width">{{valores.chave}}</td>
                <td class="espacamento-linhas" *ngIf="valores.chave=='Horário'">{{valores.valor | date: "dd/MM/yyyy HH:mm:ss"}}</td>
                <td class="espacamento-linhas" *ngIf="valores.chave!='Horário'">{{valores.valor}}</td>
            <tr>
        </tbody>
    </table>
</div>
<div *ngIf="tabela==0">
    <pre [innerHTML]="data"></pre>
</div>

<div *ngIf="tabela==2">
    <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
        <img src="assets/hidrometer.png" style="width: 40px; margin-right: 0.5rem;" />
        <h2>{{versao}}</h2>
    </div>
    <ul *ngFor='let mudanca of mudancas'>
        <li>{{mudanca}}</li>
    </ul>
    <div style="display: flex; flex-direction: row; align-items: flex-end; justify-content: end">
        <img src="assets/logo-removebg-preview.png" style="width: 35px;" />
    </div>
</div>
