<mat-grid-list cols="4" rowHeight="10vh">
    <mat-grid-tile colspan="3">
        <h4 class="titulo" mat-dialog-title fxLayoutAlign="start center">
            Localização da mensagem: "{{ data.motivo }}"
        </h4>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
        <div class="button-container" fxLayoutAlign="end center">
            <button mat-icon-button #botao fxLayoutAlign="center center" mat-dialog-close>
                <img src="assets/icones-gerais/fechar-cor-preto.svg" alt="Fechar" class="icone-fechar">
            </button>
        </div>
    </mat-grid-tile>
</mat-grid-list>

<mat-dialog-content>
    <div fxLayout="column" fxLayoutAlign="center center" style="height: 50vh">
        <div class="map" id="map" class="match-parent">
            <div fxLayoutAlign="center center">
                <div fxLayoutAlign="center center" *ngIf="loadMapa" class="spinner"></div>
            </div>
        </div>
    </div>
</mat-dialog-content>
