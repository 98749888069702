<div class="detalhes">
    <mat-menu #appMenu="matMenu">
        <div class="overflow" style="height: 50vh; overflow: auto;">
            <button mat-menu-item class="botao-menu" *ngFor="let data of datas" (click)="clickFiltro(data)"
                xPosition="after">
                {{ data }}
            </button>
        </div>
    </mat-menu>
    <button mat-stroked-button class="botao_filtro" [matMenuTriggerFor]="appMenu">
        {{ dataFiltro }}
    </button>
</div>

<div class="mt-2" *ngIf="pontos.length!=0">
    Motobomba funcionou por: {{horas}} horas
</div>

<div style="overflow: auto; max-width: 100%" id="style-1">
    <ul class="clr-timeline clr-timeline-horizontal">
        <li class="clr-timeline-step disabled" *ngFor="let ponto of pontos">
            <div class="clr-timeline-step-header">{{ponto.hora + 'Z' | date: "HH:mm:ss"}}</div>
            <cds-icon *ngIf="ponto.icone=='success-standard'" shape="success-standard"></cds-icon>
            <cds-icon *ngIf="ponto.icone=='circle'" shape="circle"></cds-icon>
            <cds-icon *ngIf="ponto.icone=='error-standard'" shape="error-standard"></cds-icon>
            <cds-icon *ngIf="ponto.icone=='dot-circle'" shape="dot-circle"></cds-icon>
            <div class="clr-timeline-step-body">
                <span class="clr-timeline-step-title">{{ponto.descricao}}</span>
                <span class="clr-timeline-step-description">{{ponto.subdescricao}}</span>
            </div>
        </li>
    </ul>
</div>

<div *ngIf="pontos.length==0" id="texto-sem-dados">
    {{texto}}
</div>

<table class="table" *ngIf="pontos.length!=0">
    <caption>
        Telemetrias do dia {{dataFiltro}}
    </caption>
    <thead>
        <tr>
            <th>Hora</th>
            <th>Descrição</th>
            <th>Status</th>
            <th>Alertas</th>
            <th>Pressão</th>
            <th>Rotação</th>
            <th>Status da válvula</th>
            <th>Nível de combustível</th>
            <th>Nível de sucção</th>
            <th>Pressão da adultora</th>
            <th>Setpoint</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let mensag of mensagensParaTabela">
            <td>
                {{mensag["Horário"] | date: "HH:mm:ss"}}
                <span *ngIf='mensag["horaOrbcomm"]' matTooltip="Possivelmente mensagem gerada quando satelital estava sem sinal" matTooltipPosition="after" class="cursor-pointer">*</span>
            </td>
            <td>{{mensag["Descrição"] ? mensag["Descrição"] : "" }}</td>
            <td [ngClass]='{"warning": mensag["Estado"] == "Sem automação"}'>{{mensag['Estado'] ? mensag['Estado'] :
                ""}}</td>
            <td>{{mensag.Alertas ? mensag.Alertas : "" }}</td>
            <td>{{mensag['Pressão de saída'] ? mensag['Pressão de saída'] : "" }}</td>
            <td>{{mensag.VelocidadeRPM ? mensag.VelocidadeRPM : "" }}</td>
            <td>{{mensag['Status da válvula'] ? mensag['Status da válvula'] : "" }}</td>
            <td>{{mensag['Nível de combustível'] ? mensag['Nível de combustível'] : "" }}</td>
            <td>{{mensag['Nível de sucção'] ? mensag['Nível de sucção'] : "" }}</td>
            <td>{{mensag['Pressão de entrada'] ? mensag['Pressão de entrada'] : "" }}</td>
            <td>{{mensag.Setpoint ? mensag.Setpoint : "" }}</td>
        </tr>
    </tbody>
</table>
<div *ngIf="loading" style="width: auto; height: 5rem;" fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="loading" class="spinner"></div>
</div>