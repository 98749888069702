import { TempoEfetivoOsCarretelComponent } from './graficos/tempo-efetivo-os-carretel/tempo-efetivo-os-carretel.component';
import { ProdutividadeOsComponent } from './graficos/produtividade-os/produtividade-os.component';
import { TempoEfetivoTurnoMotobombaComponent } from './graficos/tempo-efetivo-turno-motobomba/tempo-efetivo-turno-motobomba.component';
import { TempoEfetivoOsMotobombaComponent } from './graficos/tempo-efetivo-os-motobomba/tempo-efetivo-os-motobomba.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CaracteristicasComponent } from './caracteristicas/caracteristicas.component';
import { CriarCaracteristicasComponent } from './caracteristicas/criar-caracteristicas/criar-caracteristicas.component';
import { EditaCaracteristicasComponent } from './caracteristicas/edita-caracteristicas/edita-caracteristicas.component';
import { ListaCaracteristicasComponent } from './caracteristicas/lista-caracteristicas/lista-caracteristicas.component';
import { ValorCaracteristicasComponent } from './caracteristicas/valor-caracteristicas/valor-caracteristicas.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CriarEquipamentosComponent } from './equipamentos/criar-equipamentos/criar-equipamentos.component';
import { EditarEquipamentosComponent } from './equipamentos/editar-equipamentos/editar-equipamentos.component';
import { EquipamentosComponent } from './equipamentos/equipamentos.component';
import { ListaEquipamentosComponent } from './equipamentos/lista-equipamentos/lista-equipamentos.component';
import { CriarFazendasComponent } from './fazendas/criar-fazendas/criar-fazendas.component';
import { EditarFazendasComponent } from './fazendas/editar-fazendas/editar-fazendas.component';
import { FazendasComponent } from './fazendas/fazendas.component';
import { ListarFazendasComponent } from './fazendas/listar-fazendas/listar-fazendas.component';
import { GraficosComponent } from './graficos/graficos.component';
import { HomeComponent } from './home/home.component';
import { ImportarArquivosComponent } from './importar-arquivos/importar-arquivos.component';
import { CriarLayoutsComponent } from './layout/criar-layouts/criar-layouts.component';
import { EditarLayoutsComponent } from './layout/editar-layouts/editar-layouts.component';
import { LayoutComponent } from './layout/layout.component';
import { ListarLayoutsComponent } from './layout/listar-layouts/listar-layouts.component';
import { ModelosLayoutsComponent } from './layout/modelos-layouts/modelos-layouts.component';
import { MapaComponent } from './mapa/mapa.component';
import { CriarOperacoesComponent } from './operacoes/criar-operacoes/criar-operacoes.component';
import { EditarOperacoesComponent } from './operacoes/editar-operacoes/editar-operacoes.component';
import { ListarOperacoesComponent } from './operacoes/listar-operacoes/listar-operacoes.component';
import { MonitoramentoOperacoesComponent } from './operacoes/monitoramento-operacoes/monitoramento-operacoes.component';
import { OperacoesComponent } from './operacoes/operacoes.component';
import { RedirectComponent } from './redirect/redirect.component';
import { CriarTurnoComponent } from './turnos/criar-turno/criar-turno.component';
import { EditarTurnoComponent } from './turnos/editar-turno/editar-turno.component';
import { ListarTurnoComponent } from './turnos/listar-turno/listar-turno.component';
import { TurnosComponent } from './turnos/turnos.component';
import { AbastecimentoMotobombaComponent } from './graficos/abastecimento-motobomba/abastecimento-motobomba.component';
import { ChangeLogComponent } from './change-log/change-log.component';
import { ModalGraficosComponent } from './graficos/modal-graficos/modal-graficos.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { OperadoresComponent } from './operadores/operadores.component';
import { ListarOperadoresComponent } from './operadores/listar-operadores/listar-operadores.component';
import { EditarOperadoresComponent } from './operadores/editar-operadores/editar-operadores.component';
import { MetasComponent } from './metas/metas.component';
import { ExibirMetasComponent } from './metas/exibir-metas/exibir-metas.component';
import { CadastrarMetasComponent } from './metas/cadastrar-metas/cadastrar-metas.component';
import { ListagemInformacoesEquipamentosComponent } from './listagem-informacoes-equipamentos/listagem-informacoes-equipamentos.component';
import { GlobalGuard } from './global.guard';

const routes: Routes = [
  { path: "index/:sr_id", component: RedirectComponent },
  {
    path: "hidrometer", component: HomeComponent,
    children: [
      {
        path: "", component: DashboardComponent,
      },
      {
        path: "equipamentos", component: EquipamentosComponent, canActivate: [GlobalGuard],
        children: [
          { path: "listar", component: ListaEquipamentosComponent, data: { permissao: ['listar_equipamentos_hidrometer'] }, canActivate: [GlobalGuard] },
          { path: "criar", component: CriarEquipamentosComponent, data: { permissao: ['cadastrar_equipamentos_hidrometer'] }, canActivate: [GlobalGuard] },
          { path: "editar/:id", component: EditarEquipamentosComponent, data: { permissao: ['editar_equipamentos_hidrometer'] }, canActivate: [GlobalGuard] },
        ],
      },
      {
        path: "turnos", component: TurnosComponent, canActivate: [GlobalGuard],
        children: [
          { path: "listar", component: ListarTurnoComponent, data: { permissao: ['listar_turnos_hidrometer'] }, canActivate: [GlobalGuard] },
          { path: "criar", component: CriarTurnoComponent, data: { permissao: ['cadastrar_turnos_hidrometer'] }, canActivate: [GlobalGuard] },
          { path: "editar/:id", component: EditarTurnoComponent, data: { permissao: ['editar_turnos_hidrometer'] }, canActivate: [GlobalGuard] },
        ],
      },
      {
        path: "operacoes", component: OperacoesComponent, canActivate: [GlobalGuard],
        children: [
          { path: "listar", component: ListarOperacoesComponent, data: { permissao: ['listar_operacoes_hidrometer'] }, canActivate: [GlobalGuard] },
          { path: "criar", component: CriarOperacoesComponent, data: { permissao: ['cadastrar_operacoes_hidrometer'] }, canActivate: [GlobalGuard] },
          { path: "editar/:id", component: EditarOperacoesComponent, data: { permissao: ['editar_operacoes_hidrometer'] }, canActivate: [GlobalGuard] },
          { path: "monitor/:id", component: MonitoramentoOperacoesComponent, data: { permissao: ['exibir_mensagens_hidrometer'] }, canActivate: [GlobalGuard] }
        ],
      },
      {
        path: "operadores", component: OperadoresComponent, canActivate: [GlobalGuard],
        children: [
          { path: "listar", component: ListarOperadoresComponent, data: { permissao: ['listar_operadores'] }, canActivate: [GlobalGuard] },
          { path: "editar/:id", component: EditarOperadoresComponent, data: { permissao: ['editar_operadores'] }, canActivate: [GlobalGuard] },
        ],
      },
      {
        path: "caracteristicas", component: CaracteristicasComponent, canActivate: [GlobalGuard],
        children: [
          { path: "listar", component: ListaCaracteristicasComponent, data: { permissao: ['editar_caracteristicas_adicionais_hidrometer'] }, canActivate: [GlobalGuard] },
          { path: "criar", component: CriarCaracteristicasComponent, data: { permissao: ['editar_caracteristicas_adicionais_hidrometer'] }, canActivate: [GlobalGuard] },
          { path: "editar/:id", component: EditaCaracteristicasComponent, data: { permissao: ['editar_caracteristicas_adicionais_hidrometer'] }, canActivate: [GlobalGuard] },
          { path: "valor/:id/:idEouO/:tipo", component: ValorCaracteristicasComponent, data: { permissao: ['editar_caracteristicas_adicionais_hidrometer'] }, canActivate: [GlobalGuard] }
        ],
      },
      { path: "importarArquivos", component: ImportarArquivosComponent, data: { permissao: ['importar_shape_hidrometer'] }, canActivate: [GlobalGuard] },
      {
        path: "fazendas", component: FazendasComponent, canActivate: [GlobalGuard],
        children: [
          { path: "listar", component: ListarFazendasComponent, data: { permissao: ['listar_fazendas_hidrometer'] }, canActivate: [GlobalGuard] },
          { path: "criar", component: CriarFazendasComponent, data: { permissao: ['cadastrar_fazendas_hidrometer'] }, canActivate: [GlobalGuard] },
          { path: "editar/:id", component: EditarFazendasComponent, data: { permissao: ['editar_fazendas_hidrometer'] }, canActivate: [GlobalGuard] },
        ],
      },
      { path: "graficos/:tipo", component: GraficosComponent, canActivate: [GlobalGuard], },
      { path: "changeLog", component: ChangeLogComponent, canActivate: [GlobalGuard], },
      {
        path: 'meusLayout', component: LayoutComponent, data: { permissao: ['acessar'] }, canActivate: [GlobalGuard],
        children: [
          { path: "listar", component: ListarLayoutsComponent, canActivate: [GlobalGuard] },
          { path: "modelos", component: ModelosLayoutsComponent, canActivate: [GlobalGuard] },
          { path: "criar/:modelo", component: CriarLayoutsComponent, canActivate: [GlobalGuard] },
          { path: "editar/:id", component: EditarLayoutsComponent, canActivate: [GlobalGuard] },
        ],
      },
      {
        path: 'metas', component: MetasComponent, data: { permissao: ['visualizar_meta'] }, canActivate: [GlobalGuard],
        children: [
          { path: 'exibir', component: ExibirMetasComponent, data: { permissao: ['visualizar_meta'] }, canActivate: [GlobalGuard] },
          { path: 'cadastrar', component: CadastrarMetasComponent, data: { permissao: ['cadastrar_meta'] }, canActivate: [GlobalGuard] },
          { path: '', redirectTo: 'exibir', pathMatch: 'full', }
        ]
      },
      {
        path: 'informacoes-carreteis', component: ListagemInformacoesEquipamentosComponent, data: { permissao: ['acessar_tabela_informacoes_carreteis'] }, canActivate: [GlobalGuard] 
      },
      { path: '', redirectTo: 'hidrometer', pathMatch: 'full' },
      { path: '**', redirectTo: 'hidrometer' },
    ]
  },
  { path: '', redirectTo: '/hidrometer', pathMatch: 'full' },
  { path: '**', redirectTo: '/hidrometer' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
