import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import * as _ from "underscore";
import pluginDataLabels from 'chartjs-plugin-datalabels';
import { MensagensService } from 'src/app/service/mensagens.service';
import { TurnosService } from 'src/app/service/turnos.service';
import { GraficosService } from 'src/app/service/graficos.service';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-produtividade-turno',
  templateUrl: './produtividade-turno.component.html',
  styleUrls: ['./produtividade-turno.component.css']
})
export class ProdutividadeTurnoComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
  idCliente = localStorage.getItem('cliente_filtro');
  dataInicio = localStorage.getItem('DateStartGraficos');
  dataFinal = localStorage.getItem('DateEndGraficos');
  fuso = localStorage.getItem('horario') == 'utc' ? 'utc' : 'brt';
  informacoesTabela = [] as any;
  tabela: any;
  loading = true;
  validaResp = false;
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
    },
    legend: {
      display: false
    }
  };
  public displayedColumns: string[] = [
    "turno",
    "hectares"
  ];
  public pieChartLabels: any = [];
  public pieChartData: any = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [
        '#8C51A5', '#CB5E98', '#F47B8A', '#FFA37E', '#FFD285',
        '#FFFFA5', '#B9E49F', '#78C59B', '#24635B', '#00838D',
        '#089951', '#0DA600', '#8DB309', '#BFA500', '#CC8B0A',
        '#D95900', '#E6270B', '#F20084', '#B20CF9', '#2700FD'
      ],
    },
  ];
  erro = false;
  @Input() ocultarGrafico: boolean = false

  constructor(
    private graficosService: GraficosService
  ) { }

  ngOnInit(): void {
    this.aproveitamentoTurnosNovo()
  }

  aproveitamentoTurnosNovo() {
    this.graficosService.graficoProdutividadeTurno(this.dataInicio, this.dataFinal, this.idCliente, this.fuso).subscribe((resposta: any) => {
      if (resposta && resposta.turnos && resposta.hectares) {
        this.loading = false
        const somaHectares = resposta.hectares.reduce((accumulator: any, value: any) => parseFloat(accumulator) + value, 0);
        if (parseFloat(somaHectares) == 0) {
          this.validaResp = true;
        } else {

          this.pieChartLabels = resposta.turnos;
          this.pieChartData = resposta.hectares;
          this.pieChartLabels.forEach((turno: any, index: any) => {
            this.informacoesTabela.push({ 'turno': turno, 'hectares': resposta.hectares[index], 'id': index, 'hidden': false, cor: this.pieChartColors[0]['backgroundColor'][index] })
          });
          this.tabela = this.informacoesTabela;
        }
      }
    }, err => {
      this.loading = false;
      this.erro = true;
    })
  }

  selecionarCelula(id: any) {
    var indice: any = this.chart?.datasets[0]
    var indiceMeta: any = Object.getOwnPropertyNames(indice['_meta']);
    indiceMeta = indiceMeta[0];
    var id_dado = parseInt(id);
    if (this.tabela[id_dado].hidden) {
      indice['_meta'][indiceMeta]['data'][id_dado].hidden = false
      this.tabela[id_dado].hidden = false;
    } else {
      indice['_meta'][indiceMeta]['data'][id_dado].hidden = true
      this.tabela[id_dado].hidden = true;
    }
    this.chart?.update();
  }
}
