<div class="modelo3 row">
    <div class="col-6 pr-1 pl-0 h-100">
        <div class="modelod-1 w-100 h-100">
            <app-mapa class="w-100 h-100"></app-mapa>
        </div>
    </div>
    <div class="col-6 pl-1 pr-0 h-100">
        <div class="modelod-2 w-100 h-100">
            <app-kanban class="w-100 h-100"></app-kanban>
        </div>
    </div>
</div> 