import { FormatarHoraService } from './../../service/formatar-hora.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { GraficosService } from 'src/app/service/graficos.service';

@Component({
  selector: 'app-tempo-efetivo-frota-motobomba',
  templateUrl: './tempo-efetivo-frota-motobomba.component.html',
  styleUrls: ['./tempo-efetivo-frota-motobomba.component.css']
})
export class TempoEfetivoFrotaMotobombaComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  idCliente = localStorage.getItem('cliente_filtro');
  dataInicio = localStorage.getItem('DateStartGraficos');
  dataFinal = localStorage.getItem('DateEndGraficos');
  tempoMotoBombaFrotas: any;
  frotasMaisEfetivas: any;
  frotasMenosEfetivas: any;
  mostrarSelect = false;
  tipoGrafico = 1;
  informacoesTabela = [] as any;
  tabela: any;
  public displayedColumns: string[] = [
    "frota",
    "horas"
  ];
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      }
    },
    legend: {
      display: false
    },
    tooltips: {
      bodyFontSize: 18,
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          const segundos = data.datasets[0].data.filter((element: any, index: any) => {
            return tooltipItem.index == index
          })
          const turno = data.labels.filter((element: any, index: any) => {
            return tooltipItem.index == index
          })
          let hora = Math.floor(segundos / 3600);
          let minutos = '0' + (Math.floor((segundos - (hora * 3600)) / 60));
          let segundo = '0' + (Math.floor(segundos % 60));
          let horaFormatada = hora + ":" + minutos.substring(minutos.length - 2) + ":" + segundo.substring(segundo.length - 2)
          return `${turno} - ${horaFormatada}`;
        }
      }
    }
  };
  public pieChartLabels: any = [];
  public pieChartData: any = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartColors = [
    {
      backgroundColor: [
        '#8C51A5', '#CB5E98', '#F47B8A', '#FFA37E', '#FFD285',
        '#FFFFA5', '#B9E49F', '#78C59B', '#24635B', '#00838D',
        '#089951', '#0DA600', '#8DB309', '#BFA500', '#CC8B0A',
        '#D95900', '#E6270B', '#F20084', '#B20CF9', '#2700FD'
      ],
    },
  ];
  loading = true;
  validaResp = false
  erro = false;
  @Input() ocultarGrafico: boolean = false

  constructor(
    private graficosService: GraficosService,
    private formatarHora: FormatarHoraService
  ) { }

  ngOnInit(): void {
    this.consultarMensagens();
  }

  consultarMensagens() {
    this.graficosService.graficoTempoEfetivoFrotaMotobomba(this.dataInicio, this.dataFinal, this.idCliente).subscribe((resposta: any) => {
      this.loading = false
      const indicesResposta = Object.keys(resposta);
      if (resposta.frotas && (resposta.frotas.length === 0)) {
        this.validaResp = true;
      } else {
        this.validaResp = false;
      }
      if (indicesResposta.length != 0) {
        this.tempoMotoBombaFrotas = resposta

        if (indicesResposta.includes('frotasMaisEfetivas')) {
          this.frotasMaisEfetivas = resposta.frotasMaisEfetivas;
          this.frotasMenosEfetivas = resposta.frotasMenosEfetivas;
          this.mostrarSelect = true;
          this.mudarGrafico();

        } else {
          const dadosFrotas = this.tempoMotoBombaFrotas.frotas;
          const dadosTempoTrabalhados = this.tempoMotoBombaFrotas.tempoTrabalhado;
          this.pieChartLabels = dadosFrotas;
          this.pieChartData = dadosTempoTrabalhados;
          this.pieChartLabels.forEach((frota: any, index: any) => {
            this.informacoesTabela.push({
              'frota': frota,
              'horas': this.formatarSegundosEmHoras(this.pieChartData[index]),
              'id': index,
              'hidden': false,
              cor: this.pieChartColors[0]['backgroundColor'][index]
            })
          });
          this.tabela = this.informacoesTabela;
        }
      }
    }, err => {
      this.loading = false;
      this.erro = true;
    })
  }

  mudarGrafico() {
    this.tipoGrafico
    let filtrosFrotas = this.frotasMaisEfetivas

    if (this.tipoGrafico == 1) {
      filtrosFrotas = this.frotasMenosEfetivas
    }

    this.informacoesTabela = [];

    const frotas = filtrosFrotas.frotas;
    const tempoTrabalhado = filtrosFrotas.tempoTrabalhado;
    this.pieChartLabels = frotas;
    this.pieChartData = tempoTrabalhado;
    this.pieChartLabels.forEach((frota: any, index: any) => {
      this.informacoesTabela.push({
        'frota': frota,
        'horas': this.formatarSegundosEmHoras(this.pieChartData[index]),
        'id': index,
        'hidden': false,
        cor: this.pieChartColors[0]['backgroundColor'][index]
      })
    });
    this.tabela = this.informacoesTabela;
  }

  selecionarCelula(id: any) {
    var indice: any = this.chart?.datasets[0]
    var indiceMeta: any = Object.getOwnPropertyNames(indice['_meta']);
    indiceMeta = indiceMeta[0];
    var id_dado = parseInt(id);
    if (this.tabela[id_dado].hidden) {
      indice['_meta'][indiceMeta]['data'][id_dado].hidden = false
      this.tabela[id_dado].hidden = false;
    } else {
      indice['_meta'][indiceMeta]['data'][id_dado].hidden = true
      this.tabela[id_dado].hidden = true;
    }
    this.chart?.update();
  }

  formatarSegundosEmHoras(segundos: any) {
    return this.formatarHora.formatarSegundosParaHora(segundos)
  }
}
