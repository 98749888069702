<div class="d-flex justify-content-between align-items-start">
    <div class="d-flex flex-row mb-3">
        <img src="assets/icone-motobomba/motobomba.png">
        <div class="d-flex flex-column justify-content-start align-items-start ml-3">
            <span class="titulo-frota">Frota {{LocalStorageFrota}} (OS {{operacao}})</span>
            <span class="texto-motobomba">Motobomba</span>
        </div>
    </div>
    <div class="mt-1">
        <img src="../../../assets/icones-status-equipamentos/com-conexao.svg" *ngIf="sinal" class="tamanho-icone-informacoes-equipamentos">
        <img src="../../../assets/icones-status-equipamentos/sem-conexao.svg" *ngIf="!sinal" class="tamanho-icone-informacoes-equipamentos">
        <span class="texto-infromacoes-equipamento conexao-equipamento mr-2" [ngClass]="{'sem-conexao': !sinal}"> {{sinal ? 'com' : 'sem' }} conexão </span>
        <img src="../../../assets/icones-status-equipamentos/parado.svg" *ngIf="!trabalhando" class="tamanho-icone-informacoes-equipamentos">
        <img src="../../../assets/icones-status-equipamentos/trabalhando.svg" *ngIf="trabalhando" class="tamanho-icone-informacoes-equipamentos">
        <span class="texto-infromacoes-equipamento status-equipamento" [ngClass]="{'parado': !trabalhando}"> {{trabalhando ? 'trabalhando' : 'parado' }} </span>
    </div>
</div>

<div class="container-fluid">
    <ng-container *ngIf="oqSeraExibido=='InformacoesGerais'">
        <app-informacoes-motobomba></app-informacoes-motobomba>
    </ng-container>

    <ng-container *ngIf="oqSeraExibido=='Timeline'">
        <app-funcionamento-motobomba></app-funcionamento-motobomba>
    </ng-container>
</div>

<div class="d-flex flex-row justify-content-center align-items-center mt-3" *ngIf="!loading">
    <button mat-stroked-button class="botao-estilizado mr-2"
        *ngIf="verificarPermissao(['comando_remoto_motobomba_hidrometer'])" (click)="Mensagem()">
        Enviar comando remoto
    </button>

    <ng-container *ngFor="let botao of botoes">
        <button id="botaoNavegacao" mat-stroked-button class="botao-estilizado mr-2" (click)="MudarExibicao(botao.acao)"
            *ngIf="oqSeraExibido != botao.acao" [disabled]="loading">
            {{botao.texto}}
        </button>
    </ng-container>
</div>