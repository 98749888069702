<h2 style="margin-top: 0;">Selecione o que deseja apresentar:</h2>
<form [formGroup]="form" (ngSubmit)="salvar()">
    <div fxLayout="column" fxLayoutAlign="center start">
        <clr-checkbox-container>
            <clr-checkbox-wrapper *ngFor="let componente of componentes; let i=index">
                <input type="checkbox" clrCheckbox [value]="componente" name="componentes"
                    [checked]="checar(componente)" (change)="onCheckboxChange($event)" formGroupName="ar_componentes" />
                <label> {{componente}}</label>
            </clr-checkbox-wrapper>
        </clr-checkbox-container>
        <br>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="validacao">
        DURAÇÃO DE CADA APRESENTAÇÃO:
        <input matInput class="input form-control tamanho-input" type="text" name="tempo" id="tempo"
            formControlName="tempo">
        seg.
    </div>

</form>
<div class="button-class" fxLayout="row" fxLayoutAlign="end center">
    <button class="btn-sucesso" type="submit" [mat-dialog-close]="form.value">Confirmar</button>
</div>