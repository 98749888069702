import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';
import { HttpOptionsService } from './header-options.service';

@Injectable({
  providedIn: 'root'
})
export class ComunicadoresService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
    ) {}

  public cadastrar(comunicador: any) {
    const url = `${this.BASE_URL_HIDROMETER}comunicadores`;
    return this.http.post(url, comunicador, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorCliente(cliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}comunicadores/cliente/${cliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorClientePaginate(cliente: any, size: any) {
    const url = `${this.BASE_URL_HIDROMETER}comunicadores/paginate/cliente/${cliente}/size/${size}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorId(comunicadorId: any) {
    const url = `${this.BASE_URL_HIDROMETER}comunicadores/${comunicadorId}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public Atualizar(comunicador: any, comunicadorId: any) {
    const url = `${this.BASE_URL_HIDROMETER}comunicadores/${comunicadorId}`;
    return this.http.put(url, comunicador, this.httpOptionsService.getHttpOptions());
  }

  public Desativar(comunicadorId: any) {
    const url = `${this.BASE_URL_HIDROMETER}comunicadores/${comunicadorId}`;
    return this.http.delete(url, this.httpOptionsService.getHttpOptions());
  }

  public Next(url: any) {
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public comunicadoresLivres(clientId: any) {
    const url = `${this.BASE_URL_HIDROMETER}comunicadores-livres/cliente/${clientId}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());

  }

  public comunicadoresComRelacionamento(clienteId:any){
    const url = `${this.BASE_URL_HIDROMETER}equipamentos-com-comunicadores/cliente/${clienteId}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }
}
