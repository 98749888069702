import { MatBadgeModule } from '@angular/material/badge';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http"
import { MatToolbarModule } from "@angular/material/toolbar";;
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from "@angular/material/paginator";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from "@angular/material/button";
import { MatSortModule } from "@angular/material/sort";
import { MatDialogModule } from "@angular/material/dialog";
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatMenuModule } from "@angular/material/menu";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ChartsModule } from 'ng2-charts';
import { ClarityModule } from '@clr/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectFilterModule } from 'mat-select-filter';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxMaskModule, IConfig } from 'ngx-mask'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RedirectComponent } from './redirect/redirect.component';
import { HomeComponent } from './home/home.component';
import { EquipamentosComponent } from './equipamentos/equipamentos.component';
import { ListaEquipamentosComponent } from './equipamentos/lista-equipamentos/lista-equipamentos.component';
import { CriarEquipamentosComponent } from './equipamentos/criar-equipamentos/criar-equipamentos.component';
import { EditarEquipamentosComponent } from './equipamentos/editar-equipamentos/editar-equipamentos.component';
import { DialogEquipamentosComponent } from './equipamentos/dialog-equipamentos/dialog-equipamentos.component';
import { TurnosComponent } from './turnos/turnos.component';
import { CriarTurnoComponent } from './turnos/criar-turno/criar-turno.component';
import { EditarTurnoComponent } from './turnos/editar-turno/editar-turno.component';
import { ListarTurnoComponent } from './turnos/listar-turno/listar-turno.component';
import { OperacoesComponent } from './operacoes/operacoes.component';
import { ListarOperacoesComponent } from './operacoes/listar-operacoes/listar-operacoes.component';
import { CriarOperacoesComponent } from './operacoes/criar-operacoes/criar-operacoes.component';
import { EditarOperacoesComponent } from './operacoes/editar-operacoes/editar-operacoes.component';
import { MonitoramentoOperacoesComponent } from './operacoes/monitoramento-operacoes/monitoramento-operacoes.component';
import { KanbanComponent } from './kanban/kanban.component';
import { DialogMensagensComponent } from './operacoes/monitoramento-operacoes/dialog-mensagens/dialog-mensagens.component';
import { DialogEditarFaixasComponent } from './operacoes/dialog-editar-faixas/dialog-editar-faixas.component';
import { CaracteristicasComponent } from './caracteristicas/caracteristicas.component';
import { ListaCaracteristicasComponent } from './caracteristicas/lista-caracteristicas/lista-caracteristicas.component';
import { EditaCaracteristicasComponent } from './caracteristicas/edita-caracteristicas/edita-caracteristicas.component';
import { ValorCaracteristicasComponent } from './caracteristicas/valor-caracteristicas/valor-caracteristicas.component';
import { CriarCaracteristicasComponent } from './caracteristicas/criar-caracteristicas/criar-caracteristicas.component';
import { ImportarArquivosComponent } from './importar-arquivos/importar-arquivos.component';
import { FazendasComponent } from './fazendas/fazendas.component';
import { ListarFazendasComponent } from './fazendas/listar-fazendas/listar-fazendas.component';
import { CriarFazendasComponent } from './fazendas/criar-fazendas/criar-fazendas.component';
import { EditarFazendasComponent } from './fazendas/editar-fazendas/editar-fazendas.component';
import { GraficosComponent } from './graficos/graficos.component';
import { AbastecimentoMotobombaComponent } from './graficos/abastecimento-motobomba/abastecimento-motobomba.component';
import { AlertasMotobombaComponent } from './graficos/alertas-motobomba/alertas-motobomba.component';
import { ConsumoMotobombaComponent } from './graficos/consumo-motobomba/consumo-motobomba.component';
import { ProdutividadeOsComponent } from './graficos/produtividade-os/produtividade-os.component';
import { ProdutividadeTurnoComponent } from './graficos/produtividade-turno/produtividade-turno.component';
import { TempoEfetivoFrotaMotobombaComponent } from './graficos/tempo-efetivo-frota-motobomba/tempo-efetivo-frota-motobomba.component';
import { TempoEfetivoOsCarretelComponent } from './graficos/tempo-efetivo-os-carretel/tempo-efetivo-os-carretel.component';
import { TempoEfetivoOsMotobombaComponent } from './graficos/tempo-efetivo-os-motobomba/tempo-efetivo-os-motobomba.component';
import { TempoEfetivoTurnoCarretelComponent } from './graficos/tempo-efetivo-turno-carretel/tempo-efetivo-turno-carretel.component';
import { TempoEfetivoTurnoMotobombaComponent } from './graficos/tempo-efetivo-turno-motobomba/tempo-efetivo-turno-motobomba.component';
import { BateriaCarretelComponent } from './graficos/bateria-carretel/bateria-carretel.component';
import { OdometroCarretelComponent } from './graficos/odometro-carretel/odometro-carretel.component';
import { PressaoCarretelComponent } from './graficos/pressao-carretel/pressao-carretel.component';
import { VelocidadeRecolhimentoCarretelComponent } from './graficos/velocidade-recolhimento-carretel/velocidade-recolhimento-carretel.component';
import { InformacoesCarretelComponent } from './graficos/informacoes-carretel/informacoes-carretel.component';
import { ControleRpmMotobombaComponent } from './graficos/controle-rpm-motobomba/controle-rpm-motobomba.component';
import { FuncionamentoMotobombaComponent } from './graficos/funcionamento-motobomba/funcionamento-motobomba.component';
import { CombustivelMotobombaComponent } from './graficos/combustivel-motobomba/combustivel-motobomba.component';
import { PressaoEntradaMotobombaComponent } from './graficos/pressao-entrada-motobomba/pressao-entrada-motobomba.component';
import { PressaoSaidaMotobombaComponent } from './graficos/pressao-saida-motobomba/pressao-saida-motobomba.component';
import { InformacoesMotobombaComponent } from './graficos/informacoes-motobomba/informacoes-motobomba.component';
import { InformacoesTratorComponent } from './graficos/informacoes-trator/informacoes-trator.component';
import { MapaComponent } from './mapa/mapa.component';
import { DialogCarretelComponent } from './mapa/dialog-carretel/dialog-carretel.component';
import { DialogMotobombaComponent } from './mapa/dialog-motobomba/dialog-motobomba.component';
import { DialogTratorComponent } from './mapa/dialog-trator/dialog-trator.component';
import { DialogInformacoesEsticamentoComponent } from './mapa/dialog-informacoes-esticamento/dialog-informacoes-esticamento.component';
import { LayoutComponent } from './layout/layout.component';
import { ListarLayoutsComponent } from './layout/listar-layouts/listar-layouts.component';
import { CriarLayoutsComponent } from './layout/criar-layouts/criar-layouts.component';
import { ModelosLayoutsComponent } from './layout/modelos-layouts/modelos-layouts.component';
import { EditarLayoutsComponent } from './layout/editar-layouts/editar-layouts.component';
import { DialogLayoutComponent } from './layout/dialog-layout/dialog-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ModeloDefaultComponent } from './dashboard/modelo-default/modelo-default.component';
import { Modelo1Component } from './dashboard/modelo1/modelo1.component';
import { Modelo2Component } from './dashboard/modelo2/modelo2.component';
import { Modelo3Component } from './dashboard/modelo3/modelo3.component';
import { Modelo4Component } from './dashboard/modelo4/modelo4.component';
import { Modelo5Component } from './dashboard/modelo5/modelo5.component';
import { Modelo6Component } from './dashboard/modelo6/modelo6.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogEnviarMensagemSatelitalComponent } from './dialog-enviar-mensagem-satelital/dialog-enviar-mensagem-satelital.component';
import { TemposTrabalhadosComponent } from './graficos/tempos-trabalhados/tempos-trabalhados.component';
import { TemposTiposComponent } from './graficos/tempos-trabalhados/tempos-tipos/tempos-tipos.component';
import { DetalhesMotivoComponent } from './graficos/tempos-trabalhados/detalhes-motivo/detalhes-motivo.component';
import { MensagemParadaMapaComponent } from './graficos/tempos-trabalhados/mensagem-parada-mapa/mensagem-parada-mapa.component';
import { TemposTrabalhadosFrotaComponent } from './graficos/tempos-trabalhados-frota/tempos-trabalhados-frota.component';
import { DialogTemposFrotasComponent } from './graficos/tempos-trabalhados-frota/dialog-tempos-frotas/dialog-tempos-frotas.component';
import { DialogInfoPeriodoFiltradoComponent } from './graficos/tempos-trabalhados-frota/dialog-info-periodo-filtrado/dialog-info-periodo-filtrado.component';
import { DialogMensagensNotificacaoComponent } from './mapa/dialog-mensagens-notificacao/dialog-mensagens-notificacao.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { ProdutividadeCarretelComponent } from './graficos/produtividade-carretel/produtividade-carretel.component';
import { DialogInformacoesIconesComponent } from './mapa/dialog-informacoes-icones/dialog-informacoes-icones.component';
import { ModeloMapaKanbanComponent } from './dashboard/modelo-mapa-kanban/modelo-mapa-kanban.component';
import { httpInterceptorsProviders } from './http-interceptors';
import { CancelarRequestsService } from './service/cancelar-requests.service';
import { CancelarRequestsInterceptor } from './http-interceptors/cancelar-requests.interceptor';
import { DialogNovosRecusosComponent } from './home/dialog-novos-recusos/dialog-novos-recusos.component';
import { ErroComponent } from './erro/erro.component';
import { LoaderComponent } from './loader/loader.component';
import { ChangeLogComponent } from './change-log/change-log.component';
import { HectaresProduzidosComponent } from './mapa/hectares-produzidos/hectares-produzidos.component';
import { DialogExportarGeojsonComponent } from './dialog-exportar-geojson/dialog-exportar-geojson.component';
import { MatListModule } from '@angular/material/list';
import { ModalGraficosComponent } from './graficos/modal-graficos/modal-graficos.component';
import { BotoesAcaoMapaComponent } from './botoes-acao-mapa/botoes-acao-mapa.component';
import { BotaoFiltrosComponent } from './botoes-acao-mapa/botao-filtros/botao-filtros.component';
import { BotaoBuscaEquipamentoComponent } from './botoes-acao-mapa/botao-busca-equipamento/botao-busca-equipamento.component';
import { BotaoInformativoIconesComponent } from './botoes-acao-mapa/botao-informativo-icones/botao-informativo-icones.component';
import { BotaoExportarComponent } from './botoes-acao-mapa/botao-exportar/botao-exportar.component';
import { ModalHorarioComponent } from './modal-horario/modal-horario.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BotaoMensagensComponent } from './botoes-acao-mapa/botao-mensagens/botao-mensagens.component';
import { CorpoNotificacoesComponent } from './botoes-acao-mapa/botao-mensagens/corpo-notificacoes/corpo-notificacoes.component';
import { BotaoWhatsappComponent } from './botao-whatsapp/botao-whatsapp.component';
import { MatCardModule } from '@angular/material/card';
import { DialogWhatsappComponent } from './botao-whatsapp/dialog-whatsapp/dialog-whatsapp.component';
import { OperadoresComponent } from './operadores/operadores.component';
import { ListarOperadoresComponent } from './operadores/listar-operadores/listar-operadores.component';
import { EditarOperadoresComponent } from './operadores/editar-operadores/editar-operadores.component';
import { BotaoHectaresMobileComponent } from './botoes-acao-mapa/botao-hectares-mobile/botao-hectares-mobile.component';
import { HectaresProduzidoMobileComponent } from './mapa/hectares-produzidos-mobile/hectares-produzidos-mobile';
import { MetasComponent } from './metas/metas.component';
import { ExibirMetasComponent } from './metas/exibir-metas/exibir-metas.component';
import { CadastrarMetasComponent } from './metas/cadastrar-metas/cadastrar-metas.component';
import { ListagemInformacoesEquipamentosComponent } from './listagem-informacoes-equipamentos/listagem-informacoes-equipamentos.component';
@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
    HomeComponent,
    EquipamentosComponent,
    ListaEquipamentosComponent,
    CriarEquipamentosComponent,
    EditarEquipamentosComponent,
    DialogEquipamentosComponent,
    TurnosComponent,
    CriarTurnoComponent,
    EditarTurnoComponent,
    ListarTurnoComponent,
    OperacoesComponent,
    ListarOperacoesComponent,
    CriarOperacoesComponent,
    EditarOperacoesComponent,
    MonitoramentoOperacoesComponent,
    KanbanComponent,
    DialogMensagensComponent,
    DialogEditarFaixasComponent,
    CaracteristicasComponent,
    ListaCaracteristicasComponent,
    EditaCaracteristicasComponent,
    ValorCaracteristicasComponent,
    CriarCaracteristicasComponent,
    ImportarArquivosComponent,
    FazendasComponent,
    ListarFazendasComponent,
    CriarFazendasComponent,
    EditarFazendasComponent,
    GraficosComponent,
    AbastecimentoMotobombaComponent,
    AlertasMotobombaComponent,
    ConsumoMotobombaComponent,
    ProdutividadeOsComponent,
    ProdutividadeTurnoComponent,
    TempoEfetivoFrotaMotobombaComponent,
    TempoEfetivoOsCarretelComponent,
    TempoEfetivoOsMotobombaComponent,
    TempoEfetivoTurnoCarretelComponent,
    TempoEfetivoTurnoMotobombaComponent,
    BateriaCarretelComponent,
    OdometroCarretelComponent,
    PressaoCarretelComponent,
    VelocidadeRecolhimentoCarretelComponent,
    InformacoesCarretelComponent,
    ControleRpmMotobombaComponent,
    FuncionamentoMotobombaComponent,
    CombustivelMotobombaComponent,
    PressaoEntradaMotobombaComponent,
    PressaoSaidaMotobombaComponent,
    InformacoesMotobombaComponent,
    InformacoesTratorComponent,
    MapaComponent,
    DialogCarretelComponent,
    DialogMotobombaComponent,
    DialogTratorComponent,
    DialogInformacoesEsticamentoComponent,
    LayoutComponent,
    ListarLayoutsComponent,
    CriarLayoutsComponent,
    ModelosLayoutsComponent,
    EditarLayoutsComponent,
    DialogLayoutComponent,
    DashboardComponent,
    ModeloDefaultComponent,
    Modelo1Component,
    Modelo2Component,
    Modelo3Component,
    Modelo4Component,
    Modelo5Component,
    Modelo6Component,
    DialogEnviarMensagemSatelitalComponent,
    TemposTrabalhadosComponent,
    TemposTiposComponent,
    DetalhesMotivoComponent,
    MensagemParadaMapaComponent,
    TemposTrabalhadosFrotaComponent,
    DialogTemposFrotasComponent,
    DialogInfoPeriodoFiltradoComponent,
    DialogMensagensNotificacaoComponent,
    TutorialComponent,
    ProdutividadeCarretelComponent,
    DialogInformacoesIconesComponent,
    ModeloMapaKanbanComponent,
    DialogNovosRecusosComponent,
    ErroComponent,
    LoaderComponent,
    ChangeLogComponent,
    HectaresProduzidosComponent,
    DialogExportarGeojsonComponent,
    ModalGraficosComponent,
    BotoesAcaoMapaComponent,
    BotaoFiltrosComponent,
    BotaoBuscaEquipamentoComponent,
    BotaoInformativoIconesComponent,
    BotaoExportarComponent,
    ModalHorarioComponent,
    BotaoMensagensComponent,
    CorpoNotificacoesComponent,
    BotaoWhatsappComponent,
    DialogWhatsappComponent,
    OperadoresComponent,
    ListarOperadoresComponent,
    EditarOperadoresComponent,
    BotaoHectaresMobileComponent,
    HectaresProduzidoMobileComponent,
    MetasComponent,
    ExibirMetasComponent,
    CadastrarMetasComponent,
    ListagemInformacoesEquipamentosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule,
    MatSortModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MatMenuModule,
    DragDropModule,
    ChartsModule,
    ClarityModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    NgbModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatGridListModule,
    MatSelectFilterModule,
    CommonModule,
    MatListModule,
    MatButtonToggleModule,
    MatCardModule,
    NgxMaskModule.forRoot(),
    MatBadgeModule
  ],
  providers: [
    CancelarRequestsService,
    { provide: HTTP_INTERCEPTORS, useClass: CancelarRequestsInterceptor, multi: true },
    httpInterceptorsProviders,
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
