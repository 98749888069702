import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatarHoraService {

  constructor() { }

  public formatarSegundosParaHora(segundos: number) {
    let hora = Math.floor(segundos / 3600);
    let minutos = '0' + (Math.floor((segundos - (hora * 3600)) / 60));
    let segundo = '0' + (Math.floor(segundos % 60));
    return hora + ":" + minutos.substring(minutos.length - 2) + ":" + segundo.substring(segundo.length - 2);
  }

  public formatarHora(horaInt: number) {
    let segundos = horaInt * 60
    let hora = Math.floor(segundos / 3600);
    let minutos = '0' + (Math.floor((hora * 3600)) / 60);
    let segundo = '0' + (Math.floor(hora % 60));
    return hora + ":" + minutos.substring(minutos.length - 2) + ":" + segundo.substring(segundo.length - 2);
  }
}
