<main style="width: 96%; margin: 0 2rem;">
    <div class="cabecalho">
        <div class="d-flex">
            <h1>Equipamentos</h1>
        </div>
        <span class="info-listas">lista de todos os equipamentos</span>
        <div class="linha">
            <form [formGroup]="formFilter" fxFlex.lt-md="100" fxLayout="row" fxLayoutAlign="center center">
                <label> Equipamentos da usina:
                    <select class="filtro-listas" formControlName="client">
                        <option *ngFor="let client of clients" [value]="client.id">
                            {{ client.nome }}
                        </option>
                    </select>
                </label>
            </form> 
         <div id="visibilidade" class="col-md-5">
            <form id="filtroVisibilidade" fxFlex.lt-md="60" fxLayout="row" fxLayoutAlign="center center">
                <label style="margin-top: 4px"> Filtrar por: 
                    <input type="radio" name="opcao" id="opcao3" class="radio-visibilidade" value="todos" (click)="filtrarVisibilidade()" checked/>
                    <label for="opcao3"> Todos </label>&nbsp;    
                    <input type="radio" name="opcao" id="opcao1" class="radio-visibilidade" value="oculto" (click)="filtrarVisibilidade(1)"/>
                    <label for="opcao1"> Ocultos </label>&nbsp;    
                    <input type="radio" name="opcao" id="opcao2" class="radio-visibilidade" value="exibido" (click)="filtrarVisibilidade(0)"/>
                    <label for="opcao2"> Exibidos </label>
                </label>
            </form>
         </div>
            <button class="botao-criar" routerLink="../criar"
                *ngIf="verificarPermissao(['cadastrar_equipamentos_hidrometer'])">
                <clr-icon shape="truck" size="20" class="margem-clr-icon is-solid"></clr-icon>
                Novo equipamento
            </button>
        </div>
    </div>
    <div fxLayout="column">
        <input matInput (keyup)="applyFilter($event)" placeholder="Filtrar" autocomplete="off" class="input-pesquisa">

        <div *ngIf="loading" style="width: auto; height: 60vh;" fxLayout="row" fxLayoutAlign="center center">
            <div class="spinner"></div>
        </div>
        <div class="mensagem" id="resp" *ngIf="validaResp">
            <p *ngIf="compararFiltroVisibilidade(null)" class="text-center">Não há equipamentos cadastrados dessa usina</p>
            <p *ngIf="compararFiltroVisibilidade(1)" class="text-center">Não há equipamentos ocultos dessa usina</p>
            <p *ngIf="compararFiltroVisibilidade(0)" class="text-center">Não há equipamentos exibidos dessa usina</p> 
        </div>

        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort [hidden]="loading">
            <ng-container matColumnDef="ts_criado_em">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Criado em:
                </th>
                <td mat-cell *matCellDef="let element">
                    <span>
                        {{ element.ts_criado_em | date: "dd/MM/yyyy HH:mm:ss"}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="ts_atualizado_em">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Atualizado em:
                </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element">
                        {{ element.ts_atualizado_em | date: "dd/MM/yyyy HH:mm:ss" }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="vc_tipo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Tipo</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.vc_tipo === 'TRACTOR'">
                        TRATOR
                    </span>
                    <span *ngIf="element.vc_tipo === 'REEL'">
                        CARRETEL
                    </span>
                    <span *ngIf="element.vc_tipo === 'MOTOR_PUMP'">
                        MOTOBOMBA
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="vc_frota">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Frota</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element">
                        {{ element.vc_frota ? element.vc_frota : "" }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="vc_mobileId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Comunicadores</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element">
                        {{ element.vc_mobileId ? element.vc_mobileId : "Sem comunicador" }}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="vc_versao" *ngIf="verificarPermissao(['campo_versão'])">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Versão do software</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element">
                        {{ element.vc_versao ? element.vc_versao : "Não encontrada" }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="fk_ultimo_operador">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Último operador</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element">
                        {{ element.operador }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="control" class="d-flex jsutify-content-center">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div id="tableButton" class="d-flex justify-content-center">
                        <button class="botao-editar d-flex ml-2" (click)="acompanhar(element.sr_id, element.vc_frota)">
                            <clr-icon shape="file-group"></clr-icon>Acompanhar
                        </button>
                        
                        <button class="botao-editar d-flex ml-2" routerLink="../editar/{{ element.sr_id }}"
                            *ngIf="verificarPermissao(['editar_equipamentos_hidrometer'])">
                            <clr-icon shape="pencil"></clr-icon>Editar
                        </button>
                        
                        <button class="botao-editar d-flex ml-2 btn-ocultar-equipamento justify-content-center" (click)="alterarVisibilidade(element.sr_id)" *ngIf="verificarPermissao(['ocultar_equipamento'])">
                            <span *ngIf="element.bl_oculto" class="no-wrap"><clr-icon shape="eye"></clr-icon>Exibir no mapa</span>
                            <span *ngIf="!element.bl_oculto" class="no-wrap"><clr-icon shape="eye-hide"></clr-icon>Ocultar no mapa</span>
                        </button>
                        
                        <button class="botao-excluir d-flex ml-2" (click)="delete(element.sr_id)"
                            *ngIf="verificarPermissao(['deletar_equipamentos_hidrometer'])">
                            <clr-icon shape="trash"></clr-icon>
                            Desativar
                        </button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                    <div class="example-element-detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                        *ngIf="!element.dadosAdicionais.status">
                        <table mat-table [dataSource]="element.dadosAdicionais" matSort>
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>
                                    Caracteristica
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.vc_nome }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="value">
                                <th mat-header-cell *matHeaderCellDef>
                                    Valor
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.valor ? element.valor : "" }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="controls">
                                <th mat-header-cell class="align-center" *matHeaderCellDef>
                                    Controles
                                </th>
                                <td mat-cell class="align-center" *matCellDef="let element">
                                    <button class="botao-editar"
                                        routerLink="../../caracteristicas/valor/{{ element.sr_id }}/{{ element.idEquipamento }}/equipamento"
                                        *ngIf="verificarPermissao(['editar_caracteristicas_adicionais_hidrometer'])">
                                        <clr-icon shape="pencil"></clr-icon>
                                        Editar
                                    </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="
                                    let row;
                                    columns: innerDisplayedColumns
                                "></tr>
                        </table>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>
    </div>

    <mat-paginator  fxLayoutAlign="center center" [length]="length" [pageSize]="10"
        [hidden]="loading">
    </mat-paginator>
</main>