import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { TurnosService } from 'src/app/service/turnos.service';

@Component({
  selector: 'app-editar-turno',
  templateUrl: './editar-turno.component.html',
  styleUrls: ['./editar-turno.component.css']
})
export class EditarTurnoComponent implements OnInit {

  formularioTurno!: FormGroup;
  idTurno: number = 0;
  clients = JSON.parse(localStorage.getItem('cliente') || "{}");

  constructor(
    private formBuilder: FormBuilder,
    private shiftService: TurnosService,
    private location: Location,
    private route: ActivatedRoute,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.popular();
  }

  montarForm() {
    this.formularioTurno = this.formBuilder.group({
      fk_cliente: [null, Validators.required],
      vc_nome: [null, Validators.required],
      tm_hora_inicio: [null, ""],
      tm_hora_fim: [null, ""],
    });
  }

  popular() {
    this.route.params.subscribe((params: Params) => {
      this.montarForm()
      this.idTurno = Number(params["id"]);
      this.shiftService.AcharPorId(this.idTurno).subscribe((data: any) => {
        this.formularioTurno.get("fk_cliente")?.setValue(data.cliente.it_id_premocenter);
        this.formularioTurno.get("vc_nome")?.setValue(data.vc_nome);
        this.formularioTurno.get("tm_hora_inicio")?.setValue(data.tm_hora_inicio);
        this.formularioTurno.get("tm_hora_fim")?.setValue(data.tm_hora_fim);
      })
    })
  }

  atualizarTurno() {
    this.shiftService.Atualizar(this.formularioTurno.value, this.idTurno).subscribe(() => {
      this.alertService.success('Turno atualizado com sucesso!')
      this.location.back();
    }, () => {
      this.alertService.error('Erro ao criar turno')
    });
  }

  public cancelar(): void {
    this.location.back();
  }
}
