<div class="container-fluid d-flex justify-content-center">
    <div class="w-50 text-justify mt-2">
        <h1>Bem-vindo à Página de Atualizações e Novidades do HidroMeter</h1>
        <br />
        <span>Estamos empolgados em compartilhar com você as últimas melhorias e inovações em nosso software.
            Mantendo nosso compromisso de aprimorar continuamente sua experiência, trabalhamos arduamente para oferecer
            as atualizações mais recentes que tornam nosso produto ainda melhor.</span>
        <br />
        <span>Aqui, você encontrará um resumo das cinco versões mais recentes, detalhando as mudanças, atualizações e
            novas features que incorporamos. Ficamos dedicados em ouvir seu feedback e em aprimorar nosso produto com
            base nas suas necessidades e sugestões.</span>
        <br />
        <span>Estamos comprometidos com a transparência e a comunicação aberta, e esta página serve como um canal para
            manter você informado sobre todas as melhorias recentes. Se você tiver alguma pergunta ou sugestão, não
            hesite em entrar em contato conosco.</span>
        <br />
        <span>Obrigado por fazer parte da nossa jornada de desenvolvimento e por escolher o HidroMeter. Continue lendo
            para saber mais sobre as últimas atualizações e recursos que preparamos para você.</span>
        <div *ngFor="let versao of versoes">
            <h3>Versão {{versao.it_versao_principal}}.{{versao.it_feature}}.{{versao.it_bug}}</h3>
            <span>{{versao.vc_notas}}</span>
            <clr-accordion>
                <clr-accordion-panel *ngFor="let atualizacao of versao.atualizacoes">
                    <clr-accordion-title>{{atualizacao.vc_titulo}}</clr-accordion-title>
                    <clr-accordion-content *clrIfExpanded>
                        <span class="subtitulo">{{atualizacao.vc_subtitulo}}</span><br>
                        {{atualizacao.vc_informacao}} <br>
                        <div *ngIf="atualizacao.vc_midia">
                            <img class="midia" src={{atualizacao.vc_midia}} alt={{atualizacao.vc_legenda_midia}}
                                title={{atualizacao.vc_legenda_midia}}>
                        </div>
                    </clr-accordion-content>
                </clr-accordion-panel>
            </clr-accordion>
        </div>
        <div *ngIf="versoes.length == 0 && !loading" class="text-center mt-5">
            <h3>Sem registros de novas versões</h3>
        </div>
        <div *ngIf="loading" style="width: auto; height: 40vh;" fxLayout="row" fxLayoutAlign="center center">
            <div *ngIf="loading" class="spinner"></div>
        </div>
    </div>
</div>