import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { BASE_URL_PREMOCENTER_FRONT } from 'src/app/app.constants';
import { MensagensService } from 'src/app/service/mensagens.service';
import { OperacoesService } from 'src/app/service/operacoes.service';
import { TraduzirMensagemService } from 'src/app/service/traduzir-mensagem.service';
import * as _ from "underscore";
import { DialogMensagensComponent } from './dialog-mensagens/dialog-mensagens.component';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';


@Component({
  selector: 'app-monitoramento-operacoes',
  templateUrl: './monitoramento-operacoes.component.html',
  styleUrls: ['./monitoramento-operacoes.component.css']
})
export class MonitoramentoOperacoesComponent implements OnInit {

  LocalStorageId = localStorage.getItem("cliente_filtro");
  urlPremoplan = BASE_URL_PREMOCENTER_FRONT;
  length: any;
  next: any
  public operationId?: number;
  returnMessages: any;
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public displayedColumns: string[] = ['dataHora',
    'vc_mobileId', 'it_tamanho_da_mensagem', 'vc_frota', 'group', 'descriptor', 'operador_nome',
    'fk_operacao', 'it_service_id', 'controls'];
  loading = true;
  validaResp = false
  downloading = false;

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private operationService: OperacoesService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private traduzService: TraduzirMensagemService,
    private mensagensService: MensagensService) { }

  ngOnInit(): void {
    this.initializeInvites();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public initializeInvites(): void {
    this.route.params.subscribe((params: Params) => {
      this.operationId = Number(params.id);
      this.mensagensService.MensagensPorOperacaoClient(this.operationId, this.LocalStorageId, 10000).subscribe((mensagens: any) => {
        if (mensagens.data.length == 0) {
          this.validaResp = true
        }
        mensagens.data.forEach((element: any) => {
          element.js_payload_tratado = JSON.parse(element.js_payload_tratado);

          element.dataHora = element.js_payload_tratado.timestamp || element.js_payload_tratado.timeStamp || element.ts_timestamp_mensagem;
          element.group = element.js_payload_tratado.group;
          element.descriptor = element.js_payload_tratado.descriptor;
        });
        this.length = mensagens.total;
        this.returnMessages = mensagens.data;
        this.dataSource.data = this.returnMessages;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.next = mensagens.next_page_url;
        this.loading = false
      }, (err) => {
        if (err.statusText == "Unauthorized") {
          window.location.href = `${this.urlPremoplan}`
        }
      })
    })
  }

  trocarPagina(event: any) {
    this.operationService.Next(this.next).subscribe((data: any) => {
      this.returnMessages = this.returnMessages.filter(function (i: any) {
        return i;
      });
      this.returnMessages.push(...data.data)
      this.returnMessages.length = data.total
      this.dataSource.data = this.returnMessages;
      this.next = data.next_page_url
    });
  }

  public parseMessage(e: any, id: number) {
    let icon = e.target.style.color = '#fff';
    const message = this.returnMessages.find((x: any) => x.sr_id === id);
    var pay = message.js_payload_tratado;
    if (pay) {
      var mensagem = this.traduzService.TraduzirPay(pay)
      this.dialog.open(DialogMensagensComponent, {
        width: "450px",
        data: mensagem
      });
    }
    return icon;
  }

  downloadFile() {
    this.downloading = true;
    var mensagensJuntar = [["Data e hora recebimento Msg", "Comunicador ID", "OS"]];
    this.mensagensService.MensagensPorOperacaoClient(this.operationId, this.LocalStorageId, 10000).subscribe((mensagens: any) => {
      var pgd = _.uniq(mensagens.data, function (item) {
        return item.bn_pgd
      })
      pgd.forEach(element => {
        element.js_payload_tratado = JSON.parse(element.js_payload_tratado)
        element.js_payload_traduzido = this.traduzService.TraduzirPay(element.js_payload_tratado);
        var payKey = Object.keys(element.js_payload_traduzido).sort();
        payKey.forEach((element) => {
          if (element != '0') {
            var existe = mensagensJuntar[0].includes(element);
            if (!existe) {
              mensagensJuntar[0].push(element)
            }
          }
        });
      });
      mensagens.data.forEach((mensagem: any) => {
        var array = [] as any
        if (typeof mensagem.js_payload_tratado != 'object') {
          mensagem.js_payload_tratado = JSON.parse(mensagem.js_payload_tratado)
        }
        mensagensJuntar[0].forEach((cabecalho: any) => {
          if (cabecalho == 'Data e hora recebimento Msg') {
            const formattedDateTime = format(new Date(mensagem.ts_timestamp_mensagem + 'Z'), 'dd/MM/yyyy HH:mm:ss');
            array.push(formattedDateTime)
          } else if (cabecalho == 'Comunicador ID') {
            array.push(mensagem.vc_mobileId)
          } else if (cabecalho == 'OS') {
            array.push(mensagem.it_service_id)
          } else {
            mensagem.js_payload_traduzido = this.traduzService.TraduzirPay(mensagem.js_payload_tratado);
            if (mensagem.js_payload_traduzido.Horário) {
              mensagem.js_payload_traduzido.Horário = format(new Date(mensagem.js_payload_traduzido.Horário), 'dd/MM/yyyy HH:mm:ss');
            }
            var payChave = Object.keys(mensagem.js_payload_traduzido);
            var payValor = Object.values(mensagem.js_payload_traduzido);

            if (payChave.indexOf(cabecalho) >= 0) {
              var payChave = Object.keys(mensagem.js_payload_traduzido);
              var indice = payChave.indexOf(cabecalho)
              array.push(payValor[indice])
            } else {
              array.push('')
            }
          }
        })
        mensagensJuntar.push(array)
      })

      this.criaArquivo(mensagensJuntar);
    });
  }

  criaArquivo(mensagensJuntar: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(mensagensJuntar);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Mensagens');

    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    const excelFileName: string = 'Mensagens_Hidrometer_OS' + mensagensJuntar[1][2] + '.xlsx';

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(data);
    downloadLink.download = excelFileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    this.downloading = false;
  }
}
