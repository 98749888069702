import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RedirectService } from '../service/redirect.service';
import * as _ from 'underscore';
import { BASE_URL_PREMOCENTER_FRONT } from '../app.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    camposData: string[] = [
        'ts_criado_em',
        'ts_atualizado_em',
        'ts_timestamp_mensagem',
        'timestamp',
        'timeStamp',
        'ts_data_esticamento',
        'ts_data_finalizacao',
        'inicio',
        'fim',
        'horaUltimaMensagem'
    ];
    camposDataPayload: string[] = [
        'Hora',
        'timestamp',
        'timeStamp',
    ];


    constructor(
        private accountService: RedirectService,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const token = localStorage.getItem('token');
        let request: HttpRequest<any> = req;
        if (token && !this.accountService.tokenExpirado(token)) {
            request = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`)
            });
        }

        // retorno o request com o erro tratado
        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                event = event.clone({ body: this.modifyBody(event.body) });
            }
            return event;
        }), catchError((error: HttpErrorResponse) => {
            if (error.status == 401) {
                window.location.href = BASE_URL_PREMOCENTER_FRONT;
            }
            return throwError(error);
        }));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // Erro de client-side ou de rede
            console.error('Ocorreu um erro:', error.error.message);
        } else {
            // Erro retornando pelo backend
            console.error(
                `Código do erro ${error.status}, ` +
                `Erro: ${JSON.stringify(error.error)}`);
        }
        // retornar um observable com uma mensagem amigavel.
        if (error.status == 401) {
            return throwError('401');
        }
        return throwError(`${JSON.stringify(error.error)}`);
    }

    verificaSeDataIso8601(dateString: string): boolean {
        const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d+)?([+-]\d{2}:\d{2}|Z)?)$/;
        return iso8601Regex.test(dateString);
    }

    verificaSeFusoHorarioBrt(): boolean {
        const timezone = localStorage.getItem('horario');
        return timezone == null || timezone == 'brt';
    }

    converterDataSeNecessario(element: any, nomeCampo: string): void {
        const valorCampo = element[nomeCampo];
        let data = new Date(valorCampo);

        if (this.verificaSeDataIso8601(valorCampo) && !this.verificaSeFusoHorarioBrt()) {
            data.setHours(data.getHours() + 3);
        } else if (!this.verificaSeDataIso8601(valorCampo) && this.verificaSeFusoHorarioBrt()) {
            data.setHours(data.getHours() - 3);
        }

        element[nomeCampo] = data;
    }

    verificaOpecoesPayload(element: any): void {
        let pay = [] as any;
        pay = JSON.parse(element.js_payload_tratado);

        this.camposDataPayload.forEach((fieldName: string) => {
            if (_.has(pay, fieldName)) {
                this.converterDataSeNecessario(pay, fieldName);
            }
        })
        element.js_payload_tratado = JSON.stringify(pay);
    }

    verificaOpcoes(element: any): void {
        this.camposData.forEach((fieldName: string) => {
            if (_.has(element, fieldName)) {
                this.converterDataSeNecessario(element, fieldName);
            }
        })
        if (_.has(element, 'js_payload_tratado')) {
            this.verificaOpecoesPayload(element);
        }
        if (_.has(element, 'ultima_mensagem')) {
            if (_.has(element.ultima_mensagem, 'js_payload_tratado')) {
                this.verificaOpecoesPayload(element.ultima_mensagem);
            }
        }
        if (_.has(element, 'js_telemetrias')) {
            if (element.js_telemetrias != null) {
                var telemetrias = JSON.parse(element.js_telemetrias)
                telemetrias.forEach((telemetria: any, index: any) => {
                    this.converterDataSeNecessario(telemetria, 'timestamp');
                });
                element.js_telemetrias = JSON.stringify(telemetrias)
            }
        }
    }

    private modifyBody(body: any) {
        var realBody;
        if (_.has(body, 'current_page')) {
            realBody = body
            body = body.data
        }
        if (Array.isArray(body)) {
            body.forEach((element: any, indice: number) => {
                this.verificaOpcoes(element);
            });
        } else if (typeof body === 'object' && body !== null) {
            for (const key in body) {
                if (Array.isArray(body[key])) {
                    body[key].forEach((element: any, indice: number) => {
                        this.verificaOpcoes(element);
                    });
                }
            }
            this.verificaOpcoes(body);
        }
        if (_.has(realBody, 'current_page')) {
            realBody.data = body;
            body = realBody
        }
        return body;
    }
}
