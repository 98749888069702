import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASE_URL_HIDROMETER } from '../app.constants';
import { HttpOptionsService } from './header-options.service';
import { HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MetasService {
  BASE_URL_HIDROMETER: any = BASE_URL_HIDROMETER;
  httpOptionsSevice: any;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  token = localStorage.getItem('token');

  public cadastraMeta(meta: any): Observable<any>{
    let url = `${this.BASE_URL_HIDROMETER}metas`
    return this.http.post(url, meta, this.httpOptionsService.getHttpOptions())
  }

  obterMetasPorCliente(clienteId: number): Observable<any> {
    const url = `${this.BASE_URL_HIDROMETER}metas/cliente/${clienteId}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  obterMeta(metaId: number): Observable<any> {
    const url = `${this.BASE_URL_HIDROMETER}metas/${metaId}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public deletar(metaId: number): Observable<any> {
    const url = `${this.BASE_URL_HIDROMETER}metas/${metaId}`;
    return this.http.delete(url, this.httpOptionsService.getHttpOptions());
  }

  getCsv(metaId: number): Observable<any> {
    const url = `${this.BASE_URL_HIDROMETER}metas/${metaId}/csv`;
    const options = this.httpOptionsService.getHttpOptions();
    options['responseType'] = 'blob' as 'json';

    return this.http.get<Blob>(url, options);
  }


}
