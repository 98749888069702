import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { RedirectService } from 'src/app/service/redirect.service';

@Component({
  selector: 'app-informacoes-motobomba',
  templateUrl: './informacoes-motobomba.component.html',
  styleUrls: ['./informacoes-motobomba.component.css'],
})
export class InformacoesMotobombaComponent implements OnInit {
  constructor(private appService: RedirectService) { }

  public ultimaMensagem: any = 'Sem dados';
  public pressaoEntrada: any = 'Sem dados';
  public pressaoSaida: any = 'Sem dados';
  public statusOperacao: any = 'Sem dados';
  public tipoMensagem: any = 'Sem dados';
  public nivelSuccao: any = 'Sem dados';
  public rpmMotor: any = 'Sem dados';
  public nivelDeCombustivel: any = 'Sem dados';
  public LocalStorageFrota: any;
  public LocalStorageId: any;
  public LocalStorageOperationId: any;
  public LocalStorageDateStart: any;
  public LocalStorageDateEnd: any;
  public alertas: any = '';
  load = true;
  public frota = localStorage.getItem('frota');
  public descricoesAlertasSemIcone = 'Alerta MWM';
  public finalIndex: number = 13;

  public alertasConfig: any[] = [
    { descricao: 'Restrição filtro de ar', icone: true },
    { descricao: 'Pressão baixo do óleo', icone: true },
    { descricao: 'Pressão de saída baixa', icone: true },
    { descricao: 'Nível baixo arrefecimento', icone: true },
    { descricao: 'Temperatura alta arrefecimento', icone: true },
    { descricao: 'Tensão baixa bateria', icone: true },
    { descricao: 'Nível baixo combustível', icone: true },
    { descricao: 'Nível baixo sucção', icone: true },
    { descricao: 'Alerta pressao entrada', icone: true },
    // { descricao: 'Timeout controle pressao', icone: true },
    { descricao: 'Outros alertas', icone: true },
    // { descricao: 'Timeout controle velocidade pressão baixa', icone: false },
    { descricao: 'Overspeed físico', icone: false },
    { descricao: 'Alerta temperatura auxiliar', icone: false },
    { descricao: 'Alerta pressao do gás', icone: false },
    { descricao: 'Alerta temperatura do gás', icone: false },
    { descricao: 'Alerta Pré válvula borboleta', icone: false },
    { descricao: 'Alerta MAP', icone: false },
    { descricao: 'Alerta MAT', icone: false },
    { descricao: 'Alerta sonda lambda', icone: false },
    { descricao: 'Alerta atuador do turbo', icone: false },
    { descricao: 'Alerta injetor 1', icone: false },
    { descricao: 'Alerta injetor 2', icone: false },
    { descricao: 'Alerta injetor 3', icone: false },
    { descricao: 'Alerta bobina 1', icone: false },
    { descricao: 'Alerta bobina 2', icone: false },
    { descricao: 'Alerta bobina 3', icone: false },
    { descricao: 'Alerta bobina 4', icone: false },
    { descricao: 'Alerta bobina 5', icone: false },
    { descricao: 'Alerta bobina 6', icone: false },
    { descricao: 'Alerta sincronismo motor', icone: false },
    { descricao: 'Posição válvula borboleta 1', icone: false },
    { descricao: 'Posição válvula borboleta 2', icone: false },
    { descricao: 'Timeout conexão CAN', icone: false },
    { descricao: 'Umidade do ar', icone: false },
  ];

  getAlertasIcones(i: number): string {
    return '../../../assets/icones-alertas-mototomba/' + this.alertasConfig[i].descricao + (this.alertas[i] == 1 ? '_vermelho.svg' : '_cinza.svg');
  }

  getAlertasSemIcones(): string {
    var icon: string = '../../../assets/icones-alertas-mototomba/Alertas MWM_cinza.svg';
    this.alertasConfig.forEach((alert: any, index: number) => {
      if (alert.icone == false && this.alertas[index] == 1) {
        icon = '../../../assets/icones-alertas-mototomba/Alertas MWM_vermelho.svg'
      }
    });
    return icon;
  }

  ngOnInit(): void {
    this.localstorage();
    this.buscarUltimaMensagemMotoBomba();

    const alertasSemIcone = this.alertasConfig.filter(alert => alert.icone === false);
    const descricoesAlertasSemIcone = alertasSemIcone.map(alert => alert.descricao);
    this.descricoesAlertasSemIcone = descricoesAlertasSemIcone.join(" | ");
  }

  localstorage() {
    this.LocalStorageFrota = localStorage.getItem('frota');
    this.LocalStorageId = localStorage.getItem('cliente_filtro');
    this.LocalStorageOperationId = localStorage.getItem('operacaoId');
    this.LocalStorageDateStart = localStorage.getItem('DateStart');
    this.LocalStorageDateEnd = localStorage.getItem('DateEnd');
  }

  buscarUltimaMensagemMotoBomba() {
    this.appService.informacoesUltimaMensagem.subscribe((mensagens) => {
      if (mensagens != '') {
        var payload = JSON.parse(mensagens.js_payload_tratado);
        if (typeof payload != 'string') {
          this.tratarModeloPayloadCronos(payload);
        }
      } else {
        this.load = false;
        this.ultimaMensagem = '';
      }
    });
  }

  tratarModeloPayloadCronos(payload: any) {
    if (payload.hasOwnProperty('alert') || payload.hasOwnProperty('alerts')) {
      var alertas = JSON.parse(payload.alerts ? payload.alerts : payload.alert);
      this.alertas = alertas;
      this.finalIndex = alertas.length === 16 ? 13 : alertas.length;
    }
    if (payload.timestamp) {
      this.ultimaMensagem = payload.timestamp;
    } else if (payload.timeStamp) {
      this.ultimaMensagem = payload.timeStamp;
    }
    this.tipoMensagem = payload.descriptor;
    if (payload.hasOwnProperty('aductorPressure')) {
      this.pressaoEntrada = payload.aductorPressure + ' kgf/cm²';
    } else if (payload.hasOwnProperty('adductorPressure')) {
      this.pressaoEntrada = payload.adductorPressure + ' kgf/cm²';
    }
    if (payload.hasOwnProperty('pumpPressure')) {
      this.pressaoSaida = payload.pumpPressure + ' kgf/cm²';
    }
    if (payload.hasOwnProperty('suctionLevel')) {
      this.nivelSuccao = payload.suctionLevel + ' %';
    }
    if (payload.hasOwnProperty('velocityRPM')) {
      this.rpmMotor = payload.velocityRPM + ' RPM';
    } else if (payload.hasOwnProperty('velocidadeRPM')) {
      this.rpmMotor = payload.velocidadeRPM + ' RPM';
    }
    if (payload.hasOwnProperty('fuelLevel')) {
      this.nivelDeCombustivel = payload.fuelLevel + ' %';
    }
    if (payload.status) {
      this.statusOperacao = this.tratarStatusMotoBomba(payload.status);
    }
    this.load = false;
  }

  tratarStatusMotoBomba(status: any): any {
    if (status == 1) {
      return 'Desligada';
    } else if (status == 2) {
      return 'Marcha lenta';
    } else if (status == 3) {
      return 'Controle por velocidade (enchendo rede)';
    } else if (status == 4) {
      return 'Controle por pressão';
    }
  }

}
