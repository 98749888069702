<div class="px-3 vh-100">
    <div *ngIf="!carregado" class="loading">
        <div class="spinner"></div>
    </div>

    <main *ngIf="carregado" class="container mt-4" fxLayout="column" fxLayoutAlign="top center">
        <div class="d-flex">
            <h1>Editar operador</h1>
        </div>
        <div class="row">
            <form [formGroup]="operadorForm" fxFlexOffset="5%" fxLayout="column" fxLayoutAlign="space-evenly center"
                fxShow.gt-xs fxHide.xs>
                <div class="form-row">
                    <mat-form-field appearance="outline" fxFlex fxFlexOffset="1.5rem">
                        <mat-label>ID</mat-label>
                        <label>
                            <input matInput formControlName="identifier" autocomplete="off" />
                        </label>
                        <mat-error *ngIf="operadorForm.get('identifier')?.getError('required')">
                            ID é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex fxFlexOffset="1.5rem">
                        <mat-label>Nome</mat-label>
                        <label>
                            <input matInput formControlName="name" autocomplete="off" maxlength="255" required />
                        </label>
                        <mat-error *ngIf="operadorForm.get('name')?.getError('required')">
                            Nome é <strong>obrigatório</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-row_botoes" fxLayout="row" fxLayoutAlign="center" fxFlexOffset="3%">
                    <button mat-raised-button class="botao-criar" (click)="atualizarOperador()"
                        [disabled]="this.operadorForm.invalid">
                        Salvar
                    </button>
                    <button mat-raised-button class="botao-excluir" type="reset" fxFlexOffset="10px"
                        (click)="cancelar()">
                        Cancelar
                    </button>
                </div>
            </form>
        </div>
    </main>
</div>