import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KanbanComponent } from '../kanban/kanban.component';
import { AlertService } from '../service/alert.service';
import { EquipamentosService } from '../service/equipamentos.service';
import { MensagensService } from '../service/mensagens.service';

@Component({
    selector: 'app-dialog-enviar-mensagem-satelital',
    templateUrl: './dialog-enviar-mensagem-satelital.component.html',
    styleUrls: ['./dialog-enviar-mensagem-satelital.component.css']
})
export class DialogEnviarMensagemSatelitalComponent implements OnInit {

    public formFilter!: FormGroup;
    public tipo: any = 'basica';
    public clientes = JSON.parse(localStorage.getItem('cliente') || '{}');
    loading = false;
    erroComunicador = false;
    comunicadores = JSON.parse(localStorage.getItem('comunicadores') || '{}')
    statusMotobomba = 0
    comando: any = {}

    constructor(
        public dialogRef: MatDialogRef<KanbanComponent>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private equipamentoService: EquipamentosService,
        private mesagensService: MensagensService,
        private alertService: AlertService) { }

    ngOnInit(): void {
        this.formFilter = this.formBuilder.group({
            tipo: new FormControl(this.data, Validators.required),
            valor: new FormControl('', Validators.required),
        });
        this.formFilter.get('tipo')?.valueChanges.subscribe((tipo: any) => {
            this.tipo = tipo
        })
        this.BuscarInformacoesMotobomba();
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public enviar() {
        this.loading = true
        var pgd = this.definirPGD();
        this.enviarComandoParaCronos(pgd);
    }

    ArrayFilter(id: any, arrayDado: any) {
        var dado = arrayDado.filter(function (e: any) {
            return e.id == id
        })
        return dado;
    }
    
    enviarComandoParaCronos(pgd: string){
        this.mesagensService.MandarMensagem(pgd, this.comando).subscribe((comando: any) => {
            if (comando.vc_confirmacao_envio_cronos == "ENVIADA") {
                this.alertService.success('Comando enviado com sucesso')
            } else if (comando.vc_confirmacao_envio_cronos == "ERRO") {
                this.alertService.warning('Não foi possível enviar o comando')
            }
            this.loading = false
            this.dialogRef.close();
        }, (err: any) => {
            this.loading = false
            let mensagemErro = 'Erro ao enviar comando'
            if (err.error.mensagem) {
                mensagemErro = err.error.mensagem
            }
            this.alertService.error(mensagemErro)
            this.dialogRef.close();
        })
    }

    montaJsonDoComando(informacoesMotobomba: any) {
        if (informacoesMotobomba.comunicadores.length == 0) {
            this.erroComunicador = true;
        } else {
            var mobileOrigem = this.ArrayFilter(this.data.trator_frota[0]?.comunicadores[0]?.it_id_premocenter, this.comunicadores)
            var clienteFiltrado = this.ArrayFilter(localStorage.getItem('cliente_filtro'), this.clientes)
            var mobileDestino = this.ArrayFilter(informacoesMotobomba.comunicadores[0].it_id_premocenter, this.comunicadores)
            this.comando = {
                "cliente": informacoesMotobomba.fk_cliente,
                "modem_destino": mobileDestino[0].vc_mobileId,
                "frota": this.data.trator_frota[0].vc_frota,
                "frota_moto_bomba": informacoesMotobomba.vc_frota,
                "timestamp": new Date().toISOString().replace(/T/, " ").replace(/\..+/, ""),
                "latitude": clienteFiltrado[0].latitude,
                "longitude": clienteFiltrado[0].longitude,
                "it_id_operacao": this.data.sr_id,
                'modem_origem': mobileOrigem[0].vc_mobileId,
                "setpoint_velocidade": '',
                "setpoint_pressao": '',
                "cod_operacao": ''
            };
        }
    }

    definirPGD(){
        var tipo = this.formFilter.get('tipo')?.value;
        var valor = this.formFilter.get('valor')?.value;
        let pgd = '';
        if (tipo == 'controleRpm') {
            this.comando.setpoint_velocidade = valor;
            pgd = (this.statusMotobomba == 3) ? '020201' : '020204'
        } else if (tipo == 'controlePressao') {
            this.comando.setpoint_pressao = valor;
            pgd = (this.statusMotobomba == 4) ? '020202' : '020205'
        } else {
            this.comando.cod_operacao = valor;
            pgd = '020203'
        }
        return pgd;
    }

    defineValidator() {
        const meuCampo = this.formFilter.get('valor') as FormControl;
        meuCampo.clearValidators();
        if (this.tipo == 'controleRpm') {
            meuCampo.setValidators([Validators.required, Validators.min(600), Validators.max(2200)]);
        } else if (this.tipo == 'controlePressao') {
            meuCampo.setValidators([Validators.required, Validators.min(1), Validators.max(20)]);
        } else {
            meuCampo.setValidators(Validators.required);
        }
        meuCampo.updateValueAndValidity();
        meuCampo.reset()
    }

    public BuscarInformacoesMotobomba() {
        this.equipamentoService.AcharPorId(this.data.motobombaId).subscribe((motobomba: any) => {
            this.statusMotobomba = motobomba[0]?.it_ultimo_status;

            this.montaJsonDoComando(motobomba[0]);
        });
    }
}
