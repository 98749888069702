<div class="h-100" id="container-grafico">
    <div class="d-flex justify-content-center" >
        <h4 class="m-0">Alerta por motobomba</h4>
        <div class="dropdown">
            <clr-icon shape="info-standard" class="info-icone cor-icone"></clr-icon>
            <div class="dropdown-content">
                <p>Quantidade de alertas separadas por frota.</p>
            </div>
        </div>
    </div>
    <ng-container class="mensagem">
        <p *ngIf="validaResp" style="text-align: center;">Não há mensagens nesse período</p>
    </ng-container>
    <div *ngIf="!loading && !validaResp && !erro" class="d-flex justify-content-center h-75" id="grafico">
        <canvas class="h-100" baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [chartType]="barChartType">
        </canvas>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <app-erro *ngIf="erro"></app-erro>
</div>