import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Subscription } from 'rxjs';
import { GraficosService } from 'src/app/service/graficos.service';

@Component({
  selector: 'app-abastecimento-motobomba',
  templateUrl: './abastecimento-motobomba.component.html',
  styleUrls: ['./abastecimento-motobomba.component.css']
})
export class AbastecimentoMotobombaComponent implements OnInit {


  idCliente = localStorage.getItem('cliente_filtro');
  dataInicio = localStorage.getItem('DateStartGraficos');
  dataFinal = localStorage.getItem('DateEndGraficos');
  frotas: any;
  combustivel: any;
  barChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    "scales": {
      "yAxes": [{
        "ticks": {
          "beginAtZero": true
        }
      }],
    }
  };
  barChartLabels: any;
  barChartType: ChartType = 'bar';
  barChartData: ChartDataSets[] = [];
  validaResp = false;
  loading = true;
  erro = false;

  constructor(
    private graficosService: GraficosService
  ) { }

  ngOnInit(): void {
    this.abastecimento();
  }

  abastecimento() {
    this.graficosService.graficoAbastecimentoMotobomba(this.dataInicio, this.dataFinal, this.idCliente).subscribe((resposta: any) => {
      this.loading = false
      if(resposta.frotas.length == 0){
        this.validaResp = true
      }else{
        this.frotas = resposta.frotas;
        this.combustivel = resposta.combustivel
        this.barChartLabels = this.frotas;
        this.barChartData = [{ data: this.combustivel, label: 'Litros' }];
      }
    }, err =>{
      this.loading = false;
      this.erro = true;
    })
  }
}
