import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-dialog-informacoes-icones',
  templateUrl: './dialog-informacoes-icones.component.html',
  styleUrls: ['./dialog-informacoes-icones.component.css']
})
export class DialogInformacoesIconesComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
