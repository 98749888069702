<div class="h-100" #containerTemposFrota id="container-grafico">

    <div class="d-flex justify-content-center">
        <h4 class="m-0">Tempos trabalhados por frota</h4>
        <div class="dropdown">
            <clr-icon shape="info-standard" class="info-icone cor-icone"></clr-icon>
            <div class="dropdown-content">
                <p>Para visualizar os tempos de parada justificados de cada frota, clique na respectiva barra.</p>
                <p>Clicar na legenda pode ocultar a parcela do gráfico relativa a ela.</p>
            </div>
        </div>
    </div>

    <app-loader *ngIf="loading || loadingFrotas"></app-loader>
    <ng-container class="mensagem" *ngIf="validaResp">
        <p style="text-align: center;">Não há tempos trabalhados por frota nesse período</p>
    </ng-container>
    <div *ngIf="!loading && !validaResp && !erro && !loadingFrotas && frotas" class="d-flex justify-content-center h-75"
        id="grafico">
        <canvas class="h-100" baseChart [datasets]="barChartData" [chartType]="barChartType" [options]="barChartOptions"
            [plugins]="barChartPlugins" [labels]="barChartLabels" [legend]="barChartLegend"
            (chartClick)="chartClicked($event)">
        </canvas>
    </div>
    <app-erro *ngIf="erro"></app-erro>
</div>