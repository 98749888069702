<div class="button-container" style="float:right" (click)="unsubscribe()">
    <button mat-icon-button fxLayoutAlign="center center" mat-dialog-close>
        <img src="assets/icones-gerais/fechar-cor-preto.svg" alt="Fechar" class="icone-fechar">
    </button>
</div>
<div class="row d-flex justify-content-center">
    <h3 class="titulo" style="font-family:'Poppins', sans-serif" fxLayoutAlign="center center">Tempos do grupo: {{ data.grupo }}</h3>
</div>       
        <div class="row justifiy-content-center" fxLayoutAlign="center center" style="margin-top: 3em;">
            <canvas baseChart [datasets]="pieChartDataset" [labels]="pieChartLabels" [chartType]="pieChartType"
            [options]="options" [plugins]="pieChartPlugins" [legend]="pieChartLegend" (chartClick)="chartClicked($event)"
            style="max-width: 45vw; max-height: 100%;"></canvas>
            <div class="mensagem" fxLayoutAlign="center center">
                <p *ngIf="validaResp" style="text-align: center;">Não há mensagens nesse período</p>
            </div>
            <div fxLayoutAlign="center center" *ngIf="load" class="spinner"></div>
        </div>
