<div class="h-100">
    <div class="d-flex justify-content-center">
        <h4 class="m-0">Consumo por motobomba</h4>
        <div class="dropdown">
            <clr-icon shape="info-standard" class="info-icone cor-icone"></clr-icon>
            <div class="dropdown-content">
                <p>Consumo de combustível em litros por hora das Motobombas. </p>
            </div>
        </div>
    </div>
    <div class="mensagem">
        <p *ngIf="validaResp" style="text-align: center;">Não há mensagens nesse período</p>
    </div>
    <div *ngIf="!loading && !validaResp && !erro" class="tabela-container h-75" >
        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
            [legend]="barChartLegend" [chartType]="barChartType">
        </canvas>
    </div>
    <app-loader *ngIf="loading"></app-loader>
    <app-erro *ngIf="erro"></app-erro>
</div>