import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BASE_URL_PREMOCENTER_FRONT } from 'src/app/app.constants';
import { VerificarPermissoes } from 'src/app/model/verificar-premissao.model';
import { AlertService } from 'src/app/service/alert.service';
import { LayoutsService } from 'src/app/service/layouts.service';
import { ReloadGraficosService } from 'src/app/service/reload-graficos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listar-layouts',
  templateUrl: './listar-layouts.component.html',
  styleUrls: ['./listar-layouts.component.css']
})
export class ListarLayoutsComponent implements OnInit {

  load = true;
  value = 50;
  usuario = localStorage.getItem('usuario');
  premocenter = BASE_URL_PREMOCENTER_FRONT
  token!: any;
  layouts = [] as any;
  img!: any;
  clientes = [] as any
  grupos = [] as any
  clientesArrayId = [] as any
  gruposArrayId = [] as any
  layoutPersonalizado: any;
  layoutSessionStorage: any;
  public displayedColumns: string[] = [
    "nome",
    "modelo",
    "componentes",
    "criado",
    "atualizado",
    "controls",
  ];

  validaResp = false;
  loading = true;

  constructor(
    private dashboardService: LayoutsService,
    private alertService: AlertService,
    private router: Router,
    private serviceReload: ReloadGraficosService
  ) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('token');
    this.arraysClienteGrupos()
    this.recuperarLayoutSessionStorage();
  }

  recuperarLayoutSessionStorage(): void {
    let layoutSessionStorage = sessionStorage.getItem('layouts') || ''
    this.layoutPersonalizado = JSON.parse(layoutSessionStorage);
  }

  arraysClienteGrupos() {
    this.clientes = localStorage.getItem('cliente')
    this.clientes = JSON.parse(this.clientes)
    this.grupos = localStorage.getItem('grupos')
    this.grupos = JSON.parse(this.grupos)
    this.clientes.forEach((element: any) => {
      this.clientesArrayId.push(element.id)
    });
    this.grupos.forEach((element: any) => {
      this.gruposArrayId.push(element.id)
    });
    this.listar();
  }

  listar() {
    this.dashboardService.Listar(this.token, this.clientesArrayId, this.gruposArrayId).subscribe((resp: any) => {
      this.layouts = resp

      if (resp == null) {
        localStorage.setItem('layout_padrao', 'null')
        sessionStorage.setItem('layout_padrao', 'null')
        this.validaResp = true;
        this.loading = false
      } else {
        resp.forEach((layout: any) => {
          var itens = [] as any;
          layout.layouts_personalizados_itens.forEach((item: any) => {
            itens.push(item.vc_componente)
          });
          layout.layouts_personalizados_itens = itens;
        });
        this.loading = false
      }
    }, error => {
      if (error.status === 401) {
        window.location.href = `${this.premocenter}`
      }
    })
  }

  atualizarLayot() {
    var token = localStorage.getItem('token')
    var link = `index/${token}`
    this.router.navigate([link])
  }

  desativar(id: any) {
    Swal.fire({
      title: 'Desativar Layout?',
      showCancelButton: true,
      confirmButtonColor: '#00AA5B',
      cancelButtonColor: '#df4747',
      confirmButtonText: 'Confirmar!'
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.load = true
        this.dashboardService.Desativar(id, this.token).subscribe((resp: any) => {
          this.listar();
          this.removerLayoutSessionStorage(id);
          this.alertService.success('Layout excluído com sucesso');
          this.serviceReload.recarregarListaGraficos();
        }, error => {
          this.load = false
          this.alertService.error('Erro ao desativar o layout!')
          if (error.status === 401) {
            window.location.href = `${this.premocenter}`
          }
        })
      }
    })
  }

  public verificarPermissao(permissoesFuncionalidades: string[]): boolean {
    const permissaoUsuario = localStorage.getItem('permissoes');
    return VerificarPermissoes.temPermissao(permissoesFuncionalidades, permissaoUsuario)
  }

  public removerLayoutSessionStorage(id: number): void {
    this.layoutSessionStorage = this.layoutPersonalizado.filter((element: any) => element.sr_id !== id);
    sessionStorage.setItem('layouts', JSON.stringify(this.layoutSessionStorage) || '');
  }
}
