<main class="fundo-kanban" style="height: 100%"
    [ngClass]="{'kanban-background': kanban == true,'overflow-y-hidden': kanban == true,'kanban-background1': flag == 1,'kanban-background2': flag == 2,'kanban-background3': flag == 3}">
    <div class="loading-pagina w-100 h-100 d-flex flex-row align-items-center justify-content-center"
        *ngIf="loadingMudarOS">
        <div class="spinner"></div>
    </div>
    <div id="botoesKanban" fxLayout="row" fxLayoutAlign="end">
        <mat-menu #appMenu="matMenu">
            <div style="margin-left: 1rem;">
                <mat-slide-toggle (change)="onChange($event.checked)">
                </mat-slide-toggle>
                Exibir somente operações com alertas
            </div>
            <button mat-menu-item (click)="flag = 1">
                Fundo 1
            </button>
            <button mat-menu-item (click)="flag = 2">
                Fundo 2
            </button>
            <button mat-menu-item (click)="flag = 3">
                Fundo 3
            </button>
        </mat-menu>
        <button [matMenuTriggerFor]="appMenu" class="botao-editar  botao-espaco">
            Menu
        </button>
    </div>
    <div class="board-wrapper ml-4" *ngIf="zero == true" cdkDropListGroup id="board-wrapper">
        <div class="board-columns">
            <div class="board-column" style="border-top: 6px solid #838c91; transition: 0.8s ease;">
                <div class="column-title" style="height: auto;">Sem OS</div>
                <div class="overflow">
                    <div class="task-container" cdkDropList [cdkDropListData]="equipamentos"
                        (cdkDropListDropped)="drop($event)">
                        <div class="task" cdkDrag [cdkDragData]="equipamento" [attr.data-type]="equipamento.vc_tipo"
                            *ngFor="let equipamento of equipamentos"
                            [cdkDragDisabled]="equipamento.vc_tipo == 'TRACTOR'">
                            <div *ngIf="equipamento.sr_id != 0">
                                <span *ngIf="equipamento.vc_tipo === 'TRACTOR'">
                                    TRATOR
                                </span>
                                <span *ngIf="equipamento.vc_tipo === 'REEL'">
                                    CARRETEL
                                </span>
                                <span *ngIf="equipamento.vc_tipo === 'MOTOR_PUMP'">
                                    MOTOBOMBA
                                </span>
                            </div>
                            <div *ngIf="equipamento.sr_id != 0">{{ equipamento.vc_frota }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="board-column" #coluna *ngFor="let item of datakanban"
                [ngClass]="{running: item.vc_estado === 'RUNNING',paused: item.vc_estado === 'PAUSED',new: item.vc_estado === 'NEW',con_can:item.vc_estado === 'COMPLETED' || item.vc_estado === 'CANCELED'}">
                <div class="column-title div-upload" fxLayout="row" fxLayoutAlign="space-around center">
                    <span> OS {{ item.vc_service_id }} </span>
                    <mat-menu #appMenu="matMenu">
                        <button mat-menu-item (click)="changeState(item.sr_id, item, 'RUNNING')">
                            Operar
                        </button>
                        <button mat-menu-item (click)="changeState(item.sr_id, item, 'PAUSED')">
                            Parar
                        </button>
                        <button mat-menu-item (click)="changeState(item.sr_id, item, 'COMPLETED')">
                            Finalizar
                        </button>
                        <button mat-menu-item (click)="changeState(item.sr_id, item, 'CANCELED')">
                            Cancelar
                        </button>
                    </mat-menu>
                    <button mat-button [disabled]="loading && idOperacao == item.sr_id" [matMenuTriggerFor]="appMenu"
                        [ngClass]="{'bg-disabled': item.vc_estado === 'COMPLETED' || item.vc_estado === 'CANCELED','bg-yellow': item.vc_estado === 'PAUSED','bg-green': item.vc_estado === 'RUNNING', 'bg-blue': item.vc_estado === 'NEW'}"
                        style="font-size: 13px; border-radius: 10px; color: #ffffff; font-weight: normal;"
                        [disabled]="item.vc_estado === 'COMPLETED' ||  item.vc_estado === 'CANCELED' ">
                        <div *ngIf="loading && idOperacao == item.sr_id" class="spinner spinner-sm"></div>
                        <span *ngIf="item.vc_estado === 'COMPLETED'">FINALIZADO</span>
                        <span *ngIf="item.vc_estado === 'CANCELED'"> CANCELADO</span>
                        <span *ngIf="item.vc_estado === 'PAUSED'"> PARADO </span>
                        <span *ngIf="item.vc_estado === 'RUNNING'"> OPERANDO </span>
                        <span *ngIf="item.vc_estado === 'NEW'"> NOVO </span>
                        <img src="assets/icones-gerais/expandir_seta_para_baixo.svg" alt="Expandir opções">
                    </button>
                </div>

                <div class="overflow">
                    <div class="task-container" cdkDropList [cdkDropListData]="item" cdk [attr.operacao-id]="item.sr_id"
                        (cdkDropListDropped)="drop($event)" cdkDropListDisabled
                        [cdkDropListEnterPredicate]="naoPodeEntrarNaLista">
                        <div cdkDrag cdkDragDisabled class="task" *ngFor="let trac of item.trator_frota"
                            [attr.data-id]="item.fk_equipamento_trator" data-type="TRACTOR">
                            TRATOR - {{trac.vc_frota}}
                        </div>
                    </div>
                    <div class="task-container min-height" cdkDropList [cdkDropListData]="item.equipamentos"
                        (cdkDropListDropped)="drop($event)"
                        [cdkDropListEnterPredicate]="(item.vc_estado == 'CANCELED' || item.vc_estado == 'COMPLETED') ? naoPodeEntrarNaLista : podeEntrarNaLista"
                        [attr.operacao-id]="item.sr_id" [attr.vc-estado]="item.vc_estado">
                        <div class="task"
                            [class.cdk-clicavel]="(item.vc_estado == 'RUNNING' && equip.vc_tipo =='MOTOR_PUMP')"
                            *ngFor="let equip of item.equipamentos" cdkDrag [cdkDragData]="equip"
                            [cdkDragDisabled]="item.vc_estado == 'COMPLETED' || item.vc_estado == 'CANCELED' || equip.vc_tipo == 'TRACTOR'"
                            [attr.data-id]="equip.sr_id" [attr.data-type]="equip.vc_tipo"
                            (click)="(item.vc_estado == 'RUNNING' && equip.vc_tipo =='MOTOR_PUMP' && verificarPermissao(['comando_remoto_motobomba_hidrometer']) ) ? Mensagem(item, equip.sr_id) : ''">
                            <div fxLayout="column">
                                <div fxLayout="column" *ngIf="item.vc_estado == 'PAUSED' ||item.vc_estado == 'RUNNING'">
                                    <div fxLayout="row" fxLayoutAlign="space-between">
                                        <div class="ticket-parado" *ngIf="equip.estado == 'Sem informação'">
                                            {{equip.estado}}
                                        </div>
                                        <div class="ticket-trabalhando" *ngIf="equip.estado == 'Trabalhando'">
                                            {{equip.estado}}
                                        </div>
                                        <button class="botao-detalhe" (click)="abrirModalInformacoesEquipamento(equip)">
                                            <span>{{!equip.cliqueDetalhes ? 'Detalhes' : 'Carregando'}}</span>
                                        </button>
                                        <div *ngIf="equip.iconeAlerta!=undefined">
                                            <clr-icon shape="warning-standard" class="is-solid blink_me"
                                                matTooltip="{{equip.mensagemAlerta}}" size="25">
                                            </clr-icon>
                                        </div>
                                    </div>
                                </div>
                                <span *ngIf="equip.vc_tipo == 'TRACTOR'">
                                    TRATOR
                                </span>
                                <div *ngIf="equip.vc_tipo == 'REEL'">
                                    CARRETEL
                                </div>
                                <div *ngIf="equip.vc_tipo == 'MOTOR_PUMP'">
                                    MOTOBOMBA
                                </div>
                                {{equip.vc_frota}}
                                <div *ngIf="item.vc_estado == 'PAUSED' ||item.vc_estado == 'RUNNING'">
                                    {{equip.ultimaMensagem}}
                                </div>
                                <div class="cliqueParaEnviarComando" *ngIf="validarExibicaoDoComandoRemoto(equip.vc_tipo, item.vc_estado)">
                                    Clique para enviar um comando
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>