import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';
import { HttpOptionsService } from './header-options.service';

@Injectable({
  providedIn: 'root'
})
export class TurnosService {

  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  public cadastrar(turno: any) {
    const url = `${this.BASE_URL_HIDROMETER}turnos`;
    return this.http.post(url, turno, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorCliente(cliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}turnos/cliente/${cliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorId(turnoId: any) {
    const url = `${this.BASE_URL_HIDROMETER}turnos/${turnoId}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public Atualizar(turno: any, turnoId: any) {
    const url = `${this.BASE_URL_HIDROMETER}turnos/${turnoId}`;
    return this.http.put(url, turno, this.httpOptionsService.getHttpOptions());
  }

  public Desativar(turnoId: any) {
    const url = `${this.BASE_URL_HIDROMETER}turnos/${turnoId}`;
    return this.http.delete(url, this.httpOptionsService.getHttpOptions());
  }

  public Inativar(turnoId: any) {
    const url = `${this.BASE_URL_HIDROMETER}turnos/inativar/${turnoId}`;
    return this.http.put(url, "", this.httpOptionsService.getHttpOptions());
  }

  public listarMensagensPorTurno(cliente: any, dataInicial: any, dataFinal: any) {
    const url = `${this.BASE_URL_HIDROMETER}tempo-efetivo-turno/cliente/${cliente}/data/${dataInicial}/${dataFinal}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }


  public listarMensagensPorTurnoCarretel(cliente: any, dataInicial: any, dataFinal: any) {
    const url = `${this.BASE_URL_HIDROMETER}tempo-efetivo-turno/carretel/cliente/${cliente}/data/${dataInicial}/${dataFinal}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }
}
