import { Component, OnInit } from '@angular/core';
import { VersaoService } from '../service/versao.service';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.css']
})
export class ChangeLogComponent implements OnInit {

  public versoes: { [key: string]: any }[] = [];
  public loading = true;

  constructor(
    private versaoService: VersaoService
  ) { }

  ngOnInit(): void {
    this.listarUltimasCincoMudancas();
  }

  listarUltimasCincoMudancas(): void {
    this.versaoService.listarUltimasCincoMudancas().subscribe((versoes: any) => {
      this.versoes = versoes;
      this.loading = false;
    })
  }

}
