import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissaoGuardService {
  arrayPermissoes: string[] = JSON.parse(localStorage.getItem('permissoes')!);
  
  constructor() { }

  isAuthenticated(permissoes: string[]): boolean {
    if(!permissoes || permissoes.length === 0) {
      return true;
    }
    return permissoes.every(permissao => this.arrayPermissoes.includes(permissao));
  }
}
