<main style="width: 96%; margin: 0 2rem;">

    <div class="cabecalho">
        <h1 class="mb-0">Turnos</h1>
        <span class="info-listas">lista de todos os turnos</span>
        <div class="linha">
            <label> Turnos da usina:
                <select class="filtro-listas" [(ngModel)]='LocalStorageId' (change)="mudarCliente()">
                    <option *ngFor="let client of clients" [value]="client.id">
                        {{ client.nome }}
                    </option>
                </select>
            </label>
            <button class="botao-criar" routerLink="../criar"
                *ngIf="verificarPermissao(['cadastrar_turnos_hidrometer'])">
                <clr-icon shape="clock" size="20" class="margem-clr-icon is-solid"></clr-icon>
                Novo turno
            </button>
        </div>
    </div>

    <div fxLayout="column" style="width: auto; height: 60vh;" fxLayoutAlign="center center" [hidden]="!loading">
        <div class="spinner"></div>
    </div>

    <div fxLayout="column" style="width: auto; height: 60vh;" fxLayoutAlign="center center"
        [hidden]="dataSource.data.length != 0 || loading">
        <p style="text-align: center;">Não há turnos cadastrados dessa usina</p>
    </div>

    <div [hidden]="loading">
        <mat-form-field class="w-100">
            <mat-label>Filtrar</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Turno da tarde" #input>
        </mat-form-field>

        <table mat-table [dataSource]="dataSource" matSort class="w-100">
            <ng-container *ngFor="let coluna of colunas" matColumnDef="{{coluna[0]}}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{coluna[1]}} </th>
                <td mat-cell *matCellDef="let row"
                    [ngClass]="{'cor-verde': row[coluna[0]] === 'Ativo', 'cor-vermelha': row[coluna[0]] === 'Inativo'}">
                    {{ exibirValorFormatado(coluna[0], row[coluna[0]]) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="controles">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <div id="listaBotoes" class="td-botoes d-flex justify-content-center">
                        <button class="botao-editar d-flex" routerLink="../editar/{{ element.sr_id }}"
                            *ngIf="verificarPermissao(['editar_turnos_hidrometer'])">
                            <clr-icon shape="pencil"></clr-icon>Editar
                        </button>
                        <button class="botao-excluir d-flex" (click)="SoftDelete(element.sr_id)"
                            *ngIf="verificarPermissao(['deletar_turnos_hidrometer'])">
                            <clr-icon shape="trash"></clr-icon>Excluir
                        </button>
                        <button mat-raised-button class="botao-aviso d-flex" [disabled]="element.bl_status != 'Ativo'"
                            (click)="Desativar(element.sr_id)"
                            *ngIf="verificarPermissao(['deletar_turnos_hidrometer'])">
                            <clr-icon shape="eye-hide" class="is-solid"></clr-icon>Desativar
                        </button>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

</main>