import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from 'src/app/service/alert.service';
import { CaracteristicasService } from 'src/app/service/caracteristicas.service';
import { EquipamentosService } from 'src/app/service/equipamentos.service';
import { OperacoesService } from 'src/app/service/operacoes.service';

@Component({
  selector: 'app-criar-operacoes',
  templateUrl: './criar-operacoes.component.html',
  styleUrls: ['./criar-operacoes.component.css']
})
export class CriarOperacoesComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  public operationId!: number;
  public operation?: object;
  public operationForm: FormGroup = new FormGroup({
    operationForm: new FormControl(),
  });
  public client: any;
  public displayedColumns: string[] = [
    "id",
    "caracteristica",
    "valor"
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public caracteristicas: MatTableDataSource<any> = new MatTableDataSource();
  public caracteristica: any;
  clients = JSON.parse(localStorage.getItem('cliente') || "{}")
  varificaEditar = false;
  public tractors: {disponiveis: any[], operando: any[], semComunicacao: any[]} = {
    disponiveis: [],
    operando: [],
    semComunicacao: []
  };
  public motorPumps:{disponiveis: any[], operando: any[], semComunicacao: any[]} = {
    disponiveis: [],
    operando: [],
    semComunicacao: []
  };
  public reels: {disponiveis: any[], operando: any[], semComunicacao: any[]} = {
    disponiveis: [],
    operando: [],
    semComunicacao: []
  }
  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private equipmentService: EquipamentosService,
    private operationService: OperacoesService,
    private alertService: AlertService,
    private caracteristicaService: CaracteristicasService
  ) { }

  ngOnInit(): void {
    this.montarForm();
    this.Onchange();
  }

  montarForm() {
    this.operationForm = this.formBuilder.group({
      clientId: [null, Validators.required],
      serviceId: [null, Validators.required],
      state: [null, Validators.required],
      tractorId: [null, Validators.required],
      motorPumpId: [null],
      reels: [null],
      ar_caracteristicas: this.formBuilder.array([])
    });
  }

  Onchange() {
    this.operationForm.get("clientId")?.valueChanges.subscribe((clientId) => {
      this.equipmentService.equipamentoSemOperacao(clientId).subscribe((equipamentos: any) => {
        this.equipamentosDisponiveis(equipamentos["Disponiveis"] || []);
        this.equipamentosOperando(equipamentos["Equipamentos com OS operando"]);
        this.equipamentosSemComunicacao(equipamentos["Equipamentos sem meios de comunicação"]);
      });
      this.caracteristicaService.AcharPorClienteeTipo(clientId, "operacao").subscribe((carac: any) => {
        carac?.forEach((element: any, index: any) => {
          if (element.vc_tipo == "timestamp") {
            element['input'] = 'datetime-local'
          } else if (element.vc_tipo == 'string') {
            element['input'] = 'text'
          } else {
            element['input'] = 'number'
          }
        });
        var control = this.retornaControlArCaracteristicaSemDuplicidade(<FormArray>this.operationForm.get('ar_caracteristicas'), carac);
        this.caracteristicas.data = control.value
      })
    });
  }

  public equipamentosDisponiveis(Disponiveis: any[]): void  {
    Disponiveis.forEach((equip: any) => {
      if (equip.vc_tipo == "TRACTOR") {
        this.tractors.disponiveis.push(equip);
      } else if (equip.vc_tipo == "MOTOR_PUMP") {
        this.motorPumps.disponiveis.push(equip);
      } else if (equip.vc_tipo == "REEL") {
        this.reels.disponiveis.push(equip);
      }
    });
  }

  public equipamentosOperando(Operando: any[]): void {
    Operando.forEach((equip: any) => {
      if (equip.vc_tipo == "TRACTOR") {
        this.tractors.operando.push(equip);
      } else if (equip.vc_tipo == "MOTOR_PUMP") {
        this.motorPumps.operando.push(equip);
      } else if (equip.vc_tipo == "REEL") {
        this.reels.operando.push(equip);
      }
    });
  }

  public equipamentosSemComunicacao(SemComunicacao: any[]): void {
    SemComunicacao.forEach((equip: any) => {
      if (equip.vc_tipo == "TRACTOR") {
        this.tractors.semComunicacao.push(equip);
      }else if (equip.vc_tipo == "MOTOR_PUMP") {
        this.motorPumps.semComunicacao.push(equip);
      }else if (equip.vc_tipo == "REEL") {
        this.reels.semComunicacao.push(equip);
      }
    });
  }


  public makeOperation(): void {
    if (this.validarNumero(this.operationForm.get("serviceId")?.value)) {
      this.operation = this.montarCorpoDaRequest(this.operationForm);
      this.operationService.cadastrar(this.operation).subscribe(() => {
        this.alertService.success('Os cadastrada com sucesso')
        this.location.back();
      }, err => {
        this.alertService.exibirMensagemErro(err)
      });
    } else {
      this.alertService.warning('Por favor, informe apenas números no campo No. Serviço')
    }
  }

  public montarCorpoDaRequest(form: FormGroup): object {
    if (form.get("motorPumpId")?.value || form.get("reels")?.value) {
      const motobomba = form.get("motorPumpId")?.value == null ? [] : form.get("motorPumpId")?.value;
      const carretel = form.get("reels")?.value == null ? [] : form.get("reels")?.value;
      const equipamentos = [...motobomba, ...carretel]
      return {
        "vc_service_id": form.get("serviceId")?.value,
        "vc_estado": form.get("state")?.value,
        "fk_cliente": form.get("clientId")?.value,
        "fk_equipamento_trator": form.get("tractorId")?.value,
        "ar_caracteristicas": form.get("ar_caracteristicas")?.value,
        "fk_equipamentos": equipamentos
      }
    } else {
      return {
        "vc_service_id": form.get("serviceId")?.value,
        "vc_estado": form.get("state")?.value,
        "fk_cliente": form.get("clientId")?.value,
        "fk_equipamento_trator": form.get("tractorId")?.value,
        "ar_caracteristicas": form.get("ar_caracteristicas")?.value
      }
    }
  }

  public cancel(): void {
    this.location.back();
    this.operationForm.reset();
  }

  patchValues(id: any, carac: any, valor: any, sr_id: any, vc_tipo: any, input: any) {
    return this.formBuilder.group({
      idValor: [id],
      carac: [carac],
      valor: [valor],
      idCaracteristica: [sr_id],
      vc_tipo: [vc_tipo],
      input: [input]
    })
  }

  retornaControlArCaracteristicaSemDuplicidade(control: FormArray, caracteristicas: any) {
    control.clear()
    caracteristicas?.forEach((x: any) => {
      control.push(this.patchValues(x.idValor, x.vc_nome, x.valor, x.sr_id, x.vc_tipo, x.input))
    });
    return control
  }

  validarNumero(input: string): boolean {
    const numeroRegex = /^[0-9]+$/;
    return numeroRegex.test(input);
  }
}
