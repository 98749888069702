<mat-sidenav-container id="container-nav">
    <mat-sidenav id="nav" class="nav-fechada" mode="side" opened fixedInViewport="true">
        <mat-nav-list class="nav-list" id="nav-list">

            <div id="logoMenu" class="text-light p-2">
                <span (click)="irParaMapa()">
                    <img *ngIf="open == false" src="assets/logoHidrometer.png" id="logoHidrometerClose">
                    <img *ngIf="open == true" src="assets/Logo Hidrometer - branco.png" id="logoHidrometerOpen">
                </span>
                <span (click)="irParaChangelog()">
                    <div *ngIf="versao == ''" class="d-flex justify-content-center">
                        <div id="loader" class="pontos mt-3"></div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <span id="stringVersao" *ngIf="versao !== ''">v</span>
                        <span id="versaoHidrometer" class="text-light">{{versao}}</span>
                    </div>
                </span>
            </div>

            <div id="itensMenu" *ngFor="let item of conteudoSideNav" class="sublinhado">
                <a *ngIf="itemDeveAparecer(item)" id="opcaoClique" mat-list-item class="icon" [href]="item.link"
                    matTooltip="{{item.nome}}" matTooltipPosition="after" [matTooltipDisabled]="open"
                    (click)="closeModal()">
                    <img id="iconeMenu" src="{{item.icone}}">
                    <span *ngIf="open" class="mb-3">
                        <p id="nomeMenu" class="v-middle ml-2 text-light">{{item.nome}}</p>
                    </span>
                </a>
                <a *ngIf="!item?.link" id="opcaoModal" mat-list-item class="icon" [attr.data-bs-toggle]="item.toggle"
                    [attr.data-bs-target]="item.target" [attr.aria-controls]="item.controls" matTooltip="{{item.nome}}"
                    matTooltipPosition="after" [matTooltipDisabled]="open">
                    <img id="iconeMenu" src="{{item.icone}}">
                    <span *ngIf="open" class="mb-3">
                        <p id="nomeMenu" class="v-middle ml-2 text-light">{{item.nome}}</p>
                    </span>
                </a>
            </div>
            <div id="itensMenu" class="sublinhado">
                <a *ngIf="verificarPermissao(['acessar_tabela_informacoes_carreteis'])" id="opcaoClique" mat-list-item class="icon" href="/hidrometer/informacoes-carreteis"
                    matTooltip="Informações dos carretéis" matTooltipPosition="after" [matTooltipDisabled]="open"
                    (click)="closeModal()">
                    <img id="iconeMenu" src="assets/icones-sideBar/informacoesDoCarretel.svg">
                    <span *ngIf="open" class="mb-3">
                        <p id="nomeMenu" class="v-middle ml-2 text-light">Informações dos carretéis</p>
                    </span>
                </a>
            </div>
            <div id="itensMenu">
                <a id="itemPremocenter" mat-list-item class="icon" (click)="retornarPremocenter()"
                    matTooltip="Ir para PremoCenter" matTooltipPosition="after" [matTooltipDisabled]="open == true">
                    <img id="iconeMenu" src="assets/icones-sideBar/premocenter.svg">
                    <span *ngIf="open == true" class="mb-3">
                        <p id="nomeMenu" class="v-middle ml-2 text-light">Ir para PremoCenter</p>
                    </span> 
                </a>
            </div>

            <div class="botaoAbrirFecharMenu">
                <a *ngIf="!open" mat-list-item class="icon" (click)="acaoMenu(true)">
                    <span class="text-light">
                        <img id="botaoAbrir" src="assets/icones-sideBar/openMenu.svg" class="img-fluid">
                    </span>
                </a>

                <a *ngIf="open" mat-list-item class="icon" (click)="acaoMenu(false)">
                    <span class="text-light">
                        <img id="botaoFechar" src="assets/icones-sideBar/closeMenu.svg" class="img-fluid">
                    </span>
                </a>
            </div>

        </mat-nav-list>
    </mat-sidenav>
</mat-sidenav-container>

<div id="modalGraficoHorario" class="modalFechado posicaoModalHorario">
    <div class="collapse collapse-horizontal" id="collapseModalHorario" (show.bs.collapse)="shownModal($event)"
        (hidden.bs.collapse)="modalAberto=''">
        <div class="card" id="modalHorario">
            <div class="posicaoModalESetaFecharHorario">
                <a class="botaoSetaFecharCollapse" id="botaoSetaFecharHorario" data-bs-toggle="collapse"
                    data-bs-target="#collapseModalHorario" aria-controls="collapseModalHorario">
                    <img id="setaBotaoMenu" src="assets/icones-sideBar/abrirModal.svg"
                        class="img-fluid setaBotaoFechado">
                </a>
            </div>

            <app-modal-horario></app-modal-horario>
        </div>
    </div>
</div>

<a id="botaoSetaHorario" class="posicaoModalESeta" data-bs-toggle="collapse" data-bs-target="#collapseModalHorario"
    aria-controls="collapseModalHorario">
    <img id="setaBotaoMenu" src="assets/icones-sideBar/abrirModal.svg" class="img-fluid">
</a>

<div id="modalGraficoGrafico" class="modalFechado posicaoModalGrafico">
    <div class="collapse collapse-horizontal" id="collapseModalGrafico" (show.bs.collapse)="shownModal($event)"
        (hidden.bs.collapse)="modalAberto=''">
        <div class="card pt-4" id="modalGrafico">
            <div class="posicaoModalESetaFecharGrafico">
                <a class="botaoSetaFecharCollapse" id="botaoSetaFecharGrafico" data-bs-toggle="collapse"
                    data-bs-target="#collapseModalGrafico" aria-controls="collapseModalGrafico">
                    <img id="setaBotaoMenu" src="assets/icones-sideBar/abrirModal.svg"
                        class="img-fluid setaBotaoFechado">
                </a>
            </div>

            <app-modal-graficos></app-modal-graficos>
        </div>
    </div>
</div>

<a id="botaoSetaGrafico" class="posicaoModalESeta" data-bs-toggle="collapse" data-bs-target="#collapseModalGrafico"
    aria-controls="collapseModalGrafico">
    <img id="setaBotaoMenu" src="assets/icones-sideBar/abrirModal.svg" class="img-fluid">
</a>


<div id="modalGraficoKanban" class="modalFechado">
    <div class="collapse collapse-horizontal" id="collapseModalKanban" (show.bs.collapse)="shownModal($event)"
        (hidden.bs.collapse)="modalAberto=''">
        <div class="card" id="modalKanban" style="padding-right: 11px;">
            <div class="posicaoModalESetaFecharKanban">
                <a class="botaoSetaFecharCollapse" id="botaoSetaFecharKanban" data-bs-toggle="collapse"
                    data-bs-target="#collapseModalKanban" aria-controls="collapseModalKanban">
                    <img id="setaBotaoMenu" src="assets/icones-sideBar/abrirModal.svg"
                        class="img-fluid setaBotaoFechado">
                </a>
            </div>

            <app-kanban></app-kanban>
        </div>
    </div>
</div>


<a id="botaoSetaKanban" class="posicaoModalESeta" data-bs-toggle="collapse" data-bs-target="#collapseModalKanban"
    aria-controls="collapseModalKanban">
    <img id="setaBotaoMenu" src="assets/icones-sideBar/abrirModal.svg" class="img-fluid">
</a>

<div id="router" class="conteudo">
    <router-outlet></router-outlet>
</div>