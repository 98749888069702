<div class="container-fluid">
    <div class="modelo4 row w-100">
        <div class="col-6">
            <div class="modelo4-1-1 d-flex justify-content-center">
                <ngb-carousel #carousel  class="tamanho" [interval]='tempoArea1'>
                    <ng-template ngbSlide *ngFor="let componente of area1">
                            <div style="height: 100%;">
                                <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                                </app-abastecimento-motobomba>
                                <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                                </app-alertas-motobomba>
                                <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                                </app-consumo-motobomba>
                                <app-tempo-efetivo-frota-motobomba *ngIf="componente.c5"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-frota-motobomba>
                                <app-tempo-efetivo-os-motobomba *ngIf="componente.c6"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-os-motobomba>
                                <app-tempo-efetivo-turno-motobomba *ngIf="componente.c7"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-turno-motobomba>
                                <app-tempo-efetivo-os-carretel *ngIf="componente.c8" style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-os-carretel>
                                <app-tempo-efetivo-turno-carretel *ngIf="componente.c9"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-turno-carretel>
                                <app-produtividade-os *ngIf="componente.c10" style="height: 100%; width: 100%;">
                                </app-produtividade-os>
                                <app-produtividade-turno *ngIf="componente.c11" style="height: 100%; width: 100%;">
                                </app-produtividade-turno>
                                <app-tempos-trabalhados *ngIf="componente.c13" style="height: 100%"></app-tempos-trabalhados>
                                <app-tempos-trabalhados-frota *ngIf="componente.c14" style="height: 100%;"></app-tempos-trabalhados-frota>
                                <app-produtividade-carretel *ngIf="componente.c15" style="height: 100%;"></app-produtividade-carretel>
                            </div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>
        <div class="col-6">
            <div class="modelo4-1-1 d-flex justify-content-center">
                <ngb-carousel #carousel  class="tamanho" [interval]='tempoArea3'>
                    <ng-template ngbSlide *ngFor="let componente of area3">
                            <div style="height: 100%;">
                                <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                                </app-abastecimento-motobomba>
                                <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                                </app-alertas-motobomba>
                                <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                                </app-consumo-motobomba>
                                <app-tempo-efetivo-frota-motobomba *ngIf="componente.c5"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-frota-motobomba>
                                <app-tempo-efetivo-os-motobomba *ngIf="componente.c6"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-os-motobomba>
                                <app-tempo-efetivo-turno-motobomba *ngIf="componente.c7"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-turno-motobomba>
                                <app-tempo-efetivo-os-carretel *ngIf="componente.c8" style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-os-carretel>
                                <app-tempo-efetivo-turno-carretel *ngIf="componente.c9"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-turno-carretel>
                                <app-produtividade-os *ngIf="componente.c10" style="height: 100%; width: 100%;">
                                </app-produtividade-os>
                                <app-produtividade-turno *ngIf="componente.c11" style="height: 100%; width: 100%;">
                                </app-produtividade-turno>
                                <app-tempos-trabalhados *ngIf="componente.c13" style="height: 100%;"></app-tempos-trabalhados>
                                <app-tempos-trabalhados-frota *ngIf="componente.c14" style="height: 100%;"></app-tempos-trabalhados-frota>
                                <app-produtividade-carretel *ngIf="componente.c15" style="height: 100%;"></app-produtividade-carretel>
                            </div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>
    </div>
    <div class="modelo4 row w-100">
        <div class="col-6 pt-3">
            <div class="modelo4-1-1 d-flex justify-content-center">
                <ngb-carousel #carousel  class="tamanho" [interval]='tempoArea2'>
                    <ng-template ngbSlide *ngFor="let componente of area2">
                            <div style="height: 100%;">
                                <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                                </app-abastecimento-motobomba>
                                <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                                </app-alertas-motobomba>
                                <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                                </app-consumo-motobomba>
                                <app-tempo-efetivo-frota-motobomba *ngIf="componente.c5"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-frota-motobomba>
                                <app-tempo-efetivo-os-motobomba *ngIf="componente.c6"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-os-motobomba>
                                <app-tempo-efetivo-turno-motobomba *ngIf="componente.c7"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-turno-motobomba>
                                <app-tempo-efetivo-os-carretel *ngIf="componente.c8" style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-os-carretel>
                                <app-tempo-efetivo-turno-carretel *ngIf="componente.c9"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-turno-carretel>
                                <app-produtividade-os *ngIf="componente.c10" style="height: 100%; width: 100%;">
                                </app-produtividade-os>
                                <app-produtividade-turno *ngIf="componente.c11" style="height: 100%; width: 100%;">
                                </app-produtividade-turno>
                                <app-tempos-trabalhados *ngIf="componente.c13" style="height: 100%;"></app-tempos-trabalhados>
                                <app-tempos-trabalhados-frota *ngIf="componente.c14" style="height: 100%;"></app-tempos-trabalhados-frota>
                                <app-produtividade-carretel *ngIf="componente.c15" style="height: 100%;"></app-produtividade-carretel>
                            </div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>
        <div class="col-6 pt-3">
            <div class="modelo4-1-1 d-flex justify-content-center">
                <ngb-carousel #carousel  class="tamanho" [interval]='tempoArea4'>
                    <ng-template ngbSlide *ngFor="let componente of area4">
                            <div style="height: 100%;">
                                <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                                </app-abastecimento-motobomba>
                                <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                                </app-alertas-motobomba>
                                <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                                </app-consumo-motobomba>
                                <app-tempo-efetivo-frota-motobomba *ngIf="componente.c5"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-frota-motobomba>
                                <app-tempo-efetivo-os-motobomba *ngIf="componente.c6"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-os-motobomba>
                                <app-tempo-efetivo-turno-motobomba *ngIf="componente.c7"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-turno-motobomba>
                                <app-tempo-efetivo-os-carretel *ngIf="componente.c8" style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-os-carretel>
                                <app-tempo-efetivo-turno-carretel *ngIf="componente.c9"
                                    style="height: 100%; width: 100%;">
                                </app-tempo-efetivo-turno-carretel>
                                <app-produtividade-os *ngIf="componente.c10" style="height: 100%; width: 100%;">
                                </app-produtividade-os>
                                <app-produtividade-turno *ngIf="componente.c11" style="height: 100%; width: 100%;">
                                </app-produtividade-turno>
                                <app-tempos-trabalhados *ngIf="componente.c13" style="height: 100%;"></app-tempos-trabalhados>
                                <app-tempos-trabalhados-frota *ngIf="componente.c14" style="height: 100%;"></app-tempos-trabalhados-frota>
                                <app-produtividade-carretel *ngIf="componente.c15" style="height: 100%;"></app-produtividade-carretel>
                            </div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>
    </div>
</div>
