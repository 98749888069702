import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VersaoService } from '../service/versao.service';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements OnInit {
  conteudo: any = [];

  @ViewChild('naoMostrarNovamente') naoMostrarNovamente!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<TutorialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public versaoService: VersaoService
  ) { }

  public pagina = 0;
  public habilitado = false;

  ngOnInit(): void {
    this.buscarAtualizacoes();
  }

  buscarAtualizacoes() {
    this.conteudo = this.data['atualizacoes']
  }

  proximaPagina() {
    this.habilitado = false;
    this.pagina += 1;
    setTimeout(() => { this.habilitado = true; }, 5000)
  }

  paginaAnterior() {
    this.habilitado = false;
    this.pagina -= 1;
    setTimeout(() => { this.habilitado = true; }, 5000)
  }

  finalizarTutorial() {
    if (this.pagina + 1 == this.conteudo.length || this.naoMostrarNovamente.nativeElement.checked) {
      this.versaoService.marcarComoVisualizado().subscribe((resp: any) => { });
    }
    this.dialogRef.close();
  }
}
