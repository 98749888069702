import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/service/alert.service';
import { MetasService } from 'src/app/service/metas.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cadastrar-metas',
  templateUrl: './cadastrar-metas.component.html',
  styleUrls: ['./cadastrar-metas.component.css']
})
export class CadastrarMetasComponent implements OnInit {

  form: FormGroup;
  usinas: any[] = this.getUsinas();
  public metaConteudo: any;
  // location: any;
  operationForm: any;
  trabalhaSeteDias: boolean = true;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private metaService: MetasService,
    private alertService: AlertService,
    private location: Location,
    private router: Router
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(5)]],
      metaHectares: ['', [Validators.required, Validators.min(0.1)]],
      usina: ['', Validators.required],
      dataInicio: ['', Validators.required],
      dataFim: ['', Validators.required],
      diasTrabalhados: ['7', [Validators.required, Validators.max(7), Validators.min(1)]]
    }, { validators: this.validacaoDatasInvalidas });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.form.touched && this.form.valid) {
      this.isLoading = true;

      this.metaConteudo = {
        'name': this.form.get("name")?.value,
        'metaHectares': this.form.get("metaHectares")?.value,
        'usina': this.form.get("usina")?.value,
        'dataInicio': this.form.get("dataInicio")?.value,
        'dataFim': this.form.get("dataFim")?.value,
        'diasTrabalhados': this.form.get("diasTrabalhados")?.value,
      }

      this.metaService.cadastraMeta(this.metaConteudo).subscribe(() => {
        this.alertService.success('Meta cadastrada com sucesso!');
        this.router.navigate(['hidrometer', 'metas']);
      }, err => {
        this.alertService.error('Erro ao cadastrar meta, tente novamente em alguns instantes');
        this.isLoading = false;
      });
    }
  }

  validacaoDatasInvalidas: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const dataInicio = control.get('dataInicio')?.value;
    const dataFim = control.get('dataFim')?.value;
    return dataInicio && dataFim && dataInicio > dataFim ? { datasInvalidas: true } : null;
  }

  getUsinas() {
    let clientes = localStorage.getItem('cliente');
    return clientes ? JSON.parse(clientes) : [];
  }

  setTrabalhaSeteDias(trabalhaSeteDias: boolean): void {
    this.trabalhaSeteDias = trabalhaSeteDias;
    if (trabalhaSeteDias) {
      this.form.controls['diasTrabalhados'].setValue(7);
      this.form.controls['diasTrabalhados'].disable();
    } else {
      this.form.controls['diasTrabalhados'].setValue(null);
      this.form.controls['diasTrabalhados'].enable();
    }
  }

  public cancel(): void {
    this.router.navigate(['hidrometer', 'metas']);
  }

}
