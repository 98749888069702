import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerificarPermissoes } from 'src/app/model/verificar-premissao.model';

@Component({
  selector: 'app-dialog-layout',
  templateUrl: './dialog-layout.component.html',
  styleUrls: ['./dialog-layout.component.css']
})
export class DialogLayoutComponent implements OnInit {

  form!: FormGroup;
  componentes = [
    "GRÁFICO DE ABASTECIMENTO DA MOTOBOMBA",
    "GRÁFICO DE ALERTA DA MOTOBOMBA", 
    "GRÁFICO DE CONSUMO DA MOTOBOMBA",
    "GRÁFICO DE TEMPO EFETIVO POR FROTA DA MOTOBOMBA",
    "GRÁFICO DE TEMPO EFETIVO DA OS DA MOTOBOMBA",
    "GRÁFICO DE TEMPO EFETIVO DO TURNO DA MOTOBOMBA",
    "GRÁFICO DE TEMPO EFETIVO POR OS DE CARRETEL",
    "GRÁFICO DE TEMPO EFETIVO DO TURNO DE CARRETEL",
    "GRÁFICO DE PRODUTIVIDADE DO CARRETEL",
    "GRÁFICO DE PRODUTIVIDADE DA OS",
    "GRÁFICO DE PRODUTIVIDADE DO TURNO",
    "GRÁFICO DE TEMPOS TRABALHADOS",
    "GRÁFICO DE TEMPOS TRABALHADOS POR FROTA"
  ]
  validacao = false;
  flagPararValidacao!: any
  validacaoCheck = false;
  classe_modelo: any;
  id: any;
  
  constructor(
    public dialogRef: MatDialogRef<DialogLayoutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group({
      ar_componentes: Array([]),
      tempo: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.classe_modelo = localStorage.getItem('nome_div_modelo');
    this.id = this.classe_modelo.substring(this.classe_modelo.indexOf('.')+1, this.classe_modelo.length);  
    this.classe_modelo = this.classe_modelo.substring(0, this.classe_modelo.indexOf('.'));

    if (this.data) {
      this.form.get("ar_componentes")?.setValue(this.data)
      this.form.get("tempo")?.setValue(5)
    }
  }

  public verificarPermissao(permissoesFuncionalidades: string[]): boolean {
    const permissaoUsuario = localStorage.getItem('permissoes');
    return VerificarPermissoes.temPermissao(permissoesFuncionalidades, permissaoUsuario)
  }

  checar(componente: any) {
    this.flagPararValidacao = 0;
    if (this.data) {
      this.data.forEach((element: any) => {
        if (this.flagPararValidacao == 0) {
          if (componente == element) {
            this.validacaoCheck = true;
            this.flagPararValidacao++;
          } else {
            this.validacaoCheck = false;
          }
        }
      });
      if (this.validacaoCheck) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  salvar() {
    var ar_componentes = this.form.value.ar_componentes
    var tempo = this.form.value.tempo
    var json = {
      "ar_componentes": ar_componentes,
      "tempo": tempo
    }
    return json
  }

  onCheckboxChange(e: any) {
    if (e.target.checked) {
      this.form.value.ar_componentes.push(e.target.value)
    } else {
      this.form.value.ar_componentes.forEach(function (item: any, index: any, object: any) {
        if (e.target.value == item) {
          object.splice(index, 1)
        }
      })
    }
    if (this.form.value.ar_componentes.length >= 2) {
      this.validacao = true;
    }
    else {
      this.validacao = false;
      this.form.value.tempo = 0
    }
  }
}
