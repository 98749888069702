<div fxLayoutAlign="center center" id="container-loader" class="container-loader fixed-top" *ngIf="loadMapa!=false">
    <img src="assets/loading.gif" class="loader-mapa">
</div>

<div id="container-mapa" fxLayout="column" fxLayoutAlign="center center" style="height: 100%;">
    <div class="match-parent fixed-top map" id="map">
        <app-hectares-produzidos *ngIf="!loadMapa"></app-hectares-produzidos>
        <app-botoes-acao-mapa class="botoes-acao"></app-botoes-acao-mapa>
        <div id="ultimaMensagem">
            {{stringUltimaMensagem}}
        </div>
    </div>
</div>
<div class="Message Message--orange" *ngIf="aviso==true">
    <div>
        <div class="Message-icon">
            <img src="assets/icones-gerais/alerta_erro.svg" alt="Alerta" class="icone-alerta">
        </div>
        <div class="Message-body" (click)="abrirNotificacoes('mensagemAeB')">
            <p>Há telemetrias sem ponto A e B (mensagem de esticamento)</p>
            <p>Clique aqui para ver as mensagens</p>
        </div>
        <button class="Message-close" (click)="fecharAviso('mensagemAeB')">
            <img src="assets/icones-gerais/fechar.svg" alt="Fechar" class="icone-fechar">
        </button>
    </div>
    <div class="round-time-bar" data-style="smooth" style="--duration: 20;">
        <div></div>
    </div>
</div>
<div class="Message Message--orange" *ngIf="avisoMensagemSemOs==true" [ngClass]="{'message2': aviso == true}">
    <div>
        <div class="Message-icon">
            <img src="assets/icones-gerais/alerta_erro.svg" alt="Alerta" class="icone-alerta">
        </div>
        <div class="Message-body" (click)="abrirNotificacoes('mensagemSemOS')">
            <p>Há mensagens para equipamentos sem OS vinculados</p>
            <p>Clique aqui para ver as mensagens</p>
        </div>
        <button class="Message-close" (click)="fecharAviso('mensagemSemOS')">
            <img src="assets/icones-gerais/fechar.svg" alt="Fechar" class="icone-fechar">
        </button>
    </div>
    <div class="round-time-bar" data-style="smooth" style="--duration: 20;">
        <div></div>
    </div>
</div>