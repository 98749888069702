import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AgviewsConnectService } from 'src/app/service/agviews-connect.service';
import { AlertService } from 'src/app/service/alert.service';
import { CaracteristicasService } from 'src/app/service/caracteristicas.service';
import { ComunicadoresService } from 'src/app/service/comunicadores.service';
import { EquipamentosService } from 'src/app/service/equipamentos.service';

@Component({
  selector: 'app-criar-equipamentos',
  templateUrl: './criar-equipamentos.component.html',
  styleUrls: ['./criar-equipamentos.component.css']
})
export class CriarEquipamentosComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  public equipmentForm: FormGroup = this.formBuilder.group({
    client: [null, Validators.required],
    type: [null, Validators.required],
    fleet: [null, Validators.required],
    mobileId: [null, Validators.required],
    agviewConnectId: [null, Validators.required],
    ar_caracteristicas: this.formBuilder.array([])
  });
  public modems!: any[];
  public agviewsConnect!: any[];
  private equipment: any;
  public displayedColumns: string[] = [
    "id",
    "caracteristica",
    "valor"
  ];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public modemId?: number;
  public equipmentId?: number;
  public clientId?: number;
  public caracteristicas: MatTableDataSource<any> = new MatTableDataSource();
  public caracteristica: any;
  clients = JSON.parse(localStorage.getItem('cliente') || "{}")
  validaEditar = false;
  comunicadores = JSON.parse(localStorage.getItem('comunicadores') || "{}");
  public equipamentoCriado: any;

  constructor(
    private formBuilder: FormBuilder,
    private modemService: ComunicadoresService,
    private equipmentService: EquipamentosService,
    private location: Location,
    private alertService: AlertService,
    private caracteristicaService: CaracteristicasService,
    private agviewsService: AgviewsConnectService
  ) { }

  ngOnInit(): void {
    this.onChange();
  }

  onChange() {
    this.equipmentForm.get("client")?.valueChanges.subscribe((clientId: any) => {
      this.modems = []
      this.modemService.comunicadoresLivres(clientId).subscribe((modems: any) => {
        modems.forEach((com: any, indice: any) => {
          var mobileId = this.comunicadores.filter(function (e: any) {
            return e.id == com.it_id_premocenter
          })
          if(mobileId[0]){
            com.vc_mobileId = mobileId[0]?.vc_mobileId
            this.modems.push(com)
          }
        });
      });

      this.caracteristicas.data = []
      this.caracteristicaService.AcharPorClienteeTipo(clientId, "equipamento", this.equipmentId).subscribe((carac: any) => {
        carac?.forEach((element: any) => {
          if (element.vc_tipo == "timestamp") {
            element['input'] = 'datetime-local'
          } else if (element.vc_tipo == 'string') {
            element['input'] = 'text'
          } else {
            element['input'] = 'number'
          }
        });
        var control = this.retornaControlArCaracteristicaSemDuplicidade(<FormArray>this.equipmentForm.get('ar_caracteristicas'), carac);
        this.caracteristicas.data = control.value
      })

      this.agviewsConnect = [];
      this.agviewsService.acharAGViewsConnectLivres(clientId).subscribe((agviewsConnect: any) => {
        this.agviewsConnect = agviewsConnect;
      });
    });
  }

  public makeEquipment() {
    this.equipment = {
      "vc_frota": this.equipmentForm.get("fleet")?.value,
      "vc_tipo": this.equipmentForm.get("type")?.value,
      "fk_cliente": this.equipmentForm.get("client")?.value,
      "ar_caracteristicas": this.equipmentForm.get("ar_caracteristicas")?.value,
    };

    if (this.equipmentForm.get("mobileId")?.value !== undefined && this.equipmentForm.get("mobileId")?.value !== 'semModem') {
      this.equipment["fk_comunicadores"] = this.equipmentForm.get("mobileId")?.value;
    }

    if (this.equipmentForm.get("agviewConnectId")?.value !== undefined && this.equipmentForm.get("agviewConnectId")?.value !== 'semAGViewConnect') {
      this.equipment["it_id_agview_connect"] = this.equipmentForm.get("agviewConnectId")?.value;
    }

    this.equipmentService.cadastrar(this.equipment).subscribe((data: any) => {
      this.equipamentoCriado = data;
      this.alertService.success('Equipamento criado com sucesso')
      this.location.back()

    }, err => {
      this.alertService.exibirMensagemErro(err);
    });
  }

  public cancel(): void {
    this.location.back();
  }

  patchValues(id: any, carac: any, valor: any, sr_id: any, vc_tipo: any, input: any) {
    return this.formBuilder.group({
      idValor: [id],
      carac: [carac],
      valor: [valor],
      idCaracteristica: [sr_id],
      vc_tipo: [vc_tipo],
      input: [input]
    })
  }

  retornaControlArCaracteristicaSemDuplicidade(control: FormArray, caracteristicas: any) {
    control.clear()
    caracteristicas?.forEach((x: any) => {
      control.push(this.patchValues(x.idValor, x.vc_nome, x.valor, x.sr_id, x.vc_tipo, x.input))
    });
    return control
  }
}
