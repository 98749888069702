<div class="container background">
    <div class="row">
        <div class="col-md-12 titleContainer">
            <h1>Cadastrar meta</h1>
        </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="container-fluid">
        <div class="container-fluid row">
            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Nome da Meta</mat-label>
                <input matInput formControlName="name" minlength="5" />
                <mat-error *ngIf="form.controls['name'].invalid">
                    Informe o nome da meta (Mínimo de 5 caracteres)
                </mat-error>
            </mat-form-field>
        </div>
        <div class="container-fluid row">
            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Meta (em hectares)</mat-label>
                <input matInput formControlName="metaHectares" type="number" />
                <mat-error *ngIf="form.controls['metaHectares'].invalid">
                    Informe qual é a meta em hectares (Valor não pode ser negativo)
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Usina</mat-label>
                <mat-select formControlName="usina">
                    <mat-option *ngFor="let usina of usinas" [value]="usina.id">
                        {{usina.nome}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls['usina'].invalid">
                    Informe qual é a usina
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-6 custom-datepicker form-date" (click)="picker.open()">
                <mat-label>Data de início</mat-label>
                <input matInput class="form-date" formControlName="dataInicio" [matDatepicker]="picker" readonly/>
                <mat-datepicker-toggle class="icon-date" matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="form.controls['dataInicio'].invalid">
                  Informe a data de início da meta
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-md-6 custom-datepicker form-date" (click)="pickerFim.open()">
                <mat-label>Data de Fim</mat-label>
                <input matInput class="form-date" formControlName="dataFim" [matDatepicker]="pickerFim" readonly/>
                <mat-datepicker-toggle class="icon-date" matSuffix [for]="pickerFim"></mat-datepicker-toggle>
                <mat-datepicker #pickerFim></mat-datepicker>
                <mat-error *ngIf="form.controls['dataFim'].invalid">
                  Informe a data de fim da meta
                </mat-error>
            </mat-form-field>

            <mat-error *ngIf="form.errors?.datasInvalidas">
                Data inicial não pode ser maior que a data final.
            </mat-error>

            <div class="col-md-6">
                <div>
                    <p class="mt-0">Você trabalha 7 dias na semana?</p>
                    <div class="row">
                        <div class="col-6 ">
                            <button mat-raised-button class="w-100 btn btnSim" [class.selectedSim]="trabalhaSeteDias"
                            (click)="setTrabalhaSeteDias(true)" type="button">
                                Sim
                            </button>
                        </div>
                        <div class="col-6 ">
                          <button mat-raised-button class="w-100 btn btnNao" [class.selectedNao]="!trabalhaSeteDias"
                          (click)="setTrabalhaSeteDias(false)"type="button">
                              Não
                          </button>
                        </div>
                    </div>
                </div>
            </div>

            <mat-form-field id="input-dias" *ngIf="!trabalhaSeteDias" appearance="outline" class="col-md-6 py-0">
                <mat-label>Quantos dias por semana você trabalha?</mat-label>
                <input matInput formControlName="diasTrabalhados" type="number" min="1" max="7"/>
                <mat-error *ngIf="form.controls['diasTrabalhados'].invalid">
                    Informe quantos dias você trabalha na semana (No máximo 7)
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-row_botoes" fxLayout="row" fxLayoutAlign="center">
            <button *ngIf="!isLoading" mat-raised-button class="botao-criar" type="submit" [disabled]="!form.valid">
                Gravar
            </button>
            <mat-spinner *ngIf="isLoading" diameter="40"></mat-spinner>
            <button mat-raised-button class="botao-excluir" type="reset" fxFlexOffset="10px" (click)="cancel()" *ngIf="!isLoading">
                Cancelar
            </button>
        </div>

    </form>
</div>
