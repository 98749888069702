<div  *ngIf="!load">
  <div class="row mb-3">
      <div class="col border-right">
          <div class="d-flex flex-column justify-content-start align-items-start mb-2">
              <div>
                  <img src="assets/icone-config.png" style="width: 1.2rem;">
                  <span class="texto-ultima-mensagem"> Status do carretel</span>
              </div>
              <span class="texto-informacoes ml-4">{{status}}</span>
          </div>
          <div class="d-flex flex-column justify-content-start align-items-start mb-2">
              <div>
                  <img src="assets/icone-motobomba.png" style="width: 1.3rem;">
                  <span class="texto-ultima-mensagem"> Bateria</span>
              </div>
              <span class="texto-informacoes ml-4">{{bateria ? bateria : "0"}}</span>
          </div>
          <div class="d-flex flex-column justify-content-start align-items-start mb-2">
              <div>
                  <img src="assets/icone-pressao.png" style="width: 1rem;">
                  <span class="texto-ultima-mensagem"> Odometro</span>
              </div>
              <span class="texto-informacoes ml-4">{{odometro ? odometro : "0"}}</span>
          </div>
      </div>
      <div class="col">
          <div class="d-flex flex-column justify-content-start align-items-start mb-2">
              <div>
                  <img src="assets/icone-pressao.png" style="width: 1rem;">
                  <span class="texto-ultima-mensagem"> Pressão</span>
              </div>
              <span class="texto-informacoes ml-4">{{pressao ? pressao : "0"}}</span>
          </div>
          <div class="d-flex flex-column justify-content-start align-items-start mb-2">
              <div>
                  <img src="assets/icone-pressao.png" style="width: 1rem;">
                  <span class="texto-ultima-mensagem"> Velocidade de recolhimento</span>
              </div>
              <span class="texto-informacoes ml-4 ">{{velocidadeRecolhimento ? velocidadeRecolhimento : "0"}}</span>
          </div>
          <div class="d-flex flex-column justify-content-start align-items-start mb-2" >
              <div>
                  <img id="imagemAlerta" src="assets/icone-alerta.svg" style="width: 1rem;">
                  <span class="texto-ultima-mensagem" [ngClass]="{'text-danger': alerta != ''}"> Alertas</span>
              </div>
              <span class="texto-informacoes ml-4" [ngClass]="{'text-danger': alerta != ''}" id="alertas">{{alerta != '' ? alerta : "Sem alertas"}}</span>
          </div>
      </div>
  </div>
  <div>
      <span class="texto-ultima-mensagem"><img src="assets/icone-chat.png" style="width: 1.2rem;"> Última mensagem</span>
      <hr/>
      <div class="d-flex justify-content-between">
          <span class="texto">{{tipoMensagem}} </span>
          <span class="texto-data">{{ultimaMensagem | date: "dd/MM/yyyy HH:mm:ss"}}</span>
      </div>
      <hr/>
  </div>
</div>
<div *ngIf="load" style="width: auto;" fxLayout="row" fxLayoutAlign="center center">
  <div *ngIf="load" class="spinner"></div>
</div>