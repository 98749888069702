import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, Input } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import { MensagensService } from 'src/app/service/mensagens.service';
import { OperacoesService } from 'src/app/service/operacoes.service';
import pluginDataLabels from 'chartjs-plugin-datalabels';
import * as _ from "underscore";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { GraficosService } from 'src/app/service/graficos.service';

@Component({
  selector: 'app-produtividade-os',
  templateUrl: './produtividade-os.component.html',
  styleUrls: ['./produtividade-os.component.css']
})
export class ProdutividadeOsComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  idCliente = localStorage.getItem('cliente_filtro');
  dataInicio = localStorage.getItem('DateStartGraficos');
  dataFinal = localStorage.getItem('DateEndGraficos');
  fuso = localStorage.getItem('horario') == 'utc' ? 'utc' : 'brt';
  loading = true;
  validaResp = false;
  produtividadeMaisEfetivas: any;
  produtividadeMenosEfetivas: any;
  tempoProdutividade: any;
  mostrarSelect = false;
  tipoGrafico = 1;
  informacoesTabela = [] as any;
  tabela: any;


  public displayedColumns: string[] = [
    "operacao",
    "recolhidos"
  ];
  public inputColumns: any = [];
  public inputData: any = [];
  public pieChartLabels: Label[] = [];
  public pieChartData: any = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartColors = [
    {
      backgroundColor: [
        '#8C51A5', '#CB5E98', '#F47B8A', '#FFA37E', '#FFD285',
        '#FFFFA5', '#B9E49F', '#78C59B', '#24635B', '#00838D',
        '#089951', '#0DA600', '#8DB309', '#BFA500', '#CC8B0A',
        '#D95900', '#E6270B', '#F20084', '#B20CF9', '#2700FD'
      ],
    },
  ];
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      }
    },
    legend: {
      display: false
    }
  };
  pieChartPlugins = [pluginDataLabels];
  erro = false;
  @Input() ocultarGrafico: boolean = false

  constructor(
    private graficosService: GraficosService
  ) { }

  ngOnInit(): void {
    this.produtividadePorOs();
  }

  produtividadePorOs() {
    this.graficosService.graficoProdutividadeOs(this.dataInicio, this.dataFinal, this.idCliente, this.fuso).subscribe((resposta: any) => {
      this.loading = false
      const indicesResposta = Object.keys(resposta);
      if (resposta.ordensServico && (resposta.ordensServico.length === 0)) {
        this.validaResp = true;
      } else {
        this.validaResp = false;
      }
      if (indicesResposta.length != 0) {
        this.tempoProdutividade = resposta;

        if (indicesResposta.includes("produtividadesMaisEfetivas")) {
          this.produtividadeMaisEfetivas = resposta.produtividadesMaisEfetivas;
          this.produtividadeMenosEfetivas = resposta.produtividadesMenosEfetivas;
          this.mostrarSelect = true;
          this.mudarGrafico();
        } else {
          const produtividadesOrdensServico = this.tempoProdutividade.ordensServico;
          const produtividadesRecolhidos = this.tempoProdutividade.recolhidos;
          this.pieChartLabels = produtividadesOrdensServico;
          this.pieChartData = produtividadesRecolhidos;
          this.pieChartLabels.forEach((frota: any, index: any) => {
            this.informacoesTabela.push({ 'ordemServico': frota, 'recolhidos': this.pieChartData[index], 'id': index, 'hidden': false, cor: this.pieChartColors[0]['backgroundColor'][index] })
          });
          this.tabela = this.informacoesTabela;
        }
      }
    }, err => {
      this.loading = false;
      this.erro = true;
    })
  }

  mudarGrafico() {
    this.tipoGrafico
    let filtroOs = this.produtividadeMaisEfetivas

    if (this.tipoGrafico == 1) {
      filtroOs = this.produtividadeMenosEfetivas
    }

    this.informacoesTabela = [];

    const recolhidos = filtroOs.recolhidos;
    const ordensServico = filtroOs.ordensServico;
    this.pieChartLabels = ordensServico;
    this.pieChartData = recolhidos;
    this.pieChartLabels.forEach((ordem: any, index: any) => {
      this.informacoesTabela.push({ 'ordemServico': ordem, 'recolhidos': this.pieChartData[index], 'id': index, 'hidden': false, cor: this.pieChartColors[0]['backgroundColor'][index] })
    });
    this.tabela = this.informacoesTabela;
  }

  selecionarCelula(id: any) {
    var indice: any = this.chart?.datasets[0]
    var indiceMeta: any = Object.getOwnPropertyNames(indice['_meta']);
    indiceMeta = indiceMeta[0];
    var id_dado = parseInt(id);
    if (this.tabela[id_dado].hidden) {
      indice['_meta'][indiceMeta]['data'][id_dado].hidden = false
      this.tabela[id_dado].hidden = false;
    } else {
      indice['_meta'][indiceMeta]['data'][id_dado].hidden = true
      this.tabela[id_dado].hidden = true;
    }
    this.chart?.update();
  }
}
