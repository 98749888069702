import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogMensagensComponent } from 'src/app/operacoes/monitoramento-operacoes/dialog-mensagens/dialog-mensagens.component';
import { FaixasMapaService } from 'src/app/service/faixas-mapa.service';
import { TraduzirMensagemService } from 'src/app/service/traduzir-mensagem.service';

@Component({
  selector: 'app-dialog-informacoes-esticamento',
  templateUrl: './dialog-informacoes-esticamento.component.html',
  styleUrls: ['./dialog-informacoes-esticamento.component.css']
})
export class DialogInformacoesEsticamentoComponent implements OnInit {

  tipoMapa = localStorage.getItem("tipoMapa");
  LocalStorageId = localStorage.getItem('cliente_filtro');
  map!: google.maps.Map;
  informacoes: any;
  fuso: string = localStorage.getItem('horario') == 'utc' ? 'utc' : 'brt';
  loader = true;
  mostrarMensagem = false;

  constructor(
    public dialogRef: MatDialogRef<DialogInformacoesEsticamentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private faixasMapaService: FaixasMapaService,
    private traduzService: TraduzirMensagemService,
  ) { }

  ngOnInit(): void {
    this.ajustarDados()
  }

  ajustarDados() {
    this.data.tipo = this.data.ts_data_finalizacao ? 'completa' : 'dia';
    this.faixasMapaService.informacoesDaFaixa(this.data.sr_id, this.data.tipo, this.fuso).subscribe((informacoesFaixa: any) => {
      this.loader = false;
      this.informacoes = informacoesFaixa;
      if (this.informacoes.en_estado == 'FINALIZADO' && (this.informacoes.telemetrias.length == 0 || this.informacoes.telemetrias == null)) {
        this.mostrarMensagem = true;
      } else {
        this.criarMapa();
        this.montarPoligonos(informacoesFaixa);
      }
    })
  }

  toRadians(degrees: any) {
    return (degrees * Math.PI) / 180;
  }

  toDegrees(radians: any) {
    return (radians * 180) / Math.PI;
  }

  bearing(startLat: any, startLng: any, destLat: any, destLng: any, metros: any, band: any) {
    startLat = this.toRadians(startLat);
    startLng = this.toRadians(startLng);
    destLat = this.toRadians(destLat);
    destLng = this.toRadians(destLng);
    //bearing
    var y = Math.sin(destLng - startLng) * Math.cos(destLat);
    var x = Math.cos(startLat) * Math.sin(destLat) - Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    var CarreteleTrator58 = Math.atan2(y, x);
    CarreteleTrator58 = this.toDegrees(CarreteleTrator58);
    var lat = startLat;
    var lon = startLng;
    var R = 6378.1; //Radius of the Earth
    var brng = this.toRadians(CarreteleTrator58);
    var d = metros / 1000; //Distance in km
    var lat0 = Math.asin(Math.sin(lat) * Math.cos(d / R) + Math.cos(lat) * Math.sin(d / R) * Math.cos(brng));
    var lon0 = lon + Math.atan2(Math.sin(brng) * Math.sin(d / R) * Math.cos(lat), Math.cos(d / R) - Math.sin(lat) * Math.sin(lat0));
    lat0 = this.toDegrees(lat0);
    lon0 = this.toDegrees(lon0);
    var lat00 = this.toRadians(lat0);
    var lon00 = this.toRadians(lon0);
    var R = 6378.1; //Radius of the Earth
    var brng = this.toRadians(CarreteleTrator58) + 1.54;
    var d = band / 1000; //Distance in km
    var lat1 = Math.asin(Math.sin(lat00) * Math.cos(d / R) + Math.cos(lat00) * Math.sin(d / R) * Math.cos(brng));
    var lon1 = lon00 + Math.atan2(Math.sin(brng) * Math.sin(d / R) * Math.cos(lat00), Math.cos(d / R) - Math.sin(lat00) * Math.sin(lat1));
    lat1 = this.toDegrees(lat1);
    lon1 = this.toDegrees(lon1);
    var brng = this.toRadians(CarreteleTrator58) + 4.62;
    var lat2 = Math.asin(Math.sin(lat00) * Math.cos(d / R) + Math.cos(lat00) * Math.sin(d / R) * Math.cos(brng));
    var lon2 = lon00 + Math.atan2(Math.sin(brng) * Math.sin(d / R) * Math.cos(lat00), Math.cos(d / R) - Math.sin(lat00) * Math.sin(lat2));
    lat2 = this.toDegrees(lat2);
    lon2 = this.toDegrees(lon2);
    var lat = destLat;
    var lon = destLng;
    var brng = this.toRadians(CarreteleTrator58) + 4.62;
    var lat3 = Math.asin(
      Math.sin(lat) * Math.cos(d / R) +
      Math.cos(lat) * Math.sin(d / R) * Math.cos(brng)
    );
    var lon3 =
      lon +
      Math.atan2(Math.sin(brng) * Math.sin(d / R) * Math.cos(lat), Math.cos(d / R) - Math.sin(lat) * Math.sin(lat3));
    lat3 = this.toDegrees(lat3);
    lon3 = this.toDegrees(lon3);

    var brng = this.toRadians(CarreteleTrator58) + 1.54;
    var lat4 = Math.asin(Math.sin(lat) * Math.cos(d / R) + Math.cos(lat) * Math.sin(d / R) * Math.cos(brng));
    var lon4 = lon + Math.atan2(Math.sin(brng) * Math.sin(d / R) * Math.cos(lat), Math.cos(d / R) - Math.sin(lat) * Math.sin(lat4));
    lat4 = this.toDegrees(lat4);
    lon4 = this.toDegrees(lon4);
    var array = [lat1, lon1, lat2, lon2, lat3, lon3, lat4, lon4, CarreteleTrator58];
    return array;
  }

  circlePath(center: any, radius: any, bearing: any) {
    var a = [], p = 191 / 20, d = bearing + 265.5;
    for (var i = 0; i < 20; ++i, d += p) {
      a.push(google.maps.geometry.spherical.computeOffset(center, radius, d));
    }
    return a;
  }

  openDialog(message: any): void {
    var mensagem = this.traduzService.TraduzirPay(message)
    const dialogRef = this.dialog.open(DialogMensagensComponent, {
      maxHeight: "100vh",
      width: "500px",
      data: mensagem
    });
  }

  RetornarValorMaximoPressaoLamina(json: any): any {
    if (json && json.maxima) {
      return json.maxima;
    }

    return null;
  }

  RetornarValorMinimoPressaoLamina(json: any): any {
    if (json && json.minima) {
      return json.minima;
    }

    return null;
  }

  descobrirCorPoligono(valorComparar: number, ValorCompararMax: number, ValorCompararMin: number) {
    if (valorComparar > ValorCompararMax) {
      return "#00cbff"
    } else if (valorComparar >= ValorCompararMin && valorComparar <= ValorCompararMax) {
      return "#48ff00"
    } else if (valorComparar < ValorCompararMin) {
      return "#ff0000"
    }
    return "darkgray"
  }

  poligonoNoMapa(coordenadas: any, cor: string, payload: any = null) {
    var poligono = new google.maps.Polygon({
      paths: coordenadas,
      strokeColor: "darkgray",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: cor,
      fillOpacity: 0.8,
      zIndex: 1
    });
    poligono.setMap(this.map)
    if (payload) {
      poligono.addListener('click', (event: any) => {
        this.openDialog(payload)
      })
    }
  }

  dataFormatadaAnoMesDia(dataMensagenUTC: string) {
    const data = new Date(dataMensagenUTC);
    if (this.fuso == 'brt') {
      data.setHours(data.getHours() - 3);
    }
    const ano = data.getFullYear();
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const dia = String(data.getDate()).padStart(2, '0');
    return `${ano}-${mes}-${dia}`;
  }

  criarMapa() {
    var coordenadasCentro = JSON.parse(this.data.js_coordenadas)
    this.map = new google.maps.Map(document.getElementById("google_maps") as HTMLElement, {
      disableDefaultUI: true,
      center: new google.maps.LatLng(coordenadasCentro[0][0], coordenadasCentro[0][1]),
      zoom: 16,
      mapTypeId: 'satellite'
    });
  }

  criarLinhaPontilhada(AeB: any) {
    var start = new google.maps.LatLng(AeB.Latitude, AeB.Longitude);
    var end = new google.maps.LatLng(AeB.LatitudeInicial, AeB.LongitudeInicial);
    var linhaPontilhada = new google.maps.Polyline({
      path: [start, end],
      strokeColor: "darkgray",
      strokeOpacity: 0,
      icons: [
        {
          icon: {
            path: "M 0,-2 0,1",
            strokeOpacity: 1,
            scale: 2,
          },
          offset: "0",
          repeat: "25px",
        },
      ],
    });
    linhaPontilhada.setMap(this.map);
  }

  criarPoligonoFinal(AeB: any, coord: any, larguraDivididoPorDois: number) {
    var finalizacao = this.bearing(AeB.LatitudeInicial, AeB.LongitudeInicial, AeB.Latitude, AeB.Longitude, 0, larguraDivididoPorDois)
    var coordenadas = [
      { lat: finalizacao[0], lng: finalizacao[1] },
      { lat: finalizacao[2], lng: finalizacao[3] },
      { lat: coord[2], lng: coord[3] },
      { lat: coord[0], lng: coord[1] },
    ];
    this.poligonoNoMapa(coordenadas, "darkgray", 'Foi finalizado o esticamento');
  }

  validaExibicaoDaTelemetria(dataMensagem: string, dataDaFaixa: string, coordenadasSemiCirculo: any, cor: string, payload: any = null) {
    if (this.data.tipo == 'dia') {
      var dataFormatada = this.dataFormatadaAnoMesDia(dataMensagem);
      if (dataDaFaixa == dataFormatada) {
        this.poligonoNoMapa(coordenadasSemiCirculo, cor, payload);
      }
    } else {
      this.poligonoNoMapa(coordenadasSemiCirculo, cor, payload);
    }
  }

  montarPoligonos(informacoesFaixa: any) {
    var coord = [] as any;
    var AeB = JSON.parse(this.informacoes.js_ponto_A_e_B);
    var valoresPressaoLamina = this.tipoMapa === 'pressao' ? this.informacoes.vc_pressao : this.informacoes.vc_lamina;
    var ValorCompararMax: any = parseFloat(this.RetornarValorMaximoPressaoLamina(valoresPressaoLamina)) ?? undefined;
    var ValorCompararMin: any = parseFloat(this.RetornarValorMinimoPressaoLamina(valoresPressaoLamina)) ?? undefined;
    var larguraDivididoPorDois = this.informacoes.it_largura_faixa / 2;
    if (informacoesFaixa.telemetrias.length == 0) {
      this.criarLinhaPontilhada(AeB);
    } else {
      informacoesFaixa.telemetrias.forEach((element: any, index: number) => {
        var payload = JSON.parse(element.js_payload_tratado);
        var PintarPrimeraTele = this.bearing(AeB.LatitudeInicial, AeB.LongitudeInicial, AeB.Latitude, AeB.Longitude, payload.odometer, larguraDivididoPorDois)
        var valorComparar: any = this.tipoMapa === 'pressao' ? payload.pressure : payload.lamina;
        var cor = this.descobrirCorPoligono(valorComparar, ValorCompararMax, ValorCompararMin);
        if (index == 0) {
          var coordenadasSemiCirculo = this.circlePath(new google.maps.LatLng(AeB.Latitude, AeB.Longitude), this.informacoes.it_largura_faixa / 2, PintarPrimeraTele[8])
          var coordenadasBases = [PintarPrimeraTele[4], PintarPrimeraTele[5], PintarPrimeraTele[6], PintarPrimeraTele[7]];
          this.validaExibicaoDaTelemetria(element.ts_timestamp_mensagem, informacoesFaixa.dt_data, coordenadasSemiCirculo, cor);
        } else {
          var coordenadasBases = [coord[2], coord[3], coord[0], coord[1]];
        }
        var coordenadas = [
          { lat: PintarPrimeraTele[0], lng: PintarPrimeraTele[1] },
          { lat: PintarPrimeraTele[2], lng: PintarPrimeraTele[3] },
          { lat: coordenadasBases[0], lng: coordenadasBases[1] },
          { lat: coordenadasBases[2], lng: coordenadasBases[3] },
        ];
        this.validaExibicaoDaTelemetria(element.ts_timestamp_mensagem, informacoesFaixa.dt_data, coordenadas, cor, payload);
        coord = [PintarPrimeraTele[0], PintarPrimeraTele[1], PintarPrimeraTele[2], PintarPrimeraTele[3]]
      });
      if (this.verificaEstado('FINALIZADO')) {
        this.criarPoligonoFinal(AeB, coord, larguraDivididoPorDois);
      }
    }
  }

  verificaEstado(estado: string): boolean {
    return this.informacoes.vc_estado == estado || this.informacoes.en_estado == estado;
  }
}
