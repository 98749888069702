import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BASE_URL_PREMOCENTER_FRONT } from './app.constants';
import { AlertaSessaoService } from './service/alerta-sessao.service';
import { RedirectService } from './service/redirect.service';
import Swal from 'sweetalert2';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'hidrometer-frontend';
  form!: FormGroup
  sessaoExpirada = false
  premocenter = BASE_URL_PREMOCENTER_FRONT

  constructor(
    private formBuilder: FormBuilder,
    private alertaSessao: AlertaSessaoService,
    private authService: RedirectService
  ) {
    this.alertaSessao.alertaFimSessao().subscribe((resp: any) => {
      if (resp) {
        this.sessaoExpirada = resp
        this.confirmAlert()
      } else {
        this.sessaoExpirada = false
      }
    })

    this.alertaSessao.alertaSessaoEncerrada().subscribe((resp: any) => {
      if (resp) {
        this.modalLogin()
      }
    })
  }

  public confirmAlert(): void {
    Swal.fire({
      title: 'Deseja renovar sua sessão?',
      text: `Expira as: ${localStorage.getItem('expira_token')}`,
      timer: 8000,
      showCancelButton: true,
      confirmButtonColor: '#00AA5B',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar'
    }).then((result: any) => {
      if (result.isConfirmed) {
        var token = localStorage.getItem('token')
        this.alertaSessao.renovarToken(token).subscribe((resp: any) => {
          if (resp) {
            localStorage.setItem('token', resp.access_token)
            Swal.fire({
              icon: 'success',
              title: 'Sessão Renovada!',
              showConfirmButton: false,
              timer: 1500
            })
          }
        },
          erro => {
            Swal.fire({
              icon: 'warning',
              title: 'Ocorreu um erro, você será redirecionado para o PremoCenter!',
              showConfirmButton: false,
              timer: 2000
            })
            setTimeout(() => {
              window.location.href = `${this.premocenter}`
            }, 3000)
          }
        )

      }
    })
  }

  async modalLogin() {
    var token = localStorage.getItem('token')
    const decoded: any = jwt_decode.default(token!);
    var vc_login = decoded.login
    const { value: password } = await Swal.fire({
      title: 'Iniciar nova sessão?',
      input: 'password',
      inputLabel: vc_login,
      inputPlaceholder: 'senha',
      confirmButtonColor: '#00AA5B',
      confirmButtonText: 'Confirmar'

    })
    if (password) {
      this.form = this.formBuilder.group({
        vc_login: new FormControl('', [Validators.required]),
        vc_senha: new FormControl('', [Validators.required]),
      })

      this.form.get('vc_login')?.setValue(vc_login)
      this.form.get('vc_senha')?.setValue(password)
      this.authService.reconectar(this.form.value).subscribe((resp: any) => {
        localStorage.setItem('token', resp.token)
        Swal.fire({
          icon: 'success',
          title: 'Sessão Renovada!',
          showConfirmButton: false,
          timer: 1500
        })
      },
        erro => {
          Swal.fire({
            icon: 'warning',
            title: 'Ocorreu um erro, você será redirecionado para o PremoCenter!',
            showConfirmButton: false,
            timer: 2000
          })
          setTimeout(() => {
            window.location.href = `${this.premocenter}`
          }, 3000)
        })
    }
  }
}
