<span id="badge-mensagens" *ngIf="condicaoBadge" class="badge bg-danger position-relative z-2">
    {{quantidadeMensagens}}
</span>
<div id="mensagens" class="botao-expand z-1 d-none d-md-block">
    <div class="icon" (click)="toggleContent()">
        <img id="botao-open" src="assets/icones-botoes-acao-mapa/mensagens.svg" alt="Mensagens" matTooltip="Mensagens"
            matTooltipPosition="after" matTooltipShowDelay="500">

        <img src="assets/icones-botoes-acao-mapa/modal-aberto.svg" id="botao-branco-mensagens" alt="Ícone de fechar"
            style="z-index: 2" class="fechado me-1">

        <img src="assets/icones-botoes-acao-mapa/close.svg" id="botao-close-mensagens" alt="Ícone de fechar"
            style="z-index: 3" class="fechado">
    </div>
</div>

<div class="bg-white modal-fechado d-none d-md-block content" id="modal-mensagens">
    <div class="container container-notificacoes">
        <app-corpo-notificacoes></app-corpo-notificacoes>
    </div>

    <div class="container-botao-whatsapp d-flex justify-content-center">
        <app-botao-whatsapp></app-botao-whatsapp>
    </div>
</div>