import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CancelarRequestsService } from '../service/cancelar-requests.service';
import { ActivationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CancelarRequestsInterceptor implements HttpInterceptor {

  constructor(
    router: Router,
    private httpCancelService: CancelarRequestsService
  ) {
    router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.httpCancelService.cancelPendingRequests();
      }
    });
  }

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    let respostaRequest: Observable<any> = next.handle(req);
    if (!req.params.get('veioDaSideBar')) {
      respostaRequest = respostaRequest.pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
    }
    return respostaRequest;
  }
}
