import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { EditarFormulario } from 'src/app/model/editar-formulario.model';
import { AlertService } from 'src/app/service/alert.service';
import { CaracteristicasService } from 'src/app/service/caracteristicas.service';
import { ClienteService } from 'src/app/service/cliente.service';
import { EquipamentosService } from 'src/app/service/equipamentos.service';
import { OperacoesService } from 'src/app/service/operacoes.service';
import { equipamentosDaOperacao } from 'src/app/equipamentos-da-operacao';

@Component({
  selector: 'app-editar-operacoes',
  templateUrl: './editar-operacoes.component.html',
  styleUrls: ['./editar-operacoes.component.css'],
})
export class EditarOperacoesComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  public operationId!: number;
  public operation: any;
  public operationForm: FormGroup = new FormGroup({
    operationForm: new FormControl(),
  });
  public client: any;
  public tractors: any;
  public motorPumps: any;
  public reels: any;
  public displayedColumns: string[] = ['id', 'caracteristica', 'valor'];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  public caracteristicas: MatTableDataSource<any> = new MatTableDataSource();
  public caracteristica: any;
  clients = JSON.parse(localStorage.getItem('cliente') || '{}');
  varificaEditar = false;

  public motobombasDaOperacao: equipamentosDaOperacao[] = [];
  public carreteisDaOperacao: equipamentosDaOperacao[] = [];

  public equipamentos: any = {
    tractors: {
      disponiveis: [],
      operando: [],
      semComunicador: [],
    },
    reel: {
      disponiveis: [],
      operando: [],
      semComunicador: [],
    },
    motorpump: {
      disponiveis: [],
      operando: [],
      semComunicador: [],
    },
  };
  editarDesabilitado: boolean = true;
  valoresIniciais: any;
  carregado: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private location: Location,
    private equipmentService: EquipamentosService,
    private operationService: OperacoesService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private caracteristicaService: CaracteristicasService,
    private clienteService: ClienteService
  ) {}

  ngOnInit(): void {
    this.montarForm();
    this.Onchange();
    this.verificaEdita();
  }

  montarForm() {
    this.operationForm = this.formBuilder.group({
      clientId: [null, Validators.required],
      serviceId: [null, Validators.required],
      state: [null, Validators.required],
      tractorId: [null, Validators.required],
      motorPumpId: [null],
      reels: [null],
      ar_caracteristicas: this.formBuilder.array([]),
    });
  }

  atualizarEstadoBotao() {
    if (this.valoresIniciais) {
      this.editarDesabilitado = !EditarFormulario.temAlteracaoFormulario(
        this.valoresIniciais,
        this.operationForm
      );
    }
  }

  Onchange() {
    this.operationForm.valueChanges.subscribe(() => {
      this.atualizarEstadoBotao();
    });
    this.operationForm
      .get('clientId')
      ?.valueChanges.subscribe((clientId: any) => {
        this.equipmentService
          .equipamentoSemOperacao(clientId)
          .subscribe((equipamentos: any) => {
            this.equipamentosDisponiveis(equipamentos['Disponiveis'] || []);
            this.equipamentosOperando(
              equipamentos['Equipamentos com OS operando']
            );
            this.equipamentosSemComunicacao(
              equipamentos['Equipamentos sem meios de comunicação']
            );
          });

        this.route.params.subscribe((params: Params) => {
          this.operationId = Number(params.id);
          if (this.operationId) {
            this.caracteristicaService
              .AcharPorClienteeTipo(clientId, 'operacao', this.operationId)
              .subscribe((carac: any) => {
                carac.forEach((element: any, index: any) => {
                  if (element.vc_tipo == 'timestamp') {
                    element['input'] = 'datetime-local';
                  } else if (element.vc_tipo == 'string') {
                    element['input'] = 'text';
                  } else {
                    element['input'] = 'number';
                  }
                });
                var control = this.retornaControlArCaracteristicaSemDuplicidade(
                  <FormArray>this.operationForm.get('ar_caracteristicas'),
                  carac
                );
                this.caracteristicas.data = control.value;
                this.valoresIniciais = JSON.parse(
                  JSON.stringify(this.operationForm.getRawValue())
                );
                this.atualizarEstadoBotao();
              });
          }
        });
      });
  }

  public equipamentosDisponiveis(Disponiveis: any[]): void {
    Disponiveis.forEach((equip: any) => {
      switch (equip.vc_tipo) {
        case 'TRACTOR':
          this.equipamentos.tractors.disponiveis.push(equip);
          break;
        case 'MOTOR_PUMP':
          this.equipamentos.motorpump.disponiveis.push(equip);
          break;
        case 'REEL':
          this.equipamentos.reel.disponiveis.push(equip);
          break;
      }
    });
  }

  public equipamentosOperando(Operando: any[]): void {
    Operando.forEach((equip: any) => {
      switch (equip.vc_tipo) {
        case 'TRACTOR':
          this.equipamentos.tractors.operando.push(equip);
          break;
        case 'MOTOR_PUMP':
          this.equipamentos.motorpump.operando.push(equip);
          break;
        case 'REEL':
          this.equipamentos.reel.operando.push(equip);
          break;
      }
    });
  }

  public equipamentosSemComunicacao(SemComunicacao: any[]): void {
    SemComunicacao.forEach((equip: any) => {
      switch (equip.vc_tipo) {
        case 'TRACTOR':
          this.equipamentos.tractors.semComunicador.push(equip);
          break;
        case 'MOTOR_PUMP':
          this.equipamentos.motorpump.semComunicador.push(equip);
          break;
        case 'REEL':
          this.equipamentos.reel.semComunicador.push(equip);
          break;
      }
    });
  }

  verificaEdita() {
    this.route.params.subscribe((params: Params) => {
      this.operationId = Number(params.id);
      if (this.operationId) {
        this.varificaEditar = true;
        this.operationService.AcharPorId(this.operationId).subscribe(
          (operation) => {
            this.operation = operation;
            this.clienteService
              .AcharPremocenterCliente(this.operation.fk_cliente)
              .subscribe((cliente: any) => {
                this.operationForm
                  .get('clientId')
                  ?.setValue(cliente[0].it_id_premocenter);
              });
            this.operationForm
              .get('serviceId')
              ?.setValue(this.operation.vc_service_id);
            this.operationForm.get('state')?.setValue(this.operation.vc_estado);
            this.operationForm
              .get('tractorId')
              ?.setValue(this.operation.fk_equipamento_trator);
            this.motobombasDaOperacao = this.operation.equipamentos.filter(
              function (equip: any) {
                return equip.vc_tipo == 'MOTOR_PUMP';
              }
            );
            this.carreteisDaOperacao = this.operation.equipamentos.filter(
              function (equip: any) {
                return equip.vc_tipo == 'REEL';
              }
            );
            this.operationForm
              .get('motorPumpId')
              ?.setValue(
                this.motobombasDaOperacao.map((item: any) => item.sr_id)
              );
            this.operationForm
              .get('reels')
              ?.setValue(
                this.carreteisDaOperacao.map((item: any) => item.sr_id)
              );
            this.carregado = true;
          },
          (err) => {
            this.location.back();
          }
        );
      }
    });
  }

  public makeOperation(): void {
    if (this.validarNumero(this.operationForm.get('serviceId')?.value)) {
      this.operation = this.montarCorpoDaRequest(this.operationForm);
      this.operationService
        .Atualizar(this.operation, this.operationId)
        .subscribe(
          () => {
            this.alertService.success('OS atualizada com sucesso');
            this.location.back();
          },
          (err) => {
            this.alertService.exibirMensagemErro(err);
          }
        );
    } else {
      this.alertService.warning(
        'Por favor, informe apenas números no campo No. Serviço'
      );
    }
  }

  public montarCorpoDaRequest(form: FormGroup): object {
    if (form.get('motorPumpId')?.value || form.get('reels')?.value) {
      const motobomba =
        form.get('motorPumpId')?.value == null
          ? []
          : form.get('motorPumpId')?.value;
      const carretel =
        form.get('reels')?.value == null ? [] : form.get('reels')?.value;
      const equipamentos = [...motobomba, ...carretel];
      return {
        vc_service_id: form.get('serviceId')?.value,
        vc_estado: form.get('state')?.value,
        fk_cliente: form.get('clientId')?.value,
        fk_equipamento_trator: form.get('tractorId')?.value,
        ar_caracteristicas: form.get('ar_caracteristicas')?.value,
        fk_equipamentos: equipamentos,
      };
    } else {
      return {
        vc_service_id: form.get('serviceId')?.value,
        vc_estado: form.get('state')?.value,
        fk_cliente: form.get('clientId')?.value,
        fk_equipamento_trator: form.get('tractorId')?.value,
        ar_caracteristicas: form.get('ar_caracteristicas')?.value,
      };
    }
  }

  public cancel(): void {
    this.location.back();
    this.operationForm.reset();
  }

  patchValues(
    id: any,
    carac: any,
    valor: any,
    sr_id: any,
    vc_tipo: any,
    input: any
  ) {
    return this.formBuilder.group({
      idValor: [id],
      carac: [carac],
      valor: [valor],
      idCaracteristica: [sr_id],
      vc_tipo: [vc_tipo],
      input: [input],
    });
  }

  retornaControlArCaracteristicaSemDuplicidade(
    control: FormArray,
    caracteristicas: any
  ) {
    control.clear();
    caracteristicas?.forEach((x: any) => {
      control.push(
        this.patchValues(
          x.idValor,
          x.vc_nome,
          x.valor,
          x.sr_id,
          x.vc_tipo,
          x.input
        )
      );
    });
    return control;
  }

  validarNumero(input: string): boolean {
    const numeroRegex = /^[0-9]+$/;
    return numeroRegex.test(input);
  }

  habilitarEquipamentosRelacionadosAOperacao(sr_id: any,equipamento: string): boolean {
    switch (equipamento) {
      case 'motobomba':
        if (this.motobombasDaOperacao.filter((motobomba) => motobomba.sr_id == sr_id).length >= 1)
          return false;
        break;
      case 'carretel':
        if (this.carreteisDaOperacao.filter((carretel) => carretel.sr_id == sr_id).length >= 1)
          return false;
    }
    return true;
  }
}
