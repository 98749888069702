import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpOptionsService } from './header-options.service';
import { BASE_URL_HIDROMETER } from '../app.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OperadoresService {
  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }


  public AcharPorClientePaginate(cliente: number | string, size: number | string): Observable<any> {
    const url = `${this.BASE_URL_HIDROMETER}operadores/cliente/${cliente}/size/${size}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorId(operadorId: number | string): Observable<any> {
    const url = `${this.BASE_URL_HIDROMETER}operadores/${operadorId}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AtualizarOperador(operador: object, operadorId: number | string): Observable<any> {
    const url = `${this.BASE_URL_HIDROMETER}operadores/${operadorId}`;
    return this.http.put(url, operador, this.httpOptionsService.getHttpOptions());
  }

  public AlterarStatusOperador(operadorId: number | string): Observable<any> {
    const url = `${this.BASE_URL_HIDROMETER}operadores/${operadorId}/alterar-status`;
    return this.http.put(url, {}, this.httpOptionsService.getHttpOptions());
  }

  public Next(url: string): Observable<any> {
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }
}
