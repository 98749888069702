import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER } from '../app.constants';
import { HttpOptionsService } from './header-options.service';

@Injectable({
  providedIn: 'root'
})
export class OperacoesService {
  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  public cadastrar(operacao: any) {
    const url = `${this.BASE_URL_HIDROMETER}operacoes`;
    return this.http.post(url, operacao, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorCliente(cliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}operacoes/cliente/${cliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorClienteData(cliente: any, dataInicial: any, dataFinal: any) {
    const url = `${this.BASE_URL_HIDROMETER}operacoes/cliente/${cliente}/data/${dataInicial}/${dataFinal}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorClientePaginate(cliente: any, size: any) {
    const url = `${this.BASE_URL_HIDROMETER}operacoes/paginate/cliente/${cliente}/size/${size}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorId(operacaoId: any) {
    const url = `${this.BASE_URL_HIDROMETER}operacoes/${operacaoId}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public Atualizar(operacao: any, operacaoId: any) {
    const url = `${this.BASE_URL_HIDROMETER}operacoes/${operacaoId}`;
    return this.http.put(url, operacao, this.httpOptionsService.getHttpOptions());
  }

  public AtualizarStatus(operacao: any, operacaoId: any) {
    const url = `${this.BASE_URL_HIDROMETER}operacoes/atualizar-status/${operacaoId}`;
    return this.http.put(url, operacao, this.httpOptionsService.getHttpOptions());
  }

  public Desativar(operacaod: any) {
    const url = `${this.BASE_URL_HIDROMETER}operacoes/${operacaod}`;
    return this.http.delete(url, this.httpOptionsService.getHttpOptions());
  }

  public Next(url: any) {
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public removerEquipamentoDaOsAntigaEaddOsNova(operacaoAntiga: any, operacaoNova: any, equipamento: any) {
    const url = `${this.BASE_URL_HIDROMETER}operacoes/operacao-antiga/${operacaoAntiga}/operacao-nova/${operacaoNova}/equipamento/${equipamento}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public listarOperoesPorOrdemStatus(idCliente: any) {
    'operacoes/ordem-status/cliente/{id}'
    const url = `${this.BASE_URL_HIDROMETER}operacoes/ordem-status/cliente/${idCliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());

  }
  public listarMensagensPorOperacao(cliente: any, dataInicial: any, dataFinal: any, pgd: any) {
    const url = `${this.BASE_URL_HIDROMETER}produtividade-operacao/cliente/${cliente}/dataInicial/${dataInicial}/dataFinal/${dataFinal}/pgd/${pgd}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public OsPorEquipamentoECliente(idEquipamento: any, idCliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}operacao/equipamento/${idEquipamento}/cliente/${idCliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }

  public AcharPorFkTrator(fkTrator: any, idCliente: any) {
    const url = `${this.BASE_URL_HIDROMETER}operacao/trator/${fkTrator}/cliente/${idCliente}`;
    return this.http.get(url, this.httpOptionsService.getHttpOptions());
  }
}
