import { Params } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER, BASE_URL_CRONOS } from '../app.constants';
import { HttpOptionsService } from './header-options.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MensagensService {
  readonly BASE_URL_HIDROMETER = BASE_URL_HIDROMETER;
  readonly BASE_URL_CRONOS = BASE_URL_CRONOS;
  token = localStorage.getItem('token');
  headerOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    })
  }
  httpHeaders: any = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.token
  };

  constructor(
    private http: HttpClient,
    private httpOptionsSevice: HttpOptionsService
  ) { }

  mensagensEquipamento(cliente: any, equipamento: any, size: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagemPorEquipamento/cliente/${cliente}/equipamento/${equipamento}/size/${size}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  ultimaMensagemEquipamento(cliente: any) {
    let url = `${this.BASE_URL_HIDROMETER}ultimaMensagemEquipamento/cliente/${cliente}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  telemetriasDoAlerta(cliente: any, dataIni: any, dataFin: any) {
    let url = `${this.BASE_URL_HIDROMETER}telemetriasDoAlerta/cliente/${cliente}/data/${dataIni}/${dataFin}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  excel(cliente: any, dataIni: any, dataFin: any, operacao: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagensExcel/cliente/${cliente}/dataInicial/${dataIni}/dataFinal/${dataFin}/operacao/${operacao}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  MensagensPorOperacaoClienteData(operacao: any, cliente: any, dataInicial: any, dataFinal: any, size: any, order: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/operacao/${operacao}/cliente/${cliente}/data/${dataInicial}/${dataFinal}/size/${size}/order/${order}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptionsAcessControl())
  }

  MensagensPorOperacaoClient(operacao: any, cliente: any, size: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/operacao/${operacao}/cliente/${cliente}/size/${size}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  mensagemPorOperacaoClienteFrotaeData(cliente: any, operacao: any, frota: any, dataInicial: any, dataFinal: any, size: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/operacao/${operacao}/cliente/${cliente}/id/${frota}/data/${dataInicial}/${dataFinal}/size/${size}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  mensagensPorTurnoOperacaoClienteData(cliente: any, turno: any, operacao: any, dataInicial: any, dataFinal: any, size: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/cliente/${cliente}/turno/${turno}/operacao/${operacao}/data/${dataInicial}/${dataFinal}/size/${size}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  mensagensPorClienteData(cliente: any, dataInicial: any, dataFinal: any, size: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/cliente/${cliente}/data/${dataInicial}/${dataFinal}/size/${size}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  mensagensEsticamento(cliente: any, dataInicial: any, dataFinal: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/esticamento/cliente/${cliente}/data/${dataInicial}/${dataFinal}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  CountMensagensOS(operationId: any, carreteis: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/count/operacao/${operationId}/carreteis/${carreteis}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  AlterarBocaisOS(operacaoId: any, bocal: any, carreteis: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/operacao/${operacaoId}/bocal/${bocal}/carreteis/${carreteis}`
    return this.http.put(url, '', this.httpOptionsSevice.getHttpOptions());
  }

  AlterarBocaisMensagem(mensagemId: any, bocal: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/alterarBocal/mensagem/${mensagemId}/bocal/${bocal}`
    return this.http.put(url, '', this.httpOptionsSevice.getHttpOptions());
  }

  public Next(url: any) {
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions());
  }

  public MandarMensagem(tipo: any, json: any) {
    let url = `${this.BASE_URL_HIDROMETER}comando/${tipo}`
    return this.http.post(url, json, this.httpOptionsSevice.getHttpOptions());
  }

  LatLng(cliente: any, operationId: any, dataInicial: any, dataFinal: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/latLng/cliente/${cliente}/operacao/${operationId}/data/${dataInicial}/${dataFinal}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  ultimaMensagemTimestamp(cliente: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/cliente/${cliente}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  telemetrias(cliente: any, operationId: any, dataInicial: any, dataFinal: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/telemetrias/cliente/${cliente}/operacao/${operationId}/data/${dataInicial}/${dataFinal}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  porId(id: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/${id}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  ultimaMensagemPorOperacaoClienteFrotaeData(cliente: any, operacao: any, frota: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens-ultima/operacao/${operacao}/cliente/${cliente}/id/${frota}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  mensagensPorPgdEOperacao(cliente: any, operacao: any, dataInicial: any, dataFinal: any, pgd: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/cliente/${cliente}/operacao/${operacao}/dataInicial/${dataInicial}/dataFinal/${dataFinal}/pgd/${pgd}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  mensagensPorPgd(cliente: any, dataInicial: any, dataFinal: any, pgd: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/cliente/${cliente}/dataInicial/${dataInicial}/dataFinal/${dataFinal}/pgd/${pgd}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  tempoEfetivoPorOsMotobomba(cliente: any, dataInicial: any, dataFinal: any, tipo: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/cliente/${cliente}/dataInicial/${dataInicial}/dataFinal/${dataFinal}/tipo/${tipo}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  alertasMotobomba(cliente: any, dataInicial: any, dataFinal: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/alertas-motobomba/cliente/${cliente}/dataInicial/${dataInicial}/dataFinal/${dataFinal}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  mensagensSemOs(cliente: any, dataIni: any, dataFin: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagemSemOs/cliente/${cliente}/data/${dataIni}/${dataFin}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  ultimaMensagemEquipamentoKanban(equipamento: any) {
    let url = `${this.BASE_URL_HIDROMETER}ultimaMensagemOs/equipamentos/${equipamento}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  temposTrabalhados(cliente: any, frotas: any, dataIni: any, dataFin: any) {
    let url = `${this.BASE_URL_HIDROMETER}tempos-trabalhados`
    var headerOptions = this.httpOptionsSevice.getHttpOptions()
    headerOptions.params = {
      'cliente': `${cliente}`,
      'frotas': `${frotas}`,
      'dataInicial': `${dataIni}`,
      'dataFinal': `${dataFin}`,
    }
    // const httpParams: HttpParamsOptions = { fromObject: parametros } as HttpParamsOptions;
    // const options = { params: new HttpParams(httpParams), headers: new HttpHeaders(this.httpHeaders) }

    return this.http.get(url, headerOptions)
  }

  temposTrabalhadosPorGrupo(cliente: any, frotas: any, dataIni: any, dataFin: any, tipo: any) {
    let url = `${this.BASE_URL_HIDROMETER}tempos-trabalhados-tipo`
    var headerOptions = this.httpOptionsSevice.getHttpOptions()
    headerOptions.params = {
      'cliente': `${cliente}`,
      'frotas': `${frotas}`,
      'dataInicial': `${dataIni}`,
      'dataFinal': `${dataFin}`,
      'tipo': `${tipo}`,
    };
    // const httpParams: HttpParamsOptions = { fromObject: parametros } as HttpParamsOptions;
    // const options = { params: new HttpParams(httpParams), headers: new HttpHeaders(this.httpHeaders) }

    return this.http.get(url, headerOptions)
  }

  detalhesParada(cliente: any, frotas: any, dataIni: any, dataFin: any, codigo: any) {
    let url = `${this.BASE_URL_HIDROMETER}tempos-trabalhados-detalhes`
    var headerOptions = this.httpOptionsSevice.getHttpOptions()
    headerOptions.params = {
      'cliente': `${cliente}`,
      'frotas': `${frotas}`,
      'dataInicial': `${dataIni}`,
      'dataFinal': `${dataFin}`,
      'codigo': `${codigo}`,
    };
    // const httpParams: HttpParamsOptions = { fromObject: parametros } as HttpParamsOptions;
    // const options = { params: new HttpParams(httpParams), headers: new HttpHeaders(this.httpHeaders) }

    return this.http.get(url, headerOptions)
  }

  temposTrabalhadosFrota(cliente: any, frotas: any, dataIni: any, dataFin: any) {
    let url = `${this.BASE_URL_HIDROMETER}tempos-trabalhados-frota`
    var headerOptions = this.httpOptionsSevice.getHttpOptions()
    headerOptions.params = {
      'cliente': `${cliente}`,
      'frotas': `${frotas}`,
      'dataInicial': `${dataIni}`,
      'dataFinal': `${dataFin}`
    };
    // const httpParams: HttpParamsOptions = { fromObject: parametros } as HttpParamsOptions;
    // const options = { params: new HttpParams(httpParams), headers: new HttpHeaders(this.httpHeaders) }

    return this.http.get(url, headerOptions)
  }

  detalhesPeriodoFiltrado(cliente: any, frotas: any, dataIni: any, dataFin: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagens-periodo-filtrado/cliente/${cliente}/frotas/${frotas}/data/${dataIni}/${dataFin}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  mensagensSemOsCount(cliente: any, dataIni: any, dataFin: any) {
    let url = `${this.BASE_URL_HIDROMETER}mensagemSemOs/count/cliente/${cliente}/data/${dataIni}/${dataFin}`
    return this.http.get(url, this.httpOptionsSevice.getHttpOptions())
  }

  ultimas20MensagensWebSocket(id: any, data: any): Observable<any> {
    let url = `${this.BASE_URL_HIDROMETER}mensagens/ultimas20mensagens/${id}/${data}`
    return this.http.get<any>(url, this.httpOptionsSevice.getHttpOptions())
  }
}
