import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AlertService } from 'src/app/service/alert.service';
import { ClienteService } from 'src/app/service/cliente.service';
import { MetasService } from 'src/app/service/metas.service';
import { ChartOptions, ChartType, ChartDataSets, Chart } from 'chart.js';
import Swal from 'sweetalert2';
import { RedirectService } from 'src/app/service/redirect.service';
import { Router } from '@angular/router';
import { VerificarPermissoes } from 'src/app/model/verificar-premissao.model';

@Component({
  selector: 'app-exibir-metas',
  templateUrl: './exibir-metas.component.html',
  styleUrls: ['./exibir-metas.component.css']
})
export class ExibirMetasComponent implements OnInit {
  public clients = JSON.parse(localStorage.getItem('cliente') || "[]")
  public loading = true;
  public disabled: boolean = false;
  public metas: Array<any> = [];
  public meta: any = null;
  public alcancada!: boolean;
  public lineChartOptions!: ChartOptions;
  public lineChartLabels: string[] = [];
  public lineChartType!: ChartType;
  public lineChartLegend!: boolean;
  public lineChartData!: ChartDataSets[];
  public exportandoMeta: boolean = false;
  public classes: string[] = ['blue', 'green', 'purple', 'orange', 'red'];
  public length = 0;
  public clienteId: number = this.obterClienteId();
  public progressoAteOMomentoDaBarraDeProgresso: number = 0;
  public progressoTotalDaBarraDeProgresso: number = 0;
  public progressoPercent: number = 0;
  public ultimaMetaId: number = this.recuperaUltimaMeta();
  public formFilter: FormGroup = this.formBuilder.group({
    cliente: [null, Validators.required],
    idMeta: [0, Validators.required]
  });

  public columnsToDisplay = [
    'sr_id',
    'vc_nome',
    'it_dias_trabalhados_semana',
    'dt_inicio_meta',
    'dt_fim_meta',
    'fl_meta_dia',
    'fl_meta_hora',
    'fl_meta_semana',
    'fl_meta_mes',
    'fl_meta_total',
    'fl_horas_trabalhadas',
    'tm_primeiro_horario_dia',
    'tm_ultimo_horario_dia',
    'ts_criado_em',
    'ts_atualizado_em',
    'ts_deletado_em'
  ];

  constructor(
    private metasService: MetasService,
    private formBuilder: FormBuilder,
    private appService: RedirectService,
    public alertService: AlertService,
    public clienteService: ClienteService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.obterClientes();
    this.obterMetasDoCliente(); 
    this.carregarUltimaMeta();
  }

  obterClienteId(): number {
    let id: string | null = localStorage.getItem("cliente_filtro");
    if (!id) {
      return 0;
    }

    return parseInt(id);
  }

  recuperaUltimaMeta(): number {
    let ultimaMeta: string | null = localStorage.getItem('ultimaMetaVisualizada');
    if (ultimaMeta === null) {
      return 0;
    }

    return parseInt(ultimaMeta);
  }

  obterClientes(): void {
    this.formFilter.get("cliente")?.valueChanges.subscribe((clientId: any) => {
      localStorage.setItem("cliente_filtro", clientId);
      this.appService.enviarMudancaDeCliente();
      this.clienteId = clientId
    })
  }

  obterMetasDoCliente(): void {
    this.formFilter.get("cliente")?.setValue(this.clienteId);
    this.loading = true;
    this.metas = [];
    this.meta = null;
    this.formFilter.get('idMeta')?.setValue(0);
    this.metasService.obterMetasPorCliente(this.clienteId).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.metas = resp;
        if (this.metas.length) {
          this.carregarUltimaMeta();
        }
      }, error: (err: any) => {
        this.loading = false;
        this.alertService.error('Erro ao obter as metas da usina');
      }
    });
  }

  carregarUltimaMeta(): void {
    const idMeta = this.metas.find(meta => meta.sr_id === this.ultimaMetaId)?.sr_id ?? this.metas[0]?.sr_id ?? 0;
    this.formFilter.get('idMeta')?.setValue(idMeta);
    this.obterMeta();
  }

  obterMeta(): void {
    if (this.formFilter.get('idMeta')?.value === 0) {
      this.meta = null;
      this.loading = false;
      return; 
    }
    this.meta = null;
    this.loading = true;
    this.metasService.obterMeta(this.formFilter.get('idMeta')?.value).subscribe({
      next: (resp: any) => { 
        this.loading = false;
        this.meta = resp;
        localStorage.setItem('ultimaMetaVisualizada', this.formFilter.get('idMeta')?.value);
        this.criarGrafico();
        this.carregarBarraDeProgresso();
      }, error: (err: any) => {
        this.loading = false;
        this.alertService.error('Erro ao obter os dados da meta');
      }
    });
  }

  colorirHectares(id: number): string {
    return this.classes[id] || '';
  }

  criarGrafico() {
    Chart.defaults.global.defaultFontFamily = 'Poppins';
    this.alcancada = this.meta['graficoProduzido'].alcancada;

    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          display: true,
          ticks: {
            maxRotation: 0,
            autoSkip: true,
            maxTicksLimit: 10
          },
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          display: true,
          gridLines: {
            drawBorder: false,
            display: true,
            color: 'rgba(0, 0, 0, 0.1)',
            lineWidth: 0.6,
            zeroLineWidth: 0,
          },
          ticks: {
            callback: function(value) {
              return value + ' HA';
            },
            autoSkip: true,
            maxTicksLimit: 10
          }
        }]
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          fontFamily: 'Poppins',
          boxWidth: 10,
          padding: 20,
          fontSize: 13,
          generateLabels: function (chart) {
            if (!chart.data || !chart.data.datasets) {
              return [];
            }
            return chart.data.datasets.map((dataset, i) => ({
              text: dataset.label,
              fillStyle: typeof dataset.borderColor === 'string' ? dataset.borderColor : '',
              borderRadius: 10,
              hidden: !chart.isDatasetVisible(i),
              lineCap: dataset.borderCapStyle,
              lineDash: [],
              lineDashOffset: 0,
              lineJoin: dataset.borderJoinStyle,
              strokeStyle: typeof dataset.borderColor === 'string' ? dataset.borderColor : '',
              pointStyle: 'rectRounded',
              datasetIndex: i,
              fontColor: '#333',
              fontSize: 12,
              fontStyle: 'normal',
              fontFamily: 'Poppins'
            }));
          },
          usePointStyle: true,
        }
      },
      layout: {
        padding: {
          bottom: 10
        }
      }
    };
    this.lineChartLabels = this.meta['graficoProduzido'].diasDoGrafico;
    this.lineChartType = 'line';
    this.lineChartLegend = true;
    this.lineChartData = [
      {
        data: this.meta['graficoProduzido'].metaPorDia,
        label: 'Esperado para alcançar a meta',
        borderColor: '#3ad053',
        borderDash: [3, 3],
        fill: false,
        pointRadius: 0,
        lineTension: 0
      },
      {
        data: this.meta['graficoProduzido'].produtividadeDia,
        label: 'Produzido até o momento',
        borderColor: '#007db2',
        fill: false,
        pointRadius: 0,
        lineTension: 0
      }
    ];
  }

  deletarMeta(): void {
    Swal.fire({
      title: 'Excluir Meta?',
      showCancelButton: true,
      confirmButtonColor: '#41B6E6',
      cancelButtonColor: '#df4747',
      confirmButtonText: 'Confirmar!'
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.metasService.deletar(this.meta.sr_id).subscribe(carac => {
          this.loading = false;
          this.alertService.success('Meta excluída com sucesso!');
          this.meta = null;
          this.obterMetasDoCliente();
        }, err => {
          this.alertService.error('Erro ao excluir meta', err.mensagem ?? '');
        });
      }
    });
  }

  downloadCsv(): void {
    this.disabled = true;
    this.exportandoMeta = true;

    this.metasService.getCsv(this.meta.sr_id).subscribe(
      (data: Blob) => {
        const url = window.URL.createObjectURL(data);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${this.meta.nome}.csv`;
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }, 100);

        this.exportandoMeta = false;
        this.disabled = false;
      },
      (error) => {
        this.disabled = false;
        this.alertService.error('Erro ao exportar a meta para CSV');
      }
    );
}

  cadastrar(): void {
    this.router.navigate(['hidrometer', 'metas', 'cadastrar']);
  }

  verificarPermissao(permissoesFuncionalidades: string[]): boolean {
    const permissaoUsuario = localStorage.getItem('permissoes');
    return VerificarPermissoes.temPermissao(
      permissoesFuncionalidades,
      permissaoUsuario
    );
  }

  carregarBarraDeProgresso(): void{
    this.progressoAteOMomentoDaBarraDeProgresso = (this.meta.graficoProduzido.produtividadeTotal / this.meta.metas.total) * 100;
    this.progressoTotalDaBarraDeProgresso = this.meta.metas.total;
    this.progressoPercent = Math.min(100, Math.max(0, this.progressoAteOMomentoDaBarraDeProgresso));
  }
}
