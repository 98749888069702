<div class="itens-menu" fxLayout="column">

    <div fxLayout="row">
        <img class="imagem-legenda-mapa" src="/assets/icone-motobomba/motobomba-alerta-funcionando-sem-sinal.png">
        <img class="imagem-legenda-mapa" src="/assets/icones-carretel/carretel-alerta-funcionando-sem-sinal.png">
        <span class="texto-legenda-mapa"> Equipamento trabalhando, COM alerta e SEM conexão satelital</span>
    </div>
    <div fxLayout="row" class="margin-msg">
        <img class="imagem-legenda-mapa" src="/assets/icone-motobomba/motobomba-alerta-funcionando-sinal.png">
        <img class="imagem-legenda-mapa" src="/assets/icones-carretel/carretel-alerta-funcionando-sinal.png">
        <span class="texto-legenda-mapa"> Equipamento trabalhando, COM alerta e COM conexão satelital</span>
    </div>
    <div fxLayout="row" class="margin-msg">
        <img class="imagem-legenda-mapa" src="/assets/icone-motobomba/motobomba-alerta-parado-sem-sinal.png">
        <img class="imagem-legenda-mapa" src="/assets/icones-carretel/carretel-alerta-parado-sem-sinal.png">
        <span class="texto-legenda-mapa"> Equipamento parado, COM alerta e SEM conexão satelital</span>
    </div>
    <div fxLayout="row" class="margin-msg">
        <img class="imagem-legenda-mapa" src="/assets/icone-motobomba/motobomba-alerta-parado-sinal.png">
        <img class="imagem-legenda-mapa" src="/assets/icones-carretel/carretel-alerta-parado-sinal.png">
        <span class="texto-legenda-mapa"> Equipamento parado, COM alerta e COM conexão satelital</span>
    </div>
    <div fxLayout="row" class="margin-msg">
        <img class="imagem-legenda-mapa" src="/assets/icone-motobomba/motobomba-funcionando-sem-sinal.png">
        <img class="imagem-legenda-mapa" src="/assets/icones-carretel/carretel-funcionando-sem-sinal.png">
        <span class="texto-legenda-mapa"> Equipamento trabalhando e SEM conexão satelital</span>
    </div>
    <div fxLayout="row" class="margin-msg">
        <img class="imagem-legenda-mapa" src="/assets/icone-motobomba/motobomba-funcionando-sinal.png">
        <img class="imagem-legenda-mapa" src="/assets/icones-carretel/carretel-funcionando-sinal.png">
        <span class="texto-legenda-mapa"> Equipamento trabalhando e COM conexão satelital</span>
    </div>
    <div fxLayout="row" class="margin-msg">
        <img class="imagem-legenda-mapa" src="/assets/icone-motobomba/motobomba-parado-sem-sinal.png">
        <img class="imagem-legenda-mapa" src="/assets/icones-carretel/carretel-parado-sem-sinal.png">
        <span class="texto-legenda-mapa"> Equipamento parado e SEM conexão satelital</span>
    </div>
    <div fxLayout="row" class="margin-msg">
        <img class="imagem-legenda-mapa" src="/assets/icone-motobomba/motobomba-parado-sinal.png">
        <img class="imagem-legenda-mapa" src="/assets/icones-carretel/carretel-parado-sinal.png">
        <span class="texto-legenda-mapa"> Equipamento parado e COM conexão satelital</span>
    </div>

    <div fxLayout="column" class="margin-msg">
        <STRONG class="titulo">Como sei que o equipamento está trabalhando?</STRONG>
        <span class="margin-msg2 ">
            <STRONG class="titulo">Carretel:</STRONG> <span class="texto-legenda-mapa">Verificamos se o equipamento
                mandou uma mensagem
                nos últimos 16 minutos,
                caso ele tenha mandado significa que está trabalhando senão ele estará parado.</span>
        </span>
        <span>
            <STRONG class="titulo">Motobomba:</STRONG> <span class="texto-legenda-mapa">
                Verificamos se a última mensagem enviada pelo equipamento possui RPM
                (rotação por minuto), caso o RPM for diferente de 0 significa que o equipamento
                está trabalhando senão ele estará parado.
            </span>
        </span>
    </div>

    <div fxLayout="column" class="margin-msg">
        <STRONG class="titulo">Qual a importância da comunicação satelital?</STRONG>
        <span class="margin-msg2  texto-legenda-mapa">
            Para que a plataforma consiga mostrar os dados em tempo real, as informações geradas
            no campo são enviadas por uma antena satelital, porém caso a antena não esteja com
            sinal as informações não chegam a plataforma, por isso é de extrema importância que
            o comunicador esteja com sinal.
        </span>
    </div>
</div>