<main *ngIf="carregado" class="container mt-4" fxLayout="column" fxLayoutAlign="top center">
  <div class="d-flex">
    <h1>Editar operação</h1>
  </div>
  <div class="row">
    <form [formGroup]="operationForm" fxFlexOffset="5%" fxLayout="column" fxLayoutAlign="space-evenly center"
      fxShow.gt-xs fxHide.xs>
      <div class="form-row">
        <mat-form-field color="primary" appearance="outline" fxFlex>
          <mat-label>Cliente*</mat-label>
          <mat-select formControlName="clientId" [disabled]="varificaEditar">
            <mat-option *ngFor="let client of clients" required [value]="client.id">
              {{ client.nome }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="operationForm.get('clientId')?.getError('required')">
            Cliente é <strong>obrigatório</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex fxFlexOffset="1.5rem">
          <mat-label>No. Serviço</mat-label>
          <label>
            <input matInput formControlName="serviceId" autocomplete="off" maxlength="255" required />
          </label>
          <mat-error *ngIf="operationForm.get('serviceId')?.getError('required')">
            No. Serviço é <strong>obrigatório</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-row">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Estado</mat-label>
          <mat-select formControlName="state" required>
            <mat-option value="RUNNING">Operando</mat-option>
            <mat-option value="PAUSED">Parado</mat-option>
            <mat-option value="COMPLETED">Completado</mat-option>
            <mat-option value="CANCELED">Cancelado</mat-option>
            <mat-option value="NEW">Novo</mat-option>
          </mat-select>
          <mat-error *ngIf="operationForm.get('state')?.getError('required')">
            Estado é <strong>obrigatório</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlexOffset="1.5rem" fxFlex>
          <mat-label>Trator</mat-label>
          <mat-select formControlName="tractorId">
            <mat-optgroup label="Disponiveis">
              <mat-option *ngFor="let tractor of equipamentos.tractors.disponiveis" [value]="tractor.sr_id">
                Frota: {{ tractor.vc_frota }}

              </mat-option>
            </mat-optgroup>
            <mat-optgroup label="Vinculados à uma operação">
              <mat-option *ngFor="let tractor of equipamentos.tractors.operando" [value]="tractor.sr_id" [disabled]="true">
                Frota: {{ tractor.vc_frota }}
              </mat-option>
            </mat-optgroup>
            <mat-optgroup label="Sem meios de comunicação vinculados">
              <mat-option *ngFor="let tractor of equipamentos.tractors.semComunicador" [value]="tractor.sr_id" [disabled]="true">
                Frota: {{ tractor.vc_frota }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
          <mat-error *ngIf="operationForm.get('tractorId')!.getError('required')">
            Trator é <strong>obrigatório</strong>
          </mat-error>
          <mat-error *ngIf="operationForm.get('tractorId')?.getError('required')">
            Estado é <strong>obrigatório</strong>
          </mat-error>
        </mat-form-field>


      </div>
      <div class="form-row">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Motobomba</mat-label>
                  <mat-select formControlName="motorPumpId" multiple>
                      <mat-optgroup label="Disponiveis">
                        <mat-option *ngFor="let motorPump of equipamentos.motorpump.disponiveis" [value]="motorPump.sr_id">
                            Frota: {{ motorPump.vc_frota }}
                        </mat-option>
                      </mat-optgroup>
                      <mat-optgroup label="Vinculados à uma operação">
                        <mat-option *ngFor="let motorPump of equipamentos.motorpump.operando" [value]="motorPump.sr_id" [disabled]="habilitarEquipamentosRelacionadosAOperacao(motorPump.sr_id, 'motobomba')">
                            Frota: {{ motorPump.vc_frota }}
                        </mat-option>
                      </mat-optgroup>
                      <mat-optgroup label="Sem meios de comunicação vinculados">
                        <mat-option *ngFor="let motorPump of equipamentos.motorpump.semComunicador" [value]="motorPump.sr_id" [disabled]="true">
                            Frota: {{ motorPump.vc_frota }}
                        </mat-option>
                      </mat-optgroup>
                  </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlexOffset="1.5rem" fxFlex>
          <mat-label>Carretéis</mat-label>
          <mat-select formControlName="reels" multiple>
            <mat-optgroup label="Disponiveis">
              <mat-option *ngFor="let reel of equipamentos.reel.disponiveis" [value]="reel.sr_id">
                  Frota: {{ reel.vc_frota }}
              </mat-option>
            </mat-optgroup>
            <mat-optgroup label="Vinculados à uma operação">
              <mat-option *ngFor="let reel of equipamentos.reel.operando" [value]="reel.sr_id" [disabled]="habilitarEquipamentosRelacionadosAOperacao(reel.sr_id, 'carretel')">
                  Frota: {{ reel.vc_frota }}
              </mat-option>
            </mat-optgroup>
            <mat-optgroup label="Sem meios de comunicação vinculados">
              <mat-option *ngFor="let reel of equipamentos.reel.semComunicador" [value]="reel.sr_id" [disabled]="true">
                  Frota: {{ reel.vc_frota }}
              </mat-option>
            </mat-optgroup>
        </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center">
        <div class="d-flex">
          <h3>Características adicionais</h3>
          <div class="dropdown">
            <clr-icon shape="info-standard" class="info-icone"></clr-icon>
            <div class="dropdown-content">
              <p>Todas as características criadas pelo cliente para vincular o equipamento.</p>
            </div>
          </div>
        </div>
      </div>
      <div formArrayName="ar_caracteristicas" class="form-row">
        <table mat-table [dataSource]="caracteristicas" matSort #sort="matSort">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let element">
              {{ element.idCaracteristica }}
            </td>
          </ng-container>

          <ng-container matColumnDef="caracteristica">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Característica </th>
            <td mat-cell *matCellDef="let element">
              {{ element.carac ? element.carac : '' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-form-field [formGroupName]="i" class="input" appearance="outline" color="primary">
                <input matInput [type]=element.input formControlName="valor" [value]="element.valor" />
              </mat-form-field>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <div class="form-row_botoes" fxLayout="row" fxLayoutAlign="center" fxFlexOffset="3%">
        <button mat-raised-button class="botao-criar" (click)="makeOperation()"
          [disabled]="this.editarDesabilitado || this.operationForm.invalid">
          Gravar
        </button>
        <button mat-raised-button class="botao-excluir" type="reset" fxFlexOffset="10px" (click)="cancel()">
          Cancelar
        </button>
      </div>
    </form>
  </div>
</main>
