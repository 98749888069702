import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL_HIDROMETER_RELATORIOS } from '../app.constants';
import { HttpOptionsService } from './header-options.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportacaoService {

  readonly BASE_URL_HIDROMETER_RELATORIOS = BASE_URL_HIDROMETER_RELATORIOS;

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  public adicionarGeojsonParaFila(dadosExportar: any) {
    const url = `${this.BASE_URL_HIDROMETER_RELATORIOS}adicionar-fila-geojson`;
    return this.http.post(url, dadosExportar, this.httpOptionsService.getHttpOptions());
  }

  public adicionarRepintarFaixasParaFila(dadosExportar: any): Observable<object> {
    const url = `${this.BASE_URL_HIDROMETER_RELATORIOS}adicionar-fila-repintar-faixas`;
    return this.http.post(url, dadosExportar, this.httpOptionsService.getHttpOptions());
  }
}
