import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BASE_URL_PREMOCENTER, BASE_URL_PREMOCENTER_FRONT } from '../app.constants';
import * as jwt_decode from 'jwt-decode';
import { HttpOptionsService } from './header-options.service';

@Injectable({
  providedIn: 'root'
})
export class AlertaSessaoService {

  sessaoExpirada = false
    readonly BASE_URL_PREMOCENTER = BASE_URL_PREMOCENTER;
    premocenter = BASE_URL_PREMOCENTER_FRONT
    redirecionar!: Subject<boolean>;
    fimDaSessao!: Subject<boolean>;
    sessaoEncerrada!: Subject<boolean>;
    setInterval: any
  

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService) {
    this.fimDaSessao = new Subject();
    this.sessaoEncerrada = new Subject();
   }


  alertaFimSessao() {
    return this.fimDaSessao.asObservable();
  }
  alertaSessaoEncerrada(){
    return this.sessaoEncerrada.asObservable();
  }

  redirecionarPremocenter(){
    return this.redirecionar.asObservable();
  }

  alertaSessaoService(){
    clearInterval(this.setInterval)
    this.setInterval = setInterval(() => {
    var token = localStorage.getItem('token')
    const decoded: any = jwt_decode.default(token!);
    const dataExpiraToken = new Date(0);
    dataExpiraToken.setUTCSeconds(decoded.exp);
    var hours = "0" +dataExpiraToken.getHours();
    var minutes = "0" + dataExpiraToken.getMinutes();
    var seconds = "0" + dataExpiraToken.getSeconds();
    var formattedTime = hours.substr(-2) + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    localStorage.setItem('expira_token', formattedTime)
      var dataAtual = new Date(); //data corrente
      var dataExibirMensagem = dataExpiraToken.valueOf() - (300*1000) // data que começa exibir mensagem do final da sessao
      var dataReconectar = dataExpiraToken.valueOf() + (180*1000) // data para redirecionar para Premocenter
      if(dataAtual.valueOf() >= dataExibirMensagem && dataAtual.valueOf() <= dataExpiraToken.valueOf()){
         this.sessaoExpirada = true;
         this.fimDaSessao.next(this.sessaoExpirada)
      } else if(dataAtual.valueOf() <= dataReconectar && dataAtual.valueOf() >= dataExpiraToken.valueOf()){
        this.sessaoExpirada = true
        this.sessaoEncerrada.next(this.sessaoExpirada)
      } else if(dataAtual.valueOf() >= dataReconectar){
        window.location.href = `${this.premocenter}`
      } else {
        this.sessaoExpirada = false;
         this.fimDaSessao.next(this.sessaoExpirada)
         this.sessaoEncerrada.next(this.sessaoExpirada)
      }
    }, 10000)
  }

  renovarToken(token:any){
    var url = `${this.BASE_URL_PREMOCENTER}refresh`
    return this.http.post(url, token, this.httpOptionsService.getHttpOptions())
  }
}
