<div fxLayout="column" fxLayoutAlign="center center">
          <div *ngIf="loading && !erroComunicador" class="spinner"></div>
</div>

<form *ngIf="!loading && !erroComunicador" [formGroup]="formFilter" fxFlex.lt-md="100" fxLayout="column"
    fxLayoutAlign="center center">
    <h1>Dados da mensagem</h1>
    <label> Tipo da mensagem:
        <select class="filtro-listas" type="number" formControlName="tipo" (change)="defineValidator()">
            <option value="basica">
                Operação remota básica da bomba
            </option>
            <option *ngIf="statusMotobomba == 2" value="controleRpm">
                Operação remota da bomba (controle velocidade)
            </option>
            <option *ngIf="statusMotobomba == 2" value="controlePressao">
                Operação remota da bomba (controle pressão)
            </option>
            <option *ngIf="statusMotobomba == 3" value="controleRpm">
                Alteração de velocidade
            </option>
            <option *ngIf="statusMotobomba == 4" value="controlePressao">
                Alteração de pressão
            </option>
        </select>
    </label>
    <label *ngIf="tipo =='controleRpm'" style="margin-top: 1rem;"> Defina o setpoint de velocidade
        <input type="number" formControlName="valor" class="input-padrao">
        <ng-container *ngIf="formFilter.controls.valor.errors">
            <div *ngIf="formFilter.controls.valor.errors.max" class="text-danger" >Valor máximo {{formFilter.controls.valor.errors.max.max}}</div>
            <div *ngIf="formFilter.controls.valor.errors.min" class="text-danger">Valor mínimo {{formFilter.controls.valor.errors.min.min}}</div>
        </ng-container>
    </label>
    <label *ngIf="tipo =='controlePressao'" style="margin-top: 1rem;"> Defina o setpoint de pressão
        <input type="number" formControlName="valor" class="input-padrao">
        <ng-container *ngIf="formFilter.controls.valor.errors">
            <div *ngIf="formFilter.controls.valor.errors.max" class="text-danger">Valor máximo
                {{formFilter.controls.valor.errors.max.max}}</div>
            <div *ngIf="formFilter.controls.valor.errors.min" class="text-danger">Valor mínimo
                {{formFilter.controls.valor.errors.min.min}}</div>
        </ng-container>
    </label>
    <label *ngIf="tipo=='basica'" style="margin-top: 1rem;"> Defina o codigo da operação
        <select class="filtro-listas" type="number" formControlName="valor">
            
            <option *ngIf="statusMotobomba == 1" [value]="1">
                Dar partida/Ligar
            </option>
            <option *ngIf="statusMotobomba != 1" [value]="2">
                Afogar/Desligar
            </option>
            <option *ngIf="statusMotobomba == 3 || statusMotobomba == 4" [value]="3">
                Passar para marcha lenta / Passar para baixa rotação
            </option>
        </select>
    </label>
    <div class="form-row" fxLayout="row" fxLayoutAlign="center center" fxFlexOffset="3%">
        <button mat-raised-button class="botao-criar" type="submit" [disabled]="!formFilter.valid" (click)="enviar()">
            Enviar
        </button>
        <button mat-raised-button type="reset" fxFlexOffset="10px" (click)="cancel()" class="botao-excluir">
            Cancelar
        </button>
    </div>
</form>

<div *ngIf="erroComunicador" fxLayout="column" fxLayoutAlign="center center">
    <h2>
        A motobomba não possui um comunicador vinculado 😔
    </h2>
    <h3>
        Sendo assim, não conseguiremos enviar uma mensagem para ela.
    </h3>
    <h3>Para solucionar o problema vincule um comunicador ao equipamento</h3>
    <button mat-raised-button type="reset" fxFlexOffset="10px" (click)="cancel()" class="botao-excluir">
        Fechar
    </button>
</div>
