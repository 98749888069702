<main class="container h-100 p-2">
    <div class="d-flex justify-content-center">
        <h1>Editar equipamento</h1>
        <div class="dropdown">
            <clr-icon shape="info-standard" class="info-icone"></clr-icon>
            <div class="dropdown-content">
                <p> Durante o processo de edição, é de extrema importância vincular o equipamento a um modem
                    previamente registrado e atribuir a ele um número de frota exclusivo. É aconselhável evitar a
                    duplicação de números de frota entre os equipamentos cadastrados, uma vez que esse número
                    desempenhará um papel crucial nos gráficos e filtros da aplicação.</p>
            </div>
        </div>
    </div>
    <div class="row">
        <form [formGroup]="equipmentForm" class="form w-100">
            <div class="col-12">
              <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Cliente </mat-label>
                  <mat-select formControlName="client" required [disabled]="validaEditar">
                      <mat-option *ngFor="let client of clients" [value]="client.id" id="clientSelection">{{
                          client.nome }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="equipmentForm.get('client')?.getError('required')">
                      Tipo é <strong>obrigatório</strong>
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="outline" class="col-6">
                <mat-label>No. Frota</mat-label>
                <input matInput formControlName="fleet" autocomplete="off" required />
                <mat-error *ngIf="equipmentForm.get('fleet')?.getError('required')">
                    No. Frota é <strong>obrigatório</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-6">
                  <mat-label>Tipo</mat-label>
                  <mat-select formControlName="type" required [disabled]="validaEditar" id="tipoEquipamento">
                      <mat-option value="TRACTOR">Trator</mat-option>
                      <mat-option value="MOTOR_PUMP">Motobomba</mat-option>
                      <mat-option value="REEL">Carretel</mat-option>
                  </mat-select>
                  <mat-error *ngIf="equipmentForm.get('type')?.getError('required')">
                      Tipo é <strong>obrigatório</strong>
                  </mat-error>
              </mat-form-field>
            </div>

            <div class="col-12">
              <mat-form-field appearance="outline" class="col-6">
                <mat-label>Modem</mat-label>
                <mat-select formControlName="mobileId" required>
                    <mat-option value="semModem"> Sem modem </mat-option>
                    <mat-option *ngFor="let modem of modems" [value]="modem.it_id_premocenter">
                        Id: {{ modem.sr_id }} / MobileId: {{ modem.vc_mobileId }}
                    </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-6">
                <mat-label>AGViews Connect</mat-label>
                <mat-select formControlName="agviewConnectId" required>
                    <mat-option value="semAGViewConnect"> Sem AGView Connect </mat-option>
                    <mat-option *ngFor="let agviewConnect of agviewsConnect" [value]="agviewConnect.sr_id">
                        Id: {{ agviewConnect.sr_id }} / Imei: {{ agviewConnect.vc_imei }}
                    </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="d-flex justify-content-center p-1">
                <h3>Características adicionais</h3>
                <div class="dropdown">
                    <clr-icon shape="info-standard" class="info-icone"></clr-icon>
                    <div class="dropdown-content">
                        <p>Todas as características criadas pelo cliente para vincular o equipamento. </p>
                    </div>
                </div>
            </div>
            <div formArrayName="ar_caracteristicas" class="d-flex justify-content-center p-1">
                <div class="overflow">
                    <table mat-table [dataSource]="caracteristicas" matSort #sort="matSort">
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.idCaracteristica }}
                            </td>
                        </ng-container>

                      <ng-container matColumnDef="caracteristica">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Característica </th>
                          <td mat-cell *matCellDef="let element">
                              {{ element.carac ? element.carac : '' }}
                          </td>
                      </ng-container>

                      <ng-container matColumnDef="valor">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Valor </th>
                          <td mat-cell *matCellDef="let element; let i = index">
                              <mat-form-field [formGroupName]="i" class="input" appearance="outline" color="primary">
                                  <input matInput [type]=element.input formControlName="valor"
                                      [value]="element.valor" />
                              </mat-form-field>
                          </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
              </div>
          </div>
          <div class="d-flex justify-content-center p-1 form-row_botoes">
              <button mat-raised-button class="botao-criar mr-2" type="submit" [disabled]="this.editarDesabilitado || this.equipmentForm.invalid"
                  (click)="atualizarEquipamento()">
                  Gravar
              </button>
              <button mat-raised-button class="botao-excluir" type="reset" (click)="cancel()">
                  Cancelar
              </button>
          </div>
      </form>
  </div>
</main>
