<section class="container">
    <div class="row">

        <div class="col-4 alturaModal mt-1">
            <div class="row justify-content-center text-center">

                <div id="anoModal">
                    <p style="margin-top: 5px;">{{ano}}</p>
                </div>

                <div class="w-75 p-2 mt-1" style="border-radius: 20px; background-color: rgb(3, 129, 179);">
                    <div id="horario" class="text-light">{{dataAtual | date: 'HH:mm:ss'}}</div>
                </div>

                <div id="dataModal" class="mt-3">
                    {{semanaFinal}}, {{dia}} {{mesFinal}}
                </div>
            </div>
        </div>

        <div class="col-8 alturaModal mt-1">
            <div id="tituloHorario" class="ml-4">
                Fuso Horário <img src="assets/icones-sideBar/informacao.svg" alt="" id="infoHorario" class="mb-3"
                    matTooltipPosition="after"
                    matTooltip="O Botão de Ajuste de Horário simplifica a coordenação global ao adicionar automaticamente 3 horas ao horário local (BRT) e convertê-lo para UTC em apenas um clique, eliminando a necessidade de cálculos manuais.">
            </div>
            <ng-container *ngFor="let fuso of fusos">
                <div class="mt-2" (click)="trocarHorario(fuso.id)">
                    <div class="pl-3 pt-2 pr-2 pb-2 mr-2" id="{{fuso.id}}">
                        {{fuso.nome}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>