<div class="modelo5 row m-0 px-1">
  <div class="col-6 h-100 w-100 p-1">
    <div class="borda-cards h-100">
      <ngb-carousel #carousel class="h-100" [interval]='tempoArea1' (slid)="onSlide($event)">
        <ng-template ngbSlide *ngFor="let componente of area1">
          <div class="h-100 w-100">
            <div style="width: 100%; height: 100%">
              <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
              </app-abastecimento-motobomba>
              <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
              </app-alertas-motobomba>
              <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
              </app-consumo-motobomba>
              <app-tempo-efetivo-frota-motobomba *ngIf="componente.c5" style="height: 100%; width: 100%;">
              </app-tempo-efetivo-frota-motobomba>
              <app-tempo-efetivo-os-motobomba *ngIf="componente.c6" style="height: 100%; width: 100%;">
              </app-tempo-efetivo-os-motobomba>
              <app-tempo-efetivo-turno-motobomba *ngIf="componente.c7" style="height: 100%; width: 100%;">
              </app-tempo-efetivo-turno-motobomba>
              <app-tempo-efetivo-os-carretel *ngIf="componente.c8" style="height: 100%; width: 100%;">
              </app-tempo-efetivo-os-carretel>
              <app-tempo-efetivo-turno-carretel *ngIf="componente.c9" style="height: 100%; width: 100%;">
              </app-tempo-efetivo-turno-carretel>
              <app-produtividade-os *ngIf="componente.c10" style="height: 100%; width: 100%;">
              </app-produtividade-os>
              <app-produtividade-turno *ngIf="componente.c11" style="height: 100%; width: 100%;">
              </app-produtividade-turno>
              <app-tempos-trabalhados-frota *ngIf="componente.c13"
                style="height: 100%; width: 100%;"></app-tempos-trabalhados-frota>
              <app-tempos-trabalhados *ngIf="componente.c14" style="height: 100%;"></app-tempos-trabalhados>
              <app-produtividade-carretel *ngIf="componente.c15" style="height: 100%;"></app-produtividade-carretel>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </div>
  <div class="col-6 h-100 w-100 p-1">
    <div class="row h-100 w-100">
      <div class="col-6 h-50 px-1 pb-1">
        <div class="borda-cards h-100">
          <ngb-carousel #carousel class="h-100" [interval]='tempoArea2'>
            <ng-template ngbSlide *ngFor="let componente of area2">
              <div class="h-100 w-100">
                <div style="width: 100%; height: 100%;">
                  <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                  </app-abastecimento-motobomba>
                  <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                  </app-alertas-motobomba>
                  <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                  </app-consumo-motobomba>
                  <app-tempo-efetivo-frota-motobomba [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c5"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-frota-motobomba>
                  <app-tempo-efetivo-os-motobomba [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c6"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-os-motobomba>
                  <app-tempo-efetivo-turno-motobomba [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c7"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-turno-motobomba>
                  <app-tempo-efetivo-os-carretel [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c8"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-os-carretel>
                  <app-tempo-efetivo-turno-carretel [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c9"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-turno-carretel>
                  <app-produtividade-os [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c10"
                    style="height: 100%; width: 100%;">
                  </app-produtividade-os>
                  <app-produtividade-turno [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c11"
                    style="height: 100%; width: 100%;">
                  </app-produtividade-turno>
                  <app-tempos-trabalhados-frota *ngIf="componente.c13"
                    style="height: 100%;"></app-tempos-trabalhados-frota>
                  <app-tempos-trabalhados [ocultarGrafico]="ocultarGraficoTemposTrabalho" *ngIf="componente.c14"
                    style="height: 100%;"></app-tempos-trabalhados>
                  <app-produtividade-carretel [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c15"
                    style="height: 100%;"></app-produtividade-carretel>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
      <div class="col-6 h-50 px-1 pb-1">
        <div class="borda-cards h-100">
          <ngb-carousel #carousel class="h-100" [interval]='tempoArea3' style="width: 100%;">
            <ng-template ngbSlide *ngFor="let componente of area3">
              <div class="h-100 w-100">
                <div style="width: 100%; height: 100%;">
                  <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                  </app-abastecimento-motobomba>
                  <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                  </app-alertas-motobomba>
                  <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                  </app-consumo-motobomba>
                  <app-tempo-efetivo-frota-motobomba [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c5"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-frota-motobomba>
                  <app-tempo-efetivo-os-motobomba [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c6"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-os-motobomba>
                  <app-tempo-efetivo-turno-motobomba [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c7"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-turno-motobomba>
                  <app-tempo-efetivo-os-carretel [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c8"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-os-carretel>
                  <app-tempo-efetivo-turno-carretel [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c9"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-turno-carretel>
                  <app-produtividade-os [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c10"
                    style="height: 100%; width: 100%;">
                  </app-produtividade-os>
                  <app-produtividade-turno [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c11"
                    style="height: 100%; width: 100%;">
                  </app-produtividade-turno>
                  <app-tempos-trabalhados-frota *ngIf="componente.c13"
                    style="height: 100%;"></app-tempos-trabalhados-frota>
                  <app-tempos-trabalhados [ocultarGrafico]="ocultarGraficoTemposTrabalho" *ngIf="componente.c14"
                    style="height: 100%;"></app-tempos-trabalhados>
                  <app-produtividade-carretel [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c15"
                    style="height: 100%;"></app-produtividade-carretel>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
      <div class=" col-6 h-50 px-1 pt-1">
        <div class="borda-cards h-100">
          <ngb-carousel #carousel class="h-100" [interval]='tempoArea4' style="width: 100%;">
            <ng-template ngbSlide *ngFor="let componente of area4">
              <div class="h-100 w-100">
                <div style="width: 100%; height: 100%;">
                  <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                  </app-abastecimento-motobomba>
                  <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                  </app-alertas-motobomba>
                  <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                  </app-consumo-motobomba>
                  <app-tempo-efetivo-frota-motobomba [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c5"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-frota-motobomba>
                  <app-tempo-efetivo-os-motobomba [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c6"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-os-motobomba>
                  <app-tempo-efetivo-turno-motobomba [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c7"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-turno-motobomba>
                  <app-tempo-efetivo-os-carretel [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c8"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-os-carretel>
                  <app-tempo-efetivo-turno-carretel [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c9"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-turno-carretel>
                  <app-produtividade-os [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c10"
                    style="height: 100%; width: 100%;">
                  </app-produtividade-os>
                  <app-produtividade-turno [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c11"
                    style="height: 100%; width: 100%;">
                  </app-produtividade-turno>
                  <app-tempos-trabalhados-frota *ngIf="componente.c13"
                    style="height: 100%;"></app-tempos-trabalhados-frota>
                  <app-tempos-trabalhados [ocultarGrafico]="ocultarGraficoTemposTrabalho" *ngIf="componente.c14"
                    style="height: 100%;"></app-tempos-trabalhados>
                  <app-produtividade-carretel *ngIf="componente.c15" [ocultarGrafico]="ocultarGrafico"
                    style="height: 100%;"></app-produtividade-carretel>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
      <div class=" col-6 h-50 px-1 pt-1">
        <div class="borda-cards h-100">
          <ngb-carousel #carousel class="h-100" [interval]='tempoArea5'>
            <ng-template ngbSlide *ngFor="let componente of area5">
              <div class="h-100 w-100">
                <div style="width: 100%;  height: 100%;">
                  <app-abastecimento-motobomba style="height: 100%; width: 100%" *ngIf="componente.c2">
                  </app-abastecimento-motobomba>
                  <app-alertas-motobomba *ngIf="componente.c3" style="height: 100%; width: 100%;">
                  </app-alertas-motobomba>
                  <app-consumo-motobomba *ngIf="componente.c4" style="height: 100%; width: 100%;">
                  </app-consumo-motobomba>
                  <app-tempo-efetivo-frota-motobomba [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c5"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-frota-motobomba>
                  <app-tempo-efetivo-os-motobomba [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c6"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-os-motobomba>
                  <app-tempo-efetivo-turno-motobomba [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c7"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-turno-motobomba>
                  <app-tempo-efetivo-os-carretel [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c8"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-os-carretel>
                  <app-tempo-efetivo-turno-carretel [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c9"
                    style="height: 100%; width: 100%;">
                  </app-tempo-efetivo-turno-carretel>
                  <app-produtividade-os [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c10"
                    style="height: 100%; width: 100%;">
                  </app-produtividade-os>
                  <app-produtividade-turno [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c11"
                    style="height: 100%; width: 100%;">
                  </app-produtividade-turno>
                  <app-tempos-trabalhados-frota *ngIf="componente.c13"
                    style="height: 100%;"></app-tempos-trabalhados-frota>
                  <app-tempos-trabalhados [ocultarGrafico]="ocultarGraficoTemposTrabalho" *ngIf="componente.c14"
                    style="height: 100%;"></app-tempos-trabalhados>
                  <app-produtividade-carretel [ocultarGrafico]="ocultarGrafico" *ngIf="componente.c15"
                    style="height: 100%;"></app-produtividade-carretel>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
      </div>
    </div>

  </div>
</div>
