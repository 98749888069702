import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators } from '@angular/forms';
import { WhatsappService } from 'src/app/service/whatsapp.service';

declare var gtag: any;

@Component({
  selector: 'app-dialog-whatsapp',
  templateUrl: './dialog-whatsapp.component.html',
  styleUrls: ['./dialog-whatsapp.component.css']
})
export class DialogWhatsappComponent implements OnInit {
  formWhatsapp!: FormGroup;
  validacao: boolean | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DialogWhatsappComponent>,
    private whatsappService: WhatsappService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
  }

  initForm(): void {
    this.formWhatsapp = this.formBuilder.group({
      sr_id: [localStorage.getItem('usuarioId') ?? localStorage.getItem('usuario'), [Validators.required]],
      vc_telefone: [null, [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(13), Validators.maxLength(13)]],
    })
  }

  fecharDialog(): void {
    this.dialogRef.close();
  }

  resetaVerificacao(): void {
    if (this.validacao != null) {
      this.validacao = null;
    }
  }

  inputWhatsapp(): void {
    if(this.validacao != true){
      gtag('event', 'formulario_whatsapp', {
        'usuario_email': localStorage.getItem('usuario_email'),
      })
  
      if (this.formWhatsapp.valid) {
        this.whatsappService.atualizar(this.formWhatsapp.value).subscribe(() => {
          this.validacao = true;
          this.timerValidacao();
        }, (erro: any) => {
          this.validacao = false;
          this.timerValidacao();
        })
  
      } else {
        this.validacao = false;
        this.timerValidacao();
      }
    }
  }

  timerValidacao(): void {
    setTimeout(() => {
      if(this.validacao) {
        this.dialogRef.close();
      } else {
        this.validacao = null;
      }
    }, 3000);
  }
}
