<div class="conteudo">
    <div class="row mt-3">
        <div class="col-md-1 coluna-botao-select">
            <mat-label (click)="abrirSelectMensagens()" class="botao-filtro-tipo" matBadge="{{qtdFiltro}}"
                matBadgeOverlap="true" matBadgeSize="small" matBadgeColor="warn" [matBadgeHidden]="qtdFiltro == 0">
                <img src="assets/icones-botoes-acao-mapa/filtros.svg">
            </mat-label>
            <mat-select multiple #selectEquip color="secondary" class="select-equip">
                <mat-option color="primary" *ngFor="let item of optionsFiltroAlertas" [value]="item.valor"
                    (click)="enviarFiltroEquipamento(selectEquip.value); atividadeFiltroMensagem()">
                    <img [src]="item.imagem">
                    <span class="ml-2">{{item.titulo}}</span>
                </mat-option>
            </mat-select>
        </div>
        <div class="col-md-11 coluna-titulo">
            <strong class="titulo">
                Mensagens em tempo real
            </strong>
        </div>
    </div>

    <div class="d-flex justify-content-center mt-3">
        <div class="ml-3 mr-3">
            <button (click)="enviarFiltroEquipamento('Motobomba')" matTooltip="Motobomba">
                <img id="imagem-motobomba"
                    [src]="imagemClicada == 'Motobomba' ? 'assets/icones-botoes-acao-mapa/motobombaAzul.svg' : 'assets/icones-botoes-acao-mapa/motobomba.svg'">
            </button>
        </div>
        <div class="ml-3 mr-3">
            <button (click)="enviarFiltroEquipamento('Carretel')" matTooltip="Carretel">
                <img id="imagem-carretel"
                    [src]="imagemClicada == 'Carretel' ? 'assets/icones-botoes-acao-mapa/carretelAzul.svg' : 'assets/icones-botoes-acao-mapa/carretel.svg'">
            </button>
        </div>
        <div class="ml-3 mr-3">
            <button (click)="enviarFiltroEquipamento('Trator')" matTooltip="Trator">
                <img id="imagem-trator"
                    [src]="imagemClicada == 'Trator' ? 'assets/icones-botoes-acao-mapa/tratorAzul.svg' : 'assets/icones-botoes-acao-mapa/trator.svg'">
            </button>
        </div>
    </div>

    <div class="row mt-3" *ngIf="mensagensExibidas.length == 0">
        <div id="tratativa-zero-mensagens" class="text-center" *ngIf="loader == false">
            Não existem mensagens até o momento.
        </div>
        <div id="loader-mensagens" class="d-flex justify-content-center align-items-center" *ngIf="loader">
            <app-loader></app-loader>
        </div>
    </div>

    <div *ngIf="mensagensExibidas.length != 0" id="corpo-notificacoes" class="mt-3 modal-mensagens flex-grow-1">
        <div *ngFor="let item of mensagensExibidas; let i = index;" id="mensagens-exibidas" class="row mt-2">
            <div class="col-1">
                <div mat-card-avatar class="img-avatar mt-3"></div>
            </div>
            <div class="col-11"
                [ngClass]="{'relatorio' : item.tipo== 'RELATORIO','sucesso' : item.tipo == 'SUCESSO' ,'nao-justificada' : item.tipo == 'PARADA NÃO JUSTIFICADA', 'parada' : item.tipo == 'PARADA', 'erro' : item.tipo == 'ERRO', 'comando' : item.tipo == 'COMANDO', 'alerta' : item.tipo == 'ALERTA'}">
                <div class="row usina-card">
                    {{item.usina}}
                </div>
                <div class="top-dialogo">
                    <div class="left-dialogo">
                    </div>
                </div>
                <mat-card class="mb-3 corpo-mensagem">
                    <div class="row">
                        <div class="col-2 icone-img">
                            <div id="img-icone" class="p-0 m-0 w-100">
                                <img *ngIf="item.tipo == 'ERRO'" src="assets/icones-botoes-acao-mapa/alertaErro.svg"
                                    class="w-100">
                                <img *ngIf="item.tipo == 'PARADA NÃO JUSTIFICADA'"
                                    src="assets/icones-botoes-acao-mapa/alertaNaoJustificado.svg" class="w-100">
                                <img *ngIf="item.tipo == 'PARADA'" src="assets/icones-botoes-acao-mapa/alertaParado.svg"
                                    class="w-100">
                                <img *ngIf="item.tipo == 'ALERTA'" src="assets/icones-botoes-acao-mapa/alertas.svg"
                                    class="w-100">
                                <img *ngIf="item.tipo == 'COMANDO'"
                                    src="assets/icones-botoes-acao-mapa/alertaComandoRemoto.svg" class="w-100">
                                <img *ngIf="item.tipo == 'SUCESSO'" src="assets/icones-botoes-acao-mapa/sucesso.svg"
                                    class="w-100">
                                <img *ngIf="item.tipo == 'RELATORIO'" src="assets/icones-botoes-acao-mapa/relatorio.svg"
                                    class="w-100">
                            </div>
                        </div>
                        <div class="col pl-0 pt-0 pb-0 m-0">
                            <div class="row mb-2">
                                <div class="col-md-9">
                                    <span class="titulo-card">
                                        {{item.titulo}}
                                    </span>
                                </div>
                                <div class="col-3 horario-card">
                                    {{item.horario}}
                                </div>
                            </div>
                            <div class="row sub-titulo-card">
                                <span *ngIf="item.tipo != 'RELATORIO'">
                                    <img [src]="iconeTipoEquipamento(item.tipoEquipamento)" width="20px">
                                    <span class="negrito pl-1 equipamento">{{item.frotaEquipamento && item.frotaEquipamento != 'Sem frota' ? item.frotaEquipamento : 'Não encontrado'}}</span> &nbsp; | &nbsp;
                                    <img src="assets/icones-botoes-acao-mapa/comunicador.svg" width="20px">
                                    {{item.comunicador}}
                                    &nbsp;|&nbsp;
                                    OS: {{item.ordemServico ? item.ordemServico : 'Sem ordem de serviço'}}
                                </span>
                                <span *ngIf="item.tipo == 'RELATORIO'">
                                    <img src="assets/icones-botoes-acao-mapa/troca-turno.svg" width="20px">
                                    {{item.turno}} |
                                    <img src="assets/icones-botoes-acao-mapa/horario-troca.svg" width="20px">
                                    {{item.horarioTurno}}

                                </span>
                            </div>

                            <div *ngIf="item.tipo != 'RELATORIO'" class="row">
                                <div *ngFor="let item of item.informacoes" class="informacoes-card">
                                    <span class="negrito">{{item.titulo}}</span> {{item.valor}}
                                </div>
                            </div>

                            <div *ngIf="item.tipo == 'RELATORIO'" class="row">
                                <div *ngFor="let item of item.informacoes" class="col">
                                    <div class="informacoes-card-relatorio text-nowrap mt-2">
                                        {{item.titulo}}
                                    </div>
                                    <div class="informacoes-card-relatorio-valor pt-1">
                                        {{item.valor}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="item.badge == true" class="mt-1">
                        <div class="d-inline">
                            <span *ngFor="let item of item.informacaoesBadge" class="badge mt-1">
                                {{item}}
                            </span>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>

    </div>
</div>
